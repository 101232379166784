"use strict";
exports.__esModule = true;
exports.validateAge = void 0;
var date_helper_1 = require("./date-helper");
var getAge = function (dob) {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};
var validateAge = function (birthDateString, format, max) {
    var birthDate = new Date(birthDateString);
    if (format === "DD/MM/YYYY")
        birthDate = date_helper_1.convertStringToDate(birthDateString);
    var age = getAge(birthDate);
    if (max) {
        if (age >= 100) {
            return false;
        }
        else {
            return true;
        }
    }
    else {
        if (age <= 18) {
            return false;
        }
        else {
            return true;
        }
    }
};
exports.validateAge = validateAge;
