import { Circle, TableElement } from "@renewal/rosie";
import { TFunction } from "i18next";
import React from "react";

import { IQuote } from "../../../../__generated__/graphql-types";

function priceElements(t: TFunction, quote: IQuote): TableElement[] {
  return [
    {
      label: t("quote-retail-price-label"),
      value: t("shared:currency", {
        value: quote.retailCashPrice
      }),
      tooltip: t("quote-retail-price-tooltip"),
      trackingFunction: "tooltipRetailCash"
    },
    {
      label: t("quote-purchase-fee-label"),
      value: t("shared:currency", {
        value: quote.optionToPurchaseFee
      }),
      tooltip: t("quote-purchase-fee-tooltip"),
      trackingFunction: "tooltipOptionToPurchase"
    }
  ];
}

function termElements(t: TFunction, quote: IQuote): TableElement[] {
  return [
    {
      label: t("quote-term-label"),
      value: t("shared:duration", { months: quote.term }),
      tooltip: t("quote-term-tooltip"),
      trackingFunction: "tooltipDuration"
    },
    {
      label: t("quote-interest-rate-label"),
      value: t("quote-interest-rate-format", {
        rate: quote.annualPercentageRate
      })
    }
  ];
}

function startElements(t: TFunction, quote: IQuote): TableElement[] {
  return [
    {
      label: t("quote-additional-deposit-label"),
      value: t("shared:currency", {
        value: quote.additionalDeposit ? quote.additionalDeposit : 0
      }),
      tooltip: t("quote-additional-deposit-tooltip"),
      trackingFunction: "tooltipAdditionalDepositCovered",
      circles: [<Circle className="additional-deposit-color" />],
      hideIf: !quote.additionalDeposit || quote.additionalDeposit <= 0
    }
  ];
}

function contractElements(t: TFunction, quote: IQuote): TableElement[] {
  return [
    {
      label: t("quote-first-payment-label"),
      value: t("shared:currency", {
        value: quote.firstPayment
      })
    },
    {
      label: t("quote-payments-label", { count: quote.term }),
      value: t("shared:currency", {
        value: quote.installment
      }),
      tooltip: t("quote-payments-tooltip"),
      trackingFunction: "tooltipMonthlyPayments",
      circles: [<Circle className="monthly-payment-color" />]
    },
    {
      label: t("quote-bonus-payment-label"),
      value: t("shared:currency", {
        value: quote.bonusPayment ? quote.bonusPayment : 0
      }),
      tooltip: t("quote-bonus-payment-tooltip"),
      trackingFunction: "tooltipBonusPayment",
      circles: [<Circle className="bonus-payment-color" />]
    }
  ];
}

function endElements(t: TFunction, quote: IQuote): TableElement[] {
  return [
    {
      label: t("quote-final-payment-label"),
      value: t("shared:currency", {
        value: quote.finalPayment
      }),
      tooltip: t("quote-final-payment-tooltip"),
      trackingFunction: "tooltipOptFinalPayment",
      circles: [<Circle className="optional-final-payment-color" />]
    }
  ];
}

function amountElements(t: TFunction, quote: IQuote): TableElement[] {
  return [
    {
      label: t("quote-total-amount-label"),
      value: t("shared:currency", {
        value: quote.totalCredit
      }),
      tooltip: t("quote-total-amount-tooltip"),
      trackingFunction: "tooltipTotalCredit"
    }
  ];
}

export function TableElements(
  t: TFunction,
  quote: IQuote
): {
  priceElements: TableElement[];
  termElements: TableElement[];
  startElements: TableElement[];
  contractElements: TableElement[];
  endElements: TableElement[];
  amountElements: TableElement[];
} {
  return {
    priceElements: priceElements(t, quote),
    termElements: termElements(t, quote),
    startElements: startElements(t, quote),
    contractElements: contractElements(t, quote),
    endElements: endElements(t, quote),
    amountElements: amountElements(t, quote)
  };
}
