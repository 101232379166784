import {
  Flipper,
  TileFrontSavedOffer,
  placeholderImg,
  placeholderNode
} from "@renewal/rosie";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  IFinancePlan,
  IVehicleOffer
} from "../../../__generated__/graphql-types";
import { useCurrentContractId } from "../../../App.state";
import { RouterHelpers } from "../../../router/router-helpers";
import { useOfferOperations } from "../../helpers/saved-offers";
import {
  convertStringToDateJP,
  getFinancePlanCmsKey,
  mapToTypeOfMatch
} from "../../helpers/utility";
import { LearnMoreAboutFinancePlanTrigger } from "../LearnMoreAboutFinancePlan/LearnMoreAboutFinancePlan";
import VehicleContractTileBackContainer from "./TileBack/VehicleContractTileBackContainer";

const financePlanTranslationKey = (contractType: IFinancePlan): string => {
  return `shared:finance-plan.${getFinancePlanCmsKey(contractType)}`;
};
interface OwnProps {
  wasRemoved: boolean;
  isActive: boolean;
  match: IVehicleOffer;
  isSubmittedCar?: boolean;
  contractNumber: string;
  preferredInstallment: number;
  selectedFinancePlan: IFinancePlan;
}
const Container: React.FunctionComponent<OwnProps> = ({
  match,
  isSubmittedCar,
  wasRemoved,
  isActive,
  preferredInstallment,
  selectedFinancePlan
}) => {
  const { t } = useTranslation("vehicle-tile-component");
  const { currentContractId } = useCurrentContractId();
  const { saveOffer, deleteOffer } = useOfferOperations(currentContractId);

  const dispatchSaveOffer = () => {
    saveOffer(match, true).catch(() => {
      deleteOffer(match, true, false);
      toast.error(t("save-offer-toast-error"));
    });
  };

  const dispatchDeleteOffer = () => {
    deleteOffer(match, true).catch(() => {
      saveOffer(match, true, false);
      toast.error(t("delete-offer-toast-error"));
    });
  };

  return (
    <Flipper>
      <TileFrontSavedOffer
        t={t}
        expirationDate={convertStringToDateJP(match.expirationTime)}
        contractType={t(financePlanTranslationKey(selectedFinancePlan))}
        carModel={match.vehicle.carModel}
        carModelVariant={match.vehicle.carModelVariant}
        paymentDescriptionTwo={`${t("shared:term", {
          months: match.quote.term
        })} ${t("shared:apr", {
          interest: match.quote.annualPercentageRate
        })}`}
        installment={match.quote.installment}
        typeOfMatch={mapToTypeOfMatch(match.typeOfMatch)}
        images={match.vehicle.images}
        referenceInstallment={preferredInstallment}
        isActive={isActive}
        wasRemoved={wasRemoved}
        onSave={dispatchSaveOffer}
        onDelete={dispatchDeleteOffer}
        isSubmittedCar={isSubmittedCar}
        learnMore={
          <LearnMoreAboutFinancePlanTrigger
            linkText={t("shared:learn-more-button")}
            contractType={selectedFinancePlan}
          />
        }
        placeholder={placeholderNode("u-mt-xlarge u-mb")}
        galleryPlaceholder={placeholderImg()}
        quotePath={RouterHelpers.getQuotePath(match.vehicle.vin)}
        withProgressBar={true}
        disableRanking={true}
      />

      {isActive && <VehicleContractTileBackContainer vehicle={match.vehicle} />}
    </Flipper>
  );
};

export { Container as VehicleContractTileContainerSavedOffer };
