"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_dom_1 = require("react-router-dom");
var __1 = require("../..");
var SavedOffersSection = function (_a) {
    var savedOffersCount = _a.savedOffersCount, pathToSavedOffers = _a.pathToSavedOffers, t = _a.t;
    function hasOffers() {
        if (savedOffersCount > 0) {
            return true;
        }
        else {
            return false;
        }
    }
    return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsxs("h6", __assign({ className: "u-mb-small" }, { children: [t("offers-saved-title"), " ", hasOffers() ? "(" + savedOffersCount + ")" : "(0)"] }), void 0), hasOffers() ? (jsx_runtime_1.jsx("p", { children: jsx_runtime_1.jsx("small", { children: t("offers-saved-text") }, void 0) }, void 0)) : (jsx_runtime_1.jsx("p", { children: jsx_runtime_1.jsx("small", { children: t("offers-saved-none-text") }, void 0) }, void 0)), hasOffers() ? (jsx_runtime_1.jsx(__1.TrackableComponent, __assign({ trackingComponent: "link", trackingFunction: "linkViewSavedOffersRO" }, { children: jsx_runtime_1.jsxs(react_router_dom_1.Link, __assign({ className: "c-btn c-btn--link c-btn--small c-btn--simple", to: pathToSavedOffers }, { children: [jsx_runtime_1.jsx("i", { className: "c-btn__icon c-icon c-icon--[semantic-offers]" }, void 0), jsx_runtime_1.jsx("span", __assign({ className: "c-btn__text" }, { children: t("offers-saved-view-button") }), void 0)] }), void 0) }), void 0)) : (jsx_runtime_1.jsxs("button", __assign({ className: "c-btn c-btn--link c-btn--small c-btn--simple", disabled: true, "aria-disabled": true }, { children: [jsx_runtime_1.jsx("i", { className: "c-btn__icon c-icon c-icon--[semantic-offers]" }, void 0), jsx_runtime_1.jsx("span", __assign({ className: "c-btn__text" }, { children: t("offers-saved-view-button") }), void 0)] }), void 0))] }, void 0));
};
exports["default"] = SavedOffersSection;
