import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { LoadingSpinner } from "@renewal/rosie";
import {
  Button,
  ButtonContainer,
  SectionHeading
} from "@vwfs-its/bronson-react";
import { Formik, FormikErrors, FormikProps } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IContract,
  ICreateOrUpdateMmMutation,
  IEligibilityStatus,
  IGetMatchMakerAnswersQuery,
  IMatchMakerAnswers
} from "../../../__generated__/graphql-types";
import { useCurrentContractId } from "../../../App.state";
import { colorList } from "../../../lib/helpers/vehicle-helpers";
import { loadingOrError } from "../../../lib/ui/Loaders/GraphqlLoadingUtility";
import { RouterHelpers } from "../../../router/router-helpers";
import {
  GET_MATCH_MAKER_ANSWERS,
  PUT_MATCH_MAKER_ANSWERS
} from "../MatchMaker.gql";

export const MatchMakerCardWrapper: React.FunctionComponent<{
  className?: string;
  dataComponentId?: string;
}> = ({ className = "", dataComponentId = "", children }) => (
  <div
    className={`o-layout o-layout--equal-height ${className}`}
    data-component-id={dataComponentId}
  >
    {children}
  </div>
);

interface OwnProps {
  pageTitle: string;
  currentPercentage?: number;
  children: (
    formikProps: FormikProps<IMatchMakerAnswers>,
    contract: IContract
  ) => JSX.Element;
  isEditMode?: boolean;
  nextPage: string;
  previousPage: string;
  goToPage: (path: string) => void;
  matchMakerAnswersId: string;
  isCompleted?: boolean;
  validate?: (values: IMatchMakerAnswers) => FormikErrors<IMatchMakerAnswers>;
}

const MatchMakerQuestionPage: React.FC<OwnProps> = props => {
  const { t } = useTranslation("match-maker");
  const {
    isEditMode = false,
    currentPercentage = 0,
    pageTitle,
    previousPage,
    nextPage,
    goToPage,
    validate,
    isCompleted = false
  } = props;
  const { currentContractId } = useCurrentContractId();

  const client = useApolloClient();

  const {
    loading,
    error,
    data = {} as IGetMatchMakerAnswersQuery
  } = useQuery<IGetMatchMakerAnswersQuery>(GET_MATCH_MAKER_ANSWERS, {
    variables: { contractId: currentContractId }
  });

  const [errorMessage, setErrorMessage] = useState("");

  const [createOrUpdateMatchMakerAnswers] =
    useMutation<ICreateOrUpdateMmMutation>(PUT_MATCH_MAKER_ANSWERS);

  if (loading || error) return loadingOrError(loading, error);

  const matchMakerAnswers = data.customer.contract.matchMakerAnswers;
  const contract = data.customer.contract;

  const pageCount = () => {
    return contract.eligibleStatus === IEligibilityStatus.POSITIVE ? "5" : "4";
  };

  return (
    <Formik
      initialValues={matchMakerAnswers}
      onSubmit={values => {
        if (values.colorOfCar?.length === 0)
          colorList(t).forEach(c => {
            values.colorOfCar?.push(c.value);
          });

        return createOrUpdateMatchMakerAnswers({
          variables: {
            contractId: currentContractId,
            ...values,
            isCompleted: isEditMode ? true : isCompleted
          }
        })
          .then(result => {
            if (result.errors) {
              setErrorMessage(t("shared:connection-error"));
            } else {
              /*
               * After mutating the match maker answers the invalidate the
               * to ensure that we reload the offers on the next request.
               */
              client.resetStore();
              goToPage(
                isEditMode
                  ? RouterHelpers.getMatchMakerSummaryPagePath()
                  : nextPage
              );
            }
          })
          .catch(_error => {
            setErrorMessage(t("shared:connection-error"));
          });
      }}
      validateOnBlur={true}
      validate={validate}
    >
      {formikProps => (
        <LoadingSpinner
          isLoading={formikProps.isSubmitting}
          isCenter={true}
          isFullPage={true}
        >
          <>
            <SectionHeading smallSpacing level="3">
              {pageTitle}
            </SectionHeading>
            {!isEditMode && (
              /**
               * Be sure the 'max' property goes before the 'value' on 'process'
               * html element, otherwise the value will be always 1 on IE11. check
               * the following link for details.
               * https://stackoverflow.com/questions/38841097/react-progress-element-has-a-bug-on-ie10
               */
              <div className="o-layout">
                <div className="o-layout__item u-11/12 u-9/12@xs">
                  <progress
                    className="c-progress u-mt-small"
                    max={pageCount()}
                    value={currentPercentage}
                  />
                </div>
                <div className="o-layout__item u-1/12 u-3/12@xs u-pl-xsmall@m u-pl@xs">
                  <p>
                    {currentPercentage} / {pageCount()}
                  </p>
                </div>
              </div>
            )}

            <form
              onSubmit={formikProps.handleSubmit}
              onReset={formikProps.handleReset}
            >
              <div className="o-component-wrapper">
                {/* TODO */}
                {
                  // @ts-ignore
                  props.children(formikProps, contract)
                }
                {errorMessage && (
                  <div className="o-page-wrap o-page-wrap--small">
                    <div className="c-error-message">{errorMessage}</div>
                  </div>
                )}
              </div>

              <div className="o-component-wrapper u-pt">
                <ButtonContainer nav>
                  {isEditMode && (
                    <Button type="submit">
                      {t("match-maker:question-pages.save-continue-button")}
                    </Button>
                  )}
                  {!isEditMode && (
                    <>
                      <Button
                        type="button"
                        secondary
                        onClick={() => {
                          goToPage(previousPage);
                        }}
                        className="u-1/6 u-1/3@m u-1/1@xs"
                        inContainer
                      >
                        {t("shared:back-button")}
                      </Button>

                      <Button
                        type="submit"
                        className="u-1/6 u-1/3@m u-1/1@xs"
                        inContainer
                      >
                        {t("question-pages.next-button")}
                      </Button>
                    </>
                  )}
                </ButtonContainer>
              </div>
            </form>
          </>
        </LoadingSpinner>
      )}
    </Formik>
  );
};

export default MatchMakerQuestionPage;
