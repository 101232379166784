import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";

import { IGetContractsQuery } from "../../__generated__/graphql-types";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { fragments } from "../../lib/helpers/graphqlFragments";
import { loadingOrError } from "../../lib/ui/Loaders/GraphqlLoadingUtility";
import { ContractSelectionComponent } from "./ContractSelectionComponent";

const GET_CONTRACTS = gql`
  query getContracts {
    customer {
      contracts {
        ...AllContractFields
      }
    }
  }
  ${fragments.allContractFields}
`;

export const ContractSelectionContainer: React.FC = () => {
  const {
    loading,
    error,
    data = {} as IGetContractsQuery
  } = useQuery<IGetContractsQuery>(GET_CONTRACTS);

  useEffect(() => {
    adobeDataLayer.contractSelection();
  }, []);

  if (loading || error) return loadingOrError(loading, error);

  return <ContractSelectionComponent contractData={data.customer.contracts} />;
};
