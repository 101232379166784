import React from "react";
import { useTranslation } from "react-i18next";

interface OwnProps {
  onStartQuestionnaire: () => void;
}

const MatchMakerLandingStep: React.FC<OwnProps> = ({
  onStartQuestionnaire
}) => {
  const { t } = useTranslation("match-maker");

  return (
    <div className="u-text-center">
      <div className="o-component-wrapper">
        <h1>{t("landing-step.title")}</h1>
        <p>{t("landing-step.subtitle")}</p>

        <h6>{t("landing-step.stepper.title")}</h6>
      </div>

      <div className="o-component-wrapper">
        <ol className="c-instruction-stepper">
          <li className="c-instruction-stepper__step">
            <figure className="c-instruction-stepper__item">
              <i className="c-icon c-icon--[semantic-fs-payment]"></i>
            </figure>
            <div className="c-instruction-stepper__detail">
              <span className="c-instruction-stepper__title">
                {t("landing-step.stepper.step-1")}
              </span>
            </div>
          </li>

          <li className="c-instruction-stepper__step">
            <figure className="c-instruction-stepper__item">
              <i className="c-icon c-icon--[semantic-mobility]"></i>
            </figure>
            <div className="c-instruction-stepper__detail">
              <span className="c-instruction-stepper__title">
                {t("landing-step.stepper.step-2")}
              </span>
            </div>
          </li>

          <li className="c-instruction-stepper__step">
            <figure className="c-instruction-stepper__item">
              <i className="c-icon c-icon--[semantic-quality]"></i>
            </figure>
            <div className="c-instruction-stepper__detail">
              <span className="c-instruction-stepper__title">
                {t("landing-step.stepper.step-3")}
              </span>
            </div>
          </li>
        </ol>
      </div>

      <div className="o-component-wrapper">
        <button className="c-btn" onClick={() => onStartQuestionnaire()}>
          <span className="c-btn__text">{t("landing-step.start-button")}</span>
        </button>
      </div>
    </div>
  );
};

export default MatchMakerLandingStep;
