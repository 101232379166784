import { bronsonIconSwitchEmpty } from "@renewal/rosie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import LegalPageTemplate from "../LegalPageTemplate";
import { FAQAccordion } from "./FAQAccordion";

function LegalNotice(): JSX.Element {
  const { t } = useTranslation("shared");
  const history = useHistory();

  useEffect(() => {
    adobeDataLayer.faq();
  }, []);

  return (
    <LegalPageTemplate
      heroTeaserTitle="よくあるご質問"
      heroImageAlt="Close up of an Audi car"
      backBtnLabel={t("back-button")}
      prevCall={history.goBack}
    >
      <h2>
        お問合せの多いご質問を掲載しています。わからないことがあれば、まずはこちらをご覧ください。
      </h2>

      <FAQAccordion brand={bronsonIconSwitchEmpty("Audi", "Volkswagen")} />
    </LegalPageTemplate>
  );
}

export default LegalNotice;
