import {
  CarSelectionCard,
  CarSelectionInitialCard,
  ExchangeStates,
  RenewalCarExchange,
  SelectionStates,
  imgSwitch,
  reactNodeSwitch
} from "@renewal/rosie";
import SvgCarEmpty from "@renewal/rosie/lib/lib/icons/audi/car-empty.svg";
import SilhouetteSrcSeat, {
  ReactComponent as SilhouetteSeat
} from "@renewal/rosie/lib/lib/icons/seat/silhouette-placeholder.svg";
import SilhouetteSrcSkoda, {
  ReactComponent as SilhouetteSkoda
} from "@renewal/rosie/lib/lib/icons/skoda/silhouette-placeholder.svg";
import SilhouetteSrc, {
  ReactComponent as Silhouette
} from "@renewal/rosie/lib/lib/icons/vw/silhouette-placeholder.svg";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IAllContractFieldsFragment,
  IAllMatchMakerAnswerFieldsFragment,
  IMatchMakerAnswers,
  IVehicleOffer
} from "../../__generated__/graphql-types";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import RetailerInfoContainer from "../../lib/components/RetailerInfo/RetailerInfoContainer";
import { RouterHelpers } from "../../router/router-helpers";
import MatchMakerPreferenceCard from "./MatchMaker/MatchMakerPreferenceCard";
import RenewalCompletedMatchMaker from "./MatchMaker/RenewalCompletedMatchMaker";

interface OwnProps {
  matchMaker: IAllMatchMakerAnswerFieldsFragment;
  matchMakerCompleted: boolean;
  selectedVehicle?: IVehicleOffer;
  wantsToBeContacted: boolean;
  contract: IAllContractFieldsFragment;
  savedOffersCount: number;
  currentLocation: string;
  pushToHistory: (addParentPath: boolean, url: string) => void;
  savedOffersLoading: boolean;
}

const RenewalOverview: React.FC<OwnProps> = ({
  matchMaker,
  matchMakerCompleted,
  contract,
  selectedVehicle,
  wantsToBeContacted,
  savedOffersCount,
  currentLocation,
  pushToHistory,
  savedOffersLoading
}) => {
  const { t } = useTranslation("renewal-overview-page");
  const [init, setInit] = useState(false);

  let carExchangeState = ExchangeStates.nothingSelected;
  let carSelectionState = SelectionStates.nothingProvided;

  if (selectedVehicle && !wantsToBeContacted) {
    carExchangeState = ExchangeStates.carAndQuoteSelected;
  } else if (selectedVehicle && wantsToBeContacted) {
    carExchangeState = ExchangeStates.approvedToBeContacted;
  }

  if (matchMakerCompleted) {
    carSelectionState = SelectionStates.mmCompleted;
  } else if (savedOffersCount > 0) {
    carSelectionState = SelectionStates.savedOffers;
  }

  useEffect(() => {
    if (!savedOffersLoading && !init) {
      setInit(true);
      adobeDataLayer.setDealerData({
        companyId: "",
        companyName: contract.retailer.name
      });
      adobeDataLayer.renewalOverview(
        matchMaker as IMatchMakerAnswers,
        carExchangeState,
        carSelectionState
      );
    }
  }, [
    carExchangeState,
    carSelectionState,
    contract.retailer.name,
    matchMaker,
    savedOffersLoading,
    init
  ]);

  const onGoToPreferences = () => {
    adobeDataLayer.renewalOverviewInteraction(
      matchMaker as IMatchMakerAnswers,
      carExchangeState,
      carSelectionState,
      "Go to edit preferences"
    );
  };

  const onViewMatches = () => {
    adobeDataLayer.renewalOverviewInteraction(
      matchMaker as IMatchMakerAnswers,
      carExchangeState,
      carSelectionState,
      "View matches"
    );
  };

  const onSubmitToDealer = () => {
    adobeDataLayer.renewalOverviewInteraction(
      matchMaker as IMatchMakerAnswers,
      carExchangeState,
      carSelectionState,
      "Submit to dealer"
    );
  };

  return (
    <section className="o-content-section" data-component-id="renewal-overview">
      <div className="o-page-wrap">
        <header className="c-section-heading">
          <h2 className="c-section-heading__title">{t("title")}</h2>
        </header>
        <div className="o-layout">
          <div className="o-layout__item u-1/1 u-mb">
            <RetailerInfoContainer contractData={contract} />
            {/* To make the retailer info have a border at the bottom it
             * may not be the only item in a container */}
            <div />
          </div>
        </div>
        <div className="o-layout o-layout--equal-height u-mb-small u-mb-xsmall@s">
          <div className="o-layout__item u-1/3 u-1/1@m">
            {matchMakerCompleted ? (
              <RenewalCompletedMatchMaker
                contract={contract}
                matchMaker={matchMaker}
                wantsToBeContacted={wantsToBeContacted}
                onGoToPreferences={onGoToPreferences}
              />
            ) : (
              <MatchMakerPreferenceCard pushToHistory={pushToHistory} />
            )}
          </div>

          <div className="o-layout__item u-1/3 u-1/1@m">
            {selectedVehicle ? (
              <CarSelectionCard
                pushToHistory={pushToHistory}
                carModel={selectedVehicle.vehicle.carModel}
                carModelVariant=""
                images={selectedVehicle.vehicle.images}
                savedOffersCount={savedOffersCount}
                currentLocation={currentLocation}
                wantsToBeContacted={wantsToBeContacted}
                pathToVehicleOffers={RouterHelpers.getVehicleOfferPath(
                  selectedVehicle.vehicle.vin,
                  selectedVehicle.quote.quoteId
                )}
                pathToSavedOffers={RouterHelpers.getSavedOffersPath(
                  currentLocation
                )}
                pathToQuote={RouterHelpers.getQuotePath(
                  selectedVehicle.vehicle.vin
                )}
                pathToSelection={RouterHelpers.getVehicleSelectionPath()}
                placeholderImg={reactNodeSwitch(
                  <></>,
                  <Silhouette className="u-mb-small" />,
                  <SilhouetteSkoda className="u-mb-small" />,
                  <SilhouetteSeat className="u-mb-small" />
                )}
                galleryPlaceholder={imgSwitch(
                  SvgCarEmpty,
                  SilhouetteSrc,
                  SilhouetteSrcSkoda,
                  SilhouetteSrcSeat
                )}
                t={t}
                paymentDescriptionOne={`${t("shared:installment", {
                  amount: selectedVehicle.quote.installment
                })} ${t("shared:term", {
                  months: selectedVehicle.quote.term
                })}`}
                paymentDescriptionTwo={`${t("shared:apr", {
                  interest: selectedVehicle.quote.annualPercentageRate
                })}`}
                onSubmitToDealer={onSubmitToDealer}
              />
            ) : (
              <CarSelectionInitialCard
                t={t}
                selectionState={carSelectionState}
                pushToHistory={pushToHistory}
                savedOffersCount={savedOffersCount}
                noCarImg={imgSwitch(
                  SvgCarEmpty,
                  SilhouetteSrc,
                  SilhouetteSrcSkoda,
                  SilhouetteSrcSeat
                )}
                vehicleSelectionPath={RouterHelpers.getVehicleSelectionPath()}
                savedOffersPath={RouterHelpers.getSavedOffersPath(
                  currentLocation
                )}
                onViewMatches={onViewMatches}
              />
            )}
          </div>

          <div className="o-layout__item u-1/3 u-1/1@m">
            <RenewalCarExchange
              carExchangeState={carExchangeState}
              t={t}
              withStepper={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RenewalOverview;
