"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.TileHeader = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var ActiveHeader = function (_a) {
    var contractType = _a.contractType, learnMore = _a.learnMore, withTag = _a.withTag, tagText = _a.tagText;
    return (jsx_runtime_1.jsxs("header", __assign({ className: "c-card__header u-mb-small u-text-left" }, { children: [withTag && tagText ? (jsx_runtime_1.jsx("span", __assign({ className: "c-tag c-tag--success dr-card-right-element" }, { children: jsx_runtime_1.jsx("span", __assign({ className: "c-tag__label" }, { children: tagText }), void 0) }), void 0)) : null, jsx_runtime_1.jsx("h4", __assign({ className: "c-card__title" }, { children: contractType }), void 0), learnMore, jsx_runtime_1.jsx("hr", { className: "u-mt u-mb-none" }, void 0)] }), void 0));
};
var InactiveHeader = function (_a) {
    var contractType = _a.contractType, learnMoreText = _a.learnMoreText;
    return (jsx_runtime_1.jsxs("header", __assign({ className: "c-card__header u-mb-small u-text-left" }, { children: [jsx_runtime_1.jsx("h4", __assign({ className: "c-card__title" }, { children: contractType }), void 0), jsx_runtime_1.jsx(bronson_react_1.Button, __assign({ onClick: function () { }, icon: "semantic-forward", disabled: true, link: true, small: true }, { children: learnMoreText }), void 0), jsx_runtime_1.jsx("hr", { className: "u-mt u-mb-none" }, void 0)] }), void 0));
};
var TileHeader = function (_a) {
    var contractType = _a.contractType, _b = _a.isActive, isActive = _b === void 0 ? true : _b, withTag = _a.withTag, tagText = _a.tagText, learnMore = _a.learnMore, learnMoreText = _a.learnMoreText;
    return isActive ? (jsx_runtime_1.jsx(ActiveHeader, { contractType: contractType, withTag: withTag, tagText: tagText, learnMore: learnMore, learnMoreText: learnMoreText }, void 0)) : (jsx_runtime_1.jsx(InactiveHeader, { contractType: contractType, learnMoreText: learnMoreText, learnMore: learnMore }, void 0));
};
exports.TileHeader = TileHeader;
