import React from "react";
import { Route, RouteProps } from "react-router";

type AllProps = RouteProps;

// function getErrorPagePath(authState: AuthState): string {
//   if (authState.isAuthorized && !authState.isEligible) {
//     return RouterHelpers.getErrorPageNotEligiblePath();
//   } else {
//     switch (authState.authErrorCode) {
//       case "401":
//         return RouterHelpers.getErrorPage401Path();
//       case "403":
//       default:
//         return RouterHelpers.getErrorPage403Path();
//     }
//   }
// }

function ProtectedRoute(props: AllProps): JSX.Element {
  const { component, ...rest } = props;

  if (!component) {
    throw Error("Please pass a component to each ProtectedRoute");
  }

  // the first letter of the component has to be upper case
  // so that we can use JXS syntax to initialize an instance of that component
  const ComponentToDisplay = component;

  return (
    <Route
      {...rest}
      render={props => {
        return <ComponentToDisplay {...props} />;
      }}
    />
  );
}

export default ProtectedRoute;
