import "./VehicleSelection.scss";

import { Breakpoints } from "@renewal/rosie";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import MediaQuery from "react-responsive";

import {
  IAllVehicleOfferFieldsFragment,
  IContract,
  IFinancePlan,
  IMatchMakerAnswers,
  IVehicleOffer
} from "../../__generated__/graphql-types";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import FilterWidgetContainer from "./components/FilterWidget/FilterWidgetContainer";
import VehicleSelectionDesktop from "./VehicleSelectionDesktop";
import VehicleSelectionMobile from "./VehicleSelectionMobile";

interface OwnProps {
  vehicles: IAllVehicleOfferFieldsFragment[];
  contract: IContract;
  matchMakerAnswers: IMatchMakerAnswers;
  onFilterOptionChanged: (values: IMatchMakerAnswers) => Promise<void>;
  onSortingOptionChanged: (newValue: string) => Promise<void>;
  onEditPreferences: () => void;
}

interface OwnState {
  visibleCount: number;
  showMoreButtonVisible: boolean;
}

type AllProps = OwnProps & WithTranslation;

const PAGE_SIZE = 6;

export class VehicleSelection extends React.Component<AllProps, OwnState> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      visibleCount: PAGE_SIZE,
      showMoreButtonVisible: props.vehicles.length > PAGE_SIZE
    };

    this.loadMore = this.loadMore.bind(this);
  }

  loadMore(): void {
    adobeDataLayer.carSelectionSeeMore(this.props.matchMakerAnswers);
    this.setState((previous: OwnState) => {
      let newVisibleCount = previous.visibleCount + PAGE_SIZE;
      let newShowMoreButtonVisible = previous.showMoreButtonVisible;
      if (newVisibleCount >= this.props.vehicles.length) {
        newVisibleCount = this.props.vehicles.length;
        newShowMoreButtonVisible = false;
      }

      return {
        ...previous,
        visibleCount: previous.visibleCount + PAGE_SIZE,
        showMoreButtonVisible: newShowMoreButtonVisible
      };
    });
  }

  onFullScreenOpen(): void {
    adobeDataLayer.carSelectionPictureFullScreen(this.props.matchMakerAnswers);
  }

  onSaveOffer(offer: IVehicleOffer): void {
    adobeDataLayer.setProduct(offer);
    adobeDataLayer.carSelectionSaveForLater(this.props.matchMakerAnswers);
  }

  render(): JSX.Element {
    const {
      t,
      vehicles,
      contract,
      matchMakerAnswers,
      onFilterOptionChanged,
      onSortingOptionChanged
    } = this.props;

    return (
      <>
        <section className="o-content-section" data-component-id="match-maker">
          <div className="o-page-wrap">
            <header className="c-section-heading u-mb-xsmall">
              <h2 className="c-section-heading__title">{t("title")}</h2>
            </header>
            <FilterWidgetContainer
              matchMakerAnswers={matchMakerAnswers}
              numberOfMatchedVehicles={vehicles.length}
              contract={contract}
              onFilterOptionChanged={onFilterOptionChanged}
              onSortingOptionChanged={onSortingOptionChanged}
              t={t}
              onEditPreferences={this.props.onEditPreferences}
            />
            <div className="o-layout">
              <MediaQuery maxWidth={Breakpoints.Tablet.Max}>
                <VehicleSelectionMobile
                  vehicles={vehicles}
                  contract={contract}
                  visibleCount={this.state.visibleCount}
                  showMoreButtonVisible={this.state.showMoreButtonVisible}
                  loadMore={this.loadMore}
                  preferredInstallment={
                    matchMakerAnswers.expectedMonthlyPayment
                      ? matchMakerAnswers.expectedMonthlyPayment
                      : 0
                  }
                  selectedFinancePlan={
                    matchMakerAnswers.financePlan ?? IFinancePlan.SLOAN
                  }
                  onFullScreenOpen={this.onFullScreenOpen.bind(this)}
                  onSaveOffer={this.onSaveOffer.bind(this)}
                  matchMakerAnswers={matchMakerAnswers}
                />
              </MediaQuery>

              <MediaQuery minWidth={Breakpoints.Desktop.Min}>
                <VehicleSelectionDesktop
                  vehicles={vehicles}
                  contract={contract}
                  visibleCount={this.state.visibleCount}
                  showMoreButtonVisible={this.state.showMoreButtonVisible}
                  loadMore={this.loadMore}
                  preferredInstallment={
                    matchMakerAnswers.expectedMonthlyPayment
                      ? matchMakerAnswers.expectedMonthlyPayment
                      : 0
                  }
                  selectedFinancePlan={
                    matchMakerAnswers.financePlan ?? IFinancePlan.SLOAN
                  }
                  onFullScreenOpen={this.onFullScreenOpen.bind(this)}
                  onSaveOffer={this.onSaveOffer.bind(this)}
                  matchMakerAnswers={matchMakerAnswers}
                />
              </MediaQuery>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation("matches-page")(VehicleSelection);
