"use strict";
exports.__esModule = true;
exports.prepareTranslations = void 0;
// Disabling the rule for the whole file since something is wrong the the prepareTranslations
// functions types.
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
var lodash_1 = require("lodash");
var prepareTranslations = function (reactBrand, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
resources, language) {
    var brand;
    switch (reactBrand) {
        case "skoda":
            brand = "skoda";
            break;
        case "seat":
            brand = "seat";
            break;
        case "audi":
            brand = "audi";
            break;
        default:
            brand = "default";
            break;
    }
    var brandTranslations = resources[language][brand];
    var defaultTranslations = resources[language]["default"];
    // Overwrite keys where a brand-specific translation is available
    lodash_1.assign(defaultTranslations, brandTranslations);
    // Check for empty values with a space and remove space
    return lodash_1.mapValues(defaultTranslations, function (t) {
        t = lodash_1.mapValues(t, function (v) {
            if (v === " ")
                return "";
            return v;
        });
        return t;
    });
};
exports.prepareTranslations = prepareTranslations;
