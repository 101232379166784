"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.MatchPercentageCycle = exports.longDescription = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var lodash_1 = require("lodash");
var ColorVariables_1 = require("../../../ui/ColorVariables");
var asset_switches_1 = require("../../../utils/asset-switches");
var __1 = require("../../..");
var CarIcons_1 = require("./CarIcons");
// This diameter is just a relative value, the cycle will be resized according to the size of the container
var diameter = 70;
var outRadius = diameter / 2;
var innerRadius = outRadius - 3;
var longDescription = function (t, offerInstallment, referenceInstallment) {
    var paymentDiff = Math.abs(offerInstallment - referenceInstallment).toFixed();
    if (lodash_1.inRange(offerInstallment, referenceInstallment - 0.99, referenceInstallment + 1)) {
        return "" + t("payment-equal");
    }
    else if (offerInstallment > referenceInstallment) {
        return "" + t("payment-higher", {
            value: paymentDiff
        });
    }
    else {
        return "" + t("payment-lower", {
            value: paymentDiff
        });
    }
};
exports.longDescription = longDescription;
var getMatchDetails = function (t, typeOfMatch, offerInstallment, referenceInstallment) {
    var matchDetails = {
        percentage: 0,
        shortDescription: "" + t("match-type-no"),
        longDescription: "" + exports.longDescription(t, offerInstallment, referenceInstallment)
    };
    switch (typeOfMatch) {
        case __1.MatchType.Best:
            matchDetails.percentage = 1;
            matchDetails.shortDescription = "" + t("match-type-best");
            break;
        case __1.MatchType.Great:
            matchDetails.percentage = 0.75;
            matchDetails.shortDescription = "" + t("match-type-great");
            break;
        case __1.MatchType.Good:
            matchDetails.percentage = 0.5;
            matchDetails.shortDescription = "" + t("match-type-good");
            break;
        case __1.MatchType.Fair:
            matchDetails.percentage = 0.25;
            matchDetails.shortDescription = "" + t("match-type-fair");
            break;
    }
    return matchDetails;
};
var MatchPercentageCycle = function (_a) {
    var typeOfMatch = _a.typeOfMatch, referenceInstallment = _a.referenceInstallment, offerInstallment = _a.offerInstallment, t = _a.t;
    var _b = getMatchDetails(t, typeOfMatch, offerInstallment, referenceInstallment), percentage = _b.percentage, shortDescription = _b.shortDescription, longDescription = _b.longDescription;
    var hasLongDescription = !!longDescription;
    var angleBreakpoint = percentage * 360;
    return (jsx_runtime_1.jsxs("div", __assign({ className: "o-layout u-mb-none u-pt-xsmall" }, { children: [jsx_runtime_1.jsx("div", __assign({ className: "o-layout__item u-1/6" }, { children: jsx_runtime_1.jsxs(__1.SVGContainer, __assign({ height: diameter, width: diameter, isHeightAndWithForViewport: true, svgClassName: "dr-match-svg", translateToTheCenter: false }, { children: [jsx_runtime_1.jsx(__1.Arc, { outerRadius: outRadius, innerRadius: innerRadius, startAngle: 0, endAngle: angleBreakpoint, style: {
                                fill: asset_switches_1.colorSwitch(ColorVariables_1.audiRed, ColorVariables_1.vw6Blue, ColorVariables_1.skodaBrand, ColorVariables_1.seatBrand)
                            }, withTransform: true }, void 0), jsx_runtime_1.jsx(__1.Arc, { outerRadius: outRadius, innerRadius: innerRadius, startAngle: angleBreakpoint, endAngle: 360, style: {
                                fill: asset_switches_1.colorSwitch(ColorVariables_1.audiInactive, ColorVariables_1.vw6Inactive, ColorVariables_1.skodaPale, ColorVariables_1.seatPale)
                            }, withTransform: true }, void 0), asset_switches_1.reactNodeSwitch(CarIcons_1.audiCar, CarIcons_1.vwCar, CarIcons_1.skodaCar, CarIcons_1.seatCar)] }), void 0) }), void 0), jsx_runtime_1.jsx("div", __assign({ className: "o-layout__item u-5/6" }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "" + asset_switches_1.bronsonIconSwitchEmpty("u-ml-large", "u-ml-large", "u-ml-xlarge", "u-ml-large", "u-ml-large") }, { children: [jsx_runtime_1.jsx("p", __assign({ className: asset_switches_1.bronsonIconSwitchEmpty("u-text-alert") + " u-mb-xsmall " + (hasLongDescription
                                ? ""
                                : asset_switches_1.bronsonIconSwitchEmpty("u-mt", "u-mt-small")) }, { children: jsx_runtime_1.jsx("b", { children: t("match-type", {
                                    matchType: shortDescription
                                }) }, void 0) }), void 0), hasLongDescription && (jsx_runtime_1.jsx("p", __assign({ className: "u-text-fs-1 u-mb-small" }, { children: longDescription }), void 0))] }), void 0) }), void 0)] }), void 0));
};
exports.MatchPercentageCycle = MatchPercentageCycle;
