import { useApolloClient } from "@apollo/client";
import { LoadingSpinner } from "@renewal/rosie";
import { LicensePlate } from "@vwfs-its/bronson-react";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { useCurrentContractId } from "../../App.state";
import { RouterHelpers } from "../../router/router-helpers";

const CompareItem: React.FC<{
  t: TFunction;
  brand: string;
  model: string;
  contractNumber: string;
  contractEndDate: string;
  onSelectContract: () => void;
  licensePlate: string;
  prefectureCode: string;
  registrationPrefix: string;
  vehicleCode: string;
}> = ({
  t,
  brand,
  model,
  contractNumber,
  contractEndDate,
  licensePlate,
  prefectureCode,
  onSelectContract,
  registrationPrefix,
  vehicleCode
}) => {
  return (
    <article className="c-card c-card--offer">
      <header className="c-card__header">
        <LicensePlate
          registrationNumber={licensePlate}
          jpPrefectureCode={prefectureCode}
          jpRegistrationPrefix={registrationPrefix}
          jpVehicleCode={vehicleCode}
          size="medium"
          horizontalStripJp
          jp
        />
        <h4 className="c-card__title u-mt">
          {brand},<br></br>
          {model}
        </h4>
      </header>

      <div className="c-card__body">
        <h5 className="u-mb-xsmall">{t("finance-contract")}</h5>
        <p>{contractNumber}</p>

        <hr></hr>

        <p>{t("contract-end", { contractEnd: contractEndDate })}</p>

        <div className="o-button-container">
          <button
            className="c-btn o-button-container__button c-btn--primary"
            onClick={onSelectContract}
          >
            <span className="c-btn__text">{t("select-contract-btn")}</span>
          </button>
        </div>
      </div>
    </article>
  );
};

interface OwnProps {
  contractData: {
    brandName: string;
    carModel: string;
    contractNumber: string;
    endDate: string;
    licensePlate2: string;
    licensePlate1: string;
    licensePlate3: string;
  }[];
}

export const ContractSelectionComponent: React.FC<OwnProps> = ({
  contractData
}) => {
  const history = useHistory();
  const { t } = useTranslation("contract-selection-page");
  const { setCurrentContractId } = useCurrentContractId();
  const [isResetting, setIsResetting] = useState(false);

  const client = useApolloClient();

  if (isResetting) {
    return (
      <LoadingSpinner isLoading={isResetting} isCenter={true} isFullPage={true}>
        <div />
      </LoadingSpinner>
    );
  }

  return (
    <section className="o-content-section">
      <div className="o-page-wrap">
        <div className="o-component-wrapper">
          <header className="c-section-heading c-section-heading--subtitle c-section-heading--center">
            <h2 className="c-section-heading__title">{t("title")}</h2>
            <p className="c-section-heading__subtitle">{t("subtitle")}</p>
          </header>
        </div>
        <div className="o-component-wrapper">
          <div className="o-layout o-layout--equal-height">
            {contractData.map((d, idx) => (
              <div className="o-layout__item u-1/3 u-1/1@xs" key={idx}>
                <CompareItem
                  t={t}
                  brand={d.brandName}
                  model={d.carModel}
                  contractNumber={d.contractNumber}
                  contractEndDate={d.endDate}
                  onSelectContract={async () => {
                    setIsResetting(true);
                    setCurrentContractId(d.contractNumber);

                    // Note: We are resetting the full store here since all loaded data is
                    // contract based. To avoid unintended caching of values from other
                    // contracts we clean up prophylactically
                    await client.resetStore().catch(() => {
                      setIsResetting(false);
                    });
                    history.push(RouterHelpers.getEntryPointPath("selection"));
                    setIsResetting(false);
                  }}
                  registrationPrefix={d.licensePlate2}
                  prefectureCode={d.licensePlate1.split(/([0-9]+)/)[0]}
                  vehicleCode={d.licensePlate1.split(/([0-9]+)/)[1]}
                  licensePlate={d.licensePlate3}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
