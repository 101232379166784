import "./MatchMaker.scss";

import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router";

import {
  IGetMatchMakerAnswersQuery,
  useGetMatchMakerAnswersQuery
} from "../../__generated__/graphql-types";
import { useCurrentContractId } from "../../App.state";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { loadingOrError } from "../../lib/ui/Loaders/GraphqlLoadingUtility";
import ErrorPage404 from "../../pages/ErrorPages/ErrorPage404";
import { RouterHelpers } from "../../router/router-helpers";
import MatchMakerLandingStep from "./MatchMakerLandingStep";
import MatchMakerResult from "./MatchMakerResult";
import MatchMakerQuestion1 from "./QuestionPages/MatchMakerQuestion1";
import MatchMakerQuestion2 from "./QuestionPages/MatchMakerQuestion2";
import MatchMakerQuestion3 from "./QuestionPages/MatchMakerQuestion3";
import MatchMakerQuestion4 from "./QuestionPages/MatchMakerQuestion4";
import MatchMakerQuestion5 from "./QuestionPages/MatchMakerQuestion5";

const MATCH_MAKER_ANSWER_ID = "test-from-code1";

function MatchMakerContainer(props: RouteComponentProps) {
  const { currentContractId } = useCurrentContractId();
  const {
    loading,
    error,
    data = {} as IGetMatchMakerAnswersQuery
  } = useGetMatchMakerAnswersQuery({
    variables: { contractId: currentContractId }
  });

  useEffect(() => {
    if (data) {
      adobeDataLayer.setDealerData({
        companyId: "",
        companyName: data?.customer?.contract?.retailer?.name
      });
    }
  }, [data]);

  if (loading || error) return loadingOrError(loading, error);

  const goToPage = (path: string) => props.history.push(path);
  const matchMakerAnswers = data.customer.contract.matchMakerAnswers;

  const eligibleStatus = data.customer.contract.eligibleStatus;

  return (
    <div className="o-content-section">
      <div className="o-page-wrap">
        <Switch>
          <Route exact path={RouterHelpers.getMatchMakerStartingPagePath()}>
            <MatchMakerLandingStep
              onStartQuestionnaire={() => {
                goToPage(RouterHelpers.getMatchMakerQuestionPage1Path());
              }}
            />
          </Route>
          <Route exact path={RouterHelpers.getMatchMakerPath()}>
            <Redirect
              to={
                matchMakerAnswers.isCompleted
                  ? RouterHelpers.getMatchMakerSummaryPagePath()
                  : RouterHelpers.getMatchMakerStartingPagePath()
              }
            />
          </Route>
          <Route exact path={RouterHelpers.getMatchMakerQuestionPage1Path()}>
            <MatchMakerQuestion1
              matchMakerAnswersId={MATCH_MAKER_ANSWER_ID}
              goToPage={goToPage}
            />
          </Route>
          <Route exact path={RouterHelpers.getMatchMakerQuestionPage2Path()}>
            <MatchMakerQuestion2
              matchMakerAnswersId={MATCH_MAKER_ANSWER_ID}
              goToPage={goToPage}
            />
          </Route>
          <Route exact path={RouterHelpers.getMatchMakerQuestionPage3Path()}>
            <MatchMakerQuestion3
              matchMakerAnswersId={MATCH_MAKER_ANSWER_ID}
              goToPage={goToPage}
            />
          </Route>
          <Route exact path={RouterHelpers.getMatchMakerQuestionPage4Path()}>
            <MatchMakerQuestion4
              matchMakerAnswersId={MATCH_MAKER_ANSWER_ID}
              goToPage={goToPage}
              eligibleStatus={eligibleStatus}
            />
          </Route>
          <Route exact path={RouterHelpers.getMatchMakerQuestionPage5Path()}>
            <MatchMakerQuestion5
              matchMakerAnswersId={MATCH_MAKER_ANSWER_ID}
              goToPage={goToPage}
            />
          </Route>
          <Route exact path={RouterHelpers.getMatchMakerSummaryPagePath()}>
            <MatchMakerResult
              matchMakerAnswers={{ matchedOffers: [], ...matchMakerAnswers }}
              contract={data.customer.contract}
              onSeeMatchOffersButtonClicked={() => {
                goToPage(RouterHelpers.getVehicleSelectionPath());
              }}
            />
          </Route>
          <Route
            exact
            path={RouterHelpers.getMatchMakerQuestionPage1EditPath()}
          >
            <MatchMakerQuestion1
              matchMakerAnswersId={MATCH_MAKER_ANSWER_ID}
              goToPage={goToPage}
              isEditMode={true}
            />
          </Route>
          <Route
            exact
            path={RouterHelpers.getMatchMakerQuestionPage2EditPath()}
          >
            <MatchMakerQuestion2
              matchMakerAnswersId={MATCH_MAKER_ANSWER_ID}
              goToPage={goToPage}
              isEditMode={true}
            />
          </Route>
          <Route
            exact
            path={RouterHelpers.getMatchMakerQuestionPage3EditPath()}
          >
            <MatchMakerQuestion3
              matchMakerAnswersId={MATCH_MAKER_ANSWER_ID}
              goToPage={goToPage}
              isEditMode={true}
            />
          </Route>
          <Route
            exact
            path={RouterHelpers.getMatchMakerQuestionPage4EditPath()}
          >
            <MatchMakerQuestion4
              matchMakerAnswersId={MATCH_MAKER_ANSWER_ID}
              goToPage={goToPage}
              isEditMode={true}
              eligibleStatus={eligibleStatus}
            />
          </Route>
          <Route component={ErrorPage404} />
        </Switch>
      </div>
    </div>
  );
}

export default withRouter(MatchMakerContainer);
