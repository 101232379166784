import { gql } from "@apollo/client";

import { fragments } from "../../lib/helpers/graphqlFragments";

const SUBMIT_OFFER = gql`
  mutation submitOffer(
    $vin: String!
    $offerId: ID!
    $dealerName: String!
    $dealerAddress: String!
    $dealerEmail: String!
    $dealerCode: String!
    $contractId: String!
    $contactEmail: String!
    $contactPhoneNumber: String!
    $selectedContactMethod: ContactMethod!
    $contactDate: ContactDate
    $contactTime: ContactTime
  ) {
    submitOffer(
      vin: $vin
      offerId: $offerId
      dealerName: $dealerName
      dealerAddress: $dealerAddress
      dealerEmail: $dealerEmail
      dealerCode: $dealerCode
      contractId: $contractId
      contactEmail: $contactEmail
      contactPhoneNumber: $contactPhoneNumber
      selectedContactMethod: $selectedContactMethod
      contactDate: $contactDate
      contactTime: $contactTime
    ) {
      ...AllFinanceDetailsFields
    }
  }
  ${fragments.allFinanceDetailsFields}
`;

const GET_DEALERS = gql`
  query getDealers($region: String!) {
    dealers(region: $region) {
      email
      address
      name
      code
    }
  }
`;

export { SUBMIT_OFFER, GET_DEALERS };
