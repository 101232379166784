import {
  FieldError,
  FormFieldInput,
  MatchMakerSingleCardLayoutWithTitle
} from "@renewal/rosie";
import {
  Button,
  ButtonContainer,
  DefinitionList,
  Layout
} from "@vwfs-its/bronson-react";
import { ErrorMessage, Formik } from "formik";
import { TFunction } from "i18next";
import React, { ChangeEvent } from "react";

import { IMatchMakerAnswers } from "../../../__generated__/graphql-types";
import { adobeDataLayer } from "../../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { PhoneNumberInput } from "../../../lib/components/WhatsNext/PhoneNumberInput";
import { DealerAppointmentState } from "../DealerAppointmentComponent";
import { ActionTypes } from "../reducerTypes";
import { contactDetailsValidationSchema } from "./validationSchemas";

export const renderContactDetailsSections = (
  state: DealerAppointmentState,
  dispatch: React.Dispatch<ActionTypes>,
  t: TFunction,
  matchMakerAnswers: IMatchMakerAnswers
): JSX.Element => {
  return state.contactDetailsEditable ? (
    <Formik
      initialValues={state.contactDetails}
      validateOnBlur={true}
      onSubmit={values => {
        dispatch({
          type: "SET_CONTACT_DETAILS_EDITABLE",
          payload: false
        });
        dispatch({
          type: "UPDATE_CONTACT_DETAILS",
          payload: {
            email: values.email,
            phoneNumber: values.phoneNumber
          }
        });
      }}
      validationSchema={contactDetailsValidationSchema(t)}
    >
      {formikProps => (
        <form onSubmit={formikProps.handleSubmit}>
          <MatchMakerSingleCardLayoutWithTitle
            title={t(
              "contract-submission.contact-details-edit.edit-contact-section-title"
            )}
            editButton={
              <div className="u-text-right">
                <Button icon="semantic-close" iconReversed link type="submit">
                  {t("contract-submission.button-top-close")}
                </Button>
              </div>
            }
          >
            <Layout>
              <Layout.Item default="1/2" s="1/1" xs="1/1">
                <FormFieldInput
                  labelText={t(
                    "contract-submission.contact-details-view.view-email-title"
                  )}
                  trackingComponent="inputbox"
                  trackingFunction="dealerAppointmentEmail"
                  fieldId="email"
                  value={formikProps.values.email}
                  handleChange={(
                    event: ChangeEvent<Element> & { target: { value: string } }
                  ) => formikProps.setFieldValue("email", event.target.value)}
                  fieldType="text"
                />
                <ErrorMessage
                  name="email"
                  render={msg => <FieldError errorMessage={msg} />}
                />
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1" xs="1/1">
                <PhoneNumberInput
                  labelText={t(
                    "contract-submission.contact-details-view.view-phone-title"
                  )}
                  trackingComponent="inputbox"
                  trackingFunction="dealerAppointmentPhone"
                  value={formikProps.values.phoneNumber}
                  handleChange={(newValue: string) =>
                    formikProps.setFieldValue("phoneNumber", newValue)
                  }
                />
                <ErrorMessage
                  name="phoneNumber"
                  render={msg => <FieldError errorMessage={msg} />}
                />
              </Layout.Item>
            </Layout>
            <ButtonContainer className="u-mt" center>
              <Button
                secondary
                className="u-3/12 u-1/3@m u-1/1@s"
                inContainer
                onClick={() =>
                  dispatch({
                    type: "SET_CONTACT_DETAILS_EDITABLE",
                    payload: false
                  })
                }
              >
                {t("contract-submission.edit-button-cancel")}
              </Button>

              <Button
                type="submit"
                className="u-3/12 u-1/3@m u-1/1@s"
                inContainer
              >
                {t("contract-submission.edit-button-confirm")}
              </Button>
            </ButtonContainer>
          </MatchMakerSingleCardLayoutWithTitle>
        </form>
      )}
    </Formik>
  ) : (
    <MatchMakerSingleCardLayoutWithTitle
      title={t(
        "contract-submission.contact-details-view.view-contact-section-title"
      )}
      editButton={
        <div className="u-text-right">
          <Button
            icon="semantic-edit"
            iconReversed
            link
            onClick={() => {
              adobeDataLayer.dealerSelectionEdit(
                matchMakerAnswers,
                "Personal contact data"
              );
              return dispatch({
                type: "SET_CONTACT_DETAILS_EDITABLE",
                payload: true
              });
            }}
          >
            {t("contract-submission.button-top-change")}
          </Button>
        </div>
      }
    >
      <DefinitionList split>
        <DefinitionList.Item
          title={t("contract-submission.contact-details-view.view-email-title")}
        >
          {state.contactDetails.email}
        </DefinitionList.Item>
        <DefinitionList.Item
          title={t("contract-submission.contact-details-view.view-phone-title")}
        >
          {state.contactDetails.phoneNumber}
        </DefinitionList.Item>
      </DefinitionList>
    </MatchMakerSingleCardLayoutWithTitle>
  );
};
