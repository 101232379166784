import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

interface OwnProps {
  showMoreButtonVisible: boolean;
  loadMore: () => void;
}

type AllProps = OwnProps & WithTranslation;

class LoadMoreButton extends React.Component<AllProps> {
  render() {
    const { t } = this.props;

    const ref = React.createRef<HTMLButtonElement>();

    const loadMoreAndBlur = (): void => {
      if (ref.current) {
        this.props.loadMore();
        ref.current.blur();
      }
    };

    return (
      <>
        {this.props.showMoreButtonVisible && (
          <div className="u-pt-large u-text-center">
            <button
              ref={ref}
              className={"c-btn c-btn--secondary"}
              onClick={loadMoreAndBlur}
            >
              <span className="c-btn__text">
                {t("matches-show-more-button")}
              </span>
              <i className={`c-btn__icon c-icon  c-icon--[arrow-down]`} />
            </button>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation("matches-page")(LoadMoreButton);
