import { bronsonIconSwitchEmpty, reactNodeSwitch } from "@renewal/rosie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { IEligibilityStatus } from "../../../__generated__/graphql-types";
import { adobeDataLayer } from "../../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { FinancePlan } from "../../../lib/components/MatchMakerAnswersEditComponents/FinancePlan";
import { FinancePlanVW } from "../../../lib/components/MatchMakerAnswersEditComponents/FinancePlanVW";
import { getFieldValidators } from "../../../lib/helpers/match-maker-validator";
import { RouterHelpers } from "../../../router/router-helpers";
import { genericValidator } from "../MatchMakerHelper";
import MatchMakerQuestionWrapper from "./MatchMakerQuestionWrapper";

interface OwnProps {
  matchMakerAnswersId: string;
  goToPage: (path: string) => void;
  isEditMode?: boolean;
  eligibleStatus: IEligibilityStatus;
}

const MatchMakerQuestion4: React.FC<OwnProps> = ({
  goToPage,
  matchMakerAnswersId,
  isEditMode,
  eligibleStatus
}) => {
  const { t } = useTranslation("match-maker");

  useEffect(() => {
    adobeDataLayer.matchMakerQuestionPage(4, "Financing plan");
  }, []);

  return (
    <MatchMakerQuestionWrapper
      pageTitle={t("question-pages.question-page-four.title")}
      isEditMode={isEditMode}
      currentPercentage={4}
      nextPage={
        eligibleStatus === IEligibilityStatus.POSITIVE
          ? RouterHelpers.getMatchMakerQuestionPage5Path()
          : RouterHelpers.getMatchMakerSummaryPagePath()
      }
      previousPage={RouterHelpers.getMatchMakerQuestionPage3Path()}
      goToPage={goToPage}
      validate={genericValidator.bind(
        this,
        getFieldValidators(t, ["financePlan"])
      )}
      matchMakerAnswersId={matchMakerAnswersId}
      isCompleted={eligibleStatus === IEligibilityStatus.NEGATIVE}
    >
      {() => (
        <div className="o-page-wrap o-page-wrap--small">
          <h4>{t("question-pages.items.finance-plan.label")}</h4>
          {reactNodeSwitch(<FinancePlan />, <FinancePlanVW />)}

          <p className="u-text-fs-1 u-mt">
            {bronsonIconSwitchEmpty(t("question-pages.items.finance-plan.tac"))}
          </p>
        </div>
      )}
    </MatchMakerQuestionWrapper>
  );
};

export default MatchMakerQuestion4;
