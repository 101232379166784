"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.MatchMakerSingleCardLayoutWithTitle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var MatchMakerSingleCardLayoutWithTitle = function (_a) {
    var title = _a.title, contentToBottom = _a.contentToBottom, editButton = _a.editButton, children = _a.children, titleIcon = _a.titleIcon, className = _a.className;
    return (jsx_runtime_1.jsxs("article", __assign({ className: "c-card " + className }, { children: [jsx_runtime_1.jsxs("header", __assign({ className: "c-card__header u-text-left" }, { children: [editButton && editButton, jsx_runtime_1.jsxs("h4", __assign({ className: "c-card__title" }, { children: [titleIcon && (jsx_runtime_1.jsx("i", { className: "c-icon c-icon--[" + titleIcon + "] c-item-teaser__icon dr-card-title-icon" }, void 0)), title] }), void 0)] }), void 0), contentToBottom ? (
            // Quickfix until Bronson figured out a flex problem on their side
            jsx_runtime_1.jsx("div", __assign({ className: "c-card__footer", style: { display: "block" } }, { children: jsx_runtime_1.jsx("div", { children: children }, void 0) }), void 0)) : (jsx_runtime_1.jsx("div", __assign({ className: "c-card__body u-text-left" }, { children: children }), void 0))] }), void 0));
};
exports.MatchMakerSingleCardLayoutWithTitle = MatchMakerSingleCardLayoutWithTitle;
