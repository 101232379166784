"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.Group = exports.Text = exports.Polygone = exports.Arc = exports.TranslateTransform = exports.RotateTransform = exports.SVGContainer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var d3_shape_1 = require("d3-shape");
var react_1 = require("react");
var SVGContainer = /** @class */ (function (_super) {
    __extends(SVGContainer, _super);
    function SVGContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SVGContainer.prototype.render = function () {
        var _a = this.props, height = _a.height, width = _a.width, isHeightAndWithForViewport = _a.isHeightAndWithForViewport, svgClassName = _a.svgClassName, _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.translateToTheCenter, translateToTheCenter = _c === void 0 ? true : _c, children = _a.children;
        var margins = { left: width / 2, top: height / 2 };
        var transform = translateToTheCenter
            ? "translate(" + margins.left + ", " + margins.top + ")"
            : "";
        if (isHeightAndWithForViewport) {
            return (jsx_runtime_1.jsx("svg", __assign({ width: width, height: height, shapeRendering: "geometricPrecision", className: svgClassName, id: id, ref: "svgContainer" }, { children: jsx_runtime_1.jsx("g", __assign({ transform: transform }, { children: children }), void 0) }), void 0));
        }
        else {
            return (jsx_runtime_1.jsx("svg", __assign({ viewBox: "0 0 " + width + " " + height, shapeRendering: "geometricPrecision", className: svgClassName, id: id, ref: "svgContainer" }, { children: jsx_runtime_1.jsx("g", __assign({ transform: transform }, { children: children }), void 0) }), void 0));
        }
    };
    return SVGContainer;
}(react_1.Component));
exports.SVGContainer = SVGContainer;
var RotateTransform = function (_a) {
    var rotateAngle = _a.rotateAngle, children = _a.children;
    return jsx_runtime_1.jsx("g", __assign({ transform: "rotate(" + rotateAngle + ")" }, { children: children }), void 0);
};
exports.RotateTransform = RotateTransform;
var TranslateTransform = function (_a) {
    var x = _a.x, y = _a.y, children = _a.children, restProps = __rest(_a, ["x", "y", "children"]);
    return (jsx_runtime_1.jsx("g", __assign({ transform: "translate(" + x + "," + y + ")" }, restProps, { children: children }), void 0));
};
exports.TranslateTransform = TranslateTransform;
var Arc = /** @class */ (function (_super) {
    __extends(Arc, _super);
    function Arc() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Arc.prototype.render = function () {
        var _a = this.props, outerRadius = _a.outerRadius, innerRadius = _a.innerRadius, startAngle = _a.startAngle, endAngle = _a.endAngle, _b = _a.withTransform, withTransform = _b === void 0 ? false : _b, restProps = __rest(_a, ["outerRadius", "innerRadius", "startAngle", "endAngle", "withTransform"]);
        var myArc = d3_shape_1.arc()
            .context(null)
            .outerRadius(outerRadius)
            .innerRadius(innerRadius)
            .startAngle((startAngle * Math.PI) / 180)
            .endAngle((endAngle * Math.PI) / 180);
        var transform = withTransform
            ? "translate(" + outerRadius + ", " + outerRadius + ")"
            : "";
        return (jsx_runtime_1.jsx("g", __assign({ transform: transform }, restProps, { children: jsx_runtime_1.jsx("path", { d: myArc(this) || "" }, void 0) }), void 0));
    };
    return Arc;
}(react_1.PureComponent));
exports.Arc = Arc;
var Polygone = function (_a) {
    var vertices = _a.vertices, restProps = __rest(_a, ["vertices"]);
    var myLine = d3_shape_1.line()(vertices);
    return (jsx_runtime_1.jsx("g", __assign({}, restProps, { children: jsx_runtime_1.jsx("path", { d: myLine || "" }, void 0) }), void 0));
};
exports.Polygone = Polygone;
var Text = function (_a) {
    var _b = _a.text, text = _b === void 0 ? "" : _b, _c = _a.textAnchor, textAnchor = _c === void 0 ? "left" : _c, children = _a.children, restProps = __rest(_a, ["text", "textAnchor", "children"]);
    return (jsx_runtime_1.jsxs("text", __assign({ textAnchor: textAnchor }, restProps, { children: [text, children] }), void 0));
};
exports.Text = Text;
var Group = function (props) {
    return jsx_runtime_1.jsx("g", __assign({}, props), void 0);
};
exports.Group = Group;
