import { FieldError } from "@renewal/rosie";
import { ErrorMessage, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { IMatchMakerAnswers } from "../../../__generated__/graphql-types";

export const ExpectedDuration: React.FC = () => {
  const { t } = useTranslation("match-maker");
  const { setFieldValue, values, setFieldTouched } =
    useFormikContext<IMatchMakerAnswers>();

  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1/2">
          <label className="c-radio">
            <input
              className="c-radio__input"
              type="radio"
              name="terms"
              value="36"
              checked={values.expectedDuration === 36}
              onChange={() => {
                setFieldTouched("expectedDuration", true);
                setFieldValue("expectedDuration", 36);
              }}
            />
            <span className="c-radio__label">
              {t("question-pages.items.duration.options.one")}
            </span>
          </label>
        </div>
        <div className="o-layout__item u-1/2">
          <label className="c-radio">
            <input
              className="c-radio__input"
              type="radio"
              name="terms"
              value="60"
              checked={values.expectedDuration === 60}
              onChange={() => {
                setFieldTouched("expectedDuration", true);
                setFieldValue("expectedDuration", 60);
              }}
            />
            <span className="c-radio__label">
              {t("question-pages.items.duration.options.two")}
            </span>
          </label>
        </div>
      </div>
      <ErrorMessage
        name="expectedDuration"
        render={msg => (
          <div className="c-error-message">
            <FieldError errorMessage={msg} />
          </div>
        )}
      />
    </>
  );
};
