"use strict";
exports.__esModule = true;
exports.MatchType = void 0;
var MatchType;
(function (MatchType) {
    MatchType[MatchType["Best"] = 4] = "Best";
    MatchType[MatchType["Great"] = 3] = "Great";
    MatchType[MatchType["Good"] = 2] = "Good";
    MatchType[MatchType["Fair"] = 1] = "Fair";
    MatchType[MatchType["NoMatch"] = 0] = "NoMatch";
})(MatchType = exports.MatchType || (exports.MatchType = {}));
