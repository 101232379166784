import { Card, Icons, bronsonIconSwitchEmpty } from "@renewal/rosie";
import { InfoIcon, LicensePlate } from "@vwfs-its/bronson-react";
import { connect as formikConnect } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { adobeDataLayer } from "../../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { RouterHelpers } from "../../../router/router-helpers";
import MatchMakerQuestionWrapper from "./MatchMakerQuestionWrapper";

interface OwnProps {
  matchMakerAnswersId: string;
  goToPage: (path: string) => void;
}

const MatchMakerQuestion5: React.FC<OwnProps> = props => {
  const { t } = useTranslation("match-maker");
  const { goToPage, matchMakerAnswersId } = props;

  useEffect(() => {
    adobeDataLayer.matchMakerQuestionPage(5, "Equity");
  }, []);

  return (
    <MatchMakerQuestionWrapper
      pageTitle={t("question-pages.question-page-five.title")}
      isEditMode={false}
      currentPercentage={5}
      nextPage={RouterHelpers.getMatchMakerSummaryPagePath()}
      previousPage={RouterHelpers.getMatchMakerQuestionPage4Path()}
      goToPage={goToPage}
      matchMakerAnswersId={matchMakerAnswersId}
      isCompleted={true}
    >
      {(
        // Disabled in order to still use the Wrapper without providing unused formik variables
        // eslint-disable-next-line no-empty-pattern
        {},
        contract
      ) => (
        <div className="o-page-wrap" data-component-id="match-maker-question-3">
          <Card bodyModifiers={bronsonIconSwitchEmpty("", "u-pv")}>
            <div className="o-layout__item u-pl-none">
              <div className="o-layout__item u-8/12 u-1/1@xs u-pl-none">
                <div className="u-hide@xs-up u-mb@xs">
                  <LicensePlate
                    registrationNumber={contract.licensePlate3}
                    horizontalStripJp
                    size="small"
                    jpRegistrationPrefix={contract.licensePlate2}
                    jpPrefectureCode={
                      contract.licensePlate1.split(/([0-9]+)/)[0]
                    }
                    jpVehicleCode={contract.licensePlate1.split(/([0-9]+)/)[1]}
                    jp
                  />
                </div>
                <h3
                  className={`${bronsonIconSwitchEmpty(
                    "u-mv-xsmall",
                    "u-mb-none",
                    "u-mb-none",
                    "u-mb-none"
                  )}`}
                >
                  {contract.carModel}
                </h3>
              </div>
              <div className="o-layout__item u-4/12 u-text-right u-hide@xs">
                <LicensePlate
                  registrationNumber={contract.licensePlate3}
                  horizontalStripJp
                  size="small"
                  jpRegistrationPrefix={contract.licensePlate2}
                  jpPrefectureCode={contract.licensePlate1.split(/([0-9]+)/)[0]}
                  jpVehicleCode={contract.licensePlate1.split(/([0-9]+)/)[1]}
                  jp
                />
              </div>
            </div>
          </Card>

          <hr className="u-mt-xlarge u-mb-none u-hide@xs-up"></hr>

          <div className="o-layout o-layout--equal-height u-mt">
            <div className="o-layout__item u-1/4 u-1/1@xs">
              <Card
                center={["body", "footer"]}
                withFooter={
                  <p className="u-mb-xsmall">
                    <b>
                      {t("shared:currency", {
                        value: contract.currentVehicleValue
                      })}
                    </b>
                    <InfoIcon
                      icon="semantic-info"
                      tippyProps={{ placement: "top" }}
                    >
                      {t(
                        "question-pages.infos.equity-calculation.car-value.tooltip"
                      )}
                    </InfoIcon>
                  </p>
                }
              >
                <span>
                  <i
                    className={`c-icon
                              c-icon--[${Icons.CarSearch}]
                              ${bronsonIconSwitchEmpty(
                                "",
                                " c-icon--xxlarge",
                                "",
                                " c-icon--xxlarge"
                              )}
                            `}
                  />
                </span>
                <p className="u-mt-small">
                  {t("question-pages.infos.equity-calculation.car-value.text")}
                </p>
              </Card>
            </div>
            <div className="o-layout__item u-1/12 u-1/1@xs dr-align-middle u-text-center">
              <h3 className="u-mt u-mb-none u-mt-none@xs">-</h3>
            </div>
            <div className="o-layout__item u-1/4 u-1/1@xs">
              <Card
                center={["body", "footer"]}
                withFooter={
                  <p className="u-mb-xsmall">
                    <b>
                      {t("shared:currency", {
                        value: contract.amountToSettle
                      })}
                    </b>
                    <InfoIcon
                      icon="semantic-info"
                      tippyProps={{ placement: "top" }}
                    >
                      {t(
                        "question-pages.infos.equity-calculation.outstanding-finance.tooltip"
                      )}
                    </InfoIcon>
                  </p>
                }
              >
                <span>
                  <i
                    className={`c-icon
                              c-icon--[${Icons.Calculator}]
                              ${bronsonIconSwitchEmpty(
                                "",
                                " c-icon--xxlarge",
                                "",
                                " c-icon--xxlarge"
                              )}
                            `}
                  />
                </span>
                <p className="u-mt-small">
                  {t(
                    "question-pages.infos.equity-calculation.outstanding-finance.text"
                  )}
                </p>
              </Card>
            </div>
            <div className="o-layout__item u-1/12 u-1/1@xs dr-align-middle u-text-center">
              <h3 className="u-mt u-mb-none u-mt-none@xs">=</h3>
            </div>
            <div className="o-layout__item u-1/3 u-1/1@xs">
              <Card
                center={["body", "footer"]}
                withFooter={
                  <p className="u-mb-xsmall">
                    <b>
                      {t("shared:currency", {
                        value: contract.equity
                      })}
                    </b>
                    <br className="u-hide@m-up"></br>
                    <InfoIcon
                      icon="semantic-info"
                      tippyProps={{ placement: "top" }}
                    >
                      {t(
                        "question-pages.infos.equity-calculation.equity.tooltip"
                      )}
                    </InfoIcon>
                  </p>
                }
              >
                <span>
                  <i
                    className={`c-icon
                              c-icon--[${Icons.FinalEvaluation}]
                              ${bronsonIconSwitchEmpty(
                                "",
                                " c-icon--xxlarge",
                                "",
                                " c-icon--xxlarge"
                              )}
                            `}
                  />
                </span>
                <p className="u-mt-small">
                  {t("question-pages.infos.equity-calculation.equity.text")}
                </p>
              </Card>
            </div>

            <div className="o-layout__item u-1/1 u-text-center">
              <p>
                <small>
                  {t("question-pages.infos.equity-calculation.legal-text")}
                </small>
              </p>
            </div>
          </div>
        </div>
      )}
    </MatchMakerQuestionWrapper>
  );
};

export default formikConnect(MatchMakerQuestion5);
