import { Roadmap, heroImgSwitch, imgSwitch } from "@renewal/rosie";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import MatchMaker1280 from "./assets/audi/match-maker-1280x720.jpg";
import MatchMaker1920 from "./assets/audi/match-maker-1920x1080.jpg";
import MatchMaker480 from "./assets/audi/match-maker-480x270.jpg";
import MatchMaker720 from "./assets/audi/match-maker-720x405.jpg";
import MatchMaker960 from "./assets/audi/match-maker-960x540.jpg";
import Range from "./assets/audi/range.png";
import TradeIn1280 from "./assets/audi/trade-in-1280x720.jpg";
import TradeIn1920 from "./assets/audi/trade-in-1920x1080.jpg";
import TradeIn480 from "./assets/audi/trade-in-480x270.jpg";
import TradeIn720 from "./assets/audi/trade-in-720x405.jpg";
import TradeIn960 from "./assets/audi/trade-in-960x540.jpg";
import MatchMaker1280VW from "./assets/vw/match-maker-1280x720.jpg";
import MatchMaker1920VW from "./assets/vw/match-maker-1920x1080.jpg";
import MatchMaker480VW from "./assets/vw/match-maker-480x270.jpg";
import MatchMaker720VW from "./assets/vw/match-maker-720x405.jpg";
import MatchMaker960VW from "./assets/vw/match-maker-960x540.jpg";
import RangeVW from "./assets/vw/range.png";
import TradeIn1280VW from "./assets/vw/trade-in-1280x720.jpg";
import TradeIn1920VW from "./assets/vw/trade-in-1920x1080.jpg";
import TradeIn480VW from "./assets/vw/trade-in-480x270.jpg";
import TradeIn720VW from "./assets/vw/trade-in-720x405.jpg";
import TradeIn960VW from "./assets/vw/trade-in-960x540.jpg";

interface StepProps {
  title: string;
  description: string;
  icon?: React.ReactNode;
  iconBronson?: string;
}

const Step: React.FunctionComponent<StepProps> = ({
  title = "",
  description,
  icon,
  iconBronson
}) => {
  return (
    <li className="c-instruction-stepper__step">
      <figure className="c-instruction-stepper__item">
        {icon && icon}
        {iconBronson && <i className={`c-icon  c-icon--[${iconBronson}]`} />}
      </figure>
      <div className="c-instruction-stepper__detail">
        <span className="c-instruction-stepper__title">{title}</span>
        <span className="c-instruction-stepper__description">
          {description}
        </span>
      </div>
    </li>
  );
};

class LandingPage extends React.Component<WithTranslation> {
  componentDidMount() {
    adobeDataLayer.landingPage();
  }

  render() {
    const { t } = this.props;
    const loginUrl = `/api/v1/login?language=${process.env.REACT_APP_LANG}&brand=${process.env.REACT_APP_BRAND}`;

    return (
      <div className="o-main">
        <section className="o-content-section">
          <div className="o-page-wrap">
            <h1>{t("page-title")}</h1>
            <section className="c-media-teaser c-media-teaser--reversed">
              <div className="c-media-teaser__media">
                <img
                  className="c-media-teaser__image js-object-fit"
                  src={imgSwitch(Range, RangeVW)}
                  alt={t("range-image-alt")}
                />
              </div>
              <div className="c-media-teaser__caption">
                <div className="c-media-teaser__body">
                  <ul className="c-icon-list">
                    <li>
                      <i className="c-icon-list__icon  c-icon  c-icon--[semantic-checkmark]"></i>{" "}
                      {t("bullet-points.point-one")}
                    </li>
                    <li>
                      <i className="c-icon-list__icon  c-icon  c-icon--[semantic-checkmark]"></i>{" "}
                      {t("bullet-points.point-two")}
                    </li>
                    <li>
                      <i className="c-icon-list__icon  c-icon  c-icon--[semantic-checkmark]"></i>{" "}
                      {t("bullet-points.point-three")}
                    </li>
                  </ul>
                </div>
                <div className="c-media-teaser__footer">
                  <a
                    className="c-btn c-btn--secondary"
                    href={loginUrl}
                    onClick={() => {
                      adobeDataLayer.landingPageStartMM();
                    }}
                  >
                    <span className="c-btn__text">{t("login-button-top")}</span>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className="o-content-section">
          <div className="c-hero-teaser c-hero-teaser--shallow c-hero-teaser--inverted">
            <img
              className="c-hero-teaser__image"
              src={imgSwitch(TradeIn1920, TradeIn1920VW)}
              srcSet={heroImgSwitch(
                [TradeIn480, TradeIn720, TradeIn960, TradeIn1280, TradeIn1920],
                [
                  TradeIn480VW,
                  TradeIn720VW,
                  TradeIn960VW,
                  TradeIn1280VW,
                  TradeIn1920VW
                ]
              )}
              alt={t("trade-in.image-alt")}
            />

            <div id="js-hero-teaser-content" className="c-hero-teaser__content">
              <div className="c-hero-teaser__content-inner">
                <header className="c-hero-teaser__heading">
                  <div className="c-hero-teaser__title">
                    <h1>
                      <span className="c-hero-teaser__title-row">
                        {t("trade-in.image-title")}
                      </span>
                    </h1>
                  </div>
                </header>
              </div>
            </div>
          </div>
        </section>

        <section className="o-content-section">
          <div className="o-page-wrap ">
            <div className="o-component-wrapper">
              <header className="c-section-heading c-section-heading--center">
                <h4 className="c-section-heading__title">
                  {t("trade-in.title")}
                </h4>
              </header>
            </div>
            <div className="o-component-wrapper">
              <Roadmap
                today={t("trade-in.today")}
                contractStart={t("trade-in.contract-start")}
                contractEnd={t("trade-in.contract-end")}
                tradeIn={t("trade-in.trade-in-opportunity")}
                renew={t("trade-in.renew")}
              />
            </div>
          </div>
        </section>

        <section className="o-content-section">
          <div className="c-hero-teaser c-hero-teaser--shallow c-hero-teaser--inverted">
            <img
              className="c-hero-teaser__image"
              src={imgSwitch(MatchMaker1920)}
              srcSet={heroImgSwitch(
                [
                  MatchMaker480,
                  MatchMaker720,
                  MatchMaker960,
                  MatchMaker1280,
                  MatchMaker1920
                ],
                [
                  MatchMaker480VW,
                  MatchMaker720VW,
                  MatchMaker960VW,
                  MatchMaker1280VW,
                  MatchMaker1920VW
                ]
              )}
              alt={t("our-match-maker.image-alt")}
            />

            <div id="js-hero-teaser-content" className="c-hero-teaser__content">
              <div className="c-hero-teaser__content-inner">
                <header className="c-hero-teaser__heading">
                  <div className="c-hero-teaser__title">
                    <h1>
                      <span className="c-hero-teaser__title-row">
                        {t("our-match-maker.image-title")}
                      </span>
                    </h1>
                  </div>
                  <div className="c-hero-teaser__subtitle">
                    <p>{t("our-match-maker.image-subtitle")}</p>
                  </div>
                </header>
              </div>
            </div>
          </div>
        </section>
        <section className="o-content-section">
          <div className="o-page-wrap ">
            <div className="o-component-wrapper">
              <header className="c-section-heading c-section-heading--center">
                <h4 className="c-section-heading__title">
                  {t("our-match-maker.title")}
                </h4>
              </header>
            </div>

            <div className="o-component-wrapper">
              <ol className="c-instruction-stepper">
                <Step
                  iconBronson="semantic-user"
                  title={t("our-match-maker.stepper.step-one.title")}
                  description={t(
                    "our-match-maker.stepper.step-one.description"
                  )}
                />

                <Step
                  iconBronson="semantic-car"
                  title={t("our-match-maker.stepper.step-two.title")}
                  description={t(
                    "our-match-maker.stepper.step-two.description"
                  )}
                />

                <Step
                  iconBronson="semantic-lock"
                  title={t("our-match-maker.stepper.step-three.title")}
                  description={t(
                    "our-match-maker.stepper.step-three.description"
                  )}
                />
              </ol>
            </div>
            <div className="u-text-center">
              <a
                className="c-btn"
                href={loginUrl}
                onClick={() => {
                  adobeDataLayer.landingPageStartMM();
                }}
              >
                <span className="c-btn__text">
                  {t("our-match-maker.login-button-bottom")}
                </span>
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation("landing-page-2-0")(LandingPage);
