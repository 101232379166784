import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery
} from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import {
  IContactMethod,
  IGetDealersQuery,
  IMutationSubmitOfferArgs
} from "../../__generated__/graphql-types";
import { useCurrentContractId } from "../../App.state";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { useOfferOperations } from "../../lib/helpers/saved-offers";
import { loadingOrError } from "../../lib/ui/Loaders/GraphqlLoadingUtility";
import {
  RouterHelpers,
  RouterHelpersQuotePath
} from "../../router/router-helpers";
import { GET_OFFER } from "../CarOffer/CarOffer.graphql";
import { GET_DEALERS, SUBMIT_OFFER } from "./DealerAppointment.graphql";
import DealerAppointmentComponent, {
  DealerAppointmentState
} from "./DealerAppointmentComponent";

const DealerAppointmentContainer: React.FC<RouterHelpersQuotePath> = props => {
  const { t } = useTranslation("dealer-appointment-page");
  const offerId = props.match.params.id;
  const { vin } = useParams<{ vin: string; id: string }>();
  const { currentContractId } = useCurrentContractId();
  const { savedOffers } = useOfferOperations(currentContractId);
  const [init, setInit] = useState(false);

  const { loading, error, data } = useQuery(GET_OFFER, {
    variables: { vin: vin, contractNumber: currentContractId }
  });

  const [submitOfferMutation, { error: submitError }] =
    useMutation(SUBMIT_OFFER);
  const apolloClient = useApolloClient();

  const [getDealers, { data: dealers, error: dealersError }] =
    useLazyQuery<IGetDealersQuery>(GET_DEALERS);

  useEffect(() => {
    const offer = savedOffers.find(o => o.wasSelected);
    if (data && data.customer.contract.matchMakerAnswers && offer && !init) {
      setInit(true);
      adobeDataLayer.setDealerData({
        companyId: "",
        companyName: data?.customer?.contract?.retailer?.name
      });
      adobeDataLayer.setProduct(offer);
      adobeDataLayer.dealerSelection(
        data?.customer?.contract?.matchMakerAnswers
      );
    }
  }, [data, savedOffers, init]);

  if (loading) return loadingOrError(loading);

  if (!data || !data.financeOffer) {
    return <div>offer{offerId} does not exist</div>;
  }

  const pushToHistory = (addParentPath: boolean, url: string): void => {
    if (addParentPath === true) {
      props.history.push(`${props.match.path}/${url}`);
    } else {
      props.history.push(`${url}`);
    }
  };

  const onSubmitHandler = (values: DealerAppointmentState) => {
    if (!data.financeOffer) return;

    const queryVariables: IMutationSubmitOfferArgs = {
      vin: vin,
      offerId: offerId,
      dealerAddress: values.dealerData.address,
      dealerName: values.dealerData.name,
      dealerEmail: values.dealerData.email,
      dealerCode: values.dealerData.code,
      contractId: data.customer.contract.contractNumber,
      selectedContactMethod: values.contactMethod.selectedMethod,
      contactDate:
        values.contactMethod.selectedMethod === IContactMethod.EMAIL
          ? undefined
          : values.contactMethod.date,
      contactTime:
        values.contactMethod.selectedMethod === IContactMethod.EMAIL
          ? undefined
          : values.contactMethod.time,
      contactEmail: values.contactDetails.email,
      contactPhoneNumber: values.contactDetails.phoneNumber
    };
    submitOfferMutation({ variables: queryVariables })
      .then(() => {
        adobeDataLayer.dealerSelectionContactSuccess(
          data.customer.contract.matchMakerAnswers,
          values.contactMethod.selectedMethod === "PHONE"
        );
        toast.success(t("submitted-toast-success"), { autoClose: 6 * 1000 });
        pushToHistory(false, RouterHelpers.getRenewalOverviewPath());
        apolloClient.resetStore();
      })
      .catch(e => {
        adobeDataLayer.dealerSelectionContactError(
          data.customer.contract.matchMakerAnswers
        );
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(e));
      });
  };
  return (
    <DealerAppointmentComponent
      onSubmit={onSubmitHandler}
      offer={data}
      t={t}
      prevCall={props.history.goBack}
      getDealers={getDealers}
      dealers={dealers}
      retrievalError={dealersError || error}
      submitError={submitError}
      matchMakerAnswers={data.customer.contract.matchMakerAnswers}
    />
  );
};

export default DealerAppointmentContainer;
