"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.FormFieldCheckbox = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var bronson_react_2 = require("@vwfs-its/bronson-react");
function FormFieldCheckbox(_a) {
    var id = _a.id, name = _a.name, tooltipText = _a.tooltipText, value = _a.value, handleBlur = _a.handleBlur, handleChange = _a.handleChange, children = _a.children;
    return (jsx_runtime_1.jsxs(bronson_react_1.FormField, __assign({ className: "u-mt", type: "checkbox" }, { children: [jsx_runtime_1.jsx(bronson_react_1.Checkbox, __assign({ value: value, onChange: handleChange, onBlur: handleBlur, checked: !!value, id: id, name: name }, { children: children }), void 0), tooltipText && (jsx_runtime_1.jsx(bronson_react_2.InfoIcon, __assign({ icon: "semantic-info", tippyProps: { placement: "top" } }, { children: jsx_runtime_1.jsx("span", { dangerouslySetInnerHTML: {
                        __html: tooltipText
                    } }, void 0) }), void 0))] }), void 0));
}
exports.FormFieldCheckbox = FormFieldCheckbox;
