import { FormFieldInput, LoadingSpinner } from "@renewal/rosie";
import { Formik } from "formik";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  useResendSmsCodeMutation,
  useVerifyAccountMutation
} from "../../__generated__/graphql-types";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { useTrackOnStartAndQuit } from "../../lib/adobe-analytics-data-layer/customHooks";
import { RouterHelpers } from "../../router/router-helpers";
import { STEP_ONE_RESPONSE } from "./SessionStorageKeys";

const validationSchema = Yup.object().shape({
  onetimecode: Yup.string()
    .required("SMS認証コードを入力してください。")
    .matches(/^[0-9]{6}$/, "正しいSMS認証コードを入力してください。")
});

export function RegisterSMSForm({
  history
}: {
  history: History;
}): JSX.Element {
  const [errorMessage, setErrorMessage] = useState("");
  const [stepOneResponse, setStepOneResponse] = useState<{
    customerId: string;
    iv: string;
    tag: string;
  }>({ customerId: "", iv: "", tag: "" });
  const [lastTouched, setLastTouched] = useState("");
  const [init, setInit] = useState(false);

  const { t } = useTranslation();
  const [verifyAccount] = useVerifyAccountMutation();
  const [resendSMSCode] = useResendSmsCodeMutation();

  useEffect(() => {
    const responseData = sessionStorage.getItem(STEP_ONE_RESPONSE);

    if (responseData) {
      const response: {
        phoneNumber: string;
        customerId: string;
        tag: string;
        iv: string;
      } = JSON.parse(responseData);

      setStepOneResponse(response);
    } else {
      history.push(RouterHelpers.getRegisterPagePath());
    }
  }, [history]);

  useTrackOnStartAndQuit(
    () => {
      setInit(true);
      adobeDataLayer.tanInput();
    },
    async () => await adobeDataLayer.tanAborted(lastTouched),
    lastTouched === "" && !init,
    "Registration"
  );

  const submitVerificationCode = async (
    values: { onetimecode: string },
    { setSubmitting }: { setSubmitting: (status: boolean) => void }
  ): Promise<void> => {
    try {
      setErrorMessage("");

      const { data } = await verifyAccount({
        variables: {
          data: JSON.stringify(stepOneResponse),
          smsCode: values.onetimecode
        }
      });
      if (!data) {
        setErrorMessage(t("shared:connection-error"));
        return;
      }

      const res = JSON.parse(data.verifyAccount || "");
      const businessCode = res.businessCode;

      switch (businessCode) {
        case "200":
          await adobeDataLayer.tanSuccess();
          window.onbeforeunload = null;
          window.onpopstate = null;
          history.push(RouterHelpers.getContractSelectionPath());
          break;
        case "410":
          // sms code expired
          setErrorMessage(
            "入力されたSMS認証コードの有効期限が切れました。新しい認証コードをリクエストしてください。"
          );
          break;
        case "499":
          setErrorMessage(
            "入力された情報に誤りがございます。正しいSMS認証コード（6桁）を入力してください。"
          );
          break;
        default:
          setErrorMessage(t("shared:connection-error"));
      }
      setSubmitting(false);
    } catch (e) {
      setErrorMessage(t("shared:connection-error"));
      setSubmitting(false);
    }
  };

  const resendVerificationCode = async (
    setSubmitting: (status: boolean) => void,
    resetField: () => void
  ): Promise<void> => {
    try {
      setSubmitting(true);
      setErrorMessage("");
      resetField();

      const { data } = await resendSMSCode({
        variables: {
          data: JSON.stringify(stepOneResponse)
        }
      });
      if (!data) {
        setErrorMessage(t("shared:connection-error"));
        return;
      }
      const res = JSON.parse(data.resendSMSCode || "");
      const businessCode: string = res.businessCode;

      if (businessCode === "200") {
        // TODO: how to display resend successful message
      } else if (businessCode === "410") {
        setErrorMessage(
          "申し訳ありません。ご入力された電話番号は、SMSメッセージを受け取ることができません。携帯電話の番号をご入力ください。問題が解決されない場合は、お問合せ窓口へお電話ください。"
        );
      } else if (businessCode === "420") {
        setErrorMessage(
          "すでに登録のある情報です。確認の上再度入力をお願いします。"
        );
      } else {
        setErrorMessage(
          "入力された情報を確認することができませんでした。正しい内容を入力してください。"
        );
      }
      setSubmitting(false);
    } catch (e) {
      setErrorMessage(t("shared:connection-error"));
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        onetimecode: ""
      }}
      validationSchema={validationSchema}
      onSubmit={submitVerificationCode}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        setFieldValue,
        setFieldTouched
      }) => (
        <LoadingSpinner
          isLoading={isSubmitting}
          isCenter={true}
          isFullPage={false}
        >
          <form onSubmit={handleSubmit}>
            <div className="u-mt-xlarge">
              <FormFieldInput
                labelText="SMS認証コード（6桁）"
                fieldId="onetimecode"
                handleChange={handleChange}
                handleBlur={params => {
                  setLastTouched("onetimecode");
                  return handleBlur(params);
                }}
                value={values.onetimecode}
                error={errors.onetimecode}
                touched={touched.onetimecode}
                trackingComponent="inputbox"
                trackingFunction="inputboxOneTimeCode"
              />
            </div>
            {errorMessage !== "" && (
              <p className="c-error-message">{errorMessage}</p>
            )}
            <div className="u-text-center u-mt-xlarge">
              <button className="c-btn" disabled={isSubmitting}>
                <span className="c-btn__text">次へ</span>
              </button>
            </div>
          </form>
          <div className="u-text-center">
            <p className="u-mt-large u-text-fs-1">
              もしSMS認証コードが届かない場合は、
              <button
                className="c-btn c-btn--link"
                onClick={() =>
                  resendVerificationCode(setSubmitting, () => {
                    setFieldValue("onetimecode", "");
                    setFieldTouched("onetimecode", false);
                  })
                }
              >
                <span className="c-btn__text">こちら</span>
              </button>
              をクリックしてください。新しいコードを送ります。
            </p>
          </div>
        </LoadingSpinner>
      )}
    </Formik>
  );
}
