import { InfoIcon } from "@vwfs-its/bronson-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { adobeDataLayer } from "../../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { AdditionalDeposit } from "../../../lib/components/MatchMakerAnswersEditComponents/AdditionalDeposit";
import { BonusPayment } from "../../../lib/components/MatchMakerAnswersEditComponents/BonusPayment";
import { ExpectedDuration } from "../../../lib/components/MatchMakerAnswersEditComponents/ExpectedDuration";
import { ExpectedMonthlyPayment } from "../../../lib/components/MatchMakerAnswersEditComponents/ExpectedMonthlyPayment";
import { getFieldValidators } from "../../../lib/helpers/match-maker-validator";
import { RouterHelpers } from "../../../router/router-helpers";
import { genericValidator } from "../MatchMakerHelper";
import MatchMakerQuestionWrapper from "./MatchMakerQuestionWrapper";

interface OwnProps {
  matchMakerAnswersId: string;
  goToPage: (path: string) => void;
  isEditMode?: boolean;
}

const MatchMakerQuestion3: React.FC<OwnProps> = props => {
  const { t } = useTranslation("match-maker");
  const { goToPage, matchMakerAnswersId, isEditMode } = props;

  useEffect(() => {
    adobeDataLayer.matchMakerQuestionPage(3, "Financial");
  }, []);

  return (
    <MatchMakerQuestionWrapper
      pageTitle={t("question-pages.question-page-three.title")}
      isEditMode={isEditMode}
      currentPercentage={3}
      nextPage={RouterHelpers.getMatchMakerQuestionPage4Path()}
      previousPage={RouterHelpers.getMatchMakerQuestionPage2Path()}
      goToPage={goToPage}
      validate={genericValidator.bind(
        this,
        getFieldValidators(t, [
          "expectedDuration",
          "expectedMonthlyPayment",
          "additionalDeposit",
          "bonusPayment"
        ])
      )}
      matchMakerAnswersId={matchMakerAnswersId}
    >
      {(_, contract) => (
        <div className="o-page-wrap">
          <div className="o-page-wrap o-page-wrap--small">
            <h4 className="u-text-center u-mt">
              {t("question-pages.items.installments.label")}
            </h4>
            <ExpectedMonthlyPayment
              installmentAmount={contract.installmentAmount}
            />
          </div>

          <hr className="u-mv-xlarge"></hr>
          <div className="o-page-wrap o-page-wrap--small">
            <h4 className="u-text-center u-mt">
              {t("question-pages.items.additional-deposit.label")}

              <InfoIcon icon="semantic-info" tippyProps={{ placement: "top" }}>
                {t("question-pages.items.additional-deposit.tooltip")}
              </InfoIcon>
            </h4>
            <AdditionalDeposit installmentAmount={contract.installmentAmount} />
          </div>

          <hr className="u-mv-xlarge"></hr>
          <div className="o-page-wrap o-page-wrap--small">
            <h4 className="u-text-center u-mt">
              {t("question-pages.items.bonus-payment.label")}

              <InfoIcon icon="semantic-info" tippyProps={{ placement: "top" }}>
                {t("question-pages.items.bonus-payment.tooltip")}
              </InfoIcon>
            </h4>

            <BonusPayment />
          </div>

          <hr className="u-mv-xlarge"></hr>
          <div className="o-page-wrap o-page-wrap--small">
            <h4 className="u-text-center u-mt">
              {t("question-pages.items.duration.label")}
            </h4>
            <ExpectedDuration />
          </div>
        </div>
      )}
    </MatchMakerQuestionWrapper>
  );
};

export default MatchMakerQuestion3;
