import { RouteComponentProps } from "react-router";

export class RouterHelpers {
  static getEntryPointPath(prev = ":prev"): string {
    return `/p/entry-point/${prev}`;
  }

  static getErrorPage401Path(): string {
    return `/error-page-401`;
  }

  static getErrorPage403Path(): string {
    return `/error-page-403`;
  }

  static getErrorPageNotEligiblePath(): string {
    return `/error-not-eligible`;
  }

  static getErrorPagePublicNotEligiblePath(): string {
    return `/p-error-not-eligible`;
  }

  static getSessionExpiredPage(): string {
    return `/session-expired`;
  }

  static getLandingPagePath(): string {
    return `/`;
  }

  static getRegisterPagePath(): string {
    return `/register`;
  }

  static getRegisterSMSAuthorizationPagePath(): string {
    return `/register-sms-authorization`;
  }

  static getLegalNoticePath(): string {
    return `/legal-notice`;
  }

  static getPrivacyPolicyPath(): string {
    return `/privacy-policy`;
  }

  static getCookiePolicyPath(): string {
    return `/cookie-policy`;
  }

  static getFAQPath(): string {
    return `/faq`;
  }

  static getPublicOutlinePath(): string {
    return `/p/`;
  }

  static getContractSelectionPath(): string {
    return `/p/contract-selection`;
  }

  static getVehicleSelectionPath(): string {
    return `/p/car-selection`;
  }

  static getVehicleOfferPath(vin = ":vin", id = ":id"): string {
    return `/p/car-offer/${vin}/${id}`;
  }

  static getRenewalOverviewPath(): string {
    return `/p/renewal-overview`;
  }

  static getMatchMakerStartingPagePath(): string {
    return `/p/match-maker/starting-page`;
  }
  static getMatchMakerPath(): string {
    return `/p/match-maker`;
  }
  static getMatchMakerQuestionPage1Path(): string {
    return `/p/match-maker/question-page1`;
  }
  static getMatchMakerQuestionPage2Path(): string {
    return `/p/match-maker/question-page2`;
  }
  static getMatchMakerQuestionPage3Path(): string {
    return `/p/match-maker/question-page3`;
  }
  static getMatchMakerQuestionPage4Path(): string {
    return `/p/match-maker/question-page4`;
  }
  static getMatchMakerQuestionPage5Path(): string {
    return `/p/match-maker/question-page5`;
  }
  static getMatchMakerSummaryPagePath(): string {
    return `/p/match-maker/summary`;
  }
  static getMatchMakerQuestionPage1EditPath(): string {
    return `/p/match-maker/edit/question-page1`;
  }
  static getMatchMakerQuestionPage2EditPath(): string {
    return `/p/match-maker/edit/question-page2`;
  }
  static getMatchMakerQuestionPage3EditPath(): string {
    return `/p/match-maker/edit/question-page3`;
  }
  static getMatchMakerQuestionPage4EditPath(): string {
    return `/p/match-maker/edit/question-page4`;
  }

  static getVehicleExchangePath(id = ":id"): string {
    return `/p/car-exchange/${id}`;
  }

  static getQuotePath(id = ":id"): string {
    return `/p/quote/${id}`;
  }

  static getSavedOffersPath(currentLocation = ""): string {
    if (currentLocation) {
      return `/p/saved-offers?prev=${currentLocation}`;
    }
    return `/p/saved-offers`;
  }

  static getLogoutPath(): string {
    return `/api/v1/logout-fsid`;
  }

  static getFullDetailsPath(id = ":quoteID", currentLocation = ""): string {
    if (currentLocation) {
      return `/p/full-details/${id}?prev=${currentLocation}`;
    }
    return `/p/full-details/${id}`;
  }

  static getDealerAppointmentPath(vin = ":vin", id = ":id"): string {
    return `/p/dealer-appointment/${vin}/${id}`;
  }
}

export declare type RouterHelpersQuotePath = RouteComponentProps<{
  id: string;
}>;

export declare type RouterHelpersFullDetailsPath = RouteComponentProps<{
  quoteID: string;
}>;
