import { gql } from "@apollo/client";

const fragments = {
  allMatchMakerAnswerFields: gql`
    fragment AllMatchMakerAnswerFields on MatchMakerAnswers {
      carSearchType
      typeOfCar
      modelOfCar
      colorOfCar
      typeOfTrain
      parkingType
      numberOfDoors
      typeOfGears
      fuelTypes
      importantAspects
      expectedMonthlyPayment
      expectedDuration
      milesPerYear
      additionalDeposit
      bonusPayment
      financePlan
      isCompleted
      sortingOption
    }
  `,
  allContractFields: gql`
    fragment AllContractFields on Contract {
      contractNumber
      eligibleStatus
      vin
      brandName
      downPayment
      bonusPayment
      licensePlate1
      licensePlate2
      licensePlate3
      contractType
      contractTypeText
      hasMonthlyPayment
      installmentAmount
      term
      endDate
      carModel
      currentVehicleValue
      amountToSettle
      equity
      customerEligibility
      retailer {
        street
        address
        website
        name
        phoneNumber
        email
        code
      }
    }
  `,
  allVehicleOfferFields: gql`
    fragment AllVehicleOfferFields on VehicleOffer {
      typeOfMatch
      hasExpired
      expirationTime
      lastModified
      wasSelected
      wantsToBeContacted
      wasSaved
      wasRemoved @client
      vehicle {
        vin
        urlOfCarImage
        carModel
        carModelCode
        carModelVariant
        drivingStyle
        numberOfDoors
        fuelType
        interiorColor
        exteriorColor
        images
        equipment {
          equipmentName
        }
        carWidth
      }
      quote {
        annualPercentageRate
        installment
        term
        quoteId
      }
    }
  `,
  allFinanceDetailsFields: gql`
    fragment AllFinanceDetailsFields on FinanceDetails {
      quote {
        quoteId
        annualPercentageRate
        brandContribution
        contractType
        finalPayment
        installment
        interestRate
        optionToPurchaseFee
        retailCashPrice
        term
        totalCredit
        additionalDeposit
        bonusPayment
        firstPayment
      }
      carVin
      carModel
      carImages
      carModelVariant
      wasSaved
    }
  `
};

export { fragments };
