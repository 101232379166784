import { QuestionItem, isAvailableIn } from "@renewal/rosie";
import audiAvant from "@renewal/rosie/lib/lib/icons/audi/avant.svg";
import audiCabriolet from "@renewal/rosie/lib/lib/icons/audi/cabriolet.svg";
import audiCoupe from "@renewal/rosie/lib/lib/icons/audi/coupe.svg";
import audiSaloon from "@renewal/rosie/lib/lib/icons/audi/saloon.svg";
import audiSportback from "@renewal/rosie/lib/lib/icons/audi/sportback.svg";
import audiSuv from "@renewal/rosie/lib/lib/icons/audi/suv.svg";
import vwCoupe from "@renewal/rosie/lib/lib/icons/vw/coupe.svg";
import vwEstate from "@renewal/rosie/lib/lib/icons/vw/estate.svg";
import vwHatchback from "@renewal/rosie/lib/lib/icons/vw/hatchback.svg";
import vwMPV from "@renewal/rosie/lib/lib/icons/vw/MPV.svg";
import vwSaloon from "@renewal/rosie/lib/lib/icons/vw/saloon.svg";
import vwSuv from "@renewal/rosie/lib/lib/icons/vw/SUV.svg";
import * as Switch from "@renewal/rosie/lib/utils/asset-switches";
import {
  Icons,
  bronsonIconSwitch,
  reactNodeSwitch
} from "@renewal/rosie/lib/utils/asset-switches";
import { TFunction } from "i18next";

import {
  IDrivingStyle,
  IFinancePlan,
  IFuelType,
  IParkingType
} from "../../__generated__/graphql-types";

export function carTypeListAudi(t: TFunction): QuestionItem<string>[] {
  return [
    {
      displayName: t("question-pages.items.bodystyle.options.one"),
      value: Switch.imgSwitch("SPORTBACK", "HATCHBACK"),
      description: t("question-pages.items.bodystyle.options.one-example"),
      img: Switch.imgSwitch(audiSportback, vwHatchback),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxSportback",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.bodystyle.options.two"),
      value: "SEDAN",
      description: t("question-pages.items.bodystyle.options.two-example"),
      img: Switch.imgSwitch(audiSaloon, vwSaloon),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxSedan",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.bodystyle.options.three"),
      value: Switch.imgSwitch("AVANT", "WAGON"),
      description: t("question-pages.items.bodystyle.options.three-example"),
      img: Switch.imgSwitch(audiAvant, vwEstate),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxAvant",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.bodystyle.options.four"),
      value: "COUPE",
      description: t("question-pages.items.bodystyle.options.four-example"),
      img: Switch.imgSwitch(audiCoupe, vwCoupe),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxCoupe",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.bodystyle.options.five"),
      value: "SUV",
      description: t("question-pages.items.bodystyle.options.five-example"),
      img: Switch.imgSwitch(audiSuv, vwSuv),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxSUV",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.bodystyle.options.six"),
      value: Switch.imgSwitch("SPORT", "MPV"),
      description: t("question-pages.items.bodystyle.options.six-example"),
      img: Switch.imgSwitch(audiCabriolet, vwMPV),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxSport",
      availableIn: true
    }
  ];
}

export function carTypeListVolkswagen(t: TFunction): QuestionItem<string>[] {
  return [
    {
      displayName: t("question-pages.items.bodystyle.options.one"),
      value: Switch.imgSwitch("SPORTBACK", "HATCHBACK"),
      description: t("question-pages.items.bodystyle.options.one-example"),
      img: Switch.imgSwitch(audiSportback, vwHatchback),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxSportback",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.bodystyle.options.two"),
      value: Switch.imgSwitch("AVANT", "WAGON"),
      description: t("question-pages.items.bodystyle.options.two-example"),
      img: Switch.imgSwitch(audiAvant, vwEstate),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxAvant",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.bodystyle.options.three"),
      value: "SUV",
      description: t("question-pages.items.bodystyle.options.three-example"),
      img: Switch.imgSwitch(audiSuv, vwSuv),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxSUV",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.bodystyle.options.four"),
      value: "SEDAN",
      description: t("question-pages.items.bodystyle.options.four-example"),
      img: Switch.imgSwitch(audiSaloon, vwSaloon),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxSedan",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.bodystyle.options.five"),
      value: "COUPE",
      description: t("question-pages.items.bodystyle.options.five-example"),
      img: Switch.imgSwitch(audiCoupe, vwCoupe),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxCoupe",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.bodystyle.options.six"),
      value: Switch.imgSwitch("SPORT", "MPV"),
      description: t("question-pages.items.bodystyle.options.six-example"),
      img: Switch.imgSwitch(audiCabriolet, vwMPV),
      icon: Switch.bronsonIconSwitchEmpty(),
      trackingName: "checkboxSport",
      availableIn: true
    }
  ];
}

export function carModelList(): QuestionItem<string>[] {
  return [
    {
      displayName: Switch.imgSwitch("A1", "T-Cross"),
      availableIn: true,
      value: Switch.imgSwitch("A1", "T-Cross"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("A3", "T-Roc"),
      availableIn: true,
      value: Switch.imgSwitch("A3", "T-Roc"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("A4", "Tiguan"),
      availableIn: true,
      value: Switch.imgSwitch("A4", "Tiguan"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("A5", "Golf Touran"),
      availableIn: true,
      value: Switch.imgSwitch("A5", "Golf Touran"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("A6", "Sharan"),
      availableIn: true,
      value: Switch.imgSwitch("A6", "Sharan"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("A7", "Golf Variant"),
      availableIn: true,
      value: Switch.imgSwitch("A7", "Golf Variant"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("A8", "Golf Alltrack"),
      availableIn: true,
      value: Switch.imgSwitch("A8", "Golf Alltrack"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("Q2", "Passat Variant"),
      availableIn: true,
      value: Switch.imgSwitch("Q2", "Passat Variant"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("Q3", "Passat Alltrack"),
      availableIn: true,
      value: Switch.imgSwitch("Q3", "Passat Alltrack"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("Q5", "Polo"),
      availableIn: true,
      value: Switch.imgSwitch("Q5", "Polo"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("Q7", "Golf"),
      availableIn: true,
      value: Switch.imgSwitch("Q7", "Golf"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("Q8", "Passat"),
      availableIn: true,
      value: Switch.imgSwitch("Q8", "Passat"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("TT", "Arteon"),
      availableIn: true,
      value: Switch.imgSwitch("TT", "Arteon"),
      icon: ""
    },
    {
      displayName: Switch.imgSwitch("R8", "Arteon Shooting Brake"),
      availableIn: true,
      value: Switch.imgSwitch("R8", "Arteon Shooting Brake"),
      icon: ""
    },
    {
      displayName: "E-TRON",
      availableIn: isAvailableIn(["audi"]),
      value: "E-TRON",
      icon: ""
    },
    {
      displayName: "RS",
      availableIn: isAvailableIn(["audi"]),
      value: "RS",
      icon: ""
    }
  ].map(option => {
    return { ...option, trackingName: `checkbox${option.value}` };
  });
}

export function trainTypeList(t: TFunction): QuestionItem<string>[] {
  return [
    {
      displayName: t("question-pages.items.drivetrain.options.one"),
      value: "2WD",
      icon: Icons.FrontWheel,
      trackingName: "checkboxFWD",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.drivetrain.options.two"),
      value: "4WD",
      icon: Icons.Drivetrain,
      trackingName: "checkbox4WD",
      availableIn: true
    }
  ];
}

export function colorList(t: TFunction): QuestionItem<string>[] {
  return [
    {
      displayName: t("question-pages.items.color.options.one"),
      value: "BLACK",
      icon: "",
      trackingName: `color${t("question-pages.items.color.options.one")}`,
      availableIn: true
    },
    {
      displayName: t("question-pages.items.color.options.two"),
      value: "WHITE",
      icon: "",
      trackingName: `color${t("question-pages.items.color.options.two")}`,
      availableIn: true
    },
    {
      displayName: t("question-pages.items.color.options.three"),
      value: "GRAY",
      icon: "",
      trackingName: `color${t("question-pages.items.color.options.three")}`,
      availableIn: true
    },
    {
      displayName: t("question-pages.items.color.options.four"),
      value: "BROWN",
      icon: "",
      trackingName: `color${t("question-pages.items.color.options.four")}`,
      availableIn: true
    },
    {
      displayName: t("question-pages.items.color.options.five"),
      value: "RED",
      icon: "",
      trackingName: `color${t("question-pages.items.color.options.five")}`,
      availableIn: true
    },
    {
      displayName: t("question-pages.items.color.options.six"),
      value: "ORANGE",
      icon: "",
      trackingName: `color${t("question-pages.items.color.options.six")}`,
      availableIn: true
    },
    {
      displayName: t("question-pages.items.color.options.seven"),
      value: "YELLOW",
      icon: "",
      trackingName: `color${t("question-pages.items.color.options.seven")}`,
      availableIn: true
    },
    {
      displayName: t("question-pages.items.color.options.eight"),
      value: "GREEN",
      icon: "",
      trackingName: `color${t("question-pages.items.color.options.eight")}`,
      availableIn: true
    },
    {
      displayName: t("question-pages.items.color.options.nine"),
      value: "BLUE",
      icon: "",
      trackingName: `color${t("question-pages.items.color.options.nine")}`,
      availableIn: true
    },
    {
      displayName: t("question-pages.items.color.options.ten"),
      value: "PURPLE",
      icon: "",
      trackingName: `color${t("question-pages.items.color.options.ten")}`,
      availableIn: true
    }
  ];
}

export function transmissionList(t: TFunction): QuestionItem<string>[] {
  return [
    {
      displayName: t("question-pages.items.gears.options.one"),
      value: "Manual",
      icon: Icons.TransmissionManual,
      trackingName: "checkboxManual",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.gears.options.two"),
      value: "Automatic",
      icon: Icons.TransmissionAutomatic,
      trackingName: "checkboxAutomatic",
      availableIn: true
    }
  ];
}
export function fuelList(t: TFunction): QuestionItem<IFuelType>[] {
  return [
    {
      displayName: t("question-pages.items.fuel.options.one"),
      value: IFuelType.GASOLINE,
      icon: Icons.FuelPetrol,
      trackingName: "checkboxPetrol",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.fuel.options.two"),
      value: IFuelType.DIESEL,
      icon: Icons.FuelDiesel,
      trackingName: "checkboxDiesel",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.fuel.options.three"),
      value: IFuelType.ELECTRIC,
      icon: Icons.FuelElectric,
      trackingName: "checkboxElectric",
      availableIn: true
    }
  ];
}
export function drivingStyleList(t: TFunction): QuestionItem<IDrivingStyle>[] {
  return [
    {
      displayName: t("question-pages.items.driving-style.options.one"),
      value: IDrivingStyle.EFFICIENCY,
      icon: Icons.Efficiency,
      trackingName: "checkboxEfficiency",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.driving-style.options.two"),
      value: IDrivingStyle.PERFORMANCE,
      icon: Icons.Performance,
      trackingName: "checkboxPerformance",
      availableIn: true
    }
  ];
}
export function parkingTypeList(t: TFunction): QuestionItem<IParkingType>[] {
  return [
    {
      displayName: t("question-pages.items.width.options.one"),
      value: IParkingType.NORMAL,
      icon: bronsonIconSwitch("parking", "semantic-download"),
      trackingName: "checkboxParkingType1",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.width.options.two"),
      value: IParkingType.WIDE,
      icon: bronsonIconSwitch("caution", "alert"),
      trackingName: "checkboxParkingType2",
      availableIn: true
    }
  ];
}
export function financePlanList(t: TFunction): QuestionItem<IFinancePlan>[] {
  return [
    {
      displayName: t("question-pages.items.finance-plan.options.one"),
      displayNameSecondLine: t(
        "question-pages.items.finance-plan.options.one-subtitle"
      ),
      description: t(
        "question-pages.items.finance-plan.options.one-description"
      ),
      value: IFinancePlan.FUTURE_DRIVE,
      icon: "",
      img: window.PUBLIC_URL + "/images/AFD_image.svg",
      trackingName: "GuaranteedFutureLoan",
      availableIn: true
    },
    {
      displayName: t("question-pages.items.finance-plan.options.two"),
      displayNameSecondLine: t(
        "question-pages.items.finance-plan.options.two-subtitle"
      ),
      description: t(
        "question-pages.items.finance-plan.options.two-description"
      ),
      value: IFinancePlan.SLOAN,
      icon: "",
      img: window.PUBLIC_URL + "/images/S_loan_image.svg",
      trackingName: "BalloonLoan",
      availableIn: true
    }
  ];
}

function fieldOptions(t: TFunction): {
  [key: string]: QuestionItem<string | number>[];
} {
  return {
    typeOfCar: reactNodeSwitch(
      carTypeListAudi(t),
      carTypeListVolkswagen(t)
    ) as QuestionItem<string | number>[],
    modelOfCar: carModelList(),
    typeOfTrain: trainTypeList(t),
    parkingType: parkingTypeList(t),
    financePlan: financePlanList(t),
    fuelTypes: fuelList(t),
    importantAspects: drivingStyleList(t),
    colorOfCar: colorList(t),
    typeOfGears: transmissionList(t)
  };
}

export const getFieldSummaryText = (
  objectContainsField: object,
  fieldName: string,
  t: TFunction
): string => {
  const questionItemList: QuestionItem<string | number>[] =
    fieldOptions(t)[fieldName];
  if (!objectContainsField) return "";

  if (!questionItemList) return "";

  if (!Object.keys(objectContainsField).includes(fieldName)) return "";

  // @ts-ignore, need to access objectContainsField[fieldName] dynamically
  const fieldValue = objectContainsField[fieldName];

  if (Array.isArray(fieldValue) === false) {
    // handle single value (radio button case)
    const displayItem = questionItemList.find(
      item => item.value === fieldValue
    );
    return displayItem ? displayItem.displayName : "";
  } else {
    // handle multiple values (checkbox case)
    const results: string[] = [];
    // @ts-ignore, we need to access fieldName property's value dynamically
    fieldValue.forEach(value => {
      const displayItem = questionItemList.find(item => item.value === value);
      if (displayItem) {
        results.push(displayItem.displayName);
      }
    });

    return results.join(", ");
  }
};

export const getFuelTypeText = (fuelType: IFuelType, t: TFunction): string => {
  return getFieldSummaryText({ fuelTypes: [fuelType] }, "fuelTypes", t);
};
