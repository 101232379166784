"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.ErrorPageTemplate = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_dom_1 = require("react-router-dom");
var ErrorPageTemplate = function (_a) {
    var errorMsg = _a.errorMsg, errorCode = _a.errorCode, errorExplanation = _a.errorExplanation, btnText = _a.btnText, redirectTo = _a.redirectTo, children = _a.children;
    return (jsx_runtime_1.jsx("div", __assign({ className: "o-main" }, { children: jsx_runtime_1.jsx("section", __assign({ className: "o-content-section" }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "o-page-wrap" }, { children: [jsx_runtime_1.jsxs("header", __assign({ className: "c-section-heading c-section-heading--primary c-section-heading--alternative" }, { children: [jsx_runtime_1.jsx("p", __assign({ className: "u-text-fs-1 u-text-brand u-mb-none" }, { children: errorCode }), void 0), jsx_runtime_1.jsx("h1", __assign({ className: "c-section-heading__title u-text-brand u-mb-none" }, { children: jsx_runtime_1.jsx("span", __assign({ className: "c-section-heading__title-text" }, { children: errorMsg }), void 0) }), void 0), jsx_runtime_1.jsx("p", __assign({ className: "c-section-heading__subtitle u-text-brand" }, { children: errorExplanation }), void 0)] }), void 0), jsx_runtime_1.jsx("div", __assign({ className: "o-layout u-mt-large" }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "o-layout__item" }, { children: [children, btnText && redirectTo ? (jsx_runtime_1.jsx(react_router_dom_1.Link, __assign({ className: "c-btn u-3/12 u-1/3@m u-1/1@s", to: redirectTo }, { children: jsx_runtime_1.jsx("span", __assign({ className: "c-btn__text" }, { children: btnText }), void 0) }), void 0)) : null] }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0));
};
exports.ErrorPageTemplate = ErrorPageTemplate;
