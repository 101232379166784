import { ErrorPageTemplate } from "@renewal/rosie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { RouterHelpers } from "../../router/router-helpers";

const SessionExpiredPage: React.FC = () => {
  const { t } = useTranslation("error-pages");

  useEffect(() => {
    adobeDataLayer.errorPage({
      errorCode: "401",
      errorMessage: `${t("expired-description-1")}${t("expired-description-2")}`
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorPageTemplate
        errorCode=""
        errorMsg={t("expired-title")}
        errorExplanation={
          <>
            {t("expired-description-1")}
            <br />
            {t("expired-description-2")}
          </>
        }
        redirectTo={RouterHelpers.getLandingPagePath()}
        btnText={t("shared:back-button")}
      />
    </>
  );
};

export default SessionExpiredPage;
