import React, { Component } from "react";

import { IAllContractFieldsFragment } from "../../../__generated__/graphql-types";
import { RetailerInfo } from "./RetailerInfo";

interface OwnProps {
  contractData: IAllContractFieldsFragment;
}
type AllProps = OwnProps;

class RetailerInfoContainer extends Component<AllProps> {
  render(): JSX.Element {
    return <RetailerInfo retailer={this.props.contractData.retailer} />;
  }
}

export default RetailerInfoContainer;
