import { Breakpoints, ExchangeStates, SelectionStates } from "@renewal/rosie";
import { cloneDeep as _cloneDeep } from "lodash";

import {
  IAllVehicleOfferFieldsFragment,
  IMatchMakerAnswers,
  IVehicleOffer
} from "../../__generated__/graphql-types";
import { RouterHelpers } from "../../router/router-helpers";

const brandMapper = (brand: string): string => {
  switch (brand) {
    case "volkswagen":
      return "vw";
    case "bluelabel":
      return "vw";
    default:
      return brand;
  }
};

const dataTemplate: AdobeDataLayerObject = {
  core: {
    dataLayerVersion: "2.7",
    pageInfo: {
      pageName: "",
      intendedCustomerDeviceType: "all",
      version: "R1.0",
      language: "ja",
      releaseDate: "2021-04-29",
      market: "JP",
      publisher: "DU"
    },
    category: {
      primaryCategory: "Digital renewal",
      siteType: "standalone",
      maturityLevel: "Lead"
    },
    attributes: {
      brand: brandMapper(
        process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND : "audi"
      ),
      journeyType: "customer-facing-product-journey",
      viewChange: ""
    }
  },
  error: {
    errorCode: "",
    errorMessage: "",
    errorCausingURL: ""
  },
  product: [
    {
      name: "DigitalRenewal",
      category: "Finance",
      attributes: {
        typeOfSale: "Hybrid sales",
        paymentFrequency: "monthly payment",
        currency: "JPY",
        duration: "36",
        durationUnit: "MONTH",
        yearlyMileage: "20000",
        mileageUnit: "KILOMETERS"
      },
      vehicleModel: [
        {
          typeOfUse: "Private",
          currency: "JPY",
          condition: "New car",
          certifiedPreOwned: "false",
          engine: {
            fuelType: ""
          }
        }
      ]
    }
  ],
  form: {
    name: "",
    type: ""
  },
  dealerData: {
    companyId: "",
    companyName: ""
  },
  design: {
    browserResolutionBreakpoint: Breakpoints.Tablet.Max.toString()
  },
  customerData: {
    loggedInUserGroup: "private"
  },
  filter: [],
  dataPrivacyStatement: {},
  event: []
};

class AdobeDataLayerClass {
  private dealerData: DealerData = dataTemplate.dealerData;
  private product: Product[] = dataTemplate.product;

  public setDealerData(dealerData: DealerData) {
    this.dealerData = dealerData;
  }
  public setProduct(vehicleOffer: IVehicleOffer) {
    this.product = [this.getProduct(vehicleOffer)];
  }

  private getProduct(
    vehicleOffer: IVehicleOffer | IAllVehicleOfferFieldsFragment
  ): Product {
    const vehicle: VehicleModel = {
      certifiedPreOwned: "false",
      engine: {
        fuelType: vehicleOffer.vehicle.fuelType
      },
      typeOfUse: "Private",
      bodyType: vehicleOffer.vehicle.carWidth,
      manufacturer: process.env.REACT_APP_BRAND,
      modelVariation: vehicleOffer.vehicle.carModelVariant,
      name: vehicleOffer.vehicle.carModel,
      vehicleCategory: vehicleOffer.vehicle.carModelCode,
      currency: "JPY",
      condition: "New car"
    };

    const product: Product = {
      name: "DigitalRenewal",
      productVariants: ["PCP"],
      category: "Finance",
      attributes: {
        duration: "36",
        durationUnit: "MONTH",
        mileageUnit: "KILOMETERS",
        typeOfSale: "Hybrid sales",
        yearlyMileage: "20000",
        paymentFrequency: "monthly payment",
        currency: "JPY",
        recurringPayment: vehicleOffer.quote.installment,
        nominalInterestRate: vehicleOffer.quote.annualPercentageRate
      },
      vehicleModel: [vehicle]
    };

    return product;
  }

  private sleep(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
  private pathPageNameMapping = [
    {
      pathName: RouterHelpers.getLandingPagePath(),
      pageName: "Landing Page"
    },
    {
      pathName: RouterHelpers.getLegalNoticePath(),
      pageName: "Legal Notice"
    },
    {
      pathName: RouterHelpers.getPrivacyPolicyPath(),
      pageName: "Privacy Policy"
    },
    {
      pathName: RouterHelpers.getCookiePolicyPath(),
      pageName: "Cookie Policy"
    },
    {
      pathName: RouterHelpers.getFAQPath(),
      pageName: "FAQ"
    },
    {
      pathName: RouterHelpers.getRegisterPagePath(),
      pageName: "KYC Verification"
    },
    {
      pathName: RouterHelpers.getErrorPageNotEligiblePath(),
      pageName: "Not Eligible"
    },
    {
      pathName: RouterHelpers.getErrorPagePublicNotEligiblePath(),
      pageName: "Public Not Eligible"
    },
    {
      pathName: RouterHelpers.getMatchMakerQuestionPage1Path(),
      pageName: "MM Page 1: Body & Transmission"
    },
    {
      pathName: RouterHelpers.getMatchMakerQuestionPage1EditPath(),
      pageName: "Edit MM Page 1: Body & Transmission"
    },
    {
      pathName: RouterHelpers.getMatchMakerQuestionPage2Path(),
      pageName: "MM Page 2: Fuel & Performance"
    },
    {
      pathName: RouterHelpers.getMatchMakerQuestionPage2EditPath(),
      pageName: "Edit MM Page 2: Fuel & Performance"
    },
    {
      pathName: RouterHelpers.getMatchMakerQuestionPage3Path(),
      pageName: "MM Page 3: Trade-In"
    },
    {
      pathName: RouterHelpers.getMatchMakerQuestionPage3EditPath(),
      pageName: "Edit MM Page 3: Trade-In"
    },
    {
      pathName: RouterHelpers.getMatchMakerQuestionPage4Path(),
      pageName: "MM Page 4: Budget & Deposit"
    },
    {
      pathName: RouterHelpers.getMatchMakerQuestionPage4EditPath(),
      pageName: "Edit MM Page 4: Budget & Deposit"
    },
    {
      pathName: RouterHelpers.getMatchMakerSummaryPagePath(),
      pageName: "MM Summary"
    },
    {
      pathName: RouterHelpers.getVehicleSelectionPath(),
      pageName: "Your Matches Page"
    },
    {
      pathName: RouterHelpers.getSavedOffersPath(),
      pageName: "Saved Offers Page"
    },
    {
      pathName: RouterHelpers.getRenewalOverviewPath(),
      pageName: "Renewal Dashboard"
    },
    {
      pathName: RouterHelpers.getQuotePath(),
      pageName: "Finance Quote"
    },
    {
      pathName: RouterHelpers.getVehicleOfferPath(),
      pageName: "Lead Submission"
    },
    {
      pathName: RouterHelpers.getFullDetailsPath(),
      pageName: "Vehicle Details Page"
    },
    {
      pathName: RouterHelpers.getContractSelectionPath(),
      pageName: "Contract Selection Page"
    }
  ];

  private isFirstTimeLandingPage = true;

  private basicInfo(useProduct?: boolean) {
    const trackingData = _cloneDeep(dataTemplate);

    trackingData.dealerData = this.dealerData;
    if (useProduct) {
      trackingData.product = this.product;
    }

    return trackingData;
  }

  private getBaseInfoWithFilters(
    mmAnswers: IMatchMakerAnswers,
    useProduct?: boolean
  ) {
    const trackingData = this.basicInfo(useProduct);

    mmAnswers.typeOfCar &&
      trackingData.filter.push({
        filterName: "Body type",
        filterValue: mmAnswers.typeOfCar
      });
    mmAnswers.colorOfCar &&
      trackingData.filter.push({
        filterName: "Exterior color",
        filterValue: mmAnswers.colorOfCar
      });
    mmAnswers.colorOfCar &&
      trackingData.filter.push({
        filterName: "Drive type",
        filterValue: mmAnswers.typeOfTrain as string[]
      });
    mmAnswers.parkingType &&
      trackingData.filter.push({
        filterName: "Parking type",
        filterValue: mmAnswers.parkingType
      });
    mmAnswers.fuelTypes &&
      trackingData.filter.push({
        filterName: "Fuel type",
        filterValue: mmAnswers.fuelTypes
      });
    mmAnswers.importantAspects &&
      trackingData.filter.push({
        filterName: "Driving style",
        filterValue: mmAnswers.importantAspects
      });
    mmAnswers.expectedMonthlyPayment &&
      trackingData.filter.push({
        filterName: "Rate",
        filterValue: [`${mmAnswers.expectedMonthlyPayment} EUR`]
      });
    mmAnswers.additionalDeposit &&
      trackingData.filter.push({
        filterName: "Downpayment",
        filterValue: [`${mmAnswers.additionalDeposit} EUR`]
      });
    mmAnswers.bonusPayment &&
      trackingData.filter.push({
        filterName: "Bonus payment",
        filterValue: [mmAnswers.bonusPayment.toString()]
      });
    mmAnswers.expectedDuration &&
      trackingData.filter.push({
        filterName: "Contract duration",
        filterValue: [mmAnswers.expectedDuration.toString()]
      });
    mmAnswers.financePlan &&
      trackingData.filter.push({
        filterName: "Financing plan",
        filterValue: [mmAnswers.financePlan]
      });

    return trackingData;
  }

  private getPathName(pathName: string) {
    let pathNameWithoutParameter = pathName || "";

    if (pathName.startsWith("/p/quote")) {
      pathNameWithoutParameter = RouterHelpers.getQuotePath();
    }

    if (pathName.startsWith("/p/full-details")) {
      pathNameWithoutParameter = RouterHelpers.getFullDetailsPath();
    }

    return this.pathPageNameMapping.find(
      item => item.pathName === pathNameWithoutParameter
    );
  }

  private track(
    trackingData: AdobeDataLayerObject,
    interactionType:
      | "success"
      | "receivedPriorModel"
      | "carSelectedInDataLayer"
      | "interaction"
      | "page"
  ) {
    try {
      window.du_digitalData = trackingData;
      window._satellite.track(interactionType);
    } catch (e) {
      if (process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-console
        console.error(`message: ${e.message}, stack: ${e.stack}`);
      }
    }
  }

  private landingPageBaseInfo() {
    const trackingData = this.basicInfo();

    trackingData.core.pageInfo.pageName = "Landing page";
    trackingData.core.attributes.viewChange = "Landing page";
    trackingData.customerData.loginStatus = false;

    return trackingData;
  }

  public async landingPage() {
    await this.sleep(1000);
    const trackingData = this.landingPageBaseInfo();

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };

    trackingData.event.push(event);

    if (this.isFirstTimeLandingPage) {
      this.isFirstTimeLandingPage = false;

      const firstTimeEvent: AdobeDataLayerEvent = {
        eventInfo: {
          eventAction: "Start",
          eventType: "Product journey"
        }
      };

      trackingData.event.push(firstTimeEvent);
    }

    this.track(trackingData, "page");
  }

  public landingPageStartMM() {
    const trackingData = this.landingPageBaseInfo();

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        linkInformation: "Start Match Maker",
        eventAction: "Request",
        eventType: "Authentication"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  public async registerPage() {
    await this.sleep(1000);
    const trackingData = this.basicInfo();

    trackingData.core.pageInfo.pageName = "Registration";
    trackingData.core.attributes.viewChange = "Registration";

    trackingData.form.type = "Registration";
    trackingData.form.name = "Registration";

    trackingData.customerData.loginStatus = false;

    const viewEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };
    const startEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Start",
        eventType: "Registration"
      }
    };

    trackingData.event.push(viewEvent);
    trackingData.event.push(startEvent);

    this.track(trackingData, "page");
  }

  public async abortRegistration(lastTouchedField: string) {
    const trackingData = this.basicInfo();

    trackingData.core.pageInfo.pageName = "Registration";
    trackingData.core.attributes.viewChange = "Registration";

    trackingData.form.type = "Registration";
    trackingData.form.name = "Registration";
    trackingData.form.lastTouchedField = lastTouchedField;

    trackingData.customerData.loginStatus = false;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Aborted",
        eventType: "Form",
        linkInformation: "Registration: Form abort"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
    await this.sleep(1000);
  }

  private tanBasicInfo() {
    const trackingData = this.basicInfo();

    trackingData.core.pageInfo.pageName = "Registration";
    trackingData.core.attributes.viewChange = "Registration - TAN input";

    trackingData.form.type = "Registration";
    trackingData.form.name = "Registration";

    trackingData.customerData.loginStatus = false;

    return trackingData;
  }

  public async tanInput() {
    await this.sleep(1000);
    const trackingData = this.tanBasicInfo();

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "page");
  }

  public async tanAborted(lastTouched: string) {
    const trackingData = this.tanBasicInfo();

    trackingData.form.lastTouchedField = lastTouched;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Aborted",
        eventType: "Form",
        linkInformation: "Registration: TAN input: Form abort"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
    await this.sleep(1000);
  }

  public async tanSuccess() {
    const trackingData = this.tanBasicInfo();

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "Registration",
        linkInformation: "Registration success"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
    await this.sleep(1000);
  }

  public async logout() {
    const trackingData = this.basicInfo();

    const pathObject = this.getPathName(
      window.location.hash.substring(1, window.location.hash.length)
    );

    if (!pathObject) {
      return;
    }

    trackingData.core.pageInfo.pageName = pathObject.pageName;
    trackingData.core.attributes.viewChange = pathObject.pageName;

    trackingData.customerData.loginStatus = true;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "Logout"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");

    await this.sleep(1000);
  }

  public entryPoint() {
    const trackingData = this.basicInfo();

    trackingData.core.pageInfo.pageName = "Entry from FS.ID";
    trackingData.core.attributes.viewChange = "Entry from FS.ID";

    trackingData.customerData.loginStatus = true;

    const interActionEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "Entry from FS.ID"
      }
    };
    const contactEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Provided",
        eventType: "ProductInformation:Contact Information"
      }
    };
    const personalEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Provided",
        eventType: "ProductInformation:Personal Information"
      }
    };

    trackingData.event.push(interActionEvent);
    trackingData.event.push(contactEvent);
    trackingData.event.push(personalEvent);

    this.track(trackingData, "interaction");
  }

  public async contractSelection() {
    await this.sleep(1000);
    const trackingData = this.basicInfo();

    trackingData.core.pageInfo.pageName = "Contract selection";
    trackingData.core.attributes.viewChange = "Contract selection";

    trackingData.customerData.loginStatus = true;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "page");
  }

  public async matchMakerQuestionPage(
    number: number,
    themes:
      | "Body & Drive type"
      | "Fuel & Performance"
      | "Financial"
      | "Financing plan"
      | "Equity"
  ) {
    await this.sleep(1000);
    const trackingData = this.basicInfo();

    trackingData.core.pageInfo.pageName = "Match maker";
    trackingData.core.attributes.viewChange = `Question page ${number}: ${themes}`;

    trackingData.customerData.loginStatus = true;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "page");
  }

  public async matchMakerSummary(mmAnswers: IMatchMakerAnswers) {
    await this.sleep(1000);
    const trackingData = this.getBaseInfoWithFilters(mmAnswers);

    trackingData.core.pageInfo.pageName = "Match maker summary";
    trackingData.core.attributes.viewChange = "Match maker summary";

    trackingData.customerData.loginStatus = true;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "page");
  }

  public matchMakerSummaryEdit(
    sectionName:
      | "Body & Drive type"
      | "Fuel & Performance"
      | "Financial"
      | "Financing plan",
    mmAnswers: IMatchMakerAnswers
  ) {
    const trackingData = this.getBaseInfoWithFilters(mmAnswers);

    trackingData.core.pageInfo.pageName = "Match maker summary";
    trackingData.core.attributes.viewChange = "Match maker summary";

    trackingData.customerData.loginStatus = true;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: `Edit ${sectionName}`
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  private carSelectionBaseInfo(
    mmAnswers: IMatchMakerAnswers,
    useProduct?: boolean
  ) {
    const trackingData = this.getBaseInfoWithFilters(mmAnswers, useProduct);

    trackingData.core.pageInfo.pageName = "Car selection";
    trackingData.core.attributes.viewChange = "Car selection";

    trackingData.customerData.loginStatus = true;

    return trackingData;
  }

  public async carSelection(mmAnswers: IMatchMakerAnswers) {
    await this.sleep(1000);
    const trackingData = this.carSelectionBaseInfo(mmAnswers);

    const viewEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };
    const financialInfoEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Provided",
        eventType: "ProductInformation:Financial Information"
      }
    };
    const priceEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Calculated",
        eventType: "Product price"
      }
    };

    trackingData.event.push(viewEvent);
    trackingData.event.push(financialInfoEvent);
    trackingData.event.push(priceEvent);

    this.track(trackingData, "page");
  }

  public carSelectionSortingChanged(
    newOrder: string,
    mmAnswers: IMatchMakerAnswers
  ) {
    const trackingData = this.carSelectionBaseInfo(mmAnswers);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: `Sorting change: ${newOrder}`
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  public carSelectionSeeMore(mmAnswers: IMatchMakerAnswers) {
    const trackingData = this.carSelectionBaseInfo(mmAnswers);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "See more offers"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  public carSelectionSaveForLater(mmAnswers: IMatchMakerAnswers) {
    const trackingData = this.carSelectionBaseInfo(mmAnswers, true);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "Save offer"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  public carSelectionEditPreferences(mmAnswers: IMatchMakerAnswers) {
    const trackingData = this.carSelectionBaseInfo(mmAnswers);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "Edit preferences"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  public async carSelectionApplyPreferenceChanges(
    mmAnswers: IMatchMakerAnswers
  ) {
    const trackingData = this.carSelectionBaseInfo(mmAnswers);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "Changed preferences"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");

    await this.sleep(1000);
  }

  public carSelectionPictureFullScreen(mmAnswers: IMatchMakerAnswers) {
    const trackingData = this.carSelectionBaseInfo(mmAnswers);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "View full-screen"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  public carSelectionGetInTouch(mmAnswers: IMatchMakerAnswers) {
    const trackingData = this.carSelectionBaseInfo(mmAnswers);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "Show dealer phone number"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  private financeQuoteBaseInfo(mmAnswers: IMatchMakerAnswers) {
    const trackingData = this.getBaseInfoWithFilters(mmAnswers, true);

    trackingData.core.pageInfo.pageName = "Quote";
    trackingData.core.attributes.viewChange = "Quote";

    trackingData.customerData.loginStatus = true;

    return trackingData;
  }

  public async financeQuote(mmAnswers: IMatchMakerAnswers) {
    await this.sleep(1000);
    const trackingData = this.financeQuoteBaseInfo(mmAnswers);

    const viewEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };
    const vehicleInfoEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Provided",
        eventType: "ProductInformation:Vehicle Information"
      }
    };
    const productConfigEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Completed",
        eventType: "Product configuration"
      }
    };
    const contractDataEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Provided",
        eventType: "RequiredContractData"
      }
    };
    const summaryPageEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "SummaryPage"
      }
    };

    trackingData.event.push(viewEvent);
    trackingData.event.push(vehicleInfoEvent);
    trackingData.event.push(productConfigEvent);
    trackingData.event.push(contractDataEvent);
    trackingData.event.push(summaryPageEvent);

    this.track(trackingData, "page");
  }

  public financeQuoteHoverInfoIcon(
    keyword: string,
    mmAnswers: IMatchMakerAnswers
  ) {
    const trackingData = this.financeQuoteBaseInfo(mmAnswers);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: `Info tip: ${keyword}`
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  public financeQuoteSaveForLater(mmAnswers: IMatchMakerAnswers) {
    const trackingData = this.financeQuoteBaseInfo(mmAnswers);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "Save offer"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  private renewalOverviewBaseInfo(
    mmAnswers: IMatchMakerAnswers,
    carExchangeState: ExchangeStates,
    carSelectionState: SelectionStates
  ) {
    const trackingData = this.getBaseInfoWithFilters(mmAnswers);
    let viewState = "";
    if (carExchangeState === ExchangeStates.nothingSelected) {
      if (carSelectionState === SelectionStates.nothingProvided) {
        viewState = "without preferences";
      } else {
        viewState = "without selected car";
      }
    } else if (carExchangeState === ExchangeStates.carAndQuoteSelected) {
      viewState = "with selected car";
    } else {
      viewState = "already submitted";
    }

    trackingData.core.pageInfo.pageName = "Renewal overview";
    trackingData.core.attributes.viewChange = `Renewal overview - ${viewState}`;

    trackingData.customerData.loginStatus = true;

    return trackingData;
  }

  public async renewalOverview(
    mmAnswers: IMatchMakerAnswers,
    carExchangeState: ExchangeStates,
    carSelectionState: SelectionStates
  ) {
    await this.sleep(1000);
    const trackingData = this.renewalOverviewBaseInfo(
      mmAnswers,
      carExchangeState,
      carSelectionState
    );

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "page");
  }

  public renewalOverviewInteraction(
    mmAnswers: IMatchMakerAnswers,
    carExchangeState: ExchangeStates,
    carSelectionState: SelectionStates,
    action: "Go to edit preferences" | "View matches" | "Submit to dealer"
  ) {
    const trackingData = this.renewalOverviewBaseInfo(
      mmAnswers,
      carExchangeState,
      carSelectionState
    );

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: action
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  public async carOffer(mmAnswers: IMatchMakerAnswers) {
    await this.sleep(1000);
    const trackingData = this.getBaseInfoWithFilters(mmAnswers, true);

    trackingData.core.pageInfo.pageName = "Dealer contact";
    trackingData.core.attributes.viewChange = "Dealer contact";

    trackingData.customerData.loginStatus = true;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "page");
  }

  private dealerSelectionBaseInfo(mmAnswers: IMatchMakerAnswers) {
    const trackingData = this.getBaseInfoWithFilters(mmAnswers, true);

    trackingData.core.pageInfo.pageName = "Dealer contact";
    trackingData.core.attributes.viewChange = "Dealer selection";

    trackingData.customerData.loginStatus = true;

    return trackingData;
  }

  public async dealerSelection(mmAnswers: IMatchMakerAnswers) {
    const trackingData = this.dealerSelectionBaseInfo(mmAnswers);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "page");
  }

  public dealerSelectionEdit(mmAnswers: IMatchMakerAnswers, section: string) {
    const trackingData = this.dealerSelectionBaseInfo(mmAnswers);

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: `Edit ${section}`
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "interaction");
  }

  public dealerSelectionContactSuccess(
    mmAnswers: IMatchMakerAnswers,
    usePhone: boolean
  ) {
    const trackingData = this.dealerSelectionBaseInfo(mmAnswers);

    trackingData.dataPrivacyStatement = {
      allowElectronicAds: false,
      allowElectronicInvoices: false,
      allowMailAds: !usePhone,
      allowPhoneAds: usePhone,
      allowPostalAds: false
    };

    const interActionEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "Send to dealer - success"
      }
    };
    const sendEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "Send lead to Dealer"
      }
    };
    const saleEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "Sale"
      }
    };
    const journeyEndEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "End",
        eventType: "Product journey"
      }
    };

    trackingData.event.push(interActionEvent);
    trackingData.event.push(sendEvent);
    trackingData.event.push(saleEvent);
    trackingData.event.push(journeyEndEvent);

    this.track(trackingData, "interaction");
  }

  public dealerSelectionContactError(mmAnswers: IMatchMakerAnswers) {
    const trackingData = this.dealerSelectionBaseInfo(mmAnswers);

    const interActionEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "interAction",
        linkInformation: "Send to dealer - failed"
      }
    };
    const sendEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Failed",
        eventType: "Send lead to Dealer"
      }
    };
    const journeyEndEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "End",
        eventType: "Product journey"
      }
    };

    trackingData.event.push(interActionEvent);
    trackingData.event.push(sendEvent);
    trackingData.event.push(journeyEndEvent);

    this.track(trackingData, "interaction");
  }

  public async savedOffers() {
    await this.sleep(1000);
    const trackingData = this.basicInfo();

    trackingData.core.pageInfo.pageName = "Saved offers";
    trackingData.core.attributes.viewChange = "Saved offers";

    trackingData.customerData.loginStatus = true;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, "page");
  }

  public async faq() {
    await this.sleep(1000);
    const trackingData = this.basicInfo();

    trackingData.core.pageInfo.pageName = "Help";
    trackingData.core.attributes.viewChange = "Help";

    const viewEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };
    const registrationEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Start",
        eventType: "Registration"
      }
    };

    trackingData.event.push(viewEvent);
    trackingData.event.push(registrationEvent);

    this.track(trackingData, "page");
  }

  public async errorPage(error: AdobeDataLayerError) {
    await this.sleep(1000);
    const trackingData = this.basicInfo();

    trackingData.core.pageInfo.pageName = "Error page";
    trackingData.core.attributes.viewChange = `Error page - ${error.errorMessage}`;
    trackingData.error = error;

    const viewEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: "Success",
        eventType: "pageView"
      }
    };
    const errorEvent: AdobeDataLayerEvent = {
      eventInfo: { eventAction: "Technical error", eventType: "Error" }
    };
    if (error.errorCode === "401") {
      errorEvent.eventInfo = {
        eventAction: "Unauthorized",
        eventType: "Login"
      };
    } else if (error.errorCode === "403") {
      errorEvent.eventInfo = {
        eventAction: "Denied",
        eventType: "Access"
      };
    } else if (error.errorCode === "404") {
      errorEvent.eventInfo = {
        eventAction: "Page not found",
        eventType: "Error"
      };
    }

    trackingData.event.push(viewEvent);
    trackingData.event.push(errorEvent);

    this.track(trackingData, "page");
  }
}

const adobeDataLayer = new AdobeDataLayerClass();

export { adobeDataLayer };
