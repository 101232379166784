import {
  EditableCardProps,
  Icons,
  ItemTeaserSmall,
  RenewalCard,
  bronsonIconSwitch,
  reactNodeSwitch
} from "@renewal/rosie";
import { SvgCalculator } from "@renewal/rosie/lib/lib/icons/index";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  IAllContractFieldsFragment,
  IAllMatchMakerAnswerFieldsFragment,
  ICarSearchType,
  IFinancePlan
} from "../../../__generated__/graphql-types";
import { LearnMoreAboutFinancePlanTrigger } from "../../../lib/components/LearnMoreAboutFinancePlan/LearnMoreAboutFinancePlan";
import { getFinancePlanCmsKey } from "../../../lib/helpers/utility";
import { getFieldSummaryText } from "../../../lib/helpers/vehicle-helpers";
import { RouterHelpers } from "../../../router/router-helpers";

interface OwnProps {
  matchMaker: IAllMatchMakerAnswerFieldsFragment;
  contract: IAllContractFieldsFragment;
  wantsToBeContacted: boolean;
  onGoToPreferences: () => void;
}

const RenewalCompletedMatchMaker: React.FC<OwnProps> = ({
  matchMaker,
  contract,
  wantsToBeContacted,
  onGoToPreferences
}) => {
  const { t } = useTranslation("renewal-overview-page");
  const { t: matchMakerT } = useTranslation("match-maker");

  const vehicleType =
    getFieldSummaryText(matchMaker, "typeOfCar", matchMakerT) || "-";
  const vehicleModel =
    getFieldSummaryText(matchMaker, "modelOfCar", matchMakerT) || "-";
  const colorOfCar =
    getFieldSummaryText(matchMaker, "colorOfCar", matchMakerT) || "-";
  const trainType =
    getFieldSummaryText(matchMaker, "typeOfTrain", matchMakerT) || "-";
  const fuelTypes =
    getFieldSummaryText(matchMaker, "fuelTypes", matchMakerT) || "-";
  const parkingType =
    getFieldSummaryText(matchMaker, "parkingType", matchMakerT) || "-";

  const importantAspects =
    getFieldSummaryText(matchMaker, "importantAspects", matchMakerT) || "-";

  const duration = matchMaker.expectedDuration
    ? t("shared:duration", { months: matchMaker.expectedDuration })
    : "-";

  function getFinancePlanString(plan: IFinancePlan) {
    return t(`shared:finance-plan.${getFinancePlanCmsKey(plan)}`);
  }

  const financePlan = matchMaker.financePlan
    ? getFinancePlanString(matchMaker.financePlan)
    : "";

  const editableCardItem: EditableCardProps = {
    editable: !wantsToBeContacted,
    editText: t("shared:edit-button"),
    editToLink: RouterHelpers.getMatchMakerSummaryPagePath(),
    onClick: onGoToPreferences
  };

  return (
    <RenewalCard
      title={t("match-maker-title")}
      editableCard={editableCardItem}
      data-component-id="renewal-overview-preferences-card"
    >
      <div className="c-card__body">
        {matchMaker.carSearchType === ICarSearchType.BYTYPE ? (
          <ItemTeaserSmall
            title={t("match-maker-bodystyle-label")}
            text={vehicleType}
            iconType={Icons.BodyStyle}
            className="u-mb-xsmall"
          />
        ) : (
          <ItemTeaserSmall
            title={t("match-maker-carmodel-label")}
            text={vehicleModel}
            iconType={Icons.BodyStyle}
            className="u-mb-xsmall"
          />
        )}
        <ItemTeaserSmall
          title={t("match-maker-color-label")}
          text={colorOfCar}
          iconType={Icons.colorOfCar}
          className="u-mb-xsmall"
        />
        <ItemTeaserSmall
          title={t("match-maker-drivetrain-label")}
          text={trainType}
          iconType={Icons.Drivetrain}
          className="u-mb-xsmall"
        />
        <ItemTeaserSmall
          title={t("match-maker-width-label")}
          text={parkingType}
          iconType={bronsonIconSwitch("parking", "car-side")}
          className="u-mb-xsmall"
        />
        <ItemTeaserSmall
          title={t("match-maker-fuel-label")}
          text={fuelTypes}
          iconType={Icons.Fuel}
          className="u-mb-xsmall"
        />
        <ItemTeaserSmall
          title={t("match-maker-driving-style-label")}
          text={importantAspects}
          iconType={Icons.CarType}
          className="u-mb-xsmall"
        />
        <ItemTeaserSmall
          title={t("match-maker-budget-label")}
          text={t("shared:currency", {
            value: matchMaker.expectedMonthlyPayment
          })}
          iconType={Icons.MonthlyBudget}
          className="u-mb-xsmall"
        />
        {matchMaker.additionalDeposit && matchMaker.additionalDeposit > 0 && (
          <ItemTeaserSmall
            title={t("match-maker-additional-deposit-label")}
            text={t("shared:currency", {
              value: matchMaker.additionalDeposit
            })}
            iconType={Icons.AdditionalDeposit}
            className="u-mb-xsmall"
          />
        )}
        <ItemTeaserSmall
          title={t("match-maker-bonus-payment-label")}
          text={t("shared:currency", {
            value: matchMaker.bonusPayment
          })}
          iconType={"semantic-plus"}
          className="u-mb-xsmall"
        />
        <ItemTeaserSmall
          title={t("match-maker-term-label")}
          text={duration}
          iconType={Icons.ContractDuration}
        />

        <h6>{t("match-maker-product-type-label")}</h6>
        <ItemTeaserSmall
          iconType={Icons.FinanceContractType}
          title={financePlan}
        />
        {matchMaker.financePlan && (
          <div className="dr-cmm-learn-more">
            <LearnMoreAboutFinancePlanTrigger
              linkText={t("shared:learn-more-button")}
              contractType={matchMaker.financePlan}
            />
          </div>
        )}

        {contract.equity > 0 && (
          <>
            <hr className="u-mv" />

            <h6>{t("match-maker-equity-label")}</h6>
            <ItemTeaserSmall
              iconType={Icons.Equity}
              iconNode={reactNodeSwitch(<SvgCalculator />)}
              title={t("shared:currency", { value: contract.equity })}
            />
          </>
        )}
      </div>
    </RenewalCard>
  );
};

export default RenewalCompletedMatchMaker;
