"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.AudiSplitTextWithSpace = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
/*
 * The audi fonts are so large that we need to display the contract start and
 * end date texts in two rows.
 */
var AudiSplitTextWithSpace = function (_a) {
    var text = _a.text, x = _a.x, y = _a.y;
    if (text.indexOf(" ") === -1) {
        return (jsx_runtime_1.jsx("text", { children: jsx_runtime_1.jsx("tspan", __assign({ x: x, y: y + 10 }, { children: text }), void 0) }, void 0));
    }
    var splitText = text.split(" ");
    return (jsx_runtime_1.jsxs("text", { children: [jsx_runtime_1.jsx("tspan", __assign({ x: x, y: y }, { children: splitText[0] }), void 0), jsx_runtime_1.jsx("tspan", __assign({ x: x, y: y + 20 }, { children: splitText[1] }), void 0)] }, void 0));
};
exports.AudiSplitTextWithSpace = AudiSplitTextWithSpace;
