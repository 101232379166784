import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

// Very simple scroll to top on navigate to new page solution taken
// from: https://reacttraining.com/react-router/web/guides/scroll-restoration
//
// For a more sophisticated solution we could switch to something like
// (react-scroll)[https://scotch.io/tutorials/implementing-smooth-scrolling-in-react]
// later
class ScrollToTopOnNavigation extends React.Component<RouteComponentProps> {
  componentDidUpdate(prevProps: RouteComponentProps): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTopOnNavigation);
