import { Accordion, Link } from "@vwfs-its/bronson-react";
import React from "react";

import { IRetailer } from "../../../__generated__/graphql-types";

interface RetailerProps {
  retailer: IRetailer;
}

export const RetailerInfo: React.FunctionComponent<RetailerProps> = ({
  retailer
}) => {
  return (
    <Accordion>
      <Accordion.Item title={retailer.name}>
        <p>
          <Link
            to={`address:#`}
            href={`address:#`}
            icon="semantic-location"
            normalLink
          >
            {retailer.address}
          </Link>
        </p>
        <p>
          <Link
            to={`tel:${retailer.phoneNumber}`}
            href={`tel:${retailer.phoneNumber}`}
            icon="semantic-phone"
            normalLink
          >
            {retailer.phoneNumber}
          </Link>
        </p>
        {retailer.email && (
          <p>
            <Link
              to={`mailto:${retailer.email}`}
              href={`mailto:${retailer.email}`}
              icon="semantic-mail"
              normalLink
            >
              {retailer.email}
            </Link>
          </p>
        )}
        {retailer.website && (
          <p>
            <Link
              to={`${retailer.website}`}
              href={`${retailer.website}`}
              icon="semantic-forward"
              normalLink
              rel="noopener noreferrer"
              target="_blank"
            >
              {`${retailer.website}`}
            </Link>
          </p>
        )}
      </Accordion.Item>
    </Accordion>
  );
};
