"use strict";
/* These breakpoints are based on Bronson's breakpoints - might change in the future.
   Those are the Audi breakpoints, however different brands will have different breakpoints
 */
exports.__esModule = true;
exports.Breakpoints = void 0;
var bpMobileMax = 720;
var bpTabletMax = 960;
exports.Breakpoints = {
    Phone: {
        Max: bpMobileMax
    },
    Tablet: {
        Min: bpMobileMax + 1,
        Max: bpTabletMax
    },
    Desktop: {
        Min: bpTabletMax + 1
    }
};
