import {
  FieldValidators,
  validateChoiceCountUpTo,
  validateNumberRangeMax,
  validateRequired
} from "@renewal/rosie";
import { TFunction } from "i18next";

import { ICarSearchType } from "../../__generated__/graphql-types";

interface AdditionalDeposit {
  [brand: string]: number;
}

const MAX_ADDITIONAL_DEPOSIT: AdditionalDeposit = {
  volkswagen: 9990000,
  audi: 9990000,
  seat: 9990000,
  skoda: 9990000
};

const getBrandDependentMaxAdditionalDeposit = (): number =>
  MAX_ADDITIONAL_DEPOSIT[process.env.REACT_APP_BRAND || "volkswagen"];

function getAllFieldValidators(
  t: TFunction,
  carSearchType?: ICarSearchType
): FieldValidators {
  const minMonthlyPayment = 10000;
  const maxMonthlyPayment = 500000;
  const minAdditionalDeposit = 0;
  const maxAdditionalDeposit = getBrandDependentMaxAdditionalDeposit();
  const minBonusPayment = 0;
  const maxBonusPayment = 9990000;

  return {
    typeOfCar:
      carSearchType === ICarSearchType.BYTYPE || !carSearchType
        ? [
            validateChoiceCountUpTo(
              t(
                "match-maker:question-pages.items.bodystyle.validation.required"
              ),
              t("match-maker:question-pages.items.bodystyle.validation.max"),
              3
            )
          ]
        : [],
    modelOfCar:
      carSearchType === ICarSearchType.BYMODEL || !carSearchType
        ? [
            validateChoiceCountUpTo(
              t("match-maker:question-pages.items.model.validation.required"),
              "",
              999
            )
          ]
        : [],
    typeOfTrain: [
      validateChoiceCountUpTo(
        t("match-maker:question-pages.items.drivetrain.validation.required"),
        "",
        999
      )
    ],
    parkingType: [
      validateChoiceCountUpTo(
        t("match-maker:question-pages.items.width.validation.required"),
        "",
        999
      )
    ],
    fuelTypes: [
      validateChoiceCountUpTo(
        t("match-maker:question-pages.items.fuel.validation.required"),
        "",
        999
      )
    ],
    importantAspects: [
      validateChoiceCountUpTo(
        t("match-maker:question-pages.items.driving-style.validation.required"),
        "",
        999
      )
    ],
    expectedDuration: [
      validateRequired(
        t("match-maker:question-pages.items.duration.validation.required")
      )
    ],
    expectedMonthlyPayment: [
      validateNumberRangeMax(
        t("match-maker:question-pages.items.installments.validation.required", {
          value: 300
        }),
        t("match-maker:question-pages.items.installments.validation.min", {
          value: minMonthlyPayment
        }),
        t("match-maker:question-pages.items.installments.validation.max", {
          value: maxMonthlyPayment
        }),
        minMonthlyPayment,
        maxMonthlyPayment
      )
    ],
    additionalDeposit: [
      validateNumberRangeMax(
        "",
        t(
          "match-maker:question-pages.items.additional-deposit.validation.min",
          {
            value: maxAdditionalDeposit
          }
        ),
        t(
          "match-maker:question-pages.items.additional-deposit.validation.max",
          {
            value: maxAdditionalDeposit
          }
        ),
        minAdditionalDeposit,
        maxAdditionalDeposit
      )
    ],
    bonusPayment: [
      validateNumberRangeMax(
        "",
        t("match-maker:question-pages.items.bonus-payment.validation.min", {
          value: maxBonusPayment
        }),
        t("match-maker:question-pages.items.bonus-payment.validation.max", {
          value: maxBonusPayment
        }),
        minBonusPayment,
        maxBonusPayment
      )
    ],
    financePlan: [
      validateChoiceCountUpTo(
        t("match-maker:question-pages.items.finance-plan.validation.required"),
        "",
        999
      )
    ]
  };
}

function getFieldValidators(t: TFunction, fields: string[]): FieldValidators {
  const allFieldsValidator = getAllFieldValidators(t);

  const result: FieldValidators = {};
  fields.forEach(
    fieldName => (result[fieldName] = allFieldsValidator[fieldName])
  );

  return result;
}

export {
  getFieldValidators,
  getAllFieldValidators,
  getBrandDependentMaxAdditionalDeposit
};
