import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { adobeDataLayer } from "../../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { RouterHelpers } from "../../../router/router-helpers";

const ErrorPage499: React.FunctionComponent = () => {
  const { t } = useTranslation("error-pages");

  useEffect(() => {
    adobeDataLayer.errorPage({
      errorCode: "499",
      errorMessage: "User not eligible"
    });
  }, []);

  return (
    <div className="o-main">
      <section className="o-content-section">
        <div className="o-page-wrap">
          <header className="c-section-heading c-section-heading--primary c-section-heading--alternative">
            <h1 className="c-section-heading__title u-text-brand u-mb-none">
              <span className="c-section-heading__title-text">
                {t("uneligible-title")}
              </span>
            </h1>
            <p className="c-section-heading__subtitle u-text-brand">
              {t("uneligible-description")}
            </p>
            <p className="c-section-heading__subtitle u-text-brand">
              {t("uneligible-description1")}
              <Link
                target="_blank"
                rel="noreferrer"
                to={RouterHelpers.getFAQPath()}
              >
                {t("info-link-text")}
              </Link>
            </p>
          </header>
          <div className="o-layout u-mt-large">
            <div className="o-layout__item"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ErrorPage499;
