import { Header, HeaderLinkItem, HeaderStates } from "@renewal/rosie";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  RouteComponentProps,
  matchPath,
  useLocation,
  withRouter
} from "react-router";

import {
  IGetContractsQuery,
  useGetContractsQuery
} from "../../../__generated__/graphql-types";
import { useCurrentContractId } from "../../../App.state";
import { adobeDataLayer } from "../../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { useOfferOperations } from "../../../lib/helpers/saved-offers";
import { RouterHelpers } from "../../../router/router-helpers";

const HeaderContainer: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const { t } = useTranslation("scaffolding");
  const { data: contracts = {} as IGetContractsQuery } = useGetContractsQuery();
  const { currentContractId } = useCurrentContractId();
  const { savedOfferCount } = useOfferOperations(currentContractId);

  // To avoid a constructor we are using arrow functions
  const isLinkActive = (url: string): HeaderStates => {
    return matchPath(location.pathname, url)
      ? HeaderStates.Active
      : HeaderStates.Inactive;
  };

  const currentLink = (url: string) => {
    // If we are on the renewal overview page we just keep the same link. This
    // allows us to always have the correct state in the wizard even if someone
    // clicks multiple times on the renewal overview link.
    return matchPath(location.pathname, url)
      ? `${url}${location.search}`
      : `${url}?prev=${location.pathname}`;
  };

  const savedOffersCount = () => {
    return savedOfferCount >= 0 ? savedOfferCount : 0;
  };

  let logoLinkUrl = "";
  let links: HeaderLinkItem[] = [];
  links = [
    {
      name: t("header.overview"),
      link: currentLink(RouterHelpers.getRenewalOverviewPath()),
      status: isLinkActive(RouterHelpers.getRenewalOverviewPath())
    },
    {
      name: `${t("header.saved-offers")} (${savedOffersCount()})`,
      link: currentLink(RouterHelpers.getSavedOffersPath()),
      status: isLinkActive(RouterHelpers.getSavedOffersPath())
    }
  ];
  logoLinkUrl = RouterHelpers.getRenewalOverviewPath();

  const secondaryLinks: HeaderLinkItem[] = [
    contracts?.customer?.contracts.length > 1
      ? {
          name: t("header.contracts"),
          link: RouterHelpers.getContractSelectionPath(),
          status: isLinkActive(RouterHelpers.getContractSelectionPath())
        }
      : { name: "", link: "", status: HeaderStates.Inactive },
    {
      name: t("header.help"),
      link: RouterHelpers.getFAQPath(),
      status: isLinkActive(RouterHelpers.getFAQPath())
    },
    {
      name: t("header.logout"),
      link: RouterHelpers.getLogoutPath(),
      status: HeaderStates.Inactive,
      isAbsolute: true,
      additionalOnClick: async () => {
        await adobeDataLayer.logout();
      }
    }
  ];

  return (
    <Header
      links={links}
      secondaryLinks={secondaryLinks}
      logoLinkUrl={logoLinkUrl}
    />
  );
};

export default withRouter(HeaderContainer);
