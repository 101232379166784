"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.DefinitionItem = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
require("./DefinitionItem.scss");
var __1 = require("../..");
var DefinitionItem = function (_a) {
    var label = _a.label, data = _a.data, iconNode = _a.iconNode, icon = _a.icon;
    return (jsx_runtime_1.jsxs("p", __assign({ className: "u-mb-xsmall" }, { children: [jsx_runtime_1.jsxs("span", { children: [icon ? (jsx_runtime_1.jsx("i", { className: "c-icon c-icon--[" + icon + "] u-mr-xsmall " + __1.bronsonIconSwitchEmpty("c-icon--small", "c-icon--xsmall", "c-icon--medium", "c-icon--medium", "c-icon--xsmall") + " u-text-brand dr-definition-icon" }, void 0)) : (jsx_runtime_1.jsx("i", { children: iconNode }, void 0)), jsx_runtime_1.jsx("b", { children: label }, void 0)] }, void 0), jsx_runtime_1.jsx("br", {}, void 0), jsx_runtime_1.jsx("span", __assign({ className: "u-ml " + __1.bronsonIconSwitchEmpty("u-pl-xsmall", "", "u-pl", "u-pl-small", "u-pl-none") + " u-text-fs-1" }, { children: data }), void 0)] }), void 0));
};
exports.DefinitionItem = DefinitionItem;
