import { ErrorPageTemplate } from "@renewal/rosie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { RouterHelpers } from "../../router/router-helpers";

const ErrorPage401: React.FC = () => {
  const { t } = useTranslation("error-pages");

  useEffect(() => {
    adobeDataLayer.errorPage({
      errorCode: "401",
      errorMessage: t("401-description")
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorPageTemplate
        errorCode="401"
        errorMsg={t("401-title")}
        errorExplanation={t("401-description")}
        redirectTo={RouterHelpers.getLandingPagePath()}
        btnText={t("shared:back-button")}
      />
    </>
  );
};

export default ErrorPage401;
