import {
  ButtonGroup,
  QuestionItem,
  SelectableTileGroup,
  reactNodeSwitch
} from "@renewal/rosie";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  ICarSearchType,
  IMatchMakerAnswers
} from "../../../__generated__/graphql-types";
import {
  carModelList,
  carTypeListAudi,
  carTypeListVolkswagen
} from "../../helpers/vehicle-helpers";

export const CarType: React.FC = () => {
  const { t } = useTranslation("match-maker");
  const { values, setFieldValue } = useFormikContext<IMatchMakerAnswers>();

  // make sure the value is always initialized
  const carSearchType = values.carSearchType ?? ICarSearchType.BYTYPE;

  const showModelView = carSearchType === ICarSearchType.BYMODEL;
  const showTypeView = carSearchType === ICarSearchType.BYTYPE;

  return (
    <div className="c-tabs u-text-center">
      <nav>
        <ul className="c-tabs__nav">
          <li className="c-tabs__nav-item">
            <button
              className={classNames("c-tabs__nav-link", {
                "is-active": showTypeView
              })}
              onClick={e => {
                e.preventDefault(); // prevent reloading of page
                setFieldValue("carSearchType", "ByType");
              }}
            >
              <span className="c-tabs__text">
                {t("question-pages.items.bodystyle.short-label")}
              </span>
            </button>
          </li>
          <li className="c-tabs__nav-item">
            <button
              className={classNames("c-tabs__nav-link", {
                "is-active": showModelView
              })}
              onClick={e => {
                e.preventDefault(); // prevent reloading of page
                setFieldValue("carSearchType", "ByModel");
              }}
            >
              <span className="c-tabs__text">
                {t("question-pages.items.model.short-label")}
              </span>
            </button>
          </li>
        </ul>
      </nav>
      <div>
        <div className="c-tabs__content" aria-hidden={showModelView}>
          <SelectableTileGroup
            itemList={
              reactNodeSwitch(
                carTypeListAudi(t),
                carTypeListVolkswagen(t)
              ) as QuestionItem<string | number>[]
            }
            itemColumnCount={[4, 12]}
            propName="typeOfCar"
            propObject={values.typeOfCar || []}
            className="o-layout__item u-8/12 u-1/1@m"
            maxChoices={3}
            maxChoicesWarning={t(
              "match-maker:question-pages.items.bodystyle.validation.max"
            )}
            onPostChange={() => setFieldValue("modelOfCar", [])}
          />
        </div>
        <div className="c-tabs__content" aria-hidden={showTypeView}>
          <ButtonGroup
            itemList={carModelList()}
            displayDescription={false}
            propName="modelOfCar"
            propObject={values.modelOfCar || []}
            className="o-layout__item u-8/12 u-1/1@m"
            onPostChange={() => setFieldValue("typeOfCar", [])}
          />
        </div>
      </div>
    </div>
  );
};
