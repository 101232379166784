import React, { useEffect } from "react";
import { useHistory } from "react-router";

import {
  IFinancePlan,
  IGetSavedOffersQuery,
  useGetSavedOffersQuery
} from "../../__generated__/graphql-types";
import { useCurrentContractId } from "../../App.state";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { useOfferOperations } from "../../lib/helpers/saved-offers";
import { loadingOrError } from "../../lib/ui/Loaders/GraphqlLoadingUtility";
import EmptySavedOffersComponent from "./EmptySavedOffersComponent";
import SavedOffers from "./SavedOffersComponent";

function SavedOffersContainer(): React.ReactElement {
  const { currentContractId } = useCurrentContractId();

  const {
    loading,
    error,
    data = {} as IGetSavedOffersQuery
  } = useGetSavedOffersQuery({
    variables: { contractNumber: currentContractId }
  });
  const history = useHistory();
  const { savedOffers, savedOfferCount } =
    useOfferOperations(currentContractId);

  useEffect(() => {
    adobeDataLayer.savedOffers();
  }, []);

  if (loading || error) {
    return loadingOrError(loading, error);
  }

  const contract = data.customer.contract;

  if (savedOffers && savedOfferCount > 0) {
    return (
      <SavedOffers
        prevCall={history.goBack}
        savedOffers={savedOffers}
        currentContractNumber={contract.contractNumber}
        contract={contract}
        preferredInstallment={
          data.customer.contract.matchMakerAnswers.expectedMonthlyPayment
            ? data.customer.contract.matchMakerAnswers.expectedMonthlyPayment
            : 0
        }
        selectedFinancePlan={
          data.customer.contract.matchMakerAnswers.financePlan ??
          IFinancePlan.SLOAN
        }
      />
    );
  }

  return <EmptySavedOffersComponent />;
}

export default SavedOffersContainer;
