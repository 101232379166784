"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.preventDecimals = exports.preventLetterInput = exports.validateMaxAmount = exports.validateChoiceCountUpTo = exports.validateRequired = exports.validateNumberRangeMax = exports.validateNumberRange = void 0;
var Yup = __importStar(require("yup"));
var validateSchemaAndCatchAllErrors = function (schema, value) {
    try {
        // We do not care about the validation result because we
        // will just return null to indicate validation succeeded.
        schema.validateSync(value);
        return null;
    }
    catch (e) {
        return e.message;
    }
};
function validateNumberRange(errorMessageMandatory, errorMessageBelowMin, errorMessageAboveMax, min, max, typeError) {
    return function (value) {
        // for optional field
        if (errorMessageMandatory === "" && !value) {
            return null;
        }
        var numberSchema = Yup.number()
            .typeError(typeError !== null && typeError !== void 0 ? typeError : errorMessageMandatory)
            .required(errorMessageMandatory)
            .min(min, errorMessageBelowMin)
            .max(max, errorMessageAboveMax);
        return validateSchemaAndCatchAllErrors(numberSchema, value);
    };
}
exports.validateNumberRange = validateNumberRange;
var validateNumberRangeMax = function (errorMessageMandatory, errorMessageBelowMin, errorMessageAboveMax, min, max, typeError) {
    return function (value) {
        // for optional field
        if (errorMessageMandatory === "" && !value) {
            return null;
        }
        var numberSchema = Yup.number()
            .typeError(typeError !== null && typeError !== void 0 ? typeError : errorMessageMandatory)
            .required(errorMessageMandatory)
            .min(min, errorMessageBelowMin)
            .max(max, errorMessageAboveMax);
        return validateSchemaAndCatchAllErrors(numberSchema, value);
    };
};
exports.validateNumberRangeMax = validateNumberRangeMax;
function validateMaxAmount(errorMessageAboveMax, max, typeError) {
    return function (value) {
        var errorMessage = null;
        var numberSchema = Yup.number()
            .typeError(typeError !== null && typeError !== void 0 ? typeError : errorMessageAboveMax)
            .max(max, errorMessageAboveMax);
        try {
            // We do not care about the validation result because we
            // will just return null to indicate validation succeeded.
            numberSchema.validateSync(value);
        }
        catch (e) {
            // It's not an error if the value is just empty/null/NaN/undefined, as it's not obligatory => set errorMessage to null
            if (!value) {
                errorMessage = null;
            }
            else {
                errorMessage = e.message;
            }
        }
        return errorMessage;
    };
}
exports.validateMaxAmount = validateMaxAmount;
function validateRequired(errorMessage) {
    return function (value) { return (value ? null : errorMessage); };
}
exports.validateRequired = validateRequired;
function validateChoiceCountUpTo(errorMessage, errorMessageTooMany, maxCount) {
    return function (value) {
        if (!value)
            return errorMessage;
        // @ts-ignore
        var count = value.length;
        if (count === 0)
            return errorMessage;
        if (count > maxCount)
            return errorMessageTooMany;
        return null;
    };
}
exports.validateChoiceCountUpTo = validateChoiceCountUpTo;
var preventLetterInput = function (ev) {
    if (!ev.key.match(/[0-9.]/)) {
        ev.preventDefault();
    }
};
exports.preventLetterInput = preventLetterInput;
var preventDecimals = function (ev) {
    preventLetterInput(ev);
    if (ev.key.match(/[0-9]*(\.)[0-9]*/)) {
        ev.preventDefault();
    }
};
exports.preventDecimals = preventDecimals;
