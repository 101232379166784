import { useApolloClient } from "@apollo/client";
import { Button, Notification } from "@vwfs-its/bronson-react";
import React, { useEffect, useState } from "react";
import {
  WithTranslation,
  useTranslation,
  withTranslation
} from "react-i18next";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

import {
  IFinanceDetails,
  IMatchMakerAnswers,
  IMatchType,
  IRetailer,
  IVehicleOffer,
  useDeleteOfferMutation,
  useGetSingleOfferQuery,
  useSaveOfferMutation,
  useSelectOfferMutation
} from "../../__generated__/graphql-types";
import { useCurrentContractId } from "../../App.state";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { loadingOrError } from "../../lib/ui/Loaders/GraphqlLoadingUtility";
import {
  RouterHelpers,
  RouterHelpersQuotePath
} from "../../router/router-helpers";
import FinanceQuote from "./FinanceQuoteComponent";

type AllProps = RouterHelpersQuotePath & RouteComponentProps & WithTranslation;

function renderFinanceQuote(
  data: IFinanceDetails,
  retailer: IRetailer,
  hasMonthlyPayment: boolean,
  props: AllProps,
  onSelectAndContinue: Function,
  wantsToBeContacted: boolean,
  equity: number,
  onSave: () => void,
  onDelete: () => void,
  matchMakerAnswers: IMatchMakerAnswers
): React.ReactElement | null {
  const pushToHistory = (addParentPath: boolean, url: string): void => {
    if (addParentPath === true) {
      props.history.push(`${props.match.path}/${url}`);
    } else {
      props.history.push(`${url}`);
    }
  };

  return (
    <FinanceQuote
      prevCall={props.history.goBack}
      offer={data}
      isSaved={data.wasSaved}
      onSelectAndContinue={onSelectAndContinue}
      onBackToOverview={() => {
        pushToHistory(false, RouterHelpers.getRenewalOverviewPath());
      }}
      onChangeIsSaved={() => {
        adobeDataLayer.financeQuoteSaveForLater(
          matchMakerAnswers as IMatchMakerAnswers
        );
        if (data.wasSaved) onDelete();
        else onSave();
      }}
      wantsToBeContacted={wantsToBeContacted}
      retailer={retailer}
      isSubmittedCar={false}
      currentLocation={props.location.pathname}
      hasMonthlyPayment={hasMonthlyPayment}
      equity={equity}
      matchMakerAnswers={matchMakerAnswers}
    />
  );
}

function renderVehicleNotAvailableInfo(
  props: AllProps,
  retailer: IRetailer
): React.ReactElement {
  return (
    <>
      <Notification status="warning" shown notClosable>
        {props.t("unavailability-error", {
          dealerName: retailer.name,
          dealerPhoneNumber: retailer.phoneNumber
        })}
      </Notification>
      <div className="o-layout">
        <div className="o-layout__item u-text-center u-pt">
          <Button
            onClick={() => {
              props.history.goBack();
            }}
          >
            {props.t("shared:back-button")}
          </Button>
        </div>
      </div>
    </>
  );
}

function FinanceQuoteContainer(props: AllProps) {
  const vin = props.match.params.id;
  const { currentContractId } = useCurrentContractId();

  const { t } = useTranslation("vehicle-tile-component");

  const [saveOfferMutation] = useSaveOfferMutation();
  const [deleteOfferMutation] = useDeleteOfferMutation();

  const pushToHistory = (addParentPath: boolean, url: string): void => {
    if (addParentPath === true) {
      props.history.push(`${props.match.path}/${url}`);
    } else {
      props.history.push(`${url}`);
    }
  };

  const { loading, error, data } = useGetSingleOfferQuery({
    variables: { vin: vin, contractId: currentContractId }
  });

  const [
    selectOfferMutation,
    { loading: selectOfferLoading, error: selectOfferError }
  ] = useSelectOfferMutation();
  const apolloClient = useApolloClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const offer =
      data?.customer?.contract?.matchMakerAnswers?.matchedOffers?.find(
        m => m.vehicle.vin === vin
      );
    if (data && initialLoad && offer) {
      setInitialLoad(false);
      adobeDataLayer.setDealerData({
        companyId: "",
        companyName: data?.customer?.contract?.retailer?.name
      });
      adobeDataLayer.setProduct(offer as IVehicleOffer);
      adobeDataLayer.financeQuote(
        data?.customer?.contract?.matchMakerAnswers as IMatchMakerAnswers
      );
    }
  }, [data, initialLoad, vin]);

  if (
    loading ||
    error ||
    selectOfferLoading ||
    selectOfferError ||
    isSubmitting
  )
    return loadingOrError(
      loading || selectOfferLoading || isSubmitting,
      error || selectOfferError
    );

  const onSelectAndContinue = () => {
    if (!data) return;
    if (!data.financeOffer) return;
    setIsSubmitting(true);
    selectOfferMutation({
      variables: {
        vin: data.financeOffer.carVin,
        contractId: currentContractId,
        quoteId: data.financeOffer.quote.quoteId,
        // TODO: provide correct value
        matchType: IMatchType.NOMATCH
      }
    })
      .then(data => {
        apolloClient.resetStore();
        return data;
      })
      .then(data => {
        const quoteId = data.data?.selectOffer?.quote.quoteId;
        pushToHistory(false, RouterHelpers.getVehicleOfferPath(vin, quoteId));
        setIsSubmitting(false);
      })
      .catch(e => {
        setIsSubmitting(false);
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const dispatchSaveOffer = () => {
    saveOfferMutation({
      variables: {
        vin: vin,
        quoteId: data?.financeOffer?.quote.quoteId
          ? data?.financeOffer?.quote.quoteId
          : "",
        contractId: currentContractId ?? "",
        // TODO: provide correct value
        matchType: IMatchType.NOMATCH
      },
      refetchQueries: ["getSavedOffers", "getSingleOffer"]
    })
      .then(() => {
        toast.success(t("save-offer-toast-success"));
      })
      .catch(() => {
        toast.success(t("save-offer-toast-error"));
      });
  };

  const dispatchDeleteOffer = () => {
    deleteOfferMutation({
      variables: {
        quoteId: data?.financeOffer?.quote.quoteId
          ? data?.financeOffer?.quote.quoteId
          : "",
        contractId: currentContractId ?? ""
      },
      refetchQueries: ["getSavedOffers", "getSingleOffer"]
    })
      .then(() => {
        toast.success(t("delete-offer-toast-success"));
      })
      .catch(() => {
        toast.success(t("delete-offer-toast-error"));
      });
  };

  return (
    <section className="o-content-section" data-component-id="finance-quote">
      <div className="o-page-wrap">
        {data &&
          data.financeOffer &&
          renderFinanceQuote(
            data.financeOffer,
            data.customer.contract.retailer,
            data.customer.contract.hasMonthlyPayment,
            props,
            onSelectAndContinue,
            !!data.customer.contract.submittedOffer,
            data.customer.contract.equity,
            dispatchSaveOffer,
            dispatchDeleteOffer,
            data.customer.contract.matchMakerAnswers as IMatchMakerAnswers
          )}
        {data &&
          !data.financeOffer &&
          renderVehicleNotAvailableInfo(props, data.customer.contract.retailer)}
      </div>
    </section>
  );
}

export default withTranslation("finance-quote-page")(FinanceQuoteContainer);
