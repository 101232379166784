"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.NotFound = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
exports.NotFound = (jsx_runtime_1.jsx("svg", __assign({ width: "317px", height: "241px", viewBox: "0 0 317 241", version: "1.1", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("g", __assign({ id: "Page-1", stroke: "none", "stroke-width": "1", fill: "none", "fill-rule": "evenodd" }, { children: jsx_runtime_1.jsxs("g", __assign({ id: "Group", transform: "translate(1.000000, 1.000000)" }, { children: [jsx_runtime_1.jsx("rect", { id: "Rectangle", stroke: "#B80093", fill: "#FF00CB", x: "0", y: "0", width: "315", height: "239" }, void 0), jsx_runtime_1.jsx("text", __assign({ id: "Icon-not-found", "font-family": "VWAGTheSans-Regular, VWAG TheSans", "font-size": "36", "font-weight": "normal", fill: "#FFFFFF" }, { children: jsx_runtime_1.jsx("tspan", __assign({ x: "44", y: "131" }, { children: "Icon not found" }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0));
