import { gql, useQuery } from "@apollo/client";
import React from "react";
import { RouteComponentProps } from "react-router";

import { IGetDataForRenewalOverviewQuery } from "../../__generated__/graphql-types";
import { useCurrentContractId } from "../../App.state";
import { fragments } from "../../lib/helpers/graphqlFragments";
import { useOfferOperations } from "../../lib/helpers/saved-offers";
import { loadingOrError } from "../../lib/ui/Loaders/GraphqlLoadingUtility";
import RenewalOverview from "./RenewalOverviewComponent";

const GET_DATA_FOR_RENEWAL_OVERVIEW = gql`
  query getDataForRenewalOverview($contractId: String!) {
    customer {
      customerId
      isRegistered
      contract(contractId: $contractId) {
        ...AllContractFields
        savedOffers {
          ...AllVehicleOfferFields
        }
        matchMakerAnswers {
          ...AllMatchMakerAnswerFields
        }
      }
    }
  }
  ${fragments.allVehicleOfferFields}
  ${fragments.allContractFields}
  ${fragments.allMatchMakerAnswerFields}
`;

function RenewalOverviewContainer(
  props: RouteComponentProps
): React.ReactElement {
  const { currentContractId } = useCurrentContractId();
  const { savedOfferCount } = useOfferOperations(currentContractId);
  const {
    loading,
    error,
    data = {} as IGetDataForRenewalOverviewQuery
  } = useQuery<IGetDataForRenewalOverviewQuery>(GET_DATA_FOR_RENEWAL_OVERVIEW, {
    variables: { contractId: currentContractId }
  });

  if (loading || error) return loadingOrError(loading, error);

  const pushToHistory = (addParentPath: boolean, url: string): void => {
    if (addParentPath === true) {
      props.history.push(`${props.match.path}/${url}`);
    } else {
      props.history.push(`${url}`);
    }
  };

  const submittedOffer = data.customer.contract.savedOffers?.find(
    o => o.wantsToBeContacted
  );
  const selectedOffer = data.customer.contract.savedOffers?.find(
    o => o.wasSelected
  );

  return (
    <RenewalOverview
      matchMaker={data.customer.contract.matchMakerAnswers}
      matchMakerCompleted={
        data.customer.contract.matchMakerAnswers.isCompleted || false
      }
      contract={data.customer.contract}
      wantsToBeContacted={!!submittedOffer}
      pushToHistory={pushToHistory}
      selectedVehicle={selectedOffer}
      savedOffersCount={savedOfferCount}
      currentLocation={props.location.pathname}
      savedOffersLoading={loading}
    />
  );
}

export default RenewalOverviewContainer;
