"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.Wizard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
require("./Wizard.scss");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var react_router_dom_1 = require("react-router-dom");
var Wizard = function (_a) {
    var steps = _a.steps, noStepTitle = _a.noStepTitle;
    var history = react_router_dom_1.useHistory();
    var activeIndex = steps.findIndex(function (step) { return step.isActive === true; });
    var activeStep = steps[activeIndex];
    var stepIndex = activeIndex + 1;
    var stepCount = steps.length;
    return (jsx_runtime_1.jsx(bronson_react_1.Wizard, __assign({ collapsedText: noStepTitle, collapsedCounter: activeStep ? stepIndex + " / " + stepCount : "" }, { children: steps.map(function (s, i) { return (jsx_runtime_1.jsx(bronson_react_1.Wizard.Item, __assign({ active: s.isActive, disabled: s.isDisabled, noInteraction: s.isDisabled, done: s.isDone, onClick: function () {
                if (s.onClick) {
                    s.onClick(s);
                }
                history.push(s.link);
            } }, { children: s.stepName }), i)); }) }), void 0));
};
exports.Wizard = Wizard;
