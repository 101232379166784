"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.Flipper = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
require("./Flipper.scss");
var react_1 = __importStar(require("react"));
function Flipper(props) {
    var _a = react_1.useState({
        isFlipped: false
    }), isFlippedState = _a[0], setIsFlippedState = _a[1];
    var internalIsFlipped = function () {
        if (props.setIsFlipped !== undefined)
            return props.isFlipped;
        return isFlippedState.isFlipped;
    };
    var internalSetIsFlipped = function (newValue) {
        if (props.setIsFlipped !== undefined) {
            props.setIsFlipped(newValue);
        }
        else {
            setIsFlippedState({ isFlipped: newValue });
        }
    };
    var flipHandler = function () {
        internalSetIsFlipped(!internalIsFlipped());
    };
    if (react_1["default"].Children.count(props.children) !== 2) {
        throw new Error("The flipper needs exactly two components, the front and back");
    }
    var flippableChildren = react_1["default"].Children.map(props.children, function (child) {
        if (child) {
            return react_1["default"].cloneElement(child, {
                handleFlip: flipHandler
            });
        }
        else {
            return jsx_runtime_1.jsx(jsx_runtime_1.Fragment, {}, void 0);
        }
    });
    var flipClass = internalIsFlipped() ? "hover" : "";
    // Ensuring we really have this object to avoid
    // error TS2533: Object is possibly 'null' or 'undefined'
    if (flippableChildren === null || flippableChildren === undefined) {
        throw new Error("The flipper needs exactly two components, the front and back");
    }
    return (jsx_runtime_1.jsxs("div", __assign({ id: props.domId, className: "flipper " + flipClass }, { children: [jsx_runtime_1.jsx("div", __assign({ className: "front" }, { children: flippableChildren[0] }), void 0), jsx_runtime_1.jsx("div", __assign({ className: "back" }, { children: flippableChildren[1] }), void 0)] }), void 0));
}
exports.Flipper = Flipper;
