"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.TradeInContent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var index_1 = require("../../index");
var TradeInContent = function (_a) {
    var t = _a.t, currentVehicleValue = _a.currentVehicleValue, amountToSettle = _a.amountToSettle, _b = _a.hasVehicleOverrideValue, hasVehicleOverrideValue = _b === void 0 ? true : _b, totalDeposit = _a.totalDeposit, onOpenToolTip = _a.onOpenToolTip;
    return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [hasVehicleOverrideValue ? (jsx_runtime_1.jsxs("div", __assign({ className: "u-mb" }, { children: [jsx_runtime_1.jsxs("p", __assign({ className: "u-mb-xsmall" }, { children: [jsx_runtime_1.jsx("span", { children: t("trade-in-current-value") }, void 0), t("trade-in-current-value-tooltip") && (jsx_runtime_1.jsx(index_1.TrackableComponent, __assign({ trackingComponent: "tooltip", trackingFunction: "tooltipCurrentValue" }, { children: jsx_runtime_1.jsx(bronson_react_1.InfoIcon, __assign({ icon: "semantic-info", tippyProps: {
                                        placement: "top",
                                        onTrigger: function () {
                                            onOpenToolTip && onOpenToolTip("Trade in current value");
                                        }
                                    } }, { children: jsx_runtime_1.jsx("span", { dangerouslySetInnerHTML: {
                                            __html: t("trade-in-current-value-tooltip")
                                        } }, void 0) }), void 0) }), void 0))] }), void 0), jsx_runtime_1.jsx("small", { children: jsx_runtime_1.jsx("b", { children: t("shared:currency", {
                                value: currentVehicleValue
                            }) }, void 0) }, void 0)] }), void 0)) : null, jsx_runtime_1.jsxs("div", { children: [jsx_runtime_1.jsxs("p", __assign({ className: "u-mv-xsmall" }, { children: [jsx_runtime_1.jsx("span", { children: t("trade-in-settlement-amount") }, void 0), t("trade-in-settlement-amount-tooltip") && (jsx_runtime_1.jsx(index_1.TrackableComponent, __assign({ trackingComponent: "tooltip", trackingFunction: "tooltipSettleCurrentFinance" }, { children: jsx_runtime_1.jsx(bronson_react_1.InfoIcon, __assign({ icon: "semantic-info", tippyProps: {
                                        placement: "top",
                                        onTrigger: function () {
                                            onOpenToolTip &&
                                                onOpenToolTip("Trade in settlement amount");
                                        }
                                    } }, { children: jsx_runtime_1.jsx("span", { dangerouslySetInnerHTML: {
                                            __html: t("trade-in-settlement-amount-tooltip")
                                        } }, void 0) }), void 0) }), void 0))] }), void 0), jsx_runtime_1.jsx("small", { children: jsx_runtime_1.jsx("b", { children: t("shared:currency", {
                                value: amountToSettle
                            }) }, void 0) }, void 0)] }, void 0), totalDeposit ? (jsx_runtime_1.jsxs("div", { children: [jsx_runtime_1.jsxs("p", __assign({ className: "u-mv-xsmall" }, { children: [jsx_runtime_1.jsx("span", { children: t("trade-in-total-deposit") }, void 0), t("trade-in-total-deposit-tooltip") && (jsx_runtime_1.jsx(index_1.TrackableComponent, __assign({ trackingComponent: "tooltip", trackingFunction: "tooltipTotalCurrentDeposit" }, { children: jsx_runtime_1.jsx(bronson_react_1.InfoIcon, __assign({ icon: "semantic-info", tippyProps: {
                                        placement: "top",
                                        onTrigger: function () {
                                            onOpenToolTip && onOpenToolTip("Trade in total deposit");
                                        }
                                    } }, { children: jsx_runtime_1.jsx("span", { dangerouslySetInnerHTML: {
                                            __html: t("trade-in-total-deposit-tooltip")
                                        } }, void 0) }), void 0) }), void 0))] }), void 0), jsx_runtime_1.jsx("small", { children: jsx_runtime_1.jsx("b", { children: t("shared:currency", {
                                value: totalDeposit
                            }) }, void 0) }, void 0)] }, void 0)) : null] }, void 0));
};
exports.TradeInContent = TradeInContent;
