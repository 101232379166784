import { gql, useQuery } from "@apollo/client";
import { FullDetails, GetInTouch } from "@renewal/rosie";
import React from "react";
import {
  WithTranslation,
  useTranslation,
  withTranslation
} from "react-i18next";
import { RouteComponentProps } from "react-router";

import {
  IFuelType,
  IGetSingleVehicleQuery
} from "../../__generated__/graphql-types";
import { useCurrentContractId } from "../../App.state";
import { getFuelTypeText } from "../../lib/helpers/vehicle-helpers";
import { loadingOrError } from "../../lib/ui/Loaders/GraphqlLoadingUtility";
import { RouterHelpersFullDetailsPath } from "../../router/router-helpers";

type AllProps = RouterHelpersFullDetailsPath &
  RouteComponentProps &
  WithTranslation;

const GET_OFFER = gql`
  query getSingleVehicle($vin: ID!, $contractNumber: String!) {
    customer {
      contract(contractId: $contractNumber) {
        contractNumber
        retailer {
          street
          address
          website
          name
          phoneNumber
          email
        }
      }
    }
    detailOffer(vin: $vin) {
      vin
      urlOfCarImage
      carModel
      carModelCode
      carModelVariant
      drivingStyle
      numberOfDoors
      fuelType
      interiorColor
      exteriorColor
      images
      equipment {
        equipmentName
      }
    }
  }
`;

function FullDetailsContainer(props: AllProps) {
  const vin = props.match.params.quoteID;
  const { currentContractId } = useCurrentContractId();

  const {
    loading,
    error,
    data = {} as IGetSingleVehicleQuery
  } = useQuery<IGetSingleVehicleQuery>(GET_OFFER, {
    variables: { vin: vin, contractNumber: currentContractId }
  });

  const { t: matchMakerT } = useTranslation("match-maker");

  if (loading || error) return loadingOrError(loading, error);

  if (!data.detailOffer) {
    return <div>offer does not exist</div>;
  }

  const { t } = props;
  const { detailOffer: vehicle } = data;

  return (
    <FullDetails
      vehicle={{
        ...vehicle,
        fuelType: getFuelTypeText(vehicle.fuelType, matchMakerT),
        equipment: {
          standardEquipments:
            vehicle.equipment.length > 0
              ? [
                  {
                    category: "",
                    options: vehicle.equipment.map(e => {
                      return {
                        category: "",
                        description: e.equipmentName,
                        isStandard: true
                      };
                    })
                  }
                ]
              : [],
          optionalEquipments: []
        }
      }}
      isElectricVehicle={vehicle.fuelType === IFuelType.ELECTRIC}
      prevCall={props.history.goBack}
      t={t}
      getInTouchComponent={
        <GetInTouch
          t={t}
          retailer={{
            ...data.customer.contract.retailer
          }}
          showRetailerName={true}
        />
      }
    />
  );
}

export default withTranslation("full-details-page")(FullDetailsContainer);
