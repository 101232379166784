import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ICarSearchType } from "../../../__generated__/graphql-types";
import { adobeDataLayer } from "../../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { CarType } from "../../../lib/components/MatchMakerAnswersEditComponents/CarType";
import { ColorOfCar } from "../../../lib/components/MatchMakerAnswersEditComponents/ColorOfCar";
import { TypeOfTrain } from "../../../lib/components/MatchMakerAnswersEditComponents/TypeOfTrain";
import { getFieldValidators } from "../../../lib/helpers/match-maker-validator";
import { RouterHelpers } from "../../../router/router-helpers";
import { genericValidator } from "../MatchMakerHelper";
import MatchMakerQuestionWrapper from "./MatchMakerQuestionWrapper";

interface OwnProps {
  matchMakerAnswersId: string;
  goToPage: (path: string) => void;
  isEditMode?: boolean;
}

const MatchMakerQuestion1: React.FC<OwnProps> = props => {
  const { t } = useTranslation("match-maker");
  const { goToPage, matchMakerAnswersId, isEditMode } = props;

  useEffect(() => {
    adobeDataLayer.matchMakerQuestionPage(1, "Body & Drive type");
  }, []);

  return (
    <MatchMakerQuestionWrapper
      pageTitle={t("question-pages.question-page-one.title")}
      isEditMode={isEditMode}
      currentPercentage={1}
      nextPage={RouterHelpers.getMatchMakerQuestionPage2Path()}
      previousPage={RouterHelpers.getMatchMakerStartingPagePath()}
      goToPage={goToPage}
      validate={values => {
        let carSearchTypeFieldName: "typeOfCar" | "modelOfCar" = "typeOfCar";
        if (values.carSearchType === ICarSearchType.BYMODEL) {
          carSearchTypeFieldName = "modelOfCar";
        }

        const validationResult = genericValidator.bind(
          this,
          getFieldValidators(t, [carSearchTypeFieldName, "typeOfTrain"]),
          values
        )();

        return validationResult;
      }}
      matchMakerAnswersId={matchMakerAnswersId}
    >
      {() => (
        <div className="o-page-wrap o-page-wrap--small">
          <h4>{t("question-pages.items.bodystyle.label")}</h4>
          <CarType />
          <hr className="u-mv-xlarge"></hr>

          <h4>{t("question-pages.items.color.label")}</h4>
          <ColorOfCar />
          <hr className="u-mv-xlarge"></hr>

          <h4>{t("question-pages.items.drivetrain.label")}</h4>
          <TypeOfTrain />
        </div>
      )}
    </MatchMakerQuestionWrapper>
  );
};

export default MatchMakerQuestion1;
