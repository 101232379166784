import { GetInTouch, NoMatchesNotification } from "@renewal/rosie";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IAllVehicleOfferFieldsFragment,
  IContract,
  IFinancePlan,
  IMatchMakerAnswers,
  IVehicleOffer
} from "../../__generated__/graphql-types";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import CurrentContractCardContainer from "../../lib/components/CurrentContractCard/CurrentContractCardContainer";
import RetailerInfoContainer from "../../lib/components/RetailerInfo/RetailerInfoContainer";
import { VehicleContractTileContainer } from "../../lib/components/VehicleContractTile/VehicleContractTileContainer";
import { RouterHelpers } from "../../router/router-helpers";
import LoadMoreButton from "./components/LoadMoreButton";

interface OwnProps {
  vehicles: IAllVehicleOfferFieldsFragment[];
  contract: IContract;
  showMoreButtonVisible: boolean;
  visibleCount: number;
  loadMore: () => void;
  preferredInstallment: number;
  selectedFinancePlan: IFinancePlan;
  onFullScreenOpen: () => void;
  onSaveOffer: (offer: IVehicleOffer) => void;
  matchMakerAnswers: IMatchMakerAnswers;
}

function wantsToBeContacted(): boolean {
  return false;
}

export const VehicleSelectionDesktop: React.FC<OwnProps> = ({
  contract,
  vehicles,
  visibleCount,
  preferredInstallment,
  showMoreButtonVisible,
  loadMore,
  selectedFinancePlan,
  onFullScreenOpen,
  onSaveOffer,
  matchMakerAnswers
}) => {
  const { t } = useTranslation("matches-page");

  const [favoriteDisabled, setFavoriteDisabled] = useState<boolean>(false);

  return (
    <>
      <div className="o-layout__item u-3/4 u-2/3@l u-1/2@m u-1/1@s">
        <RetailerInfoContainer contractData={contract} />
        {vehicles.length > 0 ? (
          <>
            <div className="u-mt">
              <div className="o-layout o-layout--equal-height">
                {vehicles.slice(0, visibleCount).map((match, i) => {
                  return (
                    <div
                      className="o-layout__item  u-1/3 u-1/2@l u-1/1@m"
                      key={i}
                    >
                      <VehicleContractTileContainer
                        match={match}
                        contractNumber={contract.contractNumber}
                        preferredInstallment={preferredInstallment}
                        selectedFinancePlan={selectedFinancePlan}
                        onFullScreenOpen={onFullScreenOpen}
                        onSaveOffer={() => onSaveOffer(match as IVehicleOffer)}
                        favoriteDisabled={favoriteDisabled}
                        setFavoriteDisabled={setFavoriteDisabled}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <LoadMoreButton
              showMoreButtonVisible={showMoreButtonVisible}
              loadMore={loadMore}
            />

            <p className="u-pt-large">{t("matches-legal-notes")}</p>
          </>
        ) : (
          <NoMatchesNotification
            text={t("no-matches")}
            hasSubmittedVehicle={wantsToBeContacted()}
            hasSubmittedVehicleText={t("no-matches-submitted")}
            linkText={t("no-matches-link")}
            linkTo={RouterHelpers.getMatchMakerSummaryPagePath()}
          />
        )}

        <GetInTouch
          t={t}
          retailer={{
            ...contract.retailer
          }}
          trackingCall={() => {
            adobeDataLayer.carSelectionGetInTouch(matchMakerAnswers);
          }}
        />
      </div>
      <div className="o-layout__item u-1/4 u-1/3@l u-1/2@m u-1/1@s">
        <div className="c-accordion js-is-fx dr-contract-accordion">
          <div className="c-accordion__header">
            <h3 className="c-accordion__title">
              <button className="c-accordion__title-label">
                <span className="c-accordion__title-label-text">
                  {t("shared:contract-section-title")}
                </span>
              </button>
            </h3>
          </div>
        </div>

        <CurrentContractCardContainer />
      </div>
    </>
  );
};

export default VehicleSelectionDesktop;
