"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
require("./QuoteCircle.scss");
var react_1 = __importStar(require("react"));
var VehicleDisplayHelpers_1 = require("../../utils/VehicleDisplayHelpers");
var LegendElements_1 = require("./LegendElements");
var QuoteSVGRing_1 = require("./QuoteSVGRing");
var QuoteCircle = /** @class */ (function (_super) {
    __extends(QuoteCircle, _super);
    function QuoteCircle(props) {
        var _this = _super.call(this, props) || this;
        _this.onMouseEnter = _this.onMouseEnter.bind(_this);
        _this.onMouseLeave = _this.onMouseLeave.bind(_this);
        _this.state = { selectedGroup: "" };
        return _this;
    }
    // helper for hover functionality, finds closest parent with data-group attribute
    // eslint-disable-next-line
    QuoteCircle.prototype.getDataGroupParent = function (el) {
        if (!el || !el.getAttribute)
            return "";
        var attributeValue = el.getAttribute("data-group");
        if (attributeValue)
            return attributeValue;
        return this.getDataGroupParent(el.parentNode);
    };
    QuoteCircle.prototype.onMouseEnter = function (ev) {
        this.setState({ selectedGroup: this.getDataGroupParent(ev.target) });
    };
    QuoteCircle.prototype.onMouseLeave = function () {
        this.setState({ selectedGroup: "" });
    };
    QuoteCircle.prototype.getSelectedClass = function () {
        return this.state.selectedGroup !== ""
            ? "selected-" + this.state.selectedGroup
            : "leaving";
    };
    QuoteCircle.prototype.render = function () {
        var _a = this.props, t = _a.t, quoteCircleTitle = _a.quoteCircleTitle, quoteCircleTitle2 = _a.quoteCircleTitle2, quoteCircleTitle3 = _a.quoteCircleTitle3, _b = _a.quoteCircleDescription1, quoteCircleDescription1 = _b === void 0 ? "" : _b, _c = _a.quoteCircleDescription2, quoteCircleDescription2 = _c === void 0 ? "" : _c, _d = _a.quoteCircleDescription3, quoteCircleDescription3 = _d === void 0 ? jsx_runtime_1.jsx(jsx_runtime_1.Fragment, {}, void 0) : _d, urlOfCarImage = _a.urlOfCarImage, _e = _a.withFullDetails, withFullDetails = _e === void 0 ? true : _e, equity = _a.equity, numberOfMonthlyPayments = _a.numberOfMonthlyPayments, brandContribution = _a.brandContribution, additionalDeposit = _a.additionalDeposit, hasVehicleOverrideValue = _a.hasVehicleOverrideValue, installment = _a.installment, bonusPayment = _a.bonusPayment, finalPayment = _a.finalPayment, _f = _a.legendElements, legendElements = _f === void 0 ? [
            LegendElements_1.LegendElement.initialDeposit,
            LegendElements_1.LegendElement.brandContribution,
            LegendElements_1.LegendElement.additionalDeposit,
            LegendElements_1.LegendElement.monthlyPayments,
            LegendElements_1.LegendElement.bonusPayment,
            LegendElements_1.LegendElement.finalPayment
        ] : _f, onOpenToolTip = _a.onOpenToolTip;
        var composeArcData = function (groupName, value, style) {
            return {
                value: value && value > 0 ? [value] : [],
                className: groupName + "-color",
                dataGroup: groupName,
                style: style ? style : QuoteSVGRing_1.ArcStyle.thick
            };
        };
        var arcDataArray = [
            composeArcData("initial-deposit", equity),
            composeArcData("brand-contribution", brandContribution),
            composeArcData("additional-deposit", additionalDeposit),
            {
                value: Array(numberOfMonthlyPayments).fill(installment),
                className: "monthly-payment-color",
                dataGroup: "monthly-payment",
                style: QuoteSVGRing_1.ArcStyle.thin
            },
            composeArcData("bonus-payment", bonusPayment, QuoteSVGRing_1.ArcStyle.thin),
            composeArcData("optional-final-payment", finalPayment, QuoteSVGRing_1.ArcStyle.thin)
        ].filter(function (data) { return data.value.length > 0; });
        return (jsx_runtime_1.jsxs("div", __assign({ className: this.getSelectedClass() + " o-layout o-layout--flush", "data-component-id": "quote-circles" }, { children: [jsx_runtime_1.jsx("div", __assign({ className: "o-layout__item u-1/2 u-8/12@l u-7/12@xl u-1/1@s dr-quote-circle" }, { children: jsx_runtime_1.jsx("div", __assign({ className: "u-block-center" }, { children: jsx_runtime_1.jsx(QuoteSVGRing_1.QuoteSVGRing, { onMouseEnter: this.onMouseEnter, onMouseLeave: this.onMouseLeave, arcData: arcDataArray, selectedClass: this.getSelectedClass(), imageUrl: VehicleDisplayHelpers_1.determineImageUrl(urlOfCarImage), title: quoteCircleTitle, title2: quoteCircleTitle2, title3: quoteCircleTitle3, description1: quoteCircleDescription1, description2: quoteCircleDescription2, description3: quoteCircleDescription3, shouldDisplayFullDetailsLink: withFullDetails, fullDetailsLinkPath: this.props.fullDetailsPath, fullDetailsLinkText: t("quote-circle-details-link") }, void 0) }), void 0) }), void 0), jsx_runtime_1.jsx("div", __assign({ className: "o-layout__item u-1/2 u-4/12@l u-5/12@xl u-1/1@s u-mt-xsmall@s" }, { children: legendElements.map(function (e, idx) {
                        var _a;
                        return (jsx_runtime_1.jsx(react_1["default"].Fragment, { children: (_a = LegendElements_1.LegendElements(t, idx, equity, finalPayment, numberOfMonthlyPayments, installment, brandContribution, additionalDeposit, hasVehicleOverrideValue, bonusPayment, onOpenToolTip).find(function (element) { return element.element === e; })) === null || _a === void 0 ? void 0 : _a.node }, idx));
                    }) }), void 0)] }), void 0));
    };
    return QuoteCircle;
}(react_1.Component));
exports["default"] = QuoteCircle;
