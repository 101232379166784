import {
  Breakpoints,
  Favorite,
  OnChangeEvent,
  QuoteCircle,
  bronsonIconSwitchEmpty
} from "@renewal/rosie";
import { Button, ButtonContainer, Notification } from "@vwfs-its/bronson-react";
import React from "react";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";

import {
  IFinanceDetails,
  IMatchMakerAnswers,
  IRetailer,
  IVehicleOffer
} from "../../__generated__/graphql-types";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import CurrentContractCardContainer from "../../lib/components/CurrentContractCard/CurrentContractCardContainer";
import { RouterHelpers } from "../../router/router-helpers";
import QuoteTable from "./components/QuoteTable/QuoteTable";
import QuoteTableCard from "./components/QuoteTable/QuoteTableCard";

interface OwnProps {
  offer: IFinanceDetails;
  isSaved: boolean;
  onChangeIsSaved: OnChangeEvent<boolean>;
  onSelectAndContinue: Function;
  onBackToOverview: Function;
  prevCall: () => void;
  wantsToBeContacted: boolean;
  retailer: IRetailer;
  isSubmittedCar?: boolean;
  selectedOffer?: IVehicleOffer;
  currentLocation: string;
  hasMonthlyPayment: boolean;
  equity: number;
  matchMakerAnswers: IMatchMakerAnswers;
}

const FinanceQuote: React.FC<OwnProps> = ({
  offer,
  isSaved,
  onChangeIsSaved,
  prevCall,
  wantsToBeContacted,
  retailer,
  onBackToOverview,
  isSubmittedCar,
  currentLocation,
  selectedOffer,
  onSelectAndContinue,
  hasMonthlyPayment,
  equity,
  matchMakerAnswers
}) => {
  const { t } = useTranslation("finance-quote-page");
  const quote = offer.quote;

  const onChangeHandler = (): void => {
    onChangeIsSaved({
      propNames: ["isSaved", ""],
      newValue: !isSaved
    });
  };

  const onOpenToolTip = (keyWord: string) => {
    adobeDataLayer.financeQuoteHoverInfoIcon(keyWord, matchMakerAnswers);
  };

  const circle = () => (
    <QuoteCircle
      equity={equity}
      brandContribution={quote.brandContribution}
      additionalDeposit={quote.additionalDeposit}
      installment={quote.installment}
      bonusPayment={quote.bonusPayment}
      finalPayment={quote.finalPayment}
      quoteCircleTitle={offer.carModel}
      quoteCircleDescription1={
        hasMonthlyPayment
          ? t("shared:installment", {
              amount: quote.installment
            })
          : undefined
      }
      quoteCircleDescription2={
        hasMonthlyPayment
          ? `${t("shared:term", {
              months: quote.term
            })} ${t("shared:apr", {
              interest: quote.annualPercentageRate
            })}`
          : undefined
      }
      quoteCircleDescription3={bronsonIconSwitchEmpty(
        quote.contractType,
        t("quote-circle-product-type")
      )}
      urlOfCarImage={offer.carImages[0]}
      t={t}
      numberOfMonthlyPayments={quote.term}
      withFullDetails={true}
      fullDetailsPath={RouterHelpers.getFullDetailsPath(
        offer.carVin,
        currentLocation
      )}
      onOpenToolTip={onOpenToolTip.bind(this)}
    />
  );

  return (
    <>
      {wantsToBeContacted && (
        <div className="o-component-wrapper">
          <Notification status="success" shown notClosable>
            {t("shared:submitted-car-notification", {
              vehicleModel: selectedOffer ? selectedOffer.vehicle.carModel : "",
              dealerName: retailer.name,
              dealerPhone: retailer.phoneNumber
            })}
          </Notification>
        </div>
      )}
      <div className="o-component-wrapper">
        <header className="c-section-heading">
          <h2 className="c-section-heading__title">{t("title")}</h2>
        </header>
      </div>

      <div className="o-component-wrapper">
        <div className="o-layout">
          <div className="o-layout__item u-2/3 u-1/1@m">
            <h6>{t("quote-title")}</h6>
            <MediaQuery maxWidth={Breakpoints.Tablet.Max}>
              <div className="o-component-wrapper">
                <article className="c-card u-mb">{circle()}</article>
              </div>

              <div className="o-component-wrapper">
                <article className="c-card">
                  <QuoteTableCard
                    quote={quote}
                    onOpenToolTip={onOpenToolTip.bind(this)}
                  />

                  <h6>{t("tac-title")}</h6>
                  <p className="u-text-fs-2">{t("tac")}</p>
                </article>
              </div>
            </MediaQuery>

            <MediaQuery minWidth={Breakpoints.Desktop.Min}>
              <article className="c-card">
                <div className="c-card__body">
                  <div className="o-component-wrapper">{circle()}</div>

                  <div className="o-component-wrapper">
                    <QuoteTable
                      quote={quote}
                      onOpenToolTip={onOpenToolTip.bind(this)}
                    />
                  </div>

                  <div className="o-component-wrapper">
                    <h6>{t("tac-title")}</h6>
                    <p className="u-text-fs-2">{t("tac")}</p>
                  </div>
                </div>
              </article>
            </MediaQuery>

            {!isSubmittedCar && (
              <Favorite
                isSaved={isSaved}
                isSavedText={t("star-selected")}
                saveText={t("star-not-selected")}
                onClickHandler={onChangeHandler}
                tcTrackingFunction="checkboxSaveOfferMatch"
              />
            )}
          </div>

          <MediaQuery minWidth={Breakpoints.Desktop.Min}>
            <div className="o-layout__item u-1/3 u-1/1@s">
              <h6>{t("shared:contract-section-title")}</h6>
              <CurrentContractCardContainer
                onOpenToolTip={onOpenToolTip.bind(this)}
              />
            </div>
          </MediaQuery>
        </div>
      </div>

      <div className="o-component-wrapper">
        <ButtonContainer nav>
          <Button
            secondary
            onClick={prevCall}
            className="u-3/12 u-1/3@m u-1/1@s"
            inContainer
          >
            {t("shared:back-button")}
          </Button>
          {wantsToBeContacted ? (
            <Button
              onClick={() => onBackToOverview()}
              className="u-3/12 u-1/3@m u-1/1@s"
              inContainer
            >
              {t("shared:renewal-overview-button")}
            </Button>
          ) : (
            <Button
              onClick={() => onSelectAndContinue()}
              className="u-3/12 u-1/3@m u-1/1@s"
              inContainer
            >
              {t("select-button")}
            </Button>
          )}
        </ButtonContainer>
      </div>
    </>
  );
};

export default FinanceQuote;
