import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { IVehicle } from "../../../../__generated__/graphql-types";
import { VehicleContractTileBack } from "./VehicleContractTileBackComponent";

interface OwnProps {
  vehicle: IVehicle;
  handleFlip?: () => void;
}
type AllProps = OwnProps & RouteComponentProps;

class VehicleContractTileBackContainer extends Component<AllProps> {
  render() {
    return (
      <VehicleContractTileBack
        vehicle={this.props.vehicle}
        handleFlip={this.props.handleFlip}
        currentLocation={this.props.location.pathname}
      />
    );
  }
}

export default withRouter(VehicleContractTileBackContainer);
