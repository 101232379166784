"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.NoMatchesNotification = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var UILink_1 = require("../bronson/renewal/UILink");
var NotSubmitted = function (_a) {
    var text = _a.text, linkText = _a.linkText, linkTo = _a.linkTo;
    return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx("span", { children: text }, void 0), jsx_runtime_1.jsx("br", {}, void 0), jsx_runtime_1.jsx("br", {}, void 0), jsx_runtime_1.jsx(UILink_1.UILink, { text: linkText, to: linkTo, icon: "semantic-edit", className: "u-base-link" }, void 0)] }, void 0));
};
var Submitted = function (_a) {
    var text = _a.text;
    return jsx_runtime_1.jsx("span", { children: text }, void 0);
};
var NoMatchesNotification = function (_a) {
    var text = _a.text, linkText = _a.linkText, linkTo = _a.linkTo, hasSubmittedVehicle = _a.hasSubmittedVehicle, hasSubmittedVehicleText = _a.hasSubmittedVehicleText;
    return (jsx_runtime_1.jsx("div", __assign({ className: "c-notification__container js-notification-container u-mt" }, { children: jsx_runtime_1.jsx("div", __assign({ className: "c-notification c-notification--info dr-info-box" }, { children: jsx_runtime_1.jsx("div", __assign({ className: "c-notification__outer-wrap" }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "c-notification__inner-wrap" }, { children: [jsx_runtime_1.jsx("i", { className: "c-notification__status-icon" }, void 0), jsx_runtime_1.jsx("div", __assign({ className: "c-notification__content" }, { children: jsx_runtime_1.jsx("p", __assign({ className: "c-notification__text" }, { children: hasSubmittedVehicle ? (jsx_runtime_1.jsx(Submitted, { text: hasSubmittedVehicleText }, void 0)) : (jsx_runtime_1.jsx(NotSubmitted, { text: text, linkText: linkText, linkTo: linkTo }, void 0)) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0));
};
exports.NoMatchesNotification = NoMatchesNotification;
