import { placeholderImg, placeholderNode } from "@renewal/rosie";
import React, { MouseEventHandler, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import {
  IMatchMakerAnswers,
  IVehicleOffer,
  useGetSelectedOfferQuery
} from "../../__generated__/graphql-types";
import { useCurrentContractId } from "../../App.state";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { useOfferOperations } from "../../lib/helpers/saved-offers";
import { loadingOrError } from "../../lib/ui/Loaders/GraphqlLoadingUtility";
import {
  RouterHelpers,
  RouterHelpersQuotePath
} from "../../router/router-helpers";
import { CarOfferOverview } from "./CarOfferOverview";

const CarOfferContainer: React.FC<RouterHelpersQuotePath> = ({ match }) => {
  const { t } = useTranslation("dealer-appointment-page");
  const history = useHistory();
  const { vin } = useParams<{ vin: string; id: string }>();
  const offerId = match.params.id;

  const { currentContractId } = useCurrentContractId();
  const { savedOffers } = useOfferOperations(currentContractId);

  const { loading, error, data } = useGetSelectedOfferQuery({
    variables: { vin: vin, contractNumber: currentContractId }
  });

  useEffect(() => {
    const offer = savedOffers.find(o => o.wasSelected);
    if (data && data?.customer?.contract?.matchMakerAnswers && offer) {
      adobeDataLayer.setDealerData({
        companyId: "",
        companyName: data?.customer?.contract?.retailer?.name
      });
      adobeDataLayer.setProduct(offer as IVehicleOffer);
      adobeDataLayer.carOffer(
        data?.customer?.contract?.matchMakerAnswers as IMatchMakerAnswers
      );
    }
  }, [data, savedOffers]);

  if (loading || error) return loadingOrError(loading, error);

  if (!data || !data.financeOffer) {
    return <div>offer{offerId} does not exist</div>;
  }

  const onSubmitHandler: MouseEventHandler = () => {
    if (!data.financeOffer) return;

    history.push(RouterHelpers.getDealerAppointmentPath(vin, offerId));
  };
  return (
    <CarOfferOverview
      onSubmit={onSubmitHandler}
      prevCall={history.goBack}
      wantsToBeContacted={!!data.customer.contract.submittedOffer}
      onBackToOverview={() =>
        history.push(RouterHelpers.getRenewalOverviewPath())
      }
      selectedOfferQueryResult={data}
      placeholderImg={placeholderNode("u-mt")}
      galleryPlaceholder={placeholderImg()}
      t={t}
    />
  );
};

export default CarOfferContainer;
