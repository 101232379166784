"use strict";
exports.__esModule = true;
exports.licenseTexts = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var _0BSD_1 = require("./0BSD");
var Apache_1 = require("./Apache");
var BSD2_1 = require("./BSD2");
var BSD3_1 = require("./BSD3");
var CC0_1 = require("./CC0");
var CC3_1 = require("./CC3");
var CC4_1 = require("./CC4");
var GPL2_1 = require("./GPL2");
var GPL3_1 = require("./GPL3");
var ISC_1 = require("./ISC");
var MIT_1 = require("./MIT");
var MPL11_1 = require("./MPL11");
var MPL2_1 = require("./MPL2");
var ODC_By_1 = require("./ODC-By");
var Unlicense_1 = require("./Unlicense");
var W3C_1 = require("./W3C");
var WTFPL_1 = require("./WTFPL");
function licenseTexts(license) {
    switch (license) {
        case "MIT": {
            return jsx_runtime_1.jsx(MIT_1.MIT, {}, void 0);
        }
        case "ISC": {
            return jsx_runtime_1.jsx(ISC_1.ISC, {}, void 0);
        }
        case "BSD-3-Clause": {
            return jsx_runtime_1.jsx(BSD3_1.BSD3, {}, void 0);
        }
        case "BSD-2-Clause": {
            return jsx_runtime_1.jsx(BSD2_1.BSD2, {}, void 0);
        }
        case "Apache-2.0": {
            return jsx_runtime_1.jsx(Apache_1.Apache, {}, void 0);
        }
        case "GPL-3.0": {
            return jsx_runtime_1.jsx(GPL3_1.GPL3, {}, void 0);
        }
        case "GPL-2.0": {
            return jsx_runtime_1.jsx(GPL2_1.GPL2, {}, void 0);
        }
        case "The Unlicense": {
            return jsx_runtime_1.jsx(Unlicense_1.Unlicense, {}, void 0);
        }
        case "CC0-1.0":
            return jsx_runtime_1.jsx(CC0_1.CC0, {}, void 0);
        case "CC-BY-4.0":
            return jsx_runtime_1.jsx(CC4_1.CC4, {}, void 0);
        case "CC-BY-3.0":
            return jsx_runtime_1.jsx(CC3_1.CC3, {}, void 0);
        case "MPL-1.1":
            return jsx_runtime_1.jsx(MPL11_1.MPL11, {}, void 0);
        case "MPL-2.0":
            return jsx_runtime_1.jsx(MPL2_1.MPL2, {}, void 0);
        case "W3C":
            return jsx_runtime_1.jsx(W3C_1.W3C, {}, void 0);
        case "WTFPL":
            return jsx_runtime_1.jsx(WTFPL_1.WTFPL, {}, void 0);
        case "0BSD":
            return jsx_runtime_1.jsx(_0BSD_1.BSD0, {}, void 0);
        case "ODC-By-1.0":
            return jsx_runtime_1.jsx(ODC_By_1.ODCBy1, {}, void 0);
        default: {
            return jsx_runtime_1.jsx(jsx_runtime_1.Fragment, {}, void 0);
        }
    }
}
exports.licenseTexts = licenseTexts;
