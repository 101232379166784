import { ApolloError, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ICreateOrUpdateMmMutation,
  IMatchMakerAnswers,
  useGetVehicleOffersQuery
} from "../../__generated__/graphql-types";
import { useCurrentContractId } from "../../App.state";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { colorList } from "../../lib/helpers/vehicle-helpers";
import { loadingOrError } from "../../lib/ui/Loaders/GraphqlLoadingUtility";
import { PUT_MATCH_MAKER_ANSWERS } from "../MatchMaker/MatchMaker.gql";
import VehicleSelection from "./VehicleSelectionComponent";

const VehicleSelectionContainer: React.FC = () => {
  const { t } = useTranslation("match-maker");

  const { currentContractId } = useCurrentContractId();
  const [refetching, setRefetching] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [refetchingError, setRefetchingError] = useState<
    ApolloError | undefined
  >(undefined);
  const { loading, error, data, refetch } = useGetVehicleOffersQuery({
    variables: {
      contractId: currentContractId,
      sortingPreference: "ranking"
    }
  });

  const [
    createOrUpdateMatchMakerAnswers,
    { loading: loadingUpdateMM, error: errorUpdateMM }
  ] = useMutation<ICreateOrUpdateMmMutation>(PUT_MATCH_MAKER_ANSWERS);

  useEffect(() => {
    if (data && initialLoad) {
      setInitialLoad(false);
      adobeDataLayer.setDealerData({
        companyId: "",
        companyName: data?.customer?.contract?.retailer?.name
      });
      adobeDataLayer.carSelection(
        data?.customer?.contract?.matchMakerAnswers as IMatchMakerAnswers
      );
    }
  }, [data, initialLoad]);

  if (
    loading ||
    loadingUpdateMM ||
    refetching ||
    error ||
    errorUpdateMM ||
    refetchingError
  ) {
    return loadingOrError(
      loading || loadingUpdateMM || refetching,
      error || errorUpdateMM || refetchingError
    );
  }

  const onFilterOptionChanged = async (
    values: IMatchMakerAnswers
  ): Promise<void> => {
    adobeDataLayer.carSelectionApplyPreferenceChanges(
      data?.customer.contract.matchMakerAnswers as IMatchMakerAnswers
    );
    if (values.colorOfCar?.length === 0)
      colorList(t).forEach(c => {
        values.colorOfCar?.push(c.value);
      });

    await createOrUpdateMatchMakerAnswers({
      variables: {
        ...values,
        isCompleted: true,
        contractId: currentContractId
      }
    });

    setRefetching(true);
    refetch()
      .then(() => setRefetching(false))
      .catch(e => {
        setRefetching(false);
        setRefetchingError(e);
      });
  };

  const onSortingOptionChanged = async (newSortingOption: string) => {
    adobeDataLayer.carSelectionSortingChanged(
      newSortingOption,
      data?.customer.contract.matchMakerAnswers as IMatchMakerAnswers
    );
    await createOrUpdateMatchMakerAnswers({
      variables: {
        ...data?.customer.contract.matchMakerAnswers,
        sortingOption: newSortingOption,
        isCompleted: true,
        contractId: currentContractId
      }
    });

    setRefetching(true);
    refetch({
      contractId: currentContractId,
      sortingPreference: newSortingOption
    })
      .then(() => setRefetching(false))
      .catch(e => {
        setRefetching(false);
        setRefetchingError(e);
      });
  };

  const onEditPreferences = () => {
    adobeDataLayer.carSelectionEditPreferences(
      data?.customer.contract.matchMakerAnswers as IMatchMakerAnswers
    );
  };

  if (!data) throw new Error("No data available after loading is done");

  return (
    <VehicleSelection
      vehicles={data.customer.contract.matchMakerAnswers.matchedOffers}
      matchMakerAnswers={data.customer.contract.matchMakerAnswers}
      contract={data.customer.contract}
      onFilterOptionChanged={onFilterOptionChanged}
      onSortingOptionChanged={onSortingOptionChanged}
      onEditPreferences={onEditPreferences}
    />
  );
};

export default VehicleSelectionContainer;
