import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum ICarSearchType {
  BYMODEL = 'ByModel',
  BYTYPE = 'ByType'
}

export enum IContactDate {
  HOLIDAYS = 'HOLIDAYS',
  WEEKDAYS = 'WEEKDAYS'
}

export enum IContactMethod {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE'
}

export enum IContactTime {
  DAYTIME = 'DAYTIME',
  EVENING = 'EVENING',
  MORNING = 'MORNING'
}

export type IContract = {
  amountToSettle: Scalars['Float'];
  bonusPayment: Scalars['Float'];
  brandName: Scalars['String'];
  carModel: Scalars['String'];
  contractNumber: Scalars['ID'];
  contractType: IFinancePlan;
  contractTypeText: Scalars['String'];
  currentVehicleValue: Scalars['Float'];
  customerEligibility: Scalars['String'];
  downPayment: Scalars['Float'];
  eligibleStatus: IEligibilityStatus;
  endDate: Scalars['String'];
  equity: Scalars['Float'];
  hasMonthlyPayment: Scalars['Boolean'];
  installmentAmount: Scalars['Float'];
  licensePlate1: Scalars['String'];
  licensePlate2: Scalars['String'];
  licensePlate3: Scalars['String'];
  matchMakerAnswers: IMatchMakerAnswers;
  retailer: IRetailer;
  savedOffers?: Maybe<Array<IVehicleOffer>>;
  submittedOffer?: Maybe<IVehicleOffer>;
  term: Scalars['Float'];
  vin: Scalars['String'];
};

export type ICustomer = {
  contract: IContract;
  contracts: Array<IContract>;
  customerId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  mobilePhone?: Maybe<Scalars['String']>;
};


export type ICustomerContractArgs = {
  contractId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
};


export type ICustomerContractsArgs = {
  customerId?: Maybe<Scalars['String']>;
};

export enum IDrivingStyle {
  EFFICIENCY = 'EFFICIENCY',
  PERFORMANCE = 'PERFORMANCE'
}

export enum IEligibilityStatus {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE'
}

export type IEquipment = {
  equipmentName: Scalars['String'];
};

export type IFinanceDetails = {
  carImages: Array<Scalars['String']>;
  carModel: Scalars['String'];
  carModelVariant: Scalars['String'];
  carVin: Scalars['String'];
  quote: IQuote;
  wasSaved: Scalars['Boolean'];
};

export enum IFinancePlan {
  CLOSE_END_LEASE = 'CLOSE_END_LEASE',
  FUTURE_DRIVE = 'FUTURE_DRIVE',
  OPEN_END_LEASE = 'OPEN_END_LEASE',
  OTHERS = 'OTHERS',
  SLOAN = 'SLOAN',
  SOLUTIONS = 'SOLUTIONS'
}

export type IFinanceSummary = {
  annualPercentageRate: Scalars['Float'];
  installment: Scalars['Float'];
  quoteId: Scalars['String'];
  term: Scalars['Float'];
};

export enum IFuelType {
  DIESEL = 'DIESEL',
  ELECTRIC = 'ELECTRIC',
  GASOLINE = 'GASOLINE'
}

export type IMatchMakerAnswers = {
  additionalDeposit?: Maybe<Scalars['Float']>;
  bonusPayment?: Maybe<Scalars['Float']>;
  carSearchType?: Maybe<ICarSearchType>;
  colorOfCar?: Maybe<Array<Maybe<Scalars['String']>>>;
  expectedDuration?: Maybe<Scalars['Float']>;
  expectedMonthlyPayment?: Maybe<Scalars['Float']>;
  financePlan?: Maybe<IFinancePlan>;
  fuelTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  importantAspects?: Maybe<Array<Maybe<Scalars['String']>>>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  matchedOffers: Array<IVehicleOffer>;
  milesPerYear?: Maybe<Scalars['Float']>;
  modelOfCar?: Maybe<Array<Maybe<Scalars['String']>>>;
  numberOfDoors?: Maybe<Array<Maybe<Scalars['Float']>>>;
  parkingType?: Maybe<Array<Maybe<IParkingType>>>;
  sortingOption?: Maybe<Scalars['String']>;
  typeOfCar?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeOfGears?: Maybe<Array<Maybe<ITransmissionType>>>;
  typeOfTrain?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type IMatchMakerAnswersMatchedOffersArgs = {
  sortingPreference?: Maybe<Scalars['String']>;
};

export enum IMatchType {
  BEST = 'Best',
  FAIR = 'Fair',
  GOOD = 'Good',
  GREAT = 'Great',
  NOMATCH = 'NoMatch'
}

export type IMutation = {
  createOrUpdateMatchMakerAnswers?: Maybe<IMatchMakerAnswers>;
  deleteOffer?: Maybe<IVehicleOffer>;
  requestVerification?: Maybe<Scalars['String']>;
  resendSMSCode?: Maybe<Scalars['String']>;
  saveOffer?: Maybe<Array<Maybe<IVehicleOffer>>>;
  selectOffer?: Maybe<IVehicleOffer>;
  submitOffer?: Maybe<IFinanceDetails>;
  verifyAccount?: Maybe<Scalars['String']>;
};


export type IMutationCreateOrUpdateMatchMakerAnswersArgs = {
  additionalDeposit?: Maybe<Scalars['Float']>;
  bonusPayment?: Maybe<Scalars['Float']>;
  carSearchType?: Maybe<ICarSearchType>;
  colorOfCar?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractId: Scalars['String'];
  expectedDuration?: Maybe<Scalars['Float']>;
  expectedMonthlyPayment?: Maybe<Scalars['Float']>;
  financePlan?: Maybe<IFinancePlan>;
  fuelTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  importantAspects?: Maybe<Array<Maybe<Scalars['String']>>>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  milesPerYear?: Maybe<Scalars['Float']>;
  modelOfCar?: Maybe<Array<Maybe<Scalars['String']>>>;
  numberOfDoors?: Maybe<Array<Maybe<Scalars['Float']>>>;
  parkingType?: Maybe<Array<Maybe<IParkingType>>>;
  sortingOption?: Maybe<Scalars['String']>;
  typeOfCar?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeOfGears?: Maybe<Array<Maybe<ITransmissionType>>>;
  typeOfTrain?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type IMutationDeleteOfferArgs = {
  contractId: Scalars['String'];
  quoteId: Scalars['ID'];
};


export type IMutationRequestVerificationArgs = {
  birthdate?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  mobilePhoneNumber?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};


export type IMutationResendSmsCodeArgs = {
  data?: Maybe<Scalars['String']>;
};


export type IMutationSaveOfferArgs = {
  contractId: Scalars['String'];
  matchType: IMatchType;
  quoteId: Scalars['ID'];
  vin: Scalars['ID'];
};


export type IMutationSelectOfferArgs = {
  contractId: Scalars['String'];
  matchType: IMatchType;
  quoteId: Scalars['String'];
  vin: Scalars['ID'];
};


export type IMutationSubmitOfferArgs = {
  contactDate?: Maybe<IContactDate>;
  contactEmail: Scalars['String'];
  contactPhoneNumber: Scalars['String'];
  contactTime?: Maybe<IContactTime>;
  contractId: Scalars['String'];
  dealerAddress: Scalars['String'];
  dealerCode: Scalars['String'];
  dealerEmail: Scalars['String'];
  dealerName: Scalars['String'];
  offerId: Scalars['ID'];
  selectedContactMethod: IContactMethod;
  vin: Scalars['String'];
};


export type IMutationVerifyAccountArgs = {
  data?: Maybe<Scalars['String']>;
  smsCode?: Maybe<Scalars['String']>;
};

export enum IParkingType {
  NORMAL = 'NORMAL',
  WIDE = 'WIDE'
}

export type IQuery = {
  customer: ICustomer;
  dealers?: Maybe<Array<IRetailer>>;
  detailOffer?: Maybe<IVehicle>;
  financeOffer?: Maybe<IFinanceDetails>;
  passedKYC: Scalars['Boolean'];
};


export type IQueryDealersArgs = {
  region?: Maybe<Scalars['String']>;
};


export type IQueryDetailOfferArgs = {
  vin: Scalars['ID'];
};


export type IQueryFinanceOfferArgs = {
  contractId?: Maybe<Scalars['String']>;
  vin: Scalars['ID'];
};

export type IQuote = {
  additionalDeposit: Scalars['Float'];
  annualPercentageRate: Scalars['Float'];
  bonusPayment: Scalars['Float'];
  brandContribution: Scalars['Float'];
  contractType: Scalars['String'];
  finalPayment: Scalars['Float'];
  firstPayment: Scalars['Float'];
  installment: Scalars['Float'];
  interestRate: Scalars['Float'];
  optionToPurchaseFee: Scalars['Float'];
  quoteId: Scalars['String'];
  retailCashPrice: Scalars['Float'];
  term: Scalars['Float'];
  totalCredit: Scalars['Float'];
};

export type IRetailer = {
  address: Scalars['String'];
  code: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  website: Scalars['String'];
};

export enum ITransmissionType {
  AUTOMATIC = 'Automatic',
  MANUAL = 'Manual',
  UNKNOWN = 'Unknown'
}

export type IVehicle = {
  carModel: Scalars['String'];
  carModelCode: Scalars['String'];
  carModelVariant: Scalars['String'];
  carWidth: Scalars['String'];
  drivingStyle: IDrivingStyle;
  equipment: Array<IEquipment>;
  exteriorColor: Scalars['String'];
  fuelType: IFuelType;
  images: Array<Scalars['String']>;
  interiorColor: Scalars['String'];
  numberOfDoors: Scalars['Float'];
  urlOfCarImage: Scalars['String'];
  vin: Scalars['String'];
};

export type IVehicleOffer = {
  expirationTime: Scalars['String'];
  hasExpired: Scalars['Boolean'];
  lastModified: Scalars['String'];
  quote: IFinanceSummary;
  typeOfMatch: IMatchType;
  vehicle: IVehicle;
  wantsToBeContacted: Scalars['Boolean'];
  wasRemoved: Scalars['Boolean'];
  wasSaved: Scalars['Boolean'];
  wasSelected: Scalars['Boolean'];
};

export type IGetContractForCurrentContractCardQueryVariables = Exact<{
  contractNumber: Scalars['String'];
}>;


export type IGetContractForCurrentContractCardQuery = { customer: (
    Pick<ICustomer, 'customerId' | 'isRegistered'>
    & { contract: (
      Pick<IContract, 'contractNumber' | 'contractType' | 'contractTypeText' | 'equity' | 'installmentAmount' | 'term' | 'customerEligibility' | 'currentVehicleValue' | 'amountToSettle' | 'carModel' | 'licensePlate1' | 'licensePlate2' | 'licensePlate3'>
      & { retailer: Pick<IRetailer, 'street' | 'address' | 'website' | 'name' | 'phoneNumber' | 'email' | 'code'> }
    ) }
  ) };

export type ITest_GetMatchesDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ITest_GetMatchesDataQuery = { customer: (
    Pick<ICustomer, 'customerId' | 'isRegistered'>
    & { contract: (
      Pick<IContract, 'contractNumber'>
      & { matchMakerAnswers: { matchedOffers: Array<IAllVehicleOfferFieldsFragment> }, savedOffers?: Maybe<Array<IAllVehicleOfferFieldsFragment>> }
    ) }
  ) };

export type IAllMatchMakerAnswerFieldsFragment = Pick<IMatchMakerAnswers, 'carSearchType' | 'typeOfCar' | 'modelOfCar' | 'colorOfCar' | 'typeOfTrain' | 'parkingType' | 'numberOfDoors' | 'typeOfGears' | 'fuelTypes' | 'importantAspects' | 'expectedMonthlyPayment' | 'expectedDuration' | 'milesPerYear' | 'additionalDeposit' | 'bonusPayment' | 'financePlan' | 'isCompleted' | 'sortingOption'>;

export type IAllContractFieldsFragment = (
  Pick<IContract, 'contractNumber' | 'eligibleStatus' | 'vin' | 'brandName' | 'downPayment' | 'bonusPayment' | 'licensePlate1' | 'licensePlate2' | 'licensePlate3' | 'contractType' | 'contractTypeText' | 'hasMonthlyPayment' | 'installmentAmount' | 'term' | 'endDate' | 'carModel' | 'currentVehicleValue' | 'amountToSettle' | 'equity' | 'customerEligibility'>
  & { retailer: Pick<IRetailer, 'street' | 'address' | 'website' | 'name' | 'phoneNumber' | 'email' | 'code'> }
);

export type IAllVehicleOfferFieldsFragment = (
  Pick<IVehicleOffer, 'typeOfMatch' | 'hasExpired' | 'expirationTime' | 'lastModified' | 'wasSelected' | 'wantsToBeContacted' | 'wasSaved' | 'wasRemoved'>
  & { vehicle: (
    Pick<IVehicle, 'vin' | 'urlOfCarImage' | 'carModel' | 'carModelCode' | 'carModelVariant' | 'drivingStyle' | 'numberOfDoors' | 'fuelType' | 'interiorColor' | 'exteriorColor' | 'images' | 'carWidth'>
    & { equipment: Array<Pick<IEquipment, 'equipmentName'>> }
  ), quote: Pick<IFinanceSummary, 'annualPercentageRate' | 'installment' | 'term' | 'quoteId'> }
);

export type IAllFinanceDetailsFieldsFragment = (
  Pick<IFinanceDetails, 'carVin' | 'carModel' | 'carImages' | 'carModelVariant' | 'wasSaved'>
  & { quote: Pick<IQuote, 'quoteId' | 'annualPercentageRate' | 'brandContribution' | 'contractType' | 'finalPayment' | 'installment' | 'interestRate' | 'optionToPurchaseFee' | 'retailCashPrice' | 'term' | 'totalCredit' | 'additionalDeposit' | 'bonusPayment' | 'firstPayment'> }
);

export type ISaveOfferMutationVariables = Exact<{
  vin: Scalars['ID'];
  quoteId: Scalars['ID'];
  matchType: IMatchType;
  contractId: Scalars['String'];
}>;


export type ISaveOfferMutation = { saveOffer?: Maybe<Array<Maybe<IAllVehicleOfferFieldsFragment>>> };

export type IDeleteOfferMutationVariables = Exact<{
  quoteId: Scalars['ID'];
  contractId: Scalars['String'];
}>;


export type IDeleteOfferMutation = { deleteOffer?: Maybe<IAllVehicleOfferFieldsFragment> };

export type IGetSavedOffersQueryVariables = Exact<{
  contractNumber: Scalars['String'];
}>;


export type IGetSavedOffersQuery = { customer: (
    Pick<ICustomer, 'customerId' | 'isRegistered'>
    & { contract: (
      { savedOffers?: Maybe<Array<IAllVehicleOfferFieldsFragment>>, submittedOffer?: Maybe<IAllVehicleOfferFieldsFragment>, matchMakerAnswers: Pick<IMatchMakerAnswers, 'expectedMonthlyPayment' | 'financePlan'> }
      & IAllContractFieldsFragment
    ) }
  ) };

export type IGetSelectedOfferQueryVariables = Exact<{
  vin: Scalars['ID'];
  contractNumber: Scalars['String'];
}>;


export type IGetSelectedOfferQuery = { customer: (
    Pick<ICustomer, 'homePhone' | 'mobilePhone' | 'email'>
    & { contract: (
      Pick<IContract, 'contractNumber'>
      & { matchMakerAnswers: IAllMatchMakerAnswerFieldsFragment, retailer: Pick<IRetailer, 'street' | 'address' | 'website' | 'name' | 'phoneNumber' | 'email' | 'code'>, submittedOffer?: Maybe<IAllVehicleOfferFieldsFragment> }
    ) }
  ), financeOffer?: Maybe<IAllFinanceDetailsFieldsFragment> };

export type IGetContractsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetContractsQuery = { customer: { contracts: Array<IAllContractFieldsFragment> } };

export type ISubmitOfferMutationVariables = Exact<{
  vin: Scalars['String'];
  offerId: Scalars['ID'];
  dealerName: Scalars['String'];
  dealerAddress: Scalars['String'];
  dealerEmail: Scalars['String'];
  dealerCode: Scalars['String'];
  contractId: Scalars['String'];
  contactEmail: Scalars['String'];
  contactPhoneNumber: Scalars['String'];
  selectedContactMethod: IContactMethod;
  contactDate?: Maybe<IContactDate>;
  contactTime?: Maybe<IContactTime>;
}>;


export type ISubmitOfferMutation = { submitOffer?: Maybe<IAllFinanceDetailsFieldsFragment> };

export type IGetDealersQueryVariables = Exact<{
  region: Scalars['String'];
}>;


export type IGetDealersQuery = { dealers?: Maybe<Array<Pick<IRetailer, 'email' | 'address' | 'name' | 'code'>>> };

export type ISelectOfferMutationVariables = Exact<{
  vin: Scalars['ID'];
  contractId: Scalars['String'];
  quoteId: Scalars['String'];
  matchType: IMatchType;
}>;


export type ISelectOfferMutation = { selectOffer?: Maybe<IAllVehicleOfferFieldsFragment> };

export type IGetSingleOfferQueryVariables = Exact<{
  vin: Scalars['ID'];
  contractId: Scalars['String'];
}>;


export type IGetSingleOfferQuery = { customer: { contract: (
      Pick<IContract, 'contractNumber' | 'hasMonthlyPayment' | 'equity'>
      & { matchMakerAnswers: (
        { matchedOffers: Array<IAllVehicleOfferFieldsFragment> }
        & IAllMatchMakerAnswerFieldsFragment
      ), retailer: Pick<IRetailer, 'street' | 'address' | 'website' | 'name' | 'phoneNumber' | 'email' | 'code'>, submittedOffer?: Maybe<IAllVehicleOfferFieldsFragment> }
    ) }, financeOffer?: Maybe<IAllFinanceDetailsFieldsFragment> };

export type IGetSingleVehicleQueryVariables = Exact<{
  vin: Scalars['ID'];
  contractNumber: Scalars['String'];
}>;


export type IGetSingleVehicleQuery = { customer: { contract: (
      Pick<IContract, 'contractNumber'>
      & { retailer: Pick<IRetailer, 'street' | 'address' | 'website' | 'name' | 'phoneNumber' | 'email'> }
    ) }, detailOffer?: Maybe<(
    Pick<IVehicle, 'vin' | 'urlOfCarImage' | 'carModel' | 'carModelCode' | 'carModelVariant' | 'drivingStyle' | 'numberOfDoors' | 'fuelType' | 'interiorColor' | 'exteriorColor' | 'images'>
    & { equipment: Array<Pick<IEquipment, 'equipmentName'>> }
  )> };

export type IGetMatchMakerAnswersQueryVariables = Exact<{
  contractId: Scalars['String'];
}>;


export type IGetMatchMakerAnswersQuery = { customer: { contract: (
      { matchMakerAnswers: IAllMatchMakerAnswerFieldsFragment }
      & IAllContractFieldsFragment
    ) } };

export type ICreateOrUpdateMmMutationVariables = Exact<{
  contractId: Scalars['String'];
  typeOfCar?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  carSearchType?: Maybe<ICarSearchType>;
  modelOfCar?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  colorOfCar?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  typeOfTrain?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  parkingType?: Maybe<Array<Maybe<IParkingType>> | Maybe<IParkingType>>;
  numberOfDoors?: Maybe<Array<Maybe<Scalars['Float']>> | Maybe<Scalars['Float']>>;
  typeOfGears?: Maybe<Array<Maybe<ITransmissionType>> | Maybe<ITransmissionType>>;
  fuelTypes?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  importantAspects?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  financePlan?: Maybe<IFinancePlan>;
  expectedMonthlyPayment?: Maybe<Scalars['Float']>;
  expectedDuration?: Maybe<Scalars['Float']>;
  milesPerYear?: Maybe<Scalars['Float']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  additionalDeposit?: Maybe<Scalars['Float']>;
  bonusPayment?: Maybe<Scalars['Float']>;
  sortingOption?: Maybe<Scalars['String']>;
}>;


export type ICreateOrUpdateMmMutation = { createOrUpdateMatchMakerAnswers?: Maybe<Pick<IMatchMakerAnswers, 'carSearchType' | 'typeOfCar' | 'modelOfCar' | 'colorOfCar' | 'typeOfTrain' | 'parkingType' | 'numberOfDoors' | 'typeOfGears' | 'fuelTypes' | 'importantAspects' | 'expectedMonthlyPayment' | 'expectedDuration' | 'financePlan' | 'milesPerYear' | 'additionalDeposit' | 'bonusPayment' | 'sortingOption' | 'isCompleted'>> };

export type IGetDataForRenewalOverviewQueryVariables = Exact<{
  contractId: Scalars['String'];
}>;


export type IGetDataForRenewalOverviewQuery = { customer: (
    Pick<ICustomer, 'customerId' | 'isRegistered'>
    & { contract: (
      { savedOffers?: Maybe<Array<IAllVehicleOfferFieldsFragment>>, matchMakerAnswers: IAllMatchMakerAnswerFieldsFragment }
      & IAllContractFieldsFragment
    ) }
  ) };

export type IGetVehicleOffersQueryVariables = Exact<{
  contractId: Scalars['String'];
  sortingPreference: Scalars['String'];
}>;


export type IGetVehicleOffersQuery = { customer: (
    Pick<ICustomer, 'customerId' | 'isRegistered'>
    & { contract: (
      { matchMakerAnswers: (
        { matchedOffers: Array<IAllVehicleOfferFieldsFragment> }
        & IAllMatchMakerAnswerFieldsFragment
      ) }
      & IAllContractFieldsFragment
    ) }
  ) };

export type IRequestVerificationMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['String']>;
  mobilePhoneNumber?: Maybe<Scalars['String']>;
}>;


export type IRequestVerificationMutation = Pick<IMutation, 'requestVerification'>;

export type IVerifyAccountMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
  smsCode?: Maybe<Scalars['String']>;
}>;


export type IVerifyAccountMutation = Pick<IMutation, 'verifyAccount'>;

export type IResendSmsCodeMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type IResendSmsCodeMutation = Pick<IMutation, 'resendSMSCode'>;

export type IGetWizardDataQueryVariables = Exact<{
  contractId: Scalars['String'];
}>;


export type IGetWizardDataQuery = { customer: { contract: (
      Pick<IContract, 'contractNumber'>
      & { matchMakerAnswers: Pick<IMatchMakerAnswers, 'isCompleted'>, savedOffers?: Maybe<Array<IAllVehicleOfferFieldsFragment>> }
    ) } };

export type IGetUserPassedKycQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetUserPassedKycQuery = Pick<IQuery, 'passedKYC'>;

export type IGetCurrentStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetCurrentStatusQuery = { customer: (
    Pick<ICustomer, 'isRegistered'>
    & { contracts: Array<(
      Pick<IContract, 'contractNumber'>
      & { matchMakerAnswers: Pick<IMatchMakerAnswers, 'isCompleted'> }
    )> }
  ) };

export const AllMatchMakerAnswerFieldsFragmentDoc = gql`
    fragment AllMatchMakerAnswerFields on MatchMakerAnswers {
  carSearchType
  typeOfCar
  modelOfCar
  colorOfCar
  typeOfTrain
  parkingType
  numberOfDoors
  typeOfGears
  fuelTypes
  importantAspects
  expectedMonthlyPayment
  expectedDuration
  milesPerYear
  additionalDeposit
  bonusPayment
  financePlan
  isCompleted
  sortingOption
}
    `;
export const AllContractFieldsFragmentDoc = gql`
    fragment AllContractFields on Contract {
  contractNumber
  eligibleStatus
  vin
  brandName
  downPayment
  bonusPayment
  licensePlate1
  licensePlate2
  licensePlate3
  contractType
  contractTypeText
  hasMonthlyPayment
  installmentAmount
  term
  endDate
  carModel
  currentVehicleValue
  amountToSettle
  equity
  customerEligibility
  retailer {
    street
    address
    website
    name
    phoneNumber
    email
    code
  }
}
    `;
export const AllVehicleOfferFieldsFragmentDoc = gql`
    fragment AllVehicleOfferFields on VehicleOffer {
  typeOfMatch
  hasExpired
  expirationTime
  lastModified
  wasSelected
  wantsToBeContacted
  wasSaved
  wasRemoved @client
  vehicle {
    vin
    urlOfCarImage
    carModel
    carModelCode
    carModelVariant
    drivingStyle
    numberOfDoors
    fuelType
    interiorColor
    exteriorColor
    images
    equipment {
      equipmentName
    }
    carWidth
  }
  quote {
    annualPercentageRate
    installment
    term
    quoteId
  }
}
    `;
export const AllFinanceDetailsFieldsFragmentDoc = gql`
    fragment AllFinanceDetailsFields on FinanceDetails {
  quote {
    quoteId
    annualPercentageRate
    brandContribution
    contractType
    finalPayment
    installment
    interestRate
    optionToPurchaseFee
    retailCashPrice
    term
    totalCredit
    additionalDeposit
    bonusPayment
    firstPayment
  }
  carVin
  carModel
  carImages
  carModelVariant
  wasSaved
}
    `;
export const GetContractForCurrentContractCardDocument = gql`
    query getContractForCurrentContractCard($contractNumber: String!) {
  customer {
    customerId
    isRegistered
    contract(contractId: $contractNumber) {
      contractNumber
      contractType
      contractTypeText
      equity
      installmentAmount
      term
      customerEligibility
      currentVehicleValue
      amountToSettle
      carModel
      licensePlate1
      licensePlate2
      licensePlate3
      retailer {
        street
        address
        website
        name
        phoneNumber
        email
        code
      }
    }
  }
}
    `;

/**
 * __useGetContractForCurrentContractCardQuery__
 *
 * To run a query within a React component, call `useGetContractForCurrentContractCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForCurrentContractCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForCurrentContractCardQuery({
 *   variables: {
 *      contractNumber: // value for 'contractNumber'
 *   },
 * });
 */
export function useGetContractForCurrentContractCardQuery(baseOptions: Apollo.QueryHookOptions<IGetContractForCurrentContractCardQuery, IGetContractForCurrentContractCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetContractForCurrentContractCardQuery, IGetContractForCurrentContractCardQueryVariables>(GetContractForCurrentContractCardDocument, options);
      }
export function useGetContractForCurrentContractCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetContractForCurrentContractCardQuery, IGetContractForCurrentContractCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetContractForCurrentContractCardQuery, IGetContractForCurrentContractCardQueryVariables>(GetContractForCurrentContractCardDocument, options);
        }
export type GetContractForCurrentContractCardQueryHookResult = ReturnType<typeof useGetContractForCurrentContractCardQuery>;
export type GetContractForCurrentContractCardLazyQueryHookResult = ReturnType<typeof useGetContractForCurrentContractCardLazyQuery>;
export type GetContractForCurrentContractCardQueryResult = Apollo.QueryResult<IGetContractForCurrentContractCardQuery, IGetContractForCurrentContractCardQueryVariables>;
export const Test_GetMatchesDataDocument = gql`
    query test_getMatchesData {
  customer {
    customerId
    isRegistered
    contract {
      contractNumber
      matchMakerAnswers {
        matchedOffers {
          ...AllVehicleOfferFields
        }
      }
      savedOffers {
        ...AllVehicleOfferFields
      }
    }
  }
}
    ${AllVehicleOfferFieldsFragmentDoc}`;

/**
 * __useTest_GetMatchesDataQuery__
 *
 * To run a query within a React component, call `useTest_GetMatchesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTest_GetMatchesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTest_GetMatchesDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useTest_GetMatchesDataQuery(baseOptions?: Apollo.QueryHookOptions<ITest_GetMatchesDataQuery, ITest_GetMatchesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ITest_GetMatchesDataQuery, ITest_GetMatchesDataQueryVariables>(Test_GetMatchesDataDocument, options);
      }
export function useTest_GetMatchesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ITest_GetMatchesDataQuery, ITest_GetMatchesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ITest_GetMatchesDataQuery, ITest_GetMatchesDataQueryVariables>(Test_GetMatchesDataDocument, options);
        }
export type Test_GetMatchesDataQueryHookResult = ReturnType<typeof useTest_GetMatchesDataQuery>;
export type Test_GetMatchesDataLazyQueryHookResult = ReturnType<typeof useTest_GetMatchesDataLazyQuery>;
export type Test_GetMatchesDataQueryResult = Apollo.QueryResult<ITest_GetMatchesDataQuery, ITest_GetMatchesDataQueryVariables>;
export const SaveOfferDocument = gql`
    mutation saveOffer($vin: ID!, $quoteId: ID!, $matchType: MatchType!, $contractId: String!) {
  saveOffer(
    vin: $vin
    quoteId: $quoteId
    matchType: $matchType
    contractId: $contractId
  ) {
    ...AllVehicleOfferFields
  }
}
    ${AllVehicleOfferFieldsFragmentDoc}`;
export type ISaveOfferMutationFn = Apollo.MutationFunction<ISaveOfferMutation, ISaveOfferMutationVariables>;

/**
 * __useSaveOfferMutation__
 *
 * To run a mutation, you first call `useSaveOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOfferMutation, { data, loading, error }] = useSaveOfferMutation({
 *   variables: {
 *      vin: // value for 'vin'
 *      quoteId: // value for 'quoteId'
 *      matchType: // value for 'matchType'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useSaveOfferMutation(baseOptions?: Apollo.MutationHookOptions<ISaveOfferMutation, ISaveOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISaveOfferMutation, ISaveOfferMutationVariables>(SaveOfferDocument, options);
      }
export type SaveOfferMutationHookResult = ReturnType<typeof useSaveOfferMutation>;
export type SaveOfferMutationResult = Apollo.MutationResult<ISaveOfferMutation>;
export type SaveOfferMutationOptions = Apollo.BaseMutationOptions<ISaveOfferMutation, ISaveOfferMutationVariables>;
export const DeleteOfferDocument = gql`
    mutation deleteOffer($quoteId: ID!, $contractId: String!) {
  deleteOffer(quoteId: $quoteId, contractId: $contractId) {
    ...AllVehicleOfferFields
  }
}
    ${AllVehicleOfferFieldsFragmentDoc}`;
export type IDeleteOfferMutationFn = Apollo.MutationFunction<IDeleteOfferMutation, IDeleteOfferMutationVariables>;

/**
 * __useDeleteOfferMutation__
 *
 * To run a mutation, you first call `useDeleteOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferMutation, { data, loading, error }] = useDeleteOfferMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useDeleteOfferMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteOfferMutation, IDeleteOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IDeleteOfferMutation, IDeleteOfferMutationVariables>(DeleteOfferDocument, options);
      }
export type DeleteOfferMutationHookResult = ReturnType<typeof useDeleteOfferMutation>;
export type DeleteOfferMutationResult = Apollo.MutationResult<IDeleteOfferMutation>;
export type DeleteOfferMutationOptions = Apollo.BaseMutationOptions<IDeleteOfferMutation, IDeleteOfferMutationVariables>;
export const GetSavedOffersDocument = gql`
    query getSavedOffers($contractNumber: String!) {
  customer {
    customerId
    isRegistered
    contract(contractId: $contractNumber) {
      ...AllContractFields
      savedOffers {
        ...AllVehicleOfferFields
      }
      submittedOffer {
        ...AllVehicleOfferFields
      }
      matchMakerAnswers {
        expectedMonthlyPayment
        financePlan
      }
    }
  }
}
    ${AllContractFieldsFragmentDoc}
${AllVehicleOfferFieldsFragmentDoc}`;

/**
 * __useGetSavedOffersQuery__
 *
 * To run a query within a React component, call `useGetSavedOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedOffersQuery({
 *   variables: {
 *      contractNumber: // value for 'contractNumber'
 *   },
 * });
 */
export function useGetSavedOffersQuery(baseOptions: Apollo.QueryHookOptions<IGetSavedOffersQuery, IGetSavedOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSavedOffersQuery, IGetSavedOffersQueryVariables>(GetSavedOffersDocument, options);
      }
export function useGetSavedOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSavedOffersQuery, IGetSavedOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSavedOffersQuery, IGetSavedOffersQueryVariables>(GetSavedOffersDocument, options);
        }
export type GetSavedOffersQueryHookResult = ReturnType<typeof useGetSavedOffersQuery>;
export type GetSavedOffersLazyQueryHookResult = ReturnType<typeof useGetSavedOffersLazyQuery>;
export type GetSavedOffersQueryResult = Apollo.QueryResult<IGetSavedOffersQuery, IGetSavedOffersQueryVariables>;
export const GetSelectedOfferDocument = gql`
    query getSelectedOffer($vin: ID!, $contractNumber: String!) {
  customer {
    homePhone
    mobilePhone
    email
    contract(contractId: $contractNumber) {
      matchMakerAnswers {
        ...AllMatchMakerAnswerFields
      }
      contractNumber
      retailer {
        street
        address
        website
        name
        phoneNumber
        email
        code
      }
      submittedOffer {
        ...AllVehicleOfferFields
      }
    }
  }
  financeOffer(vin: $vin, contractId: $contractNumber) {
    ...AllFinanceDetailsFields
  }
}
    ${AllMatchMakerAnswerFieldsFragmentDoc}
${AllVehicleOfferFieldsFragmentDoc}
${AllFinanceDetailsFieldsFragmentDoc}`;

/**
 * __useGetSelectedOfferQuery__
 *
 * To run a query within a React component, call `useGetSelectedOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedOfferQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      contractNumber: // value for 'contractNumber'
 *   },
 * });
 */
export function useGetSelectedOfferQuery(baseOptions: Apollo.QueryHookOptions<IGetSelectedOfferQuery, IGetSelectedOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSelectedOfferQuery, IGetSelectedOfferQueryVariables>(GetSelectedOfferDocument, options);
      }
export function useGetSelectedOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSelectedOfferQuery, IGetSelectedOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSelectedOfferQuery, IGetSelectedOfferQueryVariables>(GetSelectedOfferDocument, options);
        }
export type GetSelectedOfferQueryHookResult = ReturnType<typeof useGetSelectedOfferQuery>;
export type GetSelectedOfferLazyQueryHookResult = ReturnType<typeof useGetSelectedOfferLazyQuery>;
export type GetSelectedOfferQueryResult = Apollo.QueryResult<IGetSelectedOfferQuery, IGetSelectedOfferQueryVariables>;
export const GetContractsDocument = gql`
    query getContracts {
  customer {
    contracts {
      ...AllContractFields
    }
  }
}
    ${AllContractFieldsFragmentDoc}`;

/**
 * __useGetContractsQuery__
 *
 * To run a query within a React component, call `useGetContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractsQuery(baseOptions?: Apollo.QueryHookOptions<IGetContractsQuery, IGetContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetContractsQuery, IGetContractsQueryVariables>(GetContractsDocument, options);
      }
export function useGetContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetContractsQuery, IGetContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetContractsQuery, IGetContractsQueryVariables>(GetContractsDocument, options);
        }
export type GetContractsQueryHookResult = ReturnType<typeof useGetContractsQuery>;
export type GetContractsLazyQueryHookResult = ReturnType<typeof useGetContractsLazyQuery>;
export type GetContractsQueryResult = Apollo.QueryResult<IGetContractsQuery, IGetContractsQueryVariables>;
export const SubmitOfferDocument = gql`
    mutation submitOffer($vin: String!, $offerId: ID!, $dealerName: String!, $dealerAddress: String!, $dealerEmail: String!, $dealerCode: String!, $contractId: String!, $contactEmail: String!, $contactPhoneNumber: String!, $selectedContactMethod: ContactMethod!, $contactDate: ContactDate, $contactTime: ContactTime) {
  submitOffer(
    vin: $vin
    offerId: $offerId
    dealerName: $dealerName
    dealerAddress: $dealerAddress
    dealerEmail: $dealerEmail
    dealerCode: $dealerCode
    contractId: $contractId
    contactEmail: $contactEmail
    contactPhoneNumber: $contactPhoneNumber
    selectedContactMethod: $selectedContactMethod
    contactDate: $contactDate
    contactTime: $contactTime
  ) {
    ...AllFinanceDetailsFields
  }
}
    ${AllFinanceDetailsFieldsFragmentDoc}`;
export type ISubmitOfferMutationFn = Apollo.MutationFunction<ISubmitOfferMutation, ISubmitOfferMutationVariables>;

/**
 * __useSubmitOfferMutation__
 *
 * To run a mutation, you first call `useSubmitOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOfferMutation, { data, loading, error }] = useSubmitOfferMutation({
 *   variables: {
 *      vin: // value for 'vin'
 *      offerId: // value for 'offerId'
 *      dealerName: // value for 'dealerName'
 *      dealerAddress: // value for 'dealerAddress'
 *      dealerEmail: // value for 'dealerEmail'
 *      dealerCode: // value for 'dealerCode'
 *      contractId: // value for 'contractId'
 *      contactEmail: // value for 'contactEmail'
 *      contactPhoneNumber: // value for 'contactPhoneNumber'
 *      selectedContactMethod: // value for 'selectedContactMethod'
 *      contactDate: // value for 'contactDate'
 *      contactTime: // value for 'contactTime'
 *   },
 * });
 */
export function useSubmitOfferMutation(baseOptions?: Apollo.MutationHookOptions<ISubmitOfferMutation, ISubmitOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISubmitOfferMutation, ISubmitOfferMutationVariables>(SubmitOfferDocument, options);
      }
export type SubmitOfferMutationHookResult = ReturnType<typeof useSubmitOfferMutation>;
export type SubmitOfferMutationResult = Apollo.MutationResult<ISubmitOfferMutation>;
export type SubmitOfferMutationOptions = Apollo.BaseMutationOptions<ISubmitOfferMutation, ISubmitOfferMutationVariables>;
export const GetDealersDocument = gql`
    query getDealers($region: String!) {
  dealers(region: $region) {
    email
    address
    name
    code
  }
}
    `;

/**
 * __useGetDealersQuery__
 *
 * To run a query within a React component, call `useGetDealersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealersQuery({
 *   variables: {
 *      region: // value for 'region'
 *   },
 * });
 */
export function useGetDealersQuery(baseOptions: Apollo.QueryHookOptions<IGetDealersQuery, IGetDealersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetDealersQuery, IGetDealersQueryVariables>(GetDealersDocument, options);
      }
export function useGetDealersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDealersQuery, IGetDealersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetDealersQuery, IGetDealersQueryVariables>(GetDealersDocument, options);
        }
export type GetDealersQueryHookResult = ReturnType<typeof useGetDealersQuery>;
export type GetDealersLazyQueryHookResult = ReturnType<typeof useGetDealersLazyQuery>;
export type GetDealersQueryResult = Apollo.QueryResult<IGetDealersQuery, IGetDealersQueryVariables>;
export const SelectOfferDocument = gql`
    mutation selectOffer($vin: ID!, $contractId: String!, $quoteId: String!, $matchType: MatchType!) {
  selectOffer(
    vin: $vin
    contractId: $contractId
    quoteId: $quoteId
    matchType: $matchType
  ) {
    ...AllVehicleOfferFields
  }
}
    ${AllVehicleOfferFieldsFragmentDoc}`;
export type ISelectOfferMutationFn = Apollo.MutationFunction<ISelectOfferMutation, ISelectOfferMutationVariables>;

/**
 * __useSelectOfferMutation__
 *
 * To run a mutation, you first call `useSelectOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectOfferMutation, { data, loading, error }] = useSelectOfferMutation({
 *   variables: {
 *      vin: // value for 'vin'
 *      contractId: // value for 'contractId'
 *      quoteId: // value for 'quoteId'
 *      matchType: // value for 'matchType'
 *   },
 * });
 */
export function useSelectOfferMutation(baseOptions?: Apollo.MutationHookOptions<ISelectOfferMutation, ISelectOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISelectOfferMutation, ISelectOfferMutationVariables>(SelectOfferDocument, options);
      }
export type SelectOfferMutationHookResult = ReturnType<typeof useSelectOfferMutation>;
export type SelectOfferMutationResult = Apollo.MutationResult<ISelectOfferMutation>;
export type SelectOfferMutationOptions = Apollo.BaseMutationOptions<ISelectOfferMutation, ISelectOfferMutationVariables>;
export const GetSingleOfferDocument = gql`
    query getSingleOffer($vin: ID!, $contractId: String!) {
  customer {
    contract(contractId: $contractId) {
      contractNumber
      hasMonthlyPayment
      matchMakerAnswers {
        ...AllMatchMakerAnswerFields
        matchedOffers {
          ...AllVehicleOfferFields
        }
      }
      retailer {
        street
        address
        website
        name
        phoneNumber
        email
        code
      }
      equity
      submittedOffer {
        ...AllVehicleOfferFields
      }
    }
  }
  financeOffer(vin: $vin, contractId: $contractId) {
    ...AllFinanceDetailsFields
  }
}
    ${AllMatchMakerAnswerFieldsFragmentDoc}
${AllVehicleOfferFieldsFragmentDoc}
${AllFinanceDetailsFieldsFragmentDoc}`;

/**
 * __useGetSingleOfferQuery__
 *
 * To run a query within a React component, call `useGetSingleOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleOfferQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetSingleOfferQuery(baseOptions: Apollo.QueryHookOptions<IGetSingleOfferQuery, IGetSingleOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSingleOfferQuery, IGetSingleOfferQueryVariables>(GetSingleOfferDocument, options);
      }
export function useGetSingleOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSingleOfferQuery, IGetSingleOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSingleOfferQuery, IGetSingleOfferQueryVariables>(GetSingleOfferDocument, options);
        }
export type GetSingleOfferQueryHookResult = ReturnType<typeof useGetSingleOfferQuery>;
export type GetSingleOfferLazyQueryHookResult = ReturnType<typeof useGetSingleOfferLazyQuery>;
export type GetSingleOfferQueryResult = Apollo.QueryResult<IGetSingleOfferQuery, IGetSingleOfferQueryVariables>;
export const GetSingleVehicleDocument = gql`
    query getSingleVehicle($vin: ID!, $contractNumber: String!) {
  customer {
    contract(contractId: $contractNumber) {
      contractNumber
      retailer {
        street
        address
        website
        name
        phoneNumber
        email
      }
    }
  }
  detailOffer(vin: $vin) {
    vin
    urlOfCarImage
    carModel
    carModelCode
    carModelVariant
    drivingStyle
    numberOfDoors
    fuelType
    interiorColor
    exteriorColor
    images
    equipment {
      equipmentName
    }
  }
}
    `;

/**
 * __useGetSingleVehicleQuery__
 *
 * To run a query within a React component, call `useGetSingleVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleVehicleQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      contractNumber: // value for 'contractNumber'
 *   },
 * });
 */
export function useGetSingleVehicleQuery(baseOptions: Apollo.QueryHookOptions<IGetSingleVehicleQuery, IGetSingleVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSingleVehicleQuery, IGetSingleVehicleQueryVariables>(GetSingleVehicleDocument, options);
      }
export function useGetSingleVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSingleVehicleQuery, IGetSingleVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSingleVehicleQuery, IGetSingleVehicleQueryVariables>(GetSingleVehicleDocument, options);
        }
export type GetSingleVehicleQueryHookResult = ReturnType<typeof useGetSingleVehicleQuery>;
export type GetSingleVehicleLazyQueryHookResult = ReturnType<typeof useGetSingleVehicleLazyQuery>;
export type GetSingleVehicleQueryResult = Apollo.QueryResult<IGetSingleVehicleQuery, IGetSingleVehicleQueryVariables>;
export const GetMatchMakerAnswersDocument = gql`
    query getMatchMakerAnswers($contractId: String!) {
  customer {
    contract(contractId: $contractId) {
      ...AllContractFields
      matchMakerAnswers {
        ...AllMatchMakerAnswerFields
      }
    }
  }
}
    ${AllContractFieldsFragmentDoc}
${AllMatchMakerAnswerFieldsFragmentDoc}`;

/**
 * __useGetMatchMakerAnswersQuery__
 *
 * To run a query within a React component, call `useGetMatchMakerAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchMakerAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchMakerAnswersQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetMatchMakerAnswersQuery(baseOptions: Apollo.QueryHookOptions<IGetMatchMakerAnswersQuery, IGetMatchMakerAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMatchMakerAnswersQuery, IGetMatchMakerAnswersQueryVariables>(GetMatchMakerAnswersDocument, options);
      }
export function useGetMatchMakerAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMatchMakerAnswersQuery, IGetMatchMakerAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMatchMakerAnswersQuery, IGetMatchMakerAnswersQueryVariables>(GetMatchMakerAnswersDocument, options);
        }
export type GetMatchMakerAnswersQueryHookResult = ReturnType<typeof useGetMatchMakerAnswersQuery>;
export type GetMatchMakerAnswersLazyQueryHookResult = ReturnType<typeof useGetMatchMakerAnswersLazyQuery>;
export type GetMatchMakerAnswersQueryResult = Apollo.QueryResult<IGetMatchMakerAnswersQuery, IGetMatchMakerAnswersQueryVariables>;
export const CreateOrUpdateMmDocument = gql`
    mutation createOrUpdateMM($contractId: String!, $typeOfCar: [String], $carSearchType: CarSearchType, $modelOfCar: [String], $colorOfCar: [String], $typeOfTrain: [String], $parkingType: [ParkingType], $numberOfDoors: [Float], $typeOfGears: [TransmissionType], $fuelTypes: [String], $importantAspects: [String], $financePlan: FinancePlan, $expectedMonthlyPayment: Float, $expectedDuration: Float, $milesPerYear: Float, $isCompleted: Boolean, $additionalDeposit: Float, $bonusPayment: Float, $sortingOption: String) {
  createOrUpdateMatchMakerAnswers(
    contractId: $contractId
    carSearchType: $carSearchType
    typeOfCar: $typeOfCar
    modelOfCar: $modelOfCar
    colorOfCar: $colorOfCar
    typeOfTrain: $typeOfTrain
    parkingType: $parkingType
    numberOfDoors: $numberOfDoors
    typeOfGears: $typeOfGears
    fuelTypes: $fuelTypes
    importantAspects: $importantAspects
    financePlan: $financePlan
    expectedMonthlyPayment: $expectedMonthlyPayment
    expectedDuration: $expectedDuration
    milesPerYear: $milesPerYear
    additionalDeposit: $additionalDeposit
    bonusPayment: $bonusPayment
    isCompleted: $isCompleted
    sortingOption: $sortingOption
  ) {
    carSearchType
    typeOfCar
    modelOfCar
    colorOfCar
    typeOfTrain
    parkingType
    numberOfDoors
    typeOfGears
    fuelTypes
    importantAspects
    expectedMonthlyPayment
    expectedDuration
    financePlan
    milesPerYear
    additionalDeposit
    bonusPayment
    sortingOption
    isCompleted
  }
}
    `;
export type ICreateOrUpdateMmMutationFn = Apollo.MutationFunction<ICreateOrUpdateMmMutation, ICreateOrUpdateMmMutationVariables>;

/**
 * __useCreateOrUpdateMmMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateMmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateMmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateMmMutation, { data, loading, error }] = useCreateOrUpdateMmMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      typeOfCar: // value for 'typeOfCar'
 *      carSearchType: // value for 'carSearchType'
 *      modelOfCar: // value for 'modelOfCar'
 *      colorOfCar: // value for 'colorOfCar'
 *      typeOfTrain: // value for 'typeOfTrain'
 *      parkingType: // value for 'parkingType'
 *      numberOfDoors: // value for 'numberOfDoors'
 *      typeOfGears: // value for 'typeOfGears'
 *      fuelTypes: // value for 'fuelTypes'
 *      importantAspects: // value for 'importantAspects'
 *      financePlan: // value for 'financePlan'
 *      expectedMonthlyPayment: // value for 'expectedMonthlyPayment'
 *      expectedDuration: // value for 'expectedDuration'
 *      milesPerYear: // value for 'milesPerYear'
 *      isCompleted: // value for 'isCompleted'
 *      additionalDeposit: // value for 'additionalDeposit'
 *      bonusPayment: // value for 'bonusPayment'
 *      sortingOption: // value for 'sortingOption'
 *   },
 * });
 */
export function useCreateOrUpdateMmMutation(baseOptions?: Apollo.MutationHookOptions<ICreateOrUpdateMmMutation, ICreateOrUpdateMmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateOrUpdateMmMutation, ICreateOrUpdateMmMutationVariables>(CreateOrUpdateMmDocument, options);
      }
export type CreateOrUpdateMmMutationHookResult = ReturnType<typeof useCreateOrUpdateMmMutation>;
export type CreateOrUpdateMmMutationResult = Apollo.MutationResult<ICreateOrUpdateMmMutation>;
export type CreateOrUpdateMmMutationOptions = Apollo.BaseMutationOptions<ICreateOrUpdateMmMutation, ICreateOrUpdateMmMutationVariables>;
export const GetDataForRenewalOverviewDocument = gql`
    query getDataForRenewalOverview($contractId: String!) {
  customer {
    customerId
    isRegistered
    contract(contractId: $contractId) {
      ...AllContractFields
      savedOffers {
        ...AllVehicleOfferFields
      }
      matchMakerAnswers {
        ...AllMatchMakerAnswerFields
      }
    }
  }
}
    ${AllContractFieldsFragmentDoc}
${AllVehicleOfferFieldsFragmentDoc}
${AllMatchMakerAnswerFieldsFragmentDoc}`;

/**
 * __useGetDataForRenewalOverviewQuery__
 *
 * To run a query within a React component, call `useGetDataForRenewalOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForRenewalOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForRenewalOverviewQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetDataForRenewalOverviewQuery(baseOptions: Apollo.QueryHookOptions<IGetDataForRenewalOverviewQuery, IGetDataForRenewalOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetDataForRenewalOverviewQuery, IGetDataForRenewalOverviewQueryVariables>(GetDataForRenewalOverviewDocument, options);
      }
export function useGetDataForRenewalOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDataForRenewalOverviewQuery, IGetDataForRenewalOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetDataForRenewalOverviewQuery, IGetDataForRenewalOverviewQueryVariables>(GetDataForRenewalOverviewDocument, options);
        }
export type GetDataForRenewalOverviewQueryHookResult = ReturnType<typeof useGetDataForRenewalOverviewQuery>;
export type GetDataForRenewalOverviewLazyQueryHookResult = ReturnType<typeof useGetDataForRenewalOverviewLazyQuery>;
export type GetDataForRenewalOverviewQueryResult = Apollo.QueryResult<IGetDataForRenewalOverviewQuery, IGetDataForRenewalOverviewQueryVariables>;
export const GetVehicleOffersDocument = gql`
    query getVehicleOffers($contractId: String!, $sortingPreference: String!) {
  customer {
    customerId
    isRegistered
    contract(contractId: $contractId) {
      ...AllContractFields
      matchMakerAnswers {
        ...AllMatchMakerAnswerFields
        matchedOffers(sortingPreference: $sortingPreference) {
          ...AllVehicleOfferFields
        }
      }
    }
  }
}
    ${AllContractFieldsFragmentDoc}
${AllMatchMakerAnswerFieldsFragmentDoc}
${AllVehicleOfferFieldsFragmentDoc}`;

/**
 * __useGetVehicleOffersQuery__
 *
 * To run a query within a React component, call `useGetVehicleOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleOffersQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      sortingPreference: // value for 'sortingPreference'
 *   },
 * });
 */
export function useGetVehicleOffersQuery(baseOptions: Apollo.QueryHookOptions<IGetVehicleOffersQuery, IGetVehicleOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetVehicleOffersQuery, IGetVehicleOffersQueryVariables>(GetVehicleOffersDocument, options);
      }
export function useGetVehicleOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetVehicleOffersQuery, IGetVehicleOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetVehicleOffersQuery, IGetVehicleOffersQueryVariables>(GetVehicleOffersDocument, options);
        }
export type GetVehicleOffersQueryHookResult = ReturnType<typeof useGetVehicleOffersQuery>;
export type GetVehicleOffersLazyQueryHookResult = ReturnType<typeof useGetVehicleOffersLazyQuery>;
export type GetVehicleOffersQueryResult = Apollo.QueryResult<IGetVehicleOffersQuery, IGetVehicleOffersQueryVariables>;
export const RequestVerificationDocument = gql`
    mutation requestVerification($firstName: String, $surname: String, $birthdate: String, $mobilePhoneNumber: String) {
  requestVerification(
    firstName: $firstName
    surname: $surname
    birthdate: $birthdate
    mobilePhoneNumber: $mobilePhoneNumber
  )
}
    `;
export type IRequestVerificationMutationFn = Apollo.MutationFunction<IRequestVerificationMutation, IRequestVerificationMutationVariables>;

/**
 * __useRequestVerificationMutation__
 *
 * To run a mutation, you first call `useRequestVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestVerificationMutation, { data, loading, error }] = useRequestVerificationMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      surname: // value for 'surname'
 *      birthdate: // value for 'birthdate'
 *      mobilePhoneNumber: // value for 'mobilePhoneNumber'
 *   },
 * });
 */
export function useRequestVerificationMutation(baseOptions?: Apollo.MutationHookOptions<IRequestVerificationMutation, IRequestVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRequestVerificationMutation, IRequestVerificationMutationVariables>(RequestVerificationDocument, options);
      }
export type RequestVerificationMutationHookResult = ReturnType<typeof useRequestVerificationMutation>;
export type RequestVerificationMutationResult = Apollo.MutationResult<IRequestVerificationMutation>;
export type RequestVerificationMutationOptions = Apollo.BaseMutationOptions<IRequestVerificationMutation, IRequestVerificationMutationVariables>;
export const VerifyAccountDocument = gql`
    mutation verifyAccount($data: String, $smsCode: String) {
  verifyAccount(data: $data, smsCode: $smsCode)
}
    `;
export type IVerifyAccountMutationFn = Apollo.MutationFunction<IVerifyAccountMutation, IVerifyAccountMutationVariables>;

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *      smsCode: // value for 'smsCode'
 *   },
 * });
 */
export function useVerifyAccountMutation(baseOptions?: Apollo.MutationHookOptions<IVerifyAccountMutation, IVerifyAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IVerifyAccountMutation, IVerifyAccountMutationVariables>(VerifyAccountDocument, options);
      }
export type VerifyAccountMutationHookResult = ReturnType<typeof useVerifyAccountMutation>;
export type VerifyAccountMutationResult = Apollo.MutationResult<IVerifyAccountMutation>;
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<IVerifyAccountMutation, IVerifyAccountMutationVariables>;
export const ResendSmsCodeDocument = gql`
    mutation resendSMSCode($data: String) {
  resendSMSCode(data: $data)
}
    `;
export type IResendSmsCodeMutationFn = Apollo.MutationFunction<IResendSmsCodeMutation, IResendSmsCodeMutationVariables>;

/**
 * __useResendSmsCodeMutation__
 *
 * To run a mutation, you first call `useResendSmsCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSmsCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSmsCodeMutation, { data, loading, error }] = useResendSmsCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResendSmsCodeMutation(baseOptions?: Apollo.MutationHookOptions<IResendSmsCodeMutation, IResendSmsCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IResendSmsCodeMutation, IResendSmsCodeMutationVariables>(ResendSmsCodeDocument, options);
      }
export type ResendSmsCodeMutationHookResult = ReturnType<typeof useResendSmsCodeMutation>;
export type ResendSmsCodeMutationResult = Apollo.MutationResult<IResendSmsCodeMutation>;
export type ResendSmsCodeMutationOptions = Apollo.BaseMutationOptions<IResendSmsCodeMutation, IResendSmsCodeMutationVariables>;
export const GetWizardDataDocument = gql`
    query getWizardData($contractId: String!) {
  customer {
    contract(contractId: $contractId) {
      contractNumber
      matchMakerAnswers {
        isCompleted
      }
      savedOffers {
        ...AllVehicleOfferFields
      }
    }
  }
}
    ${AllVehicleOfferFieldsFragmentDoc}`;

/**
 * __useGetWizardDataQuery__
 *
 * To run a query within a React component, call `useGetWizardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWizardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWizardDataQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetWizardDataQuery(baseOptions: Apollo.QueryHookOptions<IGetWizardDataQuery, IGetWizardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetWizardDataQuery, IGetWizardDataQueryVariables>(GetWizardDataDocument, options);
      }
export function useGetWizardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetWizardDataQuery, IGetWizardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetWizardDataQuery, IGetWizardDataQueryVariables>(GetWizardDataDocument, options);
        }
export type GetWizardDataQueryHookResult = ReturnType<typeof useGetWizardDataQuery>;
export type GetWizardDataLazyQueryHookResult = ReturnType<typeof useGetWizardDataLazyQuery>;
export type GetWizardDataQueryResult = Apollo.QueryResult<IGetWizardDataQuery, IGetWizardDataQueryVariables>;
export const GetUserPassedKycDocument = gql`
    query getUserPassedKYC {
  passedKYC
}
    `;

/**
 * __useGetUserPassedKycQuery__
 *
 * To run a query within a React component, call `useGetUserPassedKycQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPassedKycQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPassedKycQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPassedKycQuery(baseOptions?: Apollo.QueryHookOptions<IGetUserPassedKycQuery, IGetUserPassedKycQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserPassedKycQuery, IGetUserPassedKycQueryVariables>(GetUserPassedKycDocument, options);
      }
export function useGetUserPassedKycLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserPassedKycQuery, IGetUserPassedKycQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserPassedKycQuery, IGetUserPassedKycQueryVariables>(GetUserPassedKycDocument, options);
        }
export type GetUserPassedKycQueryHookResult = ReturnType<typeof useGetUserPassedKycQuery>;
export type GetUserPassedKycLazyQueryHookResult = ReturnType<typeof useGetUserPassedKycLazyQuery>;
export type GetUserPassedKycQueryResult = Apollo.QueryResult<IGetUserPassedKycQuery, IGetUserPassedKycQueryVariables>;
export const GetCurrentStatusDocument = gql`
    query getCurrentStatus {
  customer {
    contracts {
      contractNumber
      matchMakerAnswers {
        isCompleted
      }
    }
    isRegistered
  }
}
    `;

/**
 * __useGetCurrentStatusQuery__
 *
 * To run a query within a React component, call `useGetCurrentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentStatusQuery(baseOptions?: Apollo.QueryHookOptions<IGetCurrentStatusQuery, IGetCurrentStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCurrentStatusQuery, IGetCurrentStatusQueryVariables>(GetCurrentStatusDocument, options);
      }
export function useGetCurrentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCurrentStatusQuery, IGetCurrentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCurrentStatusQuery, IGetCurrentStatusQueryVariables>(GetCurrentStatusDocument, options);
        }
export type GetCurrentStatusQueryHookResult = ReturnType<typeof useGetCurrentStatusQuery>;
export type GetCurrentStatusLazyQueryHookResult = ReturnType<typeof useGetCurrentStatusLazyQuery>;
export type GetCurrentStatusQueryResult = Apollo.QueryResult<IGetCurrentStatusQuery, IGetCurrentStatusQueryVariables>;