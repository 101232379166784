import { QuoteTableCardElement } from "@renewal/rosie";
import React from "react";
import { useTranslation } from "react-i18next";

import { IQuote } from "../../../../__generated__/graphql-types";
import { TableElements } from "./TableElements";

interface OwnProps {
  quote: IQuote;
  onOpenToolTip?: (keyWord: string) => void;
}

const QuoteTableCard: React.FC<OwnProps> = ({ quote, onOpenToolTip }) => {
  const { t } = useTranslation("finance-quote-page");
  const elements = TableElements(t, quote);

  return (
    <div className="c-card__body">
      <QuoteTableCardElement
        elements={elements.priceElements}
        category={t("quote-table-section-car-price")}
        onOpenToolTip={onOpenToolTip}
      />

      <QuoteTableCardElement
        elements={elements.termElements}
        category={t("quote-table-section-contract-terms")}
        onOpenToolTip={onOpenToolTip}
      />

      <QuoteTableCardElement
        elements={elements.startElements}
        category={t("quote-table-section-contract-start")}
        onOpenToolTip={onOpenToolTip}
      />

      <QuoteTableCardElement
        elements={elements.amountElements}
        category={t("quote-table-section-credit-terms")}
        onOpenToolTip={onOpenToolTip}
      />

      <QuoteTableCardElement
        elements={elements.contractElements}
        category={t("quote-table-section-contract-during")}
        onOpenToolTip={onOpenToolTip}
      />

      <QuoteTableCardElement
        elements={elements.endElements}
        category={t("quote-table-section-contract-end")}
        onOpenToolTip={onOpenToolTip}
      />
    </div>
  );
};

export default QuoteTableCard;
