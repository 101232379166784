"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.CardEditLink = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
require("./CardEditLink.scss");
var __1 = require("..");
var CardEditLink = function (_a) {
    var linkText = _a.linkText, linkTo = _a.linkTo, trackingFunction = _a.trackingFunction, onClick = _a.onClick;
    return (jsx_runtime_1.jsx("div", __assign({ className: "u-text-right dr-edit-btn" }, { children: jsx_runtime_1.jsx(__1.TrackableComponent, __assign({ trackingComponent: "link", trackingFunction: trackingFunction }, { children: jsx_runtime_1.jsx(__1.UILink, { to: linkTo, text: linkText, icon: "semantic-edit", onClick: onClick }, void 0) }), void 0) }), void 0));
};
exports.CardEditLink = CardEditLink;
