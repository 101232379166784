import React, { useState } from "react";

interface PhoneNumberInputProps {
  labelText: string;
  value: string;
  handleChange?: (newValue: string) => void;
  handleBlur?: React.ChangeEventHandler;
  error?: string;
  touched?: boolean;
  trackingComponent:
    | "button"
    | "link"
    | "inputbox"
    | "checkbox"
    | "radiobutton"
    | "tooltip";
  trackingFunction: string;
}

export function PhoneNumberInput(props: PhoneNumberInputProps): JSX.Element {
  const splittedInitValue = props.value.split("-");
  const [first, setFirst] = useState(splittedInitValue[0]);
  const [second, setSecond] = useState(splittedInitValue[1]);
  const [third, setThird] = useState(splittedInitValue[2]);

  return (
    <div className="c-form-field u-mb">
      <label className="c-form-field__label">{props.labelText}</label>
      <div className="c-form-field__box">
        <div className="c-input">
          <input
            className="c-input__input"
            type="text"
            maxLength={4}
            onChange={event => {
              setFirst(event.target.value);
              if (props.handleChange) {
                const combined = `${event.target.value}-${second}-${third}`;
                props.handleChange(combined);
              }
            }}
            onBlur={props.handleBlur}
            value={first}
          />
          <span className="u-mh-small" />-<span className="u-mh-small" />
          <input
            className="c-input__input"
            type="text"
            maxLength={4}
            onChange={event => {
              setSecond(event.target.value);
              if (props.handleChange) {
                const combined = `${first}-${event.target.value}-${third}`;
                props.handleChange(combined);
              }
            }}
            onBlur={props.handleBlur}
            value={second}
          />
          <span className="u-mh-small" />-<span className="u-mh-small" />
          <input
            className="c-input__input"
            type="text"
            maxLength={4}
            onChange={event => {
              setThird(event.target.value);
              if (props.handleChange) {
                const combined = `${first}-${second}-${event.target.value}`;
                props.handleChange(combined);
              }
            }}
            onBlur={props.handleBlur}
            value={third}
          />
        </div>
      </div>
    </div>
  );
}
