import { Button } from "@vwfs-its/bronson-react";
import React, { MouseEvent, useState } from "react";
import { useHistory } from "react-router";

import { useGetUserPassedKycQuery } from "../../__generated__/graphql-types";
import { loadingOrError } from "../../lib/ui/Loaders/GraphqlLoadingUtility";
import { RouterHelpers } from "../../router/router-helpers";
import { PrivacyModal } from "./components/PrivacyModal";
import { RegisterForm } from "./RegisterForm";

function Register(): JSX.Element {
  const history = useHistory();
  const { data, loading, error } = useGetUserPassedKycQuery();
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  if (loading || error) return loadingOrError(loading, error);

  if (data && data.passedKYC) {
    history.push(RouterHelpers.getErrorPage401Path());
    return <></>;
  }
  return (
    <>
      <section className="o-content-section">
        <div className="o-page-wrap o-page-wrap--xsmall u-text-center">
          <header className="c-section-heading c-section-heading--center">
            <h1 className="c-section-heading__title">
              <span className="c-section-heading__title-text">
                ご本人様確認
              </span>
            </h1>
          </header>
          <div className="u-text-center u-text-left@s">
            <p>
              本人確認のため、以下の情報についてお答えください。
              これらの情報は、本人確認目的のみに使用されます。
              さらなる個人情報の利用目的については、
              <Button
                link
                className="u-base-link"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  setIsPrivacyModalOpen(true);
                }}
              >
                <b>プライバシーポリシー</b>
              </Button>
              をご確認ください。
            </p>
          </div>
          <div className="o-layout o-layout--center u-mt-xsmall">
            <div className="o-layout__item u-3/4">
              <RegisterForm history={history} />
            </div>
          </div>
        </div>
      </section>
      <PrivacyModal
        isPrivacyModalOpen={isPrivacyModalOpen}
        onClose={() => {
          setIsPrivacyModalOpen(false);
        }}
      />
    </>
  );
}

export default Register;
