"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var asset_switches_1 = require("../../utils/asset-switches");
var FieldError = function (_a) {
    var errorMessage = _a.errorMessage;
    return errorMessage ? (jsx_runtime_1.jsx(bronson_react_1.ErrorMessage, __assign({ className: "u-mb-none " + asset_switches_1.bronsonIconSwitchEmpty("", "", "", "u-mt-small") }, { children: errorMessage }), void 0)) : null;
};
exports["default"] = FieldError;
