import { Header, HeaderLinkItem, HeaderStates } from "@renewal/rosie";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, matchPath, withRouter } from "react-router";

import { RouterHelpers } from "../../../router/router-helpers";

type AllProps = RouteComponentProps & WithTranslation;

class HeaderContainer extends React.PureComponent<AllProps> {
  // To avoid a constructor we are using arrow functions
  isLinkActive = (url: string): HeaderStates => {
    return matchPath(this.props.location.pathname, url)
      ? HeaderStates.Active
      : HeaderStates.Inactive;
  };

  render() {
    const { t } = this.props;

    const links: HeaderLinkItem[] = [
      {
        name: t("header.help"),
        link: RouterHelpers.getFAQPath(),
        status: this.isLinkActive(RouterHelpers.getFAQPath())
      }
    ];

    return (
      <Header links={links} logoLinkUrl={RouterHelpers.getLandingPagePath()} />
    );
  }
}

export default withRouter(withTranslation("scaffolding")(HeaderContainer));
