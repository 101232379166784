import { gql } from "@apollo/client";

import { fragments } from "../../lib/helpers/graphqlFragments";

export const GET_MATCH_MAKER_ANSWERS = gql`
  query getMatchMakerAnswers($contractId: String!) {
    customer {
      contract(contractId: $contractId) {
        ...AllContractFields
        matchMakerAnswers {
          ...AllMatchMakerAnswerFields
        }
      }
    }
  }
  ${fragments.allContractFields}
  ${fragments.allMatchMakerAnswerFields}
`;

export const PUT_MATCH_MAKER_ANSWERS = gql`
  mutation createOrUpdateMM(
    $contractId: String!
    $typeOfCar: [String]
    $carSearchType: CarSearchType
    $modelOfCar: [String]
    $colorOfCar: [String]
    $typeOfTrain: [String]
    $parkingType: [ParkingType]
    $numberOfDoors: [Float]
    $typeOfGears: [TransmissionType]
    $fuelTypes: [String]
    $importantAspects: [String]
    $financePlan: FinancePlan
    $expectedMonthlyPayment: Float
    $expectedDuration: Float
    $milesPerYear: Float
    $isCompleted: Boolean
    $additionalDeposit: Float
    $bonusPayment: Float
    $sortingOption: String
  ) {
    createOrUpdateMatchMakerAnswers(
      contractId: $contractId
      carSearchType: $carSearchType
      typeOfCar: $typeOfCar
      modelOfCar: $modelOfCar
      colorOfCar: $colorOfCar
      typeOfTrain: $typeOfTrain
      parkingType: $parkingType
      numberOfDoors: $numberOfDoors
      typeOfGears: $typeOfGears
      fuelTypes: $fuelTypes
      importantAspects: $importantAspects
      financePlan: $financePlan
      expectedMonthlyPayment: $expectedMonthlyPayment
      expectedDuration: $expectedDuration
      milesPerYear: $milesPerYear
      additionalDeposit: $additionalDeposit
      bonusPayment: $bonusPayment
      isCompleted: $isCompleted
      sortingOption: $sortingOption
    ) {
      carSearchType
      typeOfCar
      modelOfCar
      colorOfCar
      typeOfTrain
      parkingType
      numberOfDoors
      typeOfGears
      fuelTypes
      importantAspects
      expectedMonthlyPayment
      expectedDuration
      financePlan
      milesPerYear
      additionalDeposit
      bonusPayment
      sortingOption
      isCompleted
    }
  }
`;
