import { gql } from "@apollo/client";

import { fragments } from "../../lib/helpers/graphqlFragments";

const GET_OFFER = gql`
  query getSelectedOffer($vin: ID!, $contractNumber: String!) {
    customer {
      homePhone
      mobilePhone
      email
      contract(contractId: $contractNumber) {
        matchMakerAnswers {
          ...AllMatchMakerAnswerFields
        }
        contractNumber
        retailer {
          street
          address
          website
          name
          phoneNumber
          email
          code
        }
        submittedOffer {
          ...AllVehicleOfferFields
        }
      }
    }
    financeOffer(vin: $vin, contractId: $contractNumber) {
      ...AllFinanceDetailsFields
    }
  }
  ${fragments.allMatchMakerAnswerFields}
  ${fragments.allFinanceDetailsFields}
  ${fragments.allVehicleOfferFields}
`;

export { GET_OFFER };
