import { QuoteTableRows } from "@renewal/rosie";
import React from "react";
import { useTranslation } from "react-i18next";

import { IQuote } from "../../../../__generated__/graphql-types";
import { TableElements } from "./TableElements";

interface OwnProps {
  quote: IQuote;
  onOpenToolTip?: (keyWord: string) => void;
}

const QuoteTable: React.FC<OwnProps> = ({ quote, onOpenToolTip }) => {
  const { t } = useTranslation("finance-quote-page");
  return (
    <div className="c-table-wrapper c-table-wrapper--no-scroll u-ml-none u-pr-none">
      <table>
        <tbody>
          <QuoteTableRows
            category={t("quote-table-section-car-price")}
            elements={TableElements(t, quote).priceElements}
            onOpenToolTip={onOpenToolTip}
          />

          <QuoteTableRows
            category={t("quote-table-section-contract-terms")}
            elements={TableElements(t, quote).termElements}
            onOpenToolTip={onOpenToolTip}
          />

          <QuoteTableRows
            category={t("quote-table-section-contract-start")}
            elements={TableElements(t, quote).startElements}
            onOpenToolTip={onOpenToolTip}
          />

          <QuoteTableRows
            category={t("quote-table-section-credit-terms")}
            elements={TableElements(t, quote).amountElements}
            onOpenToolTip={onOpenToolTip}
          />

          <QuoteTableRows
            category={t("quote-table-section-contract-during")}
            elements={TableElements(t, quote).contractElements}
            onOpenToolTip={onOpenToolTip}
          />

          <QuoteTableRows
            category={t("quote-table-section-contract-end")}
            elements={TableElements(t, quote).endElements}
            onOpenToolTip={onOpenToolTip}
          />
        </tbody>
      </table>
    </div>
  );
};

export default QuoteTable;
