import {
  Flipper,
  TileFront,
  placeholderImg,
  placeholderNode
} from "@renewal/rosie";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  IAllVehicleOfferFieldsFragment,
  IFinancePlan
} from "../../../__generated__/graphql-types";
import { useCurrentContractId } from "../../../App.state";
import { RouterHelpers } from "../../../router/router-helpers";
import { useOfferOperations } from "../../helpers/saved-offers";
import { getFinancePlanCmsKey, mapToTypeOfMatch } from "../../helpers/utility";
import { LearnMoreAboutFinancePlanTrigger } from "../LearnMoreAboutFinancePlan/LearnMoreAboutFinancePlan";
import VehicleContractTileBackContainer from "./TileBack/VehicleContractTileBackContainer";

interface OwnProps {
  match: IAllVehicleOfferFieldsFragment;
  contractNumber: string;
  preferredInstallment: number;
  selectedFinancePlan: IFinancePlan;
  onFullScreenOpen?: () => void;
  onSaveOffer?: () => void;
  favoriteDisabled: boolean;
  setFavoriteDisabled: (newValue: boolean) => void;
}

const financePlanTranslationKey = (contractType: IFinancePlan): string => {
  return `shared:finance-plan.${getFinancePlanCmsKey(contractType)}`;
};

const Container: React.FunctionComponent<OwnProps> = ({
  match,
  preferredInstallment,
  selectedFinancePlan,
  onFullScreenOpen,
  onSaveOffer,
  favoriteDisabled,
  setFavoriteDisabled
}) => {
  const { t } = useTranslation("vehicle-tile-component");
  const { currentContractId } = useCurrentContractId();
  const { saveOffer, deleteOffer } = useOfferOperations(currentContractId);

  const dispatchSaveOffer = () => {
    setFavoriteDisabled(true);
    saveOffer(match, false)
      .then(success => {
        setFavoriteDisabled(false);
        if (success) toast.success(t("save-offer-toast-success"));
      })
      .catch(async () => {
        setFavoriteDisabled(false);
        deleteOffer(match, false, false);
        toast.error(t("save-offer-toast-error"));
      });
  };

  const dispatchDeleteOffer = () => {
    deleteOffer(match, false)
      .then(() => {
        toast.success(t("delete-offer-toast-success"));
      })
      .catch(async () => {
        saveOffer(match, false, false);
        toast.error(t("delete-offer-toast-error"));
      });
  };

  return (
    <Flipper>
      <TileFront
        t={t}
        contractType={t(financePlanTranslationKey(selectedFinancePlan))}
        carModel={match.vehicle.carModel}
        carModelVariant={match.vehicle.carModelVariant}
        installment={match.quote.installment}
        typeOfMatch={mapToTypeOfMatch(match.typeOfMatch)}
        images={match.vehicle.images}
        referenceInstallment={preferredInstallment}
        paymentDescriptionTwo={`${t("shared:term", {
          months: match.quote.term
        })} ${t("shared:apr", {
          interest: match.quote.annualPercentageRate
        })}`}
        isSaved={match.wasSaved}
        onSave={dispatchSaveOffer}
        onDelete={dispatchDeleteOffer}
        learnMore={
          <LearnMoreAboutFinancePlanTrigger
            linkText={t("shared:learn-more-button")}
            contractType={selectedFinancePlan}
          />
        }
        placeholder={placeholderNode("u-mt-xlarge u-mb")}
        galleryPlaceholder={placeholderImg()}
        quotePath={RouterHelpers.getQuotePath(match.vehicle.vin)}
        onFullScreenOpen={onFullScreenOpen}
        onSaveOffer={onSaveOffer}
        isSubmittedCar={match.wantsToBeContacted}
        hideFavoriteButton={match.wantsToBeContacted}
        isDisabled={favoriteDisabled}
      />

      <VehicleContractTileBackContainer vehicle={match.vehicle} />
    </Flipper>
  );
};

export { Container as VehicleContractTileContainer };
