"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RetailerInfo = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var index_1 = require("../index");
var RetailerInfo = function (_a) {
    var retailer = _a.retailer;
    return (jsx_runtime_1.jsx(bronson_react_1.Accordion, { children: jsx_runtime_1.jsx(bronson_react_1.Accordion.Item, __assign({ title: retailer.name }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "o-layout" }, { children: [jsx_runtime_1.jsxs("div", __assign({ className: "o-layout__item u-1/2 u-1/1@s" }, { children: [jsx_runtime_1.jsx("p", { children: retailer.street }, void 0), jsx_runtime_1.jsx("p", { children: retailer.address }, void 0)] }), void 0), jsx_runtime_1.jsxs("div", __assign({ className: "o-layout__item u-1/2 u-1/1@s" }, { children: [jsx_runtime_1.jsx("p", __assign({ className: "u-mb-small" }, { children: jsx_runtime_1.jsx(index_1.UILink, { to: "tel:" + retailer.phoneNumber, text: retailer.phoneNumber, icon: "semantic-phone", useRouteLink: false }, void 0) }), void 0), jsx_runtime_1.jsx("p", { children: jsx_runtime_1.jsx(index_1.UILink, { to: "mailto:" + retailer.email, text: retailer.email, icon: "semantic-mail", useRouteLink: false }, void 0) }, void 0)] }), void 0)] }), void 0) }), void 0) }, void 0));
};
exports.RetailerInfo = RetailerInfo;
