/*
 * Adding polyfills for IE11
 * https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
 *
 * Fixing an issue with react-app-polyfills. They have to be included as the first
 * import in your application. With the new jsxRuntime this is not possible because
 * it will always first do import { jsx as _jsx } from 'react/jsx-runtime'
 *
 * So we have to disable this feature for this will. This leads to
 * import React from "react"; being mandatory here!
 */

/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-toastify/dist/ReactToastify.css";
import "@renewal/rosie/lib/ui/index.scss";

import "./i18n";

// The React import here is needed. See comment above.
import React from "react";
import { render } from "react-dom";

import App from "./App";

import(
  `@renewal/rosie/lib/ui/scss/${process.env.REACT_APP_BRONSON_BRAND}.scss`
);

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", () => {
    render(<App />, document.getElementById("root"));
  });
}

render(<App />, document.getElementById("root"));
