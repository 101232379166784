import { QueryLazyOptions } from "@apollo/client";
import {
  CustomSelect,
  FieldError,
  MatchMakerSingleCardLayoutWithTitle
} from "@renewal/rosie";
import {
  Button,
  ButtonContainer,
  DefinitionListHorizontal,
  FormFieldLabel,
  Layout
} from "@vwfs-its/bronson-react";
import { ErrorMessage, Formik } from "formik";
import { TFunction } from "i18next";
import React from "react";

import {
  IGetDealersQuery,
  IMatchMakerAnswers
} from "../../../__generated__/graphql-types";
import { adobeDataLayer } from "../../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { prefecturals } from "../../../lib/helpers/prefecturalList";
import { DealerAppointmentState } from "../DealerAppointmentComponent";
import { ActionTypes } from "../reducerTypes";
import { dealerValidationSchema } from "./validationSchemas";

export const renderDealerSection = (
  state: DealerAppointmentState,
  dispatch: React.Dispatch<ActionTypes>,
  t: TFunction,
  dealers: IGetDealersQuery | undefined,
  getDealers: (
    options?: QueryLazyOptions<Record<string, unknown>> | undefined
  ) => void,
  matchMakerAnswers: IMatchMakerAnswers
): JSX.Element => {
  return state.dealerEditable ? (
    <Formik
      initialValues={{ region: "", name: "" }}
      validationSchema={dealerValidationSchema(t)}
      validateOnBlur={true}
      onSubmit={values => {
        if (!dealers || !dealers.dealers) return;

        const newDealer = dealers.dealers.find(
          value => value.name === values.name
        );
        if (!newDealer) return;

        dispatch({
          type: "UPDATE_DEALER",
          payload: {
            code: newDealer.code,
            email: newDealer.email,
            name: newDealer.name,
            address: newDealer.address
          }
        });
        dispatch({ type: "SET_DEALER_EDITABLE", payload: false });
      }}
    >
      {formikProps => (
        <form onSubmit={formikProps.handleSubmit}>
          <MatchMakerSingleCardLayoutWithTitle
            title={t(
              "contract-submission.dealer-section-edit.edit-dealer-section-title"
            )}
            editButton={
              <div className="u-text-right">
                <Button icon="semantic-close" iconReversed link type="submit">
                  {t("contract-submission.button-top-close")}
                </Button>
              </div>
            }
          >
            <Layout>
              <Layout.Item default="1/2" s="1/1" xs="1/1">
                <FormFieldLabel>
                  {t(
                    "contract-submission.dealer-section-edit.edit-region-title"
                  )}
                </FormFieldLabel>
                <CustomSelect
                  useLinkForTracking={true}
                  trackingName="RegionDropdown"
                  onValueChanged={(newValue: string) => {
                    getDealers({
                      variables: {
                        region: newValue,
                        brand: process.env.REACT_APP_BRAND
                      }
                    });
                    formikProps.setFieldValue("region", newValue);
                  }}
                  placeholder={t(
                    "contract-submission.dealer-section-edit.edit-region-dropdown"
                  )}
                  items={prefecturals.map(prefectural => {
                    return { text: prefectural, value: prefectural };
                  })}
                />
                <ErrorMessage
                  name="region"
                  render={msg => <FieldError errorMessage={msg} />}
                />
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1" xs="1/1">
                <FormFieldLabel>
                  {t(
                    "contract-submission.dealer-section-edit.edit-dealer-name-title"
                  )}
                </FormFieldLabel>
                <CustomSelect
                  useLinkForTracking={true}
                  trackingName="DealerDropdown"
                  placeholder={t(
                    "contract-submission.dealer-section-edit.edit-dealer-dropdown"
                  )}
                  onValueChanged={(newValue: string) => {
                    formikProps.setFieldValue("name", newValue);
                  }}
                  items={
                    dealers && dealers.dealers
                      ? dealers?.dealers?.map((dealer: { name: string }) => {
                          return { text: dealer.name, value: dealer.name };
                        })
                      : []
                  }
                  disabled={!dealers}
                />
                <ErrorMessage
                  name="name"
                  render={msg => <FieldError errorMessage={msg} />}
                />
              </Layout.Item>
            </Layout>
            <ButtonContainer className="u-mt" center>
              <Button
                secondary
                className="u-3/12 u-1/3@m u-1/1@s"
                inContainer
                onClick={() =>
                  dispatch({ type: "SET_DEALER_EDITABLE", payload: false })
                }
              >
                {t("contract-submission.edit-button-cancel")}
              </Button>

              <Button
                type="submit"
                className="u-3/12 u-1/3@m u-1/1@s"
                inContainer
              >
                {t("contract-submission.edit-button-confirm")}
              </Button>
            </ButtonContainer>
          </MatchMakerSingleCardLayoutWithTitle>
        </form>
      )}
    </Formik>
  ) : (
    <MatchMakerSingleCardLayoutWithTitle
      title={t(
        "contract-submission.dealer-section-view.view-dealer-section-title"
      )}
      editButton={
        <div className="u-text-right">
          <Button
            icon="semantic-edit"
            iconReversed
            link
            onClick={() => {
              adobeDataLayer.dealerSelectionEdit(matchMakerAnswers, "Dealer");
              return dispatch({ type: "SET_DEALER_EDITABLE", payload: true });
            }}
            className="u-text-right dr-edit-btn"
          >
            {t("contract-submission.button-top-change")}
          </Button>
        </div>
      }
    >
      <DefinitionListHorizontal>
        <DefinitionListHorizontal.Item
          title={t(
            "contract-submission.dealer-section-view.view-dealer-name-title"
          )}
        >
          {state.dealerData.name}
        </DefinitionListHorizontal.Item>
        <DefinitionListHorizontal.Item
          title={t(
            "contract-submission.dealer-section-view.view-dealer-address-title"
          )}
        >
          {state.dealerData.address}
        </DefinitionListHorizontal.Item>
      </DefinitionListHorizontal>
    </MatchMakerSingleCardLayoutWithTitle>
  );
};
