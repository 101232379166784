import { Modal } from "@vwfs-its/bronson-react";
import React from "react";
import { useTranslation } from "react-i18next";

interface OwnProps {
  isPrivacyModalOpen: boolean;
  onClose: () => void;
}

export function PrivacyModal(props: OwnProps): JSX.Element {
  const { t } = useTranslation("registration-page");

  return (
    <Modal
      asPortal
      shown={props.isPrivacyModalOpen}
      title={t("privacy-policy.title")}
      buttonConfirmText={t("privacy-policy.button-text")}
      onClose={props.onClose}
      onClickOutside={props.onClose}
      onConfirm={props.onClose}
    >
      <h5>第１条（個人情報の取扱い）</h5>
      <p>
        フォルクスワーゲン・ファイナンシャル・サービス・ジャパン株式会社（以下「当社」という。）は、登録情報、本サービスを通じて取得する情報（お客様の住所、氏名、契約情報等のカスタマーポータルに入力された個人情報を含むが、これに限らない。）及びこれらの情報をもとに分析した情報（以下「本件個人情報」といいます。）を、以下の規定、並びに個人情報保護法その他関連法令等に基づき、適切に取り扱うものとします。
      </p>

      <h5>第２条（利用目的）</h5>
      <ol>
        <li>
          当社は、本件個人情報を、以下の各号に掲げる目的のために利用します。
          <ol>
            <li>住所変更の届出その他のお客様の情報の更新の受付を行うため。</li>
            <li>
              完済証明書の発行その他の書類の発行の受付及び発送を円滑に行うため。
            </li>
            <li>
              お客様との取引及び交渉経過その他の事実に関わる記録を保存するため
            </li>
            <li>その他FSIDを通じて本サービスを提供するため。</li>
            <li>
              サービス改善、お客様ニーズの把握、満足度調査などを目的とした市場調査、統計資料作成及び分析のため。
            </li>
            <li>
              当社内部におけるファイナンス商品、サービス等の研究及び開発のため。
            </li>
            <li>
              当社または関係会社の商品、サービス、各種イベント、展示会、キャンペーン等の開催に関する情報を、郵便、電話、電子メールなどの方法によりお客様にご案内するため（ただし、お客様からご案内を不要とする意思表示があった場合を除きます）。
            </li>
            <li>
              Audi Ambassador
              Card会員規約およびAudiポイントプログラム規定等に基づく当社の特典、サービスの提供のため
            </li>
            <li>
              当社が各保険会社の委託を受けて行う保険募集業務及びこれらに付帯するサービス提供のため。
            </li>
            <li>
              当社が販売店の委託を受けて行うお客様の個人情報の保管及び管理業務、販売会社支援業務、市場分析及び調査業務、その他関連する業務を遂行するため。
            </li>
          </ol>
        </li>
        <li>
          当社は、お客様との間でファイナンス契約を締結する場合には、本件個人情報を与信判断及び与信後の管理その他のファイナンス契約締結時に通知する利用目的のために利用します。
        </li>
        <li>
          第1項第9号における当社に対し保険募集業務の委託を行う保険会社における個人情報の利用目的は、次の各保険会社のホームページをご参照ください。
          <ul>
            <li>東京海上日動火災保険株式会社</li>
            <li>損害保険ジャパン株式会社</li>
            <li>三井住友海上火災保険株式会社 </li>
            <li>アリアンツ火災海上保険株式会社</li>
            <li>チューリッヒ保険会社</li>
          </ul>
        </li>
      </ol>

      <h5>第３条（第三者提供）</h5>
      <ol>
        <li>
          当社は、当社グループ会社又は提携事業者に対して、本件個人情報を提供することがあり、お客様は、これに同意します。
        </li>
        <li>
          前項における当社グループ会社又は提携事業者の範囲は、個人情報保護方針（
          <a
            href="https://corp.vfj.co.jp/audif/privacy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://corp.vfj.co.jp/audif/privacy/
          </a>
          ）をご参照ください。
        </li>
        <li>
          第１項に基づく提供を受けた当社グループ会社又は提携事業者は、以下の利用目的で本件個人情報を利用します。
          <ol>
            <li>本サービス等の運営及び提供のため</li>
            <li>
              当社等の取り扱う商品・サービスに関するお問合せへの対応のため
            </li>
            <li>
              当社等の取り扱う商品・サービス又は各種イベント・キャンペーン等のご案内のため
            </li>
            <li>
              当社等の取り扱う商品・サービスの企画開発、品質向上、お客様満足度向上等のためのアンケート調査の実施のため
            </li>
            <li>
              本サービス等の利用状況等に関する統計情報の作成、提供又は販売のため
            </li>
            <li>本サービス等の不正利用の防止のため</li>
            <li>本サービス等の改良・改善、品質向上のため</li>
          </ol>
        </li>
        <li>
          当社は、法令に定める場合及び次条に基づく共同利用を除き、お客様の事前の同意を得ることなく、前各項に定める範囲を超えて、お客様の本件個人情報を第三者に提供しません。
        </li>
      </ol>

      <h5>第４条（共同利用）</h5>
      <ol>
        <li>
          お客様は、当社、アウディジャパン株式会社（以下「AJ」といいます。）、AUDI
          AG（ドイツ連邦共和国）、Automobili Lamborghini
          S.p.A.（イタリア共和国）、Porsche AG（ドイツ連邦共和国）、Volkswagen
          AG（ドイツ連邦共和国）、フォルクスワーゲン グループ
          ジャパン株式会社その他日本国内に所在のVolkswagen
          AGのグループ会社、AJの取り扱う製品にかかるファイナンスサービスを提供する金融会社、損害保険代理店委託を受けている損害保険会社及びその取扱代理店、並びに日本国内のアウディ正規販売店及びランボルギーニ正規販売店（以下、総称して「共同利用者」といいます。）が、お客様の本件個人情報を、次項に定める範囲内で共同利用を行うことについて同意するものとします。
        </li>
        <li>
          個人情報の項目ごとの共同利用者の範囲及び共同利用の目的は、以下のとおりです。以下の①の項目についての共同利用に関する責任者は、AJとします（共同利用の詳細についてはAJのウェブサイトの個人情報の取扱いに関するページ（
          <a
            href="https://www.audi.co.jp/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.audi.co.jp/
          </a>
          ）をご覧ください。）。また、以下の②③の項目についての共同利用に関する責任者は、当社とします（詳しくは当社のウェブサイトの個人情報の取扱いに関するページ（
          <a
            href="https://www.vfj.co.jp/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.vfj.co.jp/
          </a>
          ）をご覧ください。）。なお、アウディ正規販売店の情報につきましては、ウェブサイトに記載しております（
          <a
            href="https://www.audi.co.jp/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.audi.co.jp/
          </a>
          ）。
        </li>
      </ol>
      <table>
        <tbody>
          <tr>
            <th></th>
            <th>個人情報の項目</th>
            <th>共同利用者</th>
            <th>共同利用目的</th>
          </tr>
          <tr>
            <td>①</td>
            <td>
              氏名、性別、職業、生年月日、住所、メールアドレス、電話番号、お客様が購入された車両にかかる車検証記載の情報及びウェブサイト、アンケート、注文書、その他各種書面への記入等でご提供いただいた情報
            </td>
            <td>
              <ul>
                <li>当社</li>
                <li>AJ</li>
                <li>Audi AG （ドイツ）</li>
                <li>Automobili Lamborghini S.p.A. （イタリア）</li>
                <li>Porsche AG （ドイツ）</li>
                <li>Volkswagen AG （ドイツ）</li>
                <li>
                  フォルクスワーゲン グループ
                  ジャパン株式会社その他日本国内に所在のVolkswagen
                  AGのグループ会社
                </li>
                <li>
                  AJの取り扱う製品にかかるファイナンスサービスを提供する金融会社
                </li>
                <li>
                  損害保険代理店委託を受けている損害保険会社及びその取扱代理店
                </li>
                <li>
                  日本国内のアウディ正規販売店及びランボルギーニ正規販売店
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  定期点検、車検及び保険満期等に関する情報提供を行う目的で、印刷物の送付、電子メール、電話等の方法によりお客様にご案内をするため
                </li>
                <li>売買契約や役務提供契約を締結</li>
                <li>履行するため</li>
                <li>取り扱う商品</li>
                <li>
                  サービス等あるいは各種イベント、展示会、キャンペーン等に関する企画・立案を行うため、並びにこれらについて印刷物の送付、電子メール、電話等の方法によりお客様にご案内するため
                </li>
                <li>
                  商品開発、サービス改善、お客様ニーズの把握、社内統計資料（年齢構成
                </li>
                <li>
                  性別構成等）等の作成を行うため並びにこれらのために必要な情報を収集する目的で印刷物の送付、電子メール、電話等の方法による各種アンケート調査をお客様に実施するため
                </li>
                <li>与信判断及び与信管理</li>
                <li>
                  ご購入いただいた製品に関するアフターサービスの提供を行うため
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <th></th>
            <th>個人情報の項目</th>
            <th>共同利用者</th>
            <th>共同利用目的</th>
          </tr>
          <tr>
            <td>②</td>
            <td>
              <ul>
                <li>
                  当社との間のクレジット契約又はオートリース契約（以下「クレジット契約等」といいます。）に係る契約者（申込者を含む）及び連帯保証人（連帯保証人予定者を含む）（以下、個別に又は総称して「契約者等」といいます。）の以下の情報
                </li>
                <li>
                  氏名、性別、年齢、生年月日、住所、電話番号、携帯電話番号、勤務先（お勤め先内容）、家族構成、住居状況、取引を行う目的、メールアドレス等（本契約締結後に当社若しくは保証会社が契約者等から通知等を受け、又は当社若しくは保証会社が適法かつ公正に取得したことにより知り得た変更情報を含む）
                </li>
                <li>
                  申込日、契約日、商品名、契約額、分割払手数料又は据置手数料、支払回数、支払方法、振替口座、契約番号、クレジット契約等の内容その他の取引に関する情報（申込み等を行った場所に関する情報を含む）
                </li>
                <li>
                  支払開始後の利用残高、月々の返済状況（保証会社が当社に対して保証債務を全額履行した後を含む。）
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>当社</li>
                <li>AJ</li>
                <li>日本国内のアウディ正規販売店</li>
                <li>Volkswagen Financial Services AG （ドイツ）</li>
                <li>Volkswagen AG （ドイツ）</li>
                <li>Audi AG （ドイツ）</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>自動車</li>
                <li>
                  自動車備品等販売事業、オートローン事業、オートリース事業、カード事業、クレジット事業、金融事業、保険事業等における市場調査、商品開発、リスク分析
                </li>
                <li>管理、商品</li>
                <li>
                  サービス情報、アンケート調査、関連するサービスのお知らせ、クレジット契約等に関する事務処理などに利用するため
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <th></th>
            <th>個人情報の項目</th>
            <th>共同利用者</th>
            <th>共同利用目的</th>
          </tr>
          <tr>
            <td>③</td>
            <td>
              <p>
                当社が提供するプレミアムケアに係る加入者兼サービス依頼書記載の以下の情報
                <br></br>
                ・加入者名、取扱販売店、サービス提供期間、保険会社、証券番号、登録番号、車台番号、社名、型式、初度登録年月、利用サービス内容、事故日、申請日、事故場所、損傷部位、ドアミラー損害の詳細、届け先警察署及び受理番号、車両保険使用の有無、申請額
              </p>
            </td>
            <td>
              <ul>
                <li>当社</li>
                <li>AJ</li>
                <li>日本国内のアウディ正規販売店</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>自動車</li>
                <li>
                  自動車備品等販売事業、オートローン事業、オートリース事業、カード事業、クレジット事業、金融事業、保険事業等における市場調査、商品開発、リスク分析
                </li>
                <li>管理、商品</li>
                <li>
                  サービス情報、アンケート調査、関連するサービスのお知らせ、プレミアムケアに関する事務処理などに利用するため
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <h5>第５条（委託先の監督 ）</h5>
      <p>
        お客様は、当社が個人データの取扱いを委託する場合に、当社が保護措置を講じた上で、お客様の個人情報を当該委託先に提供することがあり、当該委託先が委託目的の範囲内でこれを利用することに同意します。
      </p>

      <h5>第６条（個人情報の開示・削除等の請求）</h5>
      <ol>
        <li>
          お客様は、当社及び第３条で記載する共同利用者（当社以外の者をいう。以下、本条において同様です。）に対して、「個人情報の保護に関する法律」の定めるところにより自己に関する個人情報を開示するよう請求することができます。ただし、その場合、本人である事を証明するため、当社及び共同利用者が要求する本人確認書類等を提示しなければなりません。
          <ol>
            <li>
              当社に開示を求める場合には、第６条記載の窓口にご連絡ください。開示請求の手続については、当社の所定の方法によるものとします。
            </li>
            <li>
              共同利用者に開示を求める場合には、共同利用者に連絡して下さい。
            </li>
          </ol>
        </li>
        <li>
          万一、個人情報の内容が事実でないことが判明した場合には、当社は、速やかに利用目的の達成に必要な範囲内で訂正又は削除に応じます。
        </li>
      </ol>

      <h5>第７条（個人情報の取扱いに関するお問合せ・ご相談窓口）</h5>
      <p>
        個人情報の利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止についてのお問合せ、その他のご意見の申出に関しては、下記までお願いします。
      </p>

      <h5>個人情報に関する問合わせ・相談窓口等</h5>
      <p>
        サービスセンター部（カスタマーリレーション）
        <br></br>
        住所：〒140-0001 東京都品川区北品川4-7-35 御殿山トラストタワー17階
        <br></br>
        電話番号：03-5792-7200
        <br></br>
        対応日時：土曜日、日曜日、祝日、年末年始を除く、10:00～17:00
        <br></br>
        <br></br>
        なお、当社は、個人情報の保護に関する法律に基づく認定個人情報保護団体である日本クレジット協会の会員となっております。同協会が設置する会員の個人情報の取扱いに関する窓口は、次のとおりです。
        <br></br>
        <br></br>
        一般社団法人日本クレジット協会
        <br></br>
        個人情報に関する相談受付電話番号：03-5645-3360
      </p>
    </Modal>
  );
}
