import { Notification } from "@vwfs-its/bronson-react";
import React from "react";
import { useTranslation } from "react-i18next";

interface OwnProps {
  errorMessage?: string;
}

const DefaultError: React.FunctionComponent<OwnProps> = ({ errorMessage }) => {
  const { t } = useTranslation();
  const message: string = errorMessage || t("shared:connection-error");

  return (
    <div className="o-page-wrap">
      <Notification status="error" shown notClosable>
        {message}
      </Notification>
    </div>
  );
};

export default DefaultError;
