import { FormFieldNumberInput } from "@renewal/rosie";
import React from "react";
import { useTranslation } from "react-i18next";

export const BonusPayment: React.FC = () => {
  const { t } = useTranslation("match-maker");

  return (
    <FormFieldNumberInput
      fieldName="bonusPayment"
      placeholder={t(
        "question-pages.items.bonus-payment.input-field.placeholder"
      )}
      addon={t("question-pages.items.bonus-payment.input-field.addon")}
      info={t("question-pages.items.bonus-payment.validation.optional")}
      useNumberFormatting
    />
  );
};
