import { FieldError } from "@renewal/rosie";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IMatchMakerAnswers } from "../../../__generated__/graphql-types";
import { colorList } from "../../helpers/vehicle-helpers";

export function ColorOfCar(): React.ReactElement {
  const { t } = useTranslation("match-maker");
  const { values, setFieldValue, setFieldTouched, touched, errors } =
    useFormikContext<IMatchMakerAnswers>();
  const allColorOptions = colorList(t);

  const [selectAll, setSelectAll] = useState<boolean>(
    values.colorOfCar
      ? values.colorOfCar?.length === allColorOptions.length
      : false
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const target = event.currentTarget;
    let valueArray = values.colorOfCar ? values.colorOfCar : [];

    const value = target.value;

    const copiedValueArray = [...valueArray];
    if (target.checked) {
      copiedValueArray.push(value);
    } else {
      copiedValueArray.splice(valueArray.indexOf(value), 1);
    }
    valueArray = copiedValueArray;

    setFieldValue("colorOfCar", valueArray);

    // set "Select All" to be checked if all options are selected
    // otherwise set it to be unchecked
    if (valueArray.length === allColorOptions.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSelectAll = (): void => {
    const valueArray: string[] = [];
    if (!selectAll) {
      setSelectAll(true);

      allColorOptions.forEach(c => {
        valueArray.push(c.value);
      });
    } else {
      setSelectAll(false);
    }

    setFieldValue("colorOfCar", valueArray);
  };

  const handleBlur = (): void => {
    setFieldTouched("colorOfCar", true);
  };

  return (
    <>
      <div className="o-component-wrapper">
        <label className="c-checkbox">
          <input
            className="c-checkbox__input"
            type="checkbox"
            id="checkboxSelectColorsAll"
            name="checkboxSelectColorsAll"
            checked={selectAll}
            onChange={handleSelectAll}
            onBlur={handleBlur}
          />
          <span className="c-checkbox__label">
            {t("question-pages.items.color.options.all")}
          </span>
        </label>
      </div>

      <div className="o-component-wrapper">
        <div className="o-inline-group">
          {allColorOptions.map((c, idx) => {
            return (
              <div className="o-inline-group__item" key={idx}>
                <label className="c-checkbox">
                  <input
                    className="c-checkbox__input"
                    type="checkbox"
                    id={`checkboxSelectColors${idx}`}
                    name={`checkboxSelectColors${idx}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={(values.colorOfCar || []).includes(
                      c.value as string
                    )}
                    value={c.value}
                  />
                  <span className="c-checkbox__label">{c.displayName}</span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="o-component-wrapper">
        {touched.colorOfCar && errors.colorOfCar && (
          <div className="c-error-message">
            <FieldError errorMessage={(errors.colorOfCar as string) || ""} />
          </div>
        )}
      </div>
    </>
  );
}
