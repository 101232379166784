import React from "react";

import { IFinancePlan } from "../../../__generated__/graphql-types";

export function FinancePlanVW(): React.ReactElement {
  return (
    <img
      src={window.PUBLIC_URL + "/images/Solutions.svg"}
      alt={String(IFinancePlan.SOLUTIONS)}
      width="60%"
    />
  );
}
