import { TFunction } from "i18next";
import * as Yup from "yup";

export const dealerValidationSchema = (
  t: TFunction
): Yup.SchemaOf<{
  region: string;
  name: string;
}> => {
  return Yup.object().shape({
    region: Yup.string().required(
      t("contract-submission.error-messages.err-new-dealer-area")
    ),
    name: Yup.string().required(
      t("contract-submission.error-messages.err-new-dealer-name")
    )
  });
};

export const contactDetailsValidationSchema = (
  t: TFunction
): Yup.SchemaOf<{
  email: string;
  phoneNumber: string;
}> => {
  return Yup.object().shape({
    email: Yup.string()
      .required(t("contract-submission.error-messages.err-email-address"))
      .test(
        "valid-mail",
        t("contract-submission.error-messages.err-email-address-not-valid"),
        (value: string) => {
          return /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
            value
          );
        }
      ),
    phoneNumber: Yup.string()
      .required(t("contract-submission.error-messages.err-phone-number"))
      .test(
        "valid-number",
        t("contract-submission.error-messages.err-phone-number-not-valid"),
        (value: string) => {
          return /(\d{3,4})-?(\d{1,4})-?(\d{1,4})/.test(value);
        }
      )
  });
};
