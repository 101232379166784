import {
  PageWithHeroImageTemplate,
  TeaserFocusPoints,
  heroImgSwitch,
  imgSwitch
} from "@renewal/rosie";
import { Button } from "@vwfs-its/bronson-react";
import React from "react";

import heroTeaserImg1280Audi from "./assets/audi/legal_1280.jpg";
import heroTeaserImg1920Audi from "./assets/audi/legal_1920.jpg";
import heroTeaserImg480Audi from "./assets/audi/legal_480.jpg";
import heroTeaserImg720Audi from "./assets/audi/legal_720.jpg";
import heroTeaserImg960Audi from "./assets/audi/legal_960.jpg";
import heroTeaserImg1280VW from "./assets/vw/1280x720.jpg";
import heroTeaserImg1920VW from "./assets/vw/1920x1080.jpg";
import heroTeaserImg480VW from "./assets/vw/480x270.jpg";
import heroTeaserImg720VW from "./assets/vw/720x405.jpg";
import heroTeaserImg960VW from "./assets/vw/960x540.jpg";

interface OwnProps {
  heroTeaserTitle: string;
  heroImageAlt: string;
  backBtnLabel?: string;
  prevCall?: () => void;
}

const LegalPageTemplate: React.FunctionComponent<OwnProps> = ({
  heroTeaserTitle,
  heroImageAlt,
  backBtnLabel,
  prevCall,
  children
}) => {
  return (
    <PageWithHeroImageTemplate
      heroTeaserTitle={heroTeaserTitle}
      heroImageAlt={heroImageAlt}
      heroImg={imgSwitch(heroTeaserImg1920Audi, heroTeaserImg1920VW)}
      heroImgSet={heroImgSwitch(
        [
          heroTeaserImg480Audi,
          heroTeaserImg720Audi,
          heroTeaserImg960Audi,
          heroTeaserImg1280Audi,
          heroTeaserImg1920Audi
        ],
        [
          heroTeaserImg480VW,
          heroTeaserImg720VW,
          heroTeaserImg960VW,
          heroTeaserImg1280VW,
          heroTeaserImg1920VW
        ]
      )}
      focusPoint={TeaserFocusPoints.BottomCenter}
      teaserInverted={true}
    >
      {children}
      {backBtnLabel && prevCall && (
        <div className="o-layout">
          <div className="o-layout__item u-text-center u-pt">
            <Button onClick={prevCall}>{backBtnLabel}</Button>
          </div>
        </div>
      )}
    </PageWithHeroImageTemplate>
  );
};

export default LegalPageTemplate;
