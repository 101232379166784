"use strict";
exports.__esModule = true;
exports.calculateArcPositions = void 0;
function calculateArcPositions(data) {
    var dataSum = data.reduce(function (acc, current) { return acc + current.reduce(function (acc1, current1) { return acc1 + current1; }); }, 0);
    var FIRST_LEVEL_SPACING = (dataSum / 360) * 2;
    var SECOND_LEVEL_SPACING = (dataSum / 360) * 0.7;
    var result = [];
    var dataSumIncludingSpacing = dataSum +
        data.reduce(function (acc, current) {
            return acc +
                FIRST_LEVEL_SPACING +
                current.reduce(function (acc1) { return acc1 + SECOND_LEVEL_SPACING; }, 0);
        }, 0);
    var acc = 0;
    var accSpacing = 0;
    for (var idx1 = 0; idx1 < data.length; idx1++) {
        var tmp = [];
        for (var idx2 = 0; idx2 < data[idx1].length; idx2++) {
            var angles = {
                start: ((acc + accSpacing) / dataSumIncludingSpacing) * 360,
                end: ((acc + accSpacing + data[idx1][idx2]) / dataSumIncludingSpacing) *
                    360
            };
            acc += data[idx1][idx2];
            accSpacing += SECOND_LEVEL_SPACING;
            tmp.push(angles);
        }
        accSpacing += FIRST_LEVEL_SPACING;
        result.push(tmp);
    }
    return result;
}
exports.calculateArcPositions = calculateArcPositions;
