"use strict";
exports.__esModule = true;
exports.determineImageUrl = exports.variantAndDerivative = void 0;
var variantAndDerivative = function (variant, derivative) {
    var displayString = variant;
    if (derivative) {
        displayString += ", " + derivative;
    }
    return displayString;
};
exports.variantAndDerivative = variantAndDerivative;
// If the imageUrl is in relative path format, the process.env.PUBLIC_URL has to be added
// in front of the path in real environments. since we will deploy it to a subdirectory
// (e.g. `/en` or `/nl`)
var determineImageUrl = function (imageUrl) {
    if (!imageUrl)
        return "";
    return imageUrl.startsWith("http") ||
        imageUrl.startsWith("//") ||
        imageUrl.startsWith("/vehicle-images")
        ? imageUrl
        : "" + process.env.PUBLIC_URL + imageUrl;
};
exports.determineImageUrl = determineImageUrl;
