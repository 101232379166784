import { BrowserDeprecationNotification, Main } from "@vwfs-its/bronson-react";
import { useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

import { useIsAuthenticatedState, useIsAuthorizedState } from "../App.state";
import ScrollToTopOnNavigation from "../router/ScrollToTopOnNavigation";
import Footer from "./components/Footer/Footer";
import HeaderPrivate from "./components/Header/HeaderContainerPrivate";
import HeaderContainerPrivateNotRegistered from "./components/Header/HeaderContainerPrivateNotRegistered";
import HeaderPublic from "./components/Header/HeaderContainerPublic";
import VerticalBar from "./components/Header/VerticalBarContainer";

const MasterPage: React.FunctionComponent = ({ children }) => {
  const [showDepricationWarning, setShowDepricationWarning] = useState(true);
  const { t } = useTranslation("browser-deprecation-notification");

  const [isAuthenticated] = useIsAuthenticatedState();
  const [isAuthorized] = useIsAuthorizedState();

  return (
    <>
      <ToastContainer
        position={"top-right"}
        pauseOnHover={true}
        hideProgressBar={true}
        autoClose={6000}
      />
      <ScrollToTopOnNavigation>
        {process.env.REACT_APP_BRONSON_BRAND === "vw" && <VerticalBar />}
        <div className="o-root">
          <BrowserDeprecationNotification
            shown={showDepricationWarning}
            onClose={() => setShowDepricationWarning(false)}
            title={t("title")}
            content={t("info-text")}
          />
          {isAuthenticated ? (
            isAuthorized ? (
              <HeaderPrivate />
            ) : (
              <HeaderContainerPrivateNotRegistered />
            )
          ) : (
            <HeaderPublic />
          )}
          <Main>{children}</Main>
          <Footer />
        </div>
      </ScrollToTopOnNavigation>
    </>
  );
};

export default MasterPage;
