import { LoadingSpinner } from "@renewal/rosie";
import React from "react";

const DefaultLoading: React.FunctionComponent = () => (
  <LoadingSpinner isLoading={true} isCenter={true} isFullPage={true}>
    <div />
  </LoadingSpinner>
);

export default DefaultLoading;
