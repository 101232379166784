import {
  CustomSelect,
  FieldError,
  MatchMakerSingleCardLayoutWithTitle
} from "@renewal/rosie";
import {
  FormFieldLabel,
  Layout,
  RadioButton,
  RadioButtonGroup
} from "@vwfs-its/bronson-react";
import { TFunction } from "i18next";
import React from "react";

import {
  IContactDate,
  IContactMethod,
  IContactTime
} from "../../../__generated__/graphql-types";
import { DealerAppointmentState } from "../DealerAppointmentComponent";
import { ActionTypes } from "../reducerTypes";

export const renderContactMethodSection = (
  state: DealerAppointmentState,
  dispatch: React.Dispatch<ActionTypes>,
  t: TFunction
): JSX.Element => {
  return (
    <MatchMakerSingleCardLayoutWithTitle
      title={t("contract-submission.contact-and-time-section.contact-title")}
    >
      <RadioButtonGroup>
        <RadioButton
          name="contact-method"
          value={IContactMethod.PHONE}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={() => {
            dispatch({
              type: "UPDATE_CONTACT_METHOD",
              payload: {
                ...state.contactMethod,
                selectedMethod: IContactMethod.PHONE
              }
            });
            dispatch({ type: "SET_METHOD_ERROR", payload: false });
          }}
        >
          {t(
            "contract-submission.contact-and-time-section.contact-radio-button-phone"
          )}
        </RadioButton>
        <RadioButton
          name="contact-method"
          value={IContactMethod.EMAIL}
          onChange={() => {
            dispatch({
              type: "UPDATE_CONTACT_METHOD",
              payload: {
                ...state.contactMethod,
                selectedMethod: IContactMethod.EMAIL
              }
            });
            dispatch({ type: "SET_METHOD_ERROR", payload: false });
          }}
        >
          {t(
            "contract-submission.contact-and-time-section.contact-radio-button-email"
          )}
        </RadioButton>
      </RadioButtonGroup>
      {state.contactMethodError && (
        <FieldError
          errorMessage={t(
            "contract-submission.error-messages.err-method-selection"
          )}
        />
      )}
      {state.contactMethod.selectedMethod === IContactMethod.PHONE && (
        <>
          <br />
          <Layout>
            <Layout.Item default="1/2" s="1/1" xs="1/1">
              <FormFieldLabel>
                {t(
                  "contract-submission.contact-and-time-section.contact-date-time-title"
                )}
              </FormFieldLabel>
              <CustomSelect
                useLinkForTracking={true}
                trackingName="ContactDateSelector"
                initialValue={IContactDate.WEEKDAYS}
                icon="semantic-calendar"
                onValueChanged={(newValue: IContactDate) =>
                  dispatch({
                    type: "UPDATE_CONTACT_METHOD",
                    payload: { ...state.contactMethod, date: newValue }
                  })
                }
                items={[
                  {
                    text: t(
                      "contract-submission.contact-and-time-section.contact-date-time-dropdown.weekday"
                    ),
                    value: IContactDate.WEEKDAYS
                  },
                  {
                    text: t(
                      "contract-submission.contact-and-time-section.contact-date-time-dropdown.weekend"
                    ),
                    value: IContactDate.HOLIDAYS
                  }
                ]}
              />
            </Layout.Item>
            <Layout.Item default="1/2" s="1/1" xs="1/1">
              <FormFieldLabel>
                {t(
                  "contract-submission.contact-and-time-section.contact-time-title"
                )}
              </FormFieldLabel>
              <CustomSelect
                useLinkForTracking={true}
                trackingName="ContactTimeOfDaySelector"
                initialValue={IContactTime.DAYTIME}
                icon="semantic-clock"
                onValueChanged={(newValue: IContactTime) =>
                  dispatch({
                    type: "UPDATE_CONTACT_METHOD",
                    payload: { ...state.contactMethod, time: newValue }
                  })
                }
                items={[
                  {
                    text: t(
                      "contract-submission.contact-and-time-section.contact-time-dropdown.morning"
                    ),
                    value: IContactTime.MORNING
                  },
                  {
                    text: t(
                      "contract-submission.contact-and-time-section.contact-time-dropdown.daytime"
                    ),
                    value: IContactTime.DAYTIME
                  },
                  {
                    text: t(
                      "contract-submission.contact-and-time-section.contact-time-dropdown.evening"
                    ),
                    value: IContactTime.EVENING
                  }
                ]}
              />
            </Layout.Item>
          </Layout>
        </>
      )}
    </MatchMakerSingleCardLayoutWithTitle>
  );
};
