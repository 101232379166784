import { Button } from "@vwfs-its/bronson-react";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  IContract,
  IFinancePlan,
  IVehicleOffer
} from "../../__generated__/graphql-types";
import RetailerInfoContainer from "../../lib/components/RetailerInfo/RetailerInfoContainer";
import { VehicleContractTileContainerSavedOffer } from "../../lib/components/VehicleContractTile/VehicleContractTileContainerSavedOffer";

interface OwnProps {
  savedOffers: IVehicleOffer[];
  prevCall: () => void;
  currentContractNumber: string;
  contract: Omit<IContract, "matchMakerAnswers">;
  preferredInstallment: number;
  selectedFinancePlan: IFinancePlan;
}

const SavedOffersComponent: React.FC<OwnProps> = ({
  savedOffers,
  prevCall,
  currentContractNumber,
  contract,
  preferredInstallment,
  selectedFinancePlan
}) => {
  const { t } = useTranslation("saved-offers-page");

  return (
    <>
      <section className="o-content-section">
        <div className="o-page-wrap">
          <header className="c-section-heading">
            <h2 className="c-section-heading__title">{t("title")}</h2>
          </header>
          <div className="o-layout">
            <div className="o-layout__item  u-1/1 u-mb">
              <RetailerInfoContainer contractData={contract} />
              {/* To make the retailer info have a border at the bottom it
               * may not be the only item in a container */}
              <div />
            </div>
          </div>
          <div className="o-layout o-layout--equal-height">
            {savedOffers.map((offer, i) => {
              if (offer && offer.vehicle)
                return (
                  <div
                    className="o-layout__item u-3/12 u-4/12@l u-6/12@m u-12/12@s"
                    key={i}
                  >
                    <VehicleContractTileContainerSavedOffer
                      match={offer}
                      contractNumber={currentContractNumber}
                      wasRemoved={offer.wasRemoved}
                      isActive={
                        !(offer.hasExpired && !offer.wantsToBeContacted)
                      }
                      isSubmittedCar={offer.wantsToBeContacted}
                      preferredInstallment={preferredInstallment}
                      selectedFinancePlan={selectedFinancePlan}
                    />
                  </div>
                );

              return null;
            })}
          </div>
          <div className="o-layout">
            <div className="o-layout__item u-text-center u-pt">
              <Button onClick={prevCall}>{t("shared:back-button")}</Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SavedOffersComponent;
