"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.GPL2 = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var GPL2 = function () { return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx("p", { children: "This program is free software: you can redistribute it and/or modify it under the terms of the GNU General Public License as published by the Free Software Foundation, either version 2 of the License, or (at your option) any later version." }, void 0), jsx_runtime_1.jsxs("p", { children: ["This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the", " ", jsx_runtime_1.jsx("a", __assign({ href: "https://www.gnu.org/licenses/old-licenses/gpl-2.0.html", rel: "noopener noreferrer", target: "_blank" }, { children: "GNU General Public License" }), void 0), " ", "for more details."] }, void 0)] }, void 0)); };
exports.GPL2 = GPL2;
