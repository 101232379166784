import { FieldValidators, Validator } from "@renewal/rosie";
import { FormikErrors } from "formik";

import { IMatchMakerAnswers } from "../../__generated__/graphql-types";

export const genericValidator = (
  fieldValidators: FieldValidators,
  values: IMatchMakerAnswers
): FormikErrors<IMatchMakerAnswers> => {
  const errors: FormikErrors<IMatchMakerAnswers> = {};

  Object.keys(fieldValidators).forEach(fieldName => {
    const validators = fieldValidators[fieldName] || [];
    //@ts-ignore - type of values has no index signature
    const value = values[fieldName];

    validators.every((validatorFun: Validator) => {
      const validationResult = validatorFun(value);
      if (validationResult !== null) {
        //@ts-ignore
        errors[fieldName] = validationResult;
        return false;
      }
      return true;
    });
  });

  return errors;
};
