import { makeVar, useReactiveVar } from "@apollo/client";

export interface AppState {
  isLoggedIn: boolean;
}

export const isAuthenticatedState = makeVar<boolean>(false);

export const isAuthorizedState = makeVar<boolean>(false);

export function useIsAuthenticatedState(): [
  boolean,
  (newState: boolean) => void
] {
  return [
    useReactiveVar(isAuthenticatedState),
    (newState: boolean) => {
      isAuthenticatedState(newState);
    }
  ];
}

export function useIsAuthorizedState(): [boolean, (newState: boolean) => void] {
  return [
    useReactiveVar(isAuthorizedState),
    (newState: boolean) => {
      isAuthorizedState(newState);
    }
  ];
}

const currentContractId = makeVar<string>("");
const LOCAL_STORAGE_KEY_CONTRACT_ID = "contractNumber";
export function useCurrentContractId(): {
  currentContractId: string;
  setCurrentContractId: (newState: string) => void;
} {
  // set currentContractId to the value saved in local-storage if it exists
  if (currentContractId() === "") {
    const contractIdFromLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_KEY_CONTRACT_ID
    );
    if (!!contractIdFromLocalStorage)
      currentContractId(contractIdFromLocalStorage);
  }

  return {
    currentContractId: useReactiveVar(currentContractId),
    setCurrentContractId: (newState: string) => {
      localStorage.setItem(LOCAL_STORAGE_KEY_CONTRACT_ID, newState);
      currentContractId(newState);
    }
  };
}
