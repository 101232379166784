import { Icons, ItemTeaserSmall, RenewalCard } from "@renewal/rosie";
import React from "react";
import { useTranslation } from "react-i18next";

import { RouterHelpers } from "../../../router/router-helpers";

interface OwnProps {
  pushToHistory: (addParentPath: boolean, url: string) => void;
}

const MatchMakerPreferenceCard: React.FunctionComponent<OwnProps> = ({
  pushToHistory
}) => {
  const { t } = useTranslation("renewal-overview-page");
  const goToMMStartPage = pushToHistory.bind(
    this,
    false,
    RouterHelpers.getMatchMakerPath()
  );

  return (
    <RenewalCard title={t("match-maker-title")}>
      <div className="c-card__body">
        <p>
          <b>{t("match-maker-stepper-title")}</b>
        </p>
        <ItemTeaserSmall
          title={t("match-maker-stepper-1-title")}
          text={t("match-maker-stepper-1-text")}
          iconType={Icons.CurrentCarValue}
          dangerouslySetHTML
        />
        <ItemTeaserSmall
          title={t("match-maker-stepper-2-title")}
          text={t("match-maker-stepper-2-text")}
          iconType={Icons.PerfectCar}
          dangerouslySetHTML
        />
        <ItemTeaserSmall
          title={t("match-maker-stepper-3-title")}
          text={t("match-maker-stepper-3-text")}
          iconType={Icons.BestMatch}
          dangerouslySetHTML
        />
        <div className="u-text-center">
          <button className="c-btn" onClick={goToMMStartPage}>
            <span className="c-btn__text">{t("match-maker-start-button")}</span>
          </button>
        </div>
      </div>
    </RenewalCard>
  );
};

export default MatchMakerPreferenceCard;
