import { ErrorPageTemplate } from "@renewal/rosie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { RouterHelpers } from "../../router/router-helpers";

const ErrorPage403: React.FC = () => {
  const { t } = useTranslation("error-pages");

  useEffect(() => {
    adobeDataLayer.errorPage({
      errorCode: "403",
      errorMessage: t("403-description")
    });
  }, [t]);

  return (
    <>
      <ErrorPageTemplate
        errorCode="403"
        errorMsg={t("403-title")}
        errorExplanation={t("403-description")}
        redirectTo={RouterHelpers.getLandingPagePath()}
        btnText={t("shared:back-button")}
      />
    </>
  );
};

export default ErrorPage403;
