import { ErrorPageTemplate } from "@renewal/rosie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { RouterHelpers } from "../../router/router-helpers";

const ErrorPage404: React.FC<{ location: string }> = ({ location }) => {
  const { t } = useTranslation("error-pages");

  useEffect(() => {
    adobeDataLayer.errorPage({
      errorCode: "404",
      errorMessage: t("404-description"),
      errorCausingURL: location
    });
  }, [location, t]);

  return (
    <>
      <ErrorPageTemplate
        errorCode="404"
        errorMsg={t("404-title")}
        errorExplanation={t("404-description")}
        redirectTo={RouterHelpers.getLandingPagePath()}
        btnText={t("shared:back-button")}
      />
    </>
  );
};

export default ErrorPage404;
