import { BackToTop } from "@vwfs-its/bronson-react";
import React from "react";
import { useTranslation } from "react-i18next";

const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation("scaffolding");
  return (
    <>
      <BackToTop>{t("footer.back-to-top")}</BackToTop>

      <footer className="c-footer">
        <div className="c-footer__module c-footer__module--meta-nav">
          <div className="o-page-wrap">
            <nav className="c-footer-meta-bar">
              <ul className="c-footer-meta-nav">
                <li className="c-footer-meta-nav__item">
                  <a
                    className="c-footer-meta-nav__link"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={t("footer.link-privacy-policy-to")}
                  >
                    {t("footer.link-privacy-policy")}
                  </a>
                </li>
                <li className="c-footer-meta-nav__item">
                  <a
                    className="c-footer-meta-nav__link"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={t("footer.link-cookie-policy-to")}
                  >
                    {t("footer.link-cookie-policy")}
                  </a>
                </li>
                <li className="c-footer-meta-nav__item">
                  <a
                    className="c-footer-meta-nav__link"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={t("footer.link-anti-social-to")}
                  >
                    {t("footer.link-anti-social")}
                  </a>
                </li>
                <li className="c-footer-meta-nav__item">
                  <a
                    className="c-footer-meta-nav__link"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={t("footer.link-whistleblower-system-to")}
                  >
                    {t("footer.link-whistleblower-system")}
                  </a>
                </li>
              </ul>
              <span className="c-footer-copyright">
                <span className="c-footer-copyright__text">
                  {t("footer.copyright")}
                </span>
              </span>
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
