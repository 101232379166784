import { LoadingSpinner } from "@renewal/rosie";
import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router";

import {
  IGetCurrentStatusQuery,
  useGetCurrentStatusLazyQuery,
  useGetUserPassedKycQuery
} from "../__generated__/graphql-types";
import { useCurrentContractId } from "../App.state";
import { adobeDataLayer } from "../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { ERROR_NO_DATA } from "../lib/helpers/constants";
import { loadingOrError } from "../lib/ui/Loaders/GraphqlLoadingUtility";
import { RouterHelpers } from "./router-helpers";

function EntryPoint(): JSX.Element {
  const {
    loading: kycPassLoading,
    error: kycPassError,
    data: kycPassData
  } = useGetUserPassedKycQuery({ fetchPolicy: "no-cache" });
  const [getStatus, { loading, error, data, called }] =
    useGetCurrentStatusLazyQuery();
  const { prev } = useParams<{ prev: string }>();
  const { currentContractId, setCurrentContractId } = useCurrentContractId();

  useEffect(() => {
    if (prev && prev !== "selection") {
      adobeDataLayer.entryPoint();
    }
  }, [prev]);

  useEffect(() => {
    if (kycPassData && kycPassData.passedKYC) {
      getStatus();
    }
  }, [getStatus, kycPassData]);

  const getRedirectPath = (data: IGetCurrentStatusQuery | undefined) => {
    if (kycPassLoading || kycPassError)
      return loadingOrError(kycPassLoading, kycPassError);

    if (kycPassData && !kycPassData.passedKYC) {
      return <Redirect to={RouterHelpers.getRegisterPagePath()} />;
    }

    if (!called || loading || error)
      return loadingOrError(loading || !called, error);

    if (!data) throw ERROR_NO_DATA;

    if (data.customer.contracts.length > 1 && prev === "identity") {
      return <Redirect to={RouterHelpers.getContractSelectionPath()} />;
    } else {
      if (data.customer.contracts.length <= 0)
        return <Redirect to={RouterHelpers.getErrorPageNotEligiblePath()} />;

      let contract;
      if (data.customer.contracts.length === 1) {
        contract = data.customer.contracts[0];
        setCurrentContractId(contract.contractNumber);
      } else {
        contract = data.customer.contracts.find(
          c => c.contractNumber === currentContractId
        );
      }

      if (!contract) {
        return <Redirect to={RouterHelpers.getEntryPointPath("identity")} />;
      }

      if (
        contract.matchMakerAnswers &&
        contract.matchMakerAnswers.isCompleted
      ) {
        return <Redirect to={RouterHelpers.getRenewalOverviewPath()} />;
      } else {
        return <Redirect to={RouterHelpers.getMatchMakerPath()} />;
      }
    }
  };

  return (
    <LoadingSpinner
      isLoading={loading || kycPassLoading}
      isFullPage={true}
      isCenter={true}
    >
      {getRedirectPath(data)}
    </LoadingSpinner>
  );
}

export default EntryPoint;
