import { gql } from "@apollo/client";

import { fragments } from "./graphqlFragments";

export const SAVE_OFFER = gql`
  mutation saveOffer(
    $vin: ID!
    $quoteId: ID!
    $matchType: MatchType!
    $contractId: String!
  ) {
    saveOffer(
      vin: $vin
      quoteId: $quoteId
      matchType: $matchType
      contractId: $contractId
    ) {
      ...AllVehicleOfferFields
    }
  }
  ${fragments.allVehicleOfferFields}
`;

export const DELETE_OFFER = gql`
  mutation deleteOffer($quoteId: ID!, $contractId: String!) {
    deleteOffer(quoteId: $quoteId, contractId: $contractId) {
      ...AllVehicleOfferFields
    }
  }
  ${fragments.allVehicleOfferFields}
`;

export const GET_SAVED_OFFERS = gql`
  query getSavedOffers($contractNumber: String!) {
    customer {
      customerId
      isRegistered
      contract(contractId: $contractNumber) {
        ...AllContractFields
        savedOffers {
          ...AllVehicleOfferFields
        }
        submittedOffer {
          ...AllVehicleOfferFields
        }
        matchMakerAnswers {
          expectedMonthlyPayment
          financePlan
        }
      }
    }
  }
  ${fragments.allContractFields}
  ${fragments.allVehicleOfferFields}
`;
