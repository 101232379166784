"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.ExchangeStepper = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ExchangeStepper = function (_a) {
    var stepperElements = _a.stepperElements;
    return (jsx_runtime_1.jsx("ol", __assign({ className: "c-instruction-stepper c-instruction-stepper--vertical" }, { children: stepperElements.map(function (e, i) {
            return (e.displayIf && (jsx_runtime_1.jsxs("li", __assign({ className: "c-instruction-stepper__step" }, { children: [jsx_runtime_1.jsx("figure", __assign({ className: "c-instruction-stepper__item" }, { children: jsx_runtime_1.jsx("i", { className: "c-icon  c-icon--[" + e.icon + "]" }, void 0) }), void 0), jsx_runtime_1.jsxs("div", __assign({ className: "c-instruction-stepper__detail" }, { children: [jsx_runtime_1.jsx("span", __assign({ className: "c-instruction-stepper__title" }, { children: e.title }), void 0), jsx_runtime_1.jsx("span", { className: "c-instruction-stepper__description", dangerouslySetInnerHTML: {
                                    __html: e.text
                                } }, void 0)] }), void 0)] }), i)));
        }) }), void 0));
};
exports.ExchangeStepper = ExchangeStepper;
