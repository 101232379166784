import React from "react";
import { Route, HashRouter as Router, Switch } from "react-router-dom";

import CarOfferContainer from "../modules/CarOffer/CarOfferContainer";
import DealerAppointmentContainer from "../modules/DealerAppointment/DealerAppointmentContainer";
import FinanceQuoteContainer from "../modules/FinanceQuote/FinanceQuoteContainer";
import FullDetails from "../modules/FullDetails/FullDetailsContainer";
import MatchMakerContainer from "../modules/MatchMaker/MatchMakerContainer";
import RenewalOverview from "../modules/RenewalOverview";
import SavedOffers from "../modules/SavedOffers";
import VehicleSelectionContainer from "../modules/VehicleSelection/VehicleSelectionContainer";
import { RouterHelpers } from "../router/router-helpers";
import WizardContainer from "./components/Wizard/WizardContainer";
import ErrorPage404 from "./ErrorPages/ErrorPage404";

function PublicOutline(): JSX.Element {
  return (
    <Router>
      <div className="o-main">
        <WizardContainer />
        <Switch>
          <Route
            path={RouterHelpers.getRenewalOverviewPath()}
            component={RenewalOverview}
          />
          <Route
            path={RouterHelpers.getMatchMakerPath()}
            component={MatchMakerContainer}
          />
          <Route
            exact
            path={RouterHelpers.getVehicleSelectionPath()}
            component={VehicleSelectionContainer}
          />
          <Route
            path={RouterHelpers.getQuotePath()}
            component={FinanceQuoteContainer}
          />
          <Route
            exact
            path={RouterHelpers.getVehicleOfferPath()}
            component={CarOfferContainer}
          />
          <Route
            exact
            path={RouterHelpers.getDealerAppointmentPath()}
            component={DealerAppointmentContainer}
          />
          <Route
            path={RouterHelpers.getSavedOffersPath()}
            component={SavedOffers}
          />
          <Route
            path={RouterHelpers.getFullDetailsPath()}
            component={FullDetails}
          />
          <Route
            render={() => <ErrorPage404 location={window.location.href} />}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default PublicOutline;
