import { MatchType } from "@renewal/rosie";

import { IFinancePlan, IMatchType } from "../../__generated__/graphql-types";

export const mapToTypeOfMatch = (matchType: IMatchType): MatchType => {
  switch (matchType) {
    case IMatchType.BEST:
      return MatchType.Best;
    case IMatchType.GREAT:
      return MatchType.Great;
    case IMatchType.GOOD:
      return MatchType.Good;
    case IMatchType.FAIR:
      return MatchType.Fair;
    default:
      return MatchType.NoMatch;
  }
};

export function convertStringToDateJP(dateString: string): Date {
  const dateParts = dateString.substr(0, 10).split("-");
  return new Date(
    +dateParts[0],
    // Doesn't get recognized as a number
    // @ts-ignore
    dateParts[1] - 1,
    +dateParts[2]
  );
}

const mapping: Record<IFinancePlan, string> = {
  FUTURE_DRIVE: "audi-future-drive",
  SLOAN: "s-loan",
  OPEN_END_LEASE: "open-end-lease",
  CLOSE_END_LEASE: "close-end-lease",
  SOLUTIONS: "solutions",
  OTHERS: "others"
};
export function getFinancePlanCmsKey(financePlan: IFinancePlan): string {
  return mapping[financePlan];
}
