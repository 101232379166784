import { Modal } from "@vwfs-its/bronson-react";
import React from "react";
import { useTranslation } from "react-i18next";

interface OwnProps {
  isTermsAndConditionsOpen: boolean;
  onClose: () => void;
}

export function TaCModal(props: OwnProps): JSX.Element {
  const { t } = useTranslation("registration-page");
  return (
    <Modal
      asPortal
      shown={props.isTermsAndConditionsOpen}
      title={t("terms-and-conditions.title")}
      buttonConfirmText={t("terms-and-conditions.button-text")}
      onClose={props.onClose}
      onClickOutside={props.onClose}
      onConfirm={props.onClose}
    >
      <p>
        Volkswagen Financial Services
        JapanのID利用規約（以下「本規約」といいます。）は、フォルクスワーゲン・ファイナンシャル・サービス･ジャパン株式会社（以下「当社」といいます。）がお客様それぞれに対して発行するVFJ
        ID（以下、単に「VFJ
        ID」といいます。）及び当社等（第2条にて定義します。）がVFJIDを通じて提供する当社指定のサービス（以下「本サービス」といいます。なお、本サービスの対象は、「【別記】本サービスの対象」をご確認ください。）の利用条件を定めるものです。
      </p>

      <h5>第1条（本規約の適用）</h5>
      <ol>
        <li>
          本規約は、VFJID及び本サービス等をご利用になるお客様と当社等との関係一切に適用されます。
        </li>
        <li>
          当社等は、個別の本サービス等について、固有の利用条件等を定めた規約（以下「関連規約」といいます。）を別途設けることがあります。利用者は、本サービス等をご利用になる場合には、本規約に加えて、関連規約の定めも遵守するものとします。なお、本規約の定めと関連規約の定めが矛盾する場合には、関連規約の定めが優先して適用されるものとします。
        </li>
        <li>
          本サービス等は、別段の定めのある場合を除き、日本国内在住者であるお客様のみを対象として提供します。
        </li>
      </ol>

      <h5>第2条（定義）</h5>
      <p>
        本規約において使用する用語は、次の各号に定める意味を有するものとします。
      </p>
      <ol>
        <li>
          個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができるものを含みます。）をいいます。
        </li>
        <li>「当社等」とは、当社及び提携事業者を総称していいます。</li>
        <li>
          「当社グループ会社」とは、当社の子会社、関連会社及びその他の関係会社をいいます。
        </li>
        <li>
          「登録ユーザー」とは、本規約第4条に基づきVFJIDの利用登録を完了し、VFJIDの発行を受けた者をいいます。
        </li>
        <li>「販売店」とは、当社と提携する車両販売会社をいいます。</li>
        <li>「本規約等」とは、本規約及び関連規約を総称していいます。</li>
        <li>
          「本サービス等」とは、VFJID及びそれに関連するサービス（VFJIDの発行・連携サービス等）並びに本サービスを総称していいます。
        </li>
        <li>
          「提携サービス」とは、提携事業者が登録ユーザーに対して提供するサービスをいいます。
        </li>
      </ol>

      <h5>第3条（VFJIDの発行及び連携）</h5>
      <ol>
        <li>
          当社は、登録ユーザーに対して、本規約に定める条件の下で、本サービス等に共通してログインすることのできるVFJIDを発行します。
        </li>
        <li>
          登録ユーザーは、自己の責任で提携サービスを利用するものとし、当社は、提携事業者の提携サービスの内容及び提供等に関して、一切の責任を負わないものとします。
        </li>
        <li>
          当社は、登録ユーザーの承諾を得ることなく、本サービスの対象（「【別記】本サービスの対象」）の全部若しくは一部を追加、変更又は削減することができるものとします。
        </li>
      </ol>

      <h5>第4条（登録）</h5>
      <ol>
        <li>
          VFJIDを利用するためには、次項の定めに従い、登録ユーザーとなることが必要です。なお、本サービス等を利用するためには、これとは別に本サービス固有の利用登録が必要となる場合があります。
        </li>
        <li>
          登録ユーザーになろうとする者は、当社所定の方法に従い、氏名・携帯電話番号・生年月日・メールアドレスその他当社が指定する情報（以下「登録情報」といいます。）を入力してVFJIDの利用登録を申し込み、VFJIDの発行を受けるものとします。なお、SMSによる2段階認証を利用登録の条件とします。また、VFJID発行にあたっては、弊社顧客管理システムにデータが登録されている必要があります。
        </li>
        <li>
          登録ユーザー（登録ユーザーになろうとする者を含みます。以下、本条において同様です。）は、申込みにあたり、真実かつ正確な情報を入力するものとし、また、登録後も登録情報が最新の内容となるよう情報を適宜変更するものとします。
        </li>
        <li>
          登録ユーザーは、第2項に定める利用登録の申込みを完了した時点で、本規約の内容に同意したものとみなされます。
        </li>
        <li>
          登録ユーザーは、次の各号のいずれかに該当する場合には、当該登録ユーザーによる利用登録の申込みを完了できないことがあります。
          <ol>
            <li>
              登録ユーザーが実在しない場合又は既にVFJIDの発行を受けている場合
            </li>
            <li>仮名又は他人の名義を使用又は盗用して申込みをした場合</li>
            <li>申込内容に虚偽、不正確、誤記又は記入漏れ等がある場合</li>
            <li>
              登録ユーザーが、第13条に規定する暴力団員等若しくは同条第1項各号のいずれかに該当し、若しくは同条第2項各号のいずれかに該当する行為をし、又は同条第1項若しくは第2項の規定に基づく表明又は確約に関して虚偽の申告をしたことが判明した場合
            </li>
            <li>本規約等の内容に同意いただけない場合</li>
            <li>本規約等の定めに違反している場合</li>
            <li>その他当社が登録ユーザーとして不適当と判断した場合</li>
          </ol>
        </li>
      </ol>

      <h5>第5条（登録情報の変更）</h5>
      <ol>
        <li>
          登録ユーザーは、登録情報に変更があった場合には、当社所定の方法に従い、速やかに変更後の情報を当社に通知するものとします。
        </li>
        <li>
          登録ユーザーが前項の通知を怠ったことにより不利益を被った場合であっても、当社は、これについて一切の責任を負わないものとします。
        </li>
      </ol>

      <h5>第6条（ID等の管理責任）</h5>
      <ol>
        <li>
          登録ユーザーは、自己の責任において、VFJID、パスワード及び本サービス等固有のアカウント（以下「ID等」と総称します。）を使用及び管理するものとし、これらの使用上の過誤又は第三者の不正な使用等による損害等について、当社は一切の責任を負わないものとします。
        </li>
        <li>
          登録ユーザーは、自己のID等を第三者に公表、譲渡、売買若しくは貸与等し、又はその他の方法で第三者に使用させてはならないものとします。
        </li>
        <li>
          登録ユーザーのID等が使用された場合は、当社は、登録ユーザー本人による利用とみなします。ただし、登録ユーザーに故意又は過失がないと判断される場合には、この限りではないものとします。
        </li>
      </ol>

      <h5>第7条（著作権等）</h5>
      <ol>
        <li>
          本サービス等を通じて提供されるテキスト、画像、写真、音声、動画及び情報等（以下「テキスト等」といいます。）に関する著作権、商標権及び意匠権等の知的財産権は、当社その他正当な権限者に帰属しています。登録ユーザーは、本サービス等を通じて取得した著作物等を、当社が明示的に許諾した範囲及び著作権法で認められた私的使用の範囲を超えて使用することはできないものとします。
        </li>
        <li>
          当社に寄せられた本サービス等に係る提案、感想その他の意見（以下「提案等」といいます。）に関する著作権（著作権法第27条及び第28条の権利を含みます。）その他一切の権利は、当社に帰属するものとします。当社に提案等を行う者は、当該提案等について著作者人格権を行使しないものとします。
        </li>
      </ol>

      <h5>第8条（禁止事項）</h5>
      <p>
        登録ユーザーは、本サービス等を利用するにあたり、次の行為をしてはならないものとします。
      </p>
      <ol>
        <li>他者のID等を不正に取得又は使用する行為</li>
        <li>
          正当な理由なく複数のVFJIDの発行を受け、又は複数のVFJIDを使用する行為
        </li>
        <li>
          本サービス等で提供されるテキスト等を、複製、改変、公衆送信（送信可能化を含みます。）、頒布、譲渡、貸与、翻訳又は翻案等する行為、その他当社又は正当な権限者の知的財産権を侵害する行為
        </li>
        <li>当社又は第三者の財産・権利・プライバシーを侵害する行為</li>
        <li>当社又は第三者の名誉・信用を毀損する行為</li>
        <li>有害なコンピュータープログラム等を送信し、又は書き込む行為</li>
        <li>
          国内外のネットワークを経由して通信を行う場合には、経由する国内外のネットワークの規則に反する行為
        </li>
        <li>本サービス等の運営を妨げ、又はそのおそれのある行為</li>
        <li>本サービス等を不正又は営利の目的で使用する行為</li>
        <li>法令又は公序良俗に違反する行為</li>
        <li>本規約等の定めに違反する行為</li>
        <li>その他当社が不適切と判断する行為</li>
      </ol>

      <h5>第9条（権利譲渡の禁止）</h5>
      <p>
        登録ユーザーは、本サービス等を利用する権利又は契約上の地位を第三者に譲渡し、又は担保に供することはできません。
      </p>

      <h5>第10条（連絡方法）</h5>
      <p>
        本サービス等に関するお問合せ・ご相談その他登録ユーザーの当社に対する連絡又は通知、及び当社から登録ユーザーに対する連絡又は通知は、それぞれ当社所定の方法で行うものとします。
      </p>

      <h5>第11条（利用解除）</h5>
      <p>
        登録ユーザーは、当社所定の方法に従い、当社に利用解除を自身でWebサイトにて行うものとし、いつでも本サービス等の利用を解除することができます。
      </p>

      <h5>第12条 （登録抹消）</h5>
      <p>
        当社は、登録ユーザーが次の各号のいずれかに該当すると判断した場合には、登録ユーザーに事前に通知することなく、当該登録ユーザーによる本サービス等の利用を一時的に停止し、又は登録を抹消することができるものとします。
      </p>
      <ol>
        <li>登録ユーザーが実在しない場合</li>
        <li>仮名又は他人の名義を使用又は盗用して登録をした場合</li>
        <li>登録内容に虚偽、不正確、誤記又は記入漏れ等がある場合</li>
        <li>登録情報を登録ユーザーの要請により利用解除した場合</li>
        <li>反社会的勢力に該当する場合、又は反社会的勢力と関係を有する場合</li>
        <li>本規約の内容に同意いただけない場合</li>
        <li>本規約等の定めに違反した場合</li>
        <li>その他当社が登録ユーザーとして不適当と判断した場合</li>
      </ol>

      <h5>第13条（反社会的勢力の排除）</h5>
      <ol>
        <li>
          登録ユーザーは、自身が、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団、テロリスト、経済制裁対象者等、その他これらに準ずる者（以下、これらを「暴力団員等」という。）に該当しないこと、及び次のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。
          <ol>
            <li>暴力団員等が経営を支配していると認められる関係を有すること</li>
            <li>
              暴力団員等が経営に実質的に関与していると認められる関係を有すること
            </li>
            <li>
              自己、自社もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
            </li>
            <li>
              暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること
            </li>
            <li>
              役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
            </li>
          </ol>
        </li>
        <li>
          登録ユーザーは、自ら又は第三者を利用して次の事項に該当する行為を行わないことを確約します。
          <ol>
            <li>暴力的な要求行為</li>
            <li>法的な責任を超えた要求行為</li>
            <li>本規約に関して、脅迫的な言動をし、又は暴力を用いる行為</li>
            <li>
              風説を流布し、偽計を用い又は威力を用いて当社の信用を毀損し、又は当社の業務を妨害する行為。
            </li>
            <li>その他前各号に準ずる行為。</li>
          </ol>
        </li>
        <li>
          登録ユーザーが、暴力団員等若しくは第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、又は前2項の規定に基づく表明又は確約に関して虚偽の申告をしたことが判明し、当社が本サービス等の提供を継続することが不適切であると判断した場合には、当社は、登録ユーザーの登録を抹消することができるものとします。
        </li>
      </ol>

      <h5>第14条（利用解除等の効果）</h5>
      <ol>
        <li>
          登録ユーザーは、本サービス等を利用解除し、又は登録を抹消された場合には、当社等が別段の定めをする場合を除き、本サービス等の全てが利用できなくなります。ただし、利用解除又は登録抹消の時期によっては、メール配信の停止等が遅れることがあります。
        </li>
        <li>
          登録ユーザーは、本サービス等を利用解除し、又は登録を抹消された場合には、当社等が別段の定めをする場合を除き、本サービス等に関して当社等に対して負う債務について、直ちに履行していただきます。
        </li>
      </ol>

      <h5>第15条（本サービス等の中断）</h5>
      <p>
        当社は、次の各号のいずれかに該当する事由が生じた場合には、登録ユーザーに事前に通知することなく、本サービス等の提供を一時的に中断することがあります。
      </p>
      <ol>
        <li>
          本サービス等に関連するシステム保守を、定期的に又は緊急に行う場合
        </li>
        <li>火災、停電等により本サービスの提供ができなくなった場合</li>
        <li>
          地震、噴火、洪水、津波等の天災により本サービス等の提供ができなくなった場合
        </li>
        <li>
          戦争、暴動、騒乱、労働争議等により本サービス等の提供ができなくなった場合
        </li>
        <li>
          通信サービスが停止された場合、又は端末の使用環境その他の事情により通信障害が生じた場合
        </li>
        <li>
          その他運用上又は技術上、当社が本サービス等の提供の一時的な中断を必要と判断した場合
        </li>
      </ol>

      <h5>第16条（本サービス等の変更又は廃止）</h5>
      <ol>
        <li>
          当社等は、登録ユーザーの事前の承諾を得ることなく、本サービス等の内容の全部若しくは一部の追加、変更又は廃止等（以下「廃止等」といいます。）をすることができるものとします。
        </li>
        <li>
          当社等は、前項の廃止等により登録ユーザーに損害が生じた場合でも、当該損害について一切の責任を負わないものとします。
        </li>
      </ol>

      <h5>第17条（本規約の変更）</h5>
      <ol>
        <li>
          当社は、本規約の変更内容及びその効力発生時期を定め、当ウェブサイトへの掲載その他当社が適切と認める方法により本規約の変更後の内容及び効力発生時期を登録ユーザーに周知することにより、本規約の内容を変更することができるものとします。
        </li>
        <li>
          当社が当ウェブサイトへの掲載をもって本規約の変更を周知する場合には、周知した効力発生時期が到来した時をもって変更の効力が生じるものとします。変更内容が登録ユーザーに重大な影響を与えると当社が判断する場合には、当社は、合理的な予告期間をもって変更を周知するものとします。
        </li>
      </ol>

      <h5>第18条（損害賠償）</h5>
      <p>
        登録ユーザーが、本規約等に違反する行為、又は不正若しくは違法な行為によって、当社に損害を与え、又はそのおそれを生じさせた場合には、当社は、登録ユーザーに対して、当該行為の差し止め、及び当社の被った損害の賠償を請求することができるものとします。
      </p>

      <h5>第19条（免責）</h5>
      <ol>
        <li>
          当社は、本サービス等の特定の目的への適合性、有用性、正確性及び完全性について何ら保証を行うものではありません。
        </li>
        <li>
          当社は、登録ユーザーによる本サービス等の利用又は利用不能により生じる損害について、一切の責任を負わないものとします。
        </li>
        <li>
          当社は、以下に定める事由により登録ユーザーに損害が生じたとしても、これについて一切の責任を負わないものとします。
          <ol>
            <li>
              当社が相当の安全策を講じたにもかかわらず、第三者による不正アクセス又はコンピュータウイルスの混入等の不正行為が行われたこと
            </li>
            <li>
              通信回線、システム、フロントエンド又はサーバー等の障害による本サービス等の中断、遅滞、不能又はデータ消失等
            </li>
            <li>提携事業者の提供するテキスト等の誤記又は誤謬</li>
          </ol>
        </li>
        <li>
          当社は、登録ユーザーと第三者（他の登録ユーザー及び提携事業者を含みますが、これらに限りません。）との間で生じた紛争については、当社の故意又は過失によるものを除き、一切の責任を負わないものとします。
        </li>
      </ol>

      <h5>第20条（その他）</h5>
      <p>
        本サービス等を通じて当社が登録ユーザーに対して提供する情報等は、別段の定めのある場合を除き、当社が日本国内で取り扱う商品・サービスのみを対象とします。
      </p>

      <h5>第21条（準拠法・裁判管轄）</h5>
      <ol>
        <li>本規約は、日本法に準拠し、同法に従い解釈されるものとします。</li>
        <li>
          登録ユーザーと当社との間で本規約等又は本サービス等に関して生じた紛争については、当社の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。
        </li>
      </ol>

      <p>2021年3月1日　改訂</p>

      <p>
        【別記】本サービスの対象
        <br></br>
        <br></br>
        本サービスの対象は、以下の当社等が提供するサービスです。
        <br></br>
        <br></br>
        ■VFJIDでご利用できるサービス<br></br>
        ・カスタマーポータル
      </p>
    </Modal>
  );
}
