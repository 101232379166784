"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RadioLayoutGroup = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var lodash_1 = require("lodash");
var TrackableComponent_1 = require("../utils/TrackableComponent");
var RadioLayoutGroup = function (_a) {
    var radioButtons = _a.radioButtons, layoutWidth = _a.layoutWidth;
    return (jsx_runtime_1.jsx("div", __assign({ className: "o-layout", "data-testid": "RadioLayoutGroup-" + radioButtons[0].inputName }, { children: radioButtons.map(function (field, idx) {
            return (jsx_runtime_1.jsx("div", __assign({ className: "o-layout__item " + layoutWidth + " u-1/1@xs" }, { children: jsx_runtime_1.jsx(TrackableComponent_1.TrackableComponent, __assign({ trackingComponent: "radiobutton", trackingFunction: "radioButton" + lodash_1.upperFirst(field.inputName) + field.inputValue }, { children: jsx_runtime_1.jsxs("label", __assign({ className: "c-radio" }, { children: [jsx_runtime_1.jsx("input", { className: "c-radio__input", type: "radio", name: field.inputName, value: field.inputValue, checked: field.checkedIf, onChange: field.onChange }, void 0), jsx_runtime_1.jsx("span", __assign({ className: "c-radio__label" }, { children: field.labelText }), void 0)] }), void 0) }), void 0) }), idx));
        }) }), void 0));
};
exports.RadioLayoutGroup = RadioLayoutGroup;
