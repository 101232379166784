import { Wizard, WizardStep } from "@renewal/rosie";
import { TFunction } from "i18next";
import _cloneDeep from "lodash/cloneDeep";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, matchPath, withRouter } from "react-router";

import { useGetWizardDataQuery } from "../../../__generated__/graphql-types";
import { useCurrentContractId } from "../../../App.state";
import { ERROR_NO_DATA } from "../../../lib/helpers/constants";
import { loadingOrError } from "../../../lib/ui/Loaders/GraphqlLoadingUtility";
import { RouterHelpers } from "../../../router/router-helpers";

const WizardContainer: React.FC<RouteComponentProps> = props => {
  const { t } = useTranslation("scaffolding");
  const { currentContractId } = useCurrentContractId();

  const { loading, data, error } = useGetWizardDataQuery({
    variables: {
      contractId: currentContractId
    }
  });

  if (loading || error) return loadingOrError(loading, error);
  if (!data) throw ERROR_NO_DATA;

  const wantsToBeContacted = !!data.customer?.contract.savedOffers?.find(
    o => o.wantsToBeContacted
  );

  const matchMakerCompleted =
    data.customer?.contract.matchMakerAnswers?.isCompleted;

  const selectedVehicle = data.customer?.contract.savedOffers?.find(
    o => o.wasSelected
  );

  function getCurrentStepIndex(): number {
    if (!matchMakerCompleted) return 0;

    if (!selectedVehicle) return 1;

    if (!selectedVehicle) return 2;

    if (!wantsToBeContacted) return 3;

    return 4;
  }

  function resetDependentUrls(wizardState: WizardStep[]): void {
    if (selectedVehicle) {
      wizardState[2].link = RouterHelpers.getQuotePath(
        selectedVehicle.vehicle.vin
      );
      wizardState[3].link = RouterHelpers.getDealerAppointmentPath(
        selectedVehicle.vehicle.vin,
        selectedVehicle.quote.quoteId
      );
    }
  }

  const wizardState: WizardStep[] = _cloneDeep(steps(t, wantsToBeContacted));
  resetDependentUrls(wizardState);
  const activeIndex = getActiveWizardIndex(wizardState, props);
  const currentStepIndex = getCurrentStepIndex();

  const newWizardState = wizardState.map((value, index) => {
    return {
      ...value,
      isDone: index < currentStepIndex,
      isActive: index === activeIndex
    };
  });

  return <Wizard steps={newWizardState} noStepTitle={t("wizard.fallback")} />;
};

function steps(t: TFunction, wantsToBeContacted: boolean): WizardStep[] {
  return [
    {
      stepName: t("wizard.match-maker"),
      link: RouterHelpers.getMatchMakerPath(),
      isActive: true,
      isDone: false,
      isDisabled: wantsToBeContacted,
      additionalPaths: [
        RouterHelpers.getRenewalOverviewPath(),
        RouterHelpers.getSavedOffersPath()
      ]
    },
    {
      stepName: t("wizard.vehicle-selection"),
      link: RouterHelpers.getVehicleSelectionPath(),
      isActive: false,
      isDone: false
    },
    {
      stepName: t("wizard.finance-quote"),
      link: RouterHelpers.getQuotePath(),
      isActive: false,
      isDone: false
    },
    {
      stepName: t("wizard.car-offer"),
      link: RouterHelpers.getVehicleOfferPath(),
      isActive: false,
      isDone: false,
      additionalPaths: [RouterHelpers.getDealerAppointmentPath()]
    }
  ];
}

function getActiveWizardIndex(
  wizardState: WizardStep[],
  props: RouteComponentProps
): number {
  const pathname = props.location.pathname;
  let activeIndex = wizardState.findIndex(({ link, additionalPaths }) => {
    let doesMatch = matchPath(pathname, link) !== null;
    if (additionalPaths && additionalPaths.length > 0) {
      doesMatch ||= matchPath(pathname, additionalPaths) !== null;
    }
    // Full Details Page has special functionality since it can appear in the
    // Car Selection and the Finance Quote pages.
    if (!doesMatch && matchPath(pathname, RouterHelpers.getFullDetailsPath())) {
      const search = props.location.search;
      doesMatch ||=
        (search.includes("quote") && link.includes("quote")) ||
        (search.includes("car-selection") && link.includes("car-selection"));
    }
    return doesMatch;
  });

  // never make "Summary" item active
  if (activeIndex === 4) activeIndex = -1;

  return activeIndex;
}

export default withRouter(WizardContainer);
