import { FormFieldNumberInput } from "@renewal/rosie";
import React from "react";
import { useTranslation } from "react-i18next";

interface OwnProps {
  installmentAmount: number;
}

export function ExpectedMonthlyPayment({
  installmentAmount
}: OwnProps): JSX.Element {
  const { t } = useTranslation("match-maker");

  return (
    <FormFieldNumberInput
      fieldName="expectedMonthlyPayment"
      placeholder={t(
        "question-pages.items.installments.input-field.placeholder"
      )}
      max={(installmentAmount ? installmentAmount : 0) * 2}
      addon={t("question-pages.items.installments.input-field.addon")}
      info={t("question-pages.items.installments.input-field.info", {
        amount: installmentAmount
      })}
      useNumberFormatting
    />
  );
}
