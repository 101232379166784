"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_dom_1 = require("react-router-dom");
var index_1 = require("../../index");
var asset_switches_1 = require("../../utils/asset-switches");
var TileBody_1 = require("./TileBody");
var TileFront = function (_a) {
    var referenceInstallment = _a.referenceInstallment, t = _a.t, contractType = _a.contractType, images = _a.images, carModel = _a.carModel, carModelVariant = _a.carModelVariant, installment = _a.installment, typeOfMatch = _a.typeOfMatch, learnMore = _a.learnMore, placeholder = _a.placeholder, quotePath = _a.quotePath, galleryPlaceholder = _a.galleryPlaceholder, handleFlip = _a.handleFlip, isSaved = _a.isSaved, onDelete = _a.onDelete, onSave = _a.onSave, carDescriptionTwo = _a.carDescriptionTwo, paymentDescriptionTwo = _a.paymentDescriptionTwo, isSubmittedCar = _a.isSubmittedCar, fixedRatio = _a.fixedRatio, shouldFitInRatio = _a.shouldFitInRatio, onGoToNextPage = _a.onGoToNextPage, _b = _a.imgLoading, imgLoading = _b === void 0 ? undefined : _b, _c = _a.useCarouselForPlaceholder, useCarouselForPlaceholder = _c === void 0 ? false : _c, paymentDescriptionThree = _a.paymentDescriptionThree, onFullScreenOpen = _a.onFullScreenOpen, onSaveOffer = _a.onSaveOffer, _d = _a.hideFavoriteButton, hideFavoriteButton = _d === void 0 ? false : _d, _e = _a.isDisabled, isDisabled = _e === void 0 ? false : _e;
    var onClickHandler = function () {
        if (isSaved) {
            onDelete();
        }
        else {
            onSaveOffer && onSaveOffer();
            onSave();
        }
    };
    return (jsx_runtime_1.jsxs("article", __assign({ className: "c-card" }, { children: [contractType && (jsx_runtime_1.jsx(index_1.TileHeader, { contractType: contractType, withTag: isSubmittedCar, tagText: t("submitted-tag"), learnMore: learnMore, learnMoreText: t("shared:learn-more-button") }, void 0)), 
            // when contractType is not present, display header only for submitted offers
            !contractType && isSubmittedCar && (jsx_runtime_1.jsx("header", __assign({ className: "c-card__header u-text-left" }, { children: jsx_runtime_1.jsx("span", __assign({ className: "c-tag c-tag--success dr-card-right-element" }, { children: jsx_runtime_1.jsx("span", __assign({ className: "c-tag__label" }, { children: t("submitted-tag") }), void 0) }), void 0) }), void 0)), jsx_runtime_1.jsx(index_1.TileImage, { className: contractType ? "" : "u-pt", images: images, imgAlt: t("shared:vehicle-technical-terms.image-alt", {
                    brand: asset_switches_1.getBrandName(),
                    model: carModel,
                    variant: carModelVariant
                }), placeholder: placeholder, galleryPlaceholder: galleryPlaceholder, fixedRatio: fixedRatio, shouldFitInRatio: shouldFitInRatio, imgLoading: imgLoading, useCarouselForPlaceholder: useCarouselForPlaceholder, onFullScreenOpen: onFullScreenOpen }, void 0), jsx_runtime_1.jsx(TileBody_1.TileBody, { carModel: carModel, carModelVariant: carModelVariant, installment: installment, carDescriptionTwo: carDescriptionTwo, paymentDescriptionTwo: paymentDescriptionTwo, paymentDescriptionThree: paymentDescriptionThree, referenceInstallment: referenceInstallment, typeOfMatch: typeOfMatch, handleFlip: handleFlip, t: t }, void 0), jsx_runtime_1.jsx("div", __assign({ className: "c-card__footer" }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "dr-c-card__footer--full-width u-text-center" }, { children: [jsx_runtime_1.jsx(index_1.TrackableComponent, __assign({ trackingComponent: "link", trackingFunction: "linkFinancialQuoteMatch" }, { children: jsx_runtime_1.jsx(react_router_dom_1.Link, __assign({ className: "c-btn c-btn--full", onClick: function () {
                                    if (onGoToNextPage)
                                        onGoToNextPage();
                                }, to: quotePath }, { children: jsx_runtime_1.jsx("span", __assign({ className: "c-btn__text" }, { children: t("view-offer-button") }), void 0) }), void 0) }), void 0), jsx_runtime_1.jsx(index_1.Favorite, { isSaved: isSaved, isSavedText: t("saved-text"), saveText: t("save-text"), onClickHandler: onClickHandler, tcTrackingFunction: "checkBoxSaveOfferMatch", isHidden: hideFavoriteButton, isDisabled: isDisabled }, void 0)] }), void 0) }), void 0)] }), void 0));
};
exports["default"] = TileFront;
