"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
exports.__esModule = true;
exports.SvgCarEmpty = exports.SvgCalculator = void 0;
// see https://facebook.github.io/create-react-app/docs/adding-images-fonts-and-files
var calculator_svg_1 = require("./audi/calculator.svg");
__createBinding(exports, calculator_svg_1, "ReactComponent", "SvgCalculator");
var car_empty_svg_1 = require("./audi/car-empty.svg");
__createBinding(exports, car_empty_svg_1, "ReactComponent", "SvgCarEmpty");
