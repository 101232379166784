"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.VerticalBar = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var VerticalBar = function (_a) {
    var logoLinkUrl = _a.logoLinkUrl;
    return (jsx_runtime_1.jsx("nav", __assign({ className: "c-vertical-bar  js-vertical-bar" }, { children: jsx_runtime_1.jsx("div", __assign({ className: "c-vertical-bar__logo" }, { children: jsx_runtime_1.jsx("a", __assign({ className: "c-logo", href: "#" + (logoLinkUrl || "/") }, { children: jsx_runtime_1.jsx("span", __assign({ className: "u-hide" }, { children: "." }), void 0) }), void 0) }), void 0) }), "vw-navbar"));
};
exports.VerticalBar = VerticalBar;
