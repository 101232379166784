import { AspectRatio, Icons, TileImage, getBrandName } from "@renewal/rosie";
import { Button, ButtonContainer, Notification } from "@vwfs-its/bronson-react";
import { TFunction } from "i18next";
import React, { MouseEventHandler } from "react";

import { IGetSelectedOfferQuery } from "../../__generated__/graphql-types";

interface OwnProps {
  onSubmit: MouseEventHandler;
  onBackToOverview: Function;
  wantsToBeContacted: boolean;
  selectedOfferQueryResult: IGetSelectedOfferQuery;
  prevCall: () => void;
  placeholderImg: React.ReactNode;
  galleryPlaceholder: string;
  t: TFunction;
  stepperTitle?: string;
  hasFourthStep?: boolean;
  stepperIconOne?: string;
  stepperIconTwo?: string;
  stepperIconThree?: string;
  fixedRatio?: AspectRatio;
  shouldFitInRatio?: boolean;
  imgLoading?: undefined | "lazy" | "eager";
}

const CarOfferOverview: React.FC<OwnProps> = ({
  t,
  placeholderImg,
  galleryPlaceholder,
  stepperTitle = t("stepper-title"),
  hasFourthStep = false,
  stepperIconOne = Icons.CarOfferPage.StepOne(),
  stepperIconTwo = Icons.CarOfferPage.StepTwo(),
  stepperIconThree = Icons.FinalEvaluation(),
  fixedRatio,
  shouldFitInRatio,
  imgLoading = undefined,
  wantsToBeContacted,
  selectedOfferQueryResult,
  prevCall,
  onBackToOverview,
  onSubmit
}) => {
  return (
    <section
      className="o-content-section u-pt u-pb-xsmall"
      data-component-id="car-offer-overview"
    >
      <div className="o-page-wrap">
        {wantsToBeContacted && (
          <div className="o-component-wrapper">
            <Notification status="success" shown notClosable>
              {t("shared:submitted-car-notification", {
                vehicleModel: selectedOfferQueryResult.financeOffer?.carModel,
                dealerName:
                  selectedOfferQueryResult.customer.contract.retailer.name,
                dealerPhone:
                  selectedOfferQueryResult.customer.contract.retailer
                    .phoneNumber
              })}
            </Notification>
          </div>
        )}
        <div className="o-component-wrapper">
          <header className="c-section-heading">
            <h2 className="c-section-heading__title">{t("title")}</h2>
            <p className="c-section-heading__subtitle">{stepperTitle}</p>
          </header>
        </div>

        <div className="o-layout">
          <div className="o-layout__item u-8/12 u-1/1@s">
            <div className="o-component-wrapper">
              <ol className="c-instruction-stepper c-instruction-stepper--vertical">
                <li className="c-instruction-stepper__step is-active">
                  <figure className="c-instruction-stepper__item">
                    <i className={`c-icon c-icon--[${stepperIconOne}]`} />
                  </figure>
                  <div className="c-instruction-stepper__detail">
                    <h6 className="c-instruction-stepper__title u-mb-xsmall">
                      {t("stepper-1-title")}
                    </h6>
                    <span className="c-instruction-stepper__description">
                      {t("stepper-1-description")}
                    </span>
                  </div>
                </li>
                <li className="c-instruction-stepper__step">
                  <figure className="c-instruction-stepper__item">
                    <i className={`c-icon c-icon--[${stepperIconTwo}]`} />
                  </figure>
                  <div className="c-instruction-stepper__detail">
                    <h6 className="c-instruction-stepper__title u-mb-xsmall">
                      {t("stepper-2-title")}
                    </h6>
                    <span className="c-instruction-stepper__description">
                      {t("stepper-2-description")}
                    </span>
                  </div>
                </li>
                <li className="c-instruction-stepper__step">
                  <figure className="c-instruction-stepper__item">
                    <i className={`c-icon c-icon--[${stepperIconThree}]`} />
                  </figure>
                  <div className="c-instruction-stepper__detail">
                    <h6 className="c-instruction-stepper__title u-mb-xsmall">
                      {t("stepper-3-title")}
                    </h6>
                    <span className="c-instruction-stepper__description">
                      {t("stepper-3-description")}
                    </span>
                  </div>
                </li>
                {hasFourthStep && (
                  <li className="c-instruction-stepper__step">
                    <figure className="c-instruction-stepper__item">
                      <i className={`c-icon c-icon--[semantic-car]`} />
                    </figure>
                    <div className="c-instruction-stepper__detail">
                      <h6 className="c-instruction-stepper__title u-mb-xsmall">
                        {t("stepper-4-title")}
                      </h6>
                      <span className="c-instruction-stepper__description">
                        {t("stepper-4-description")}
                      </span>
                    </div>
                  </li>
                )}
              </ol>
            </div>
            <div className="o-component-wrapper">
              <ButtonContainer center>
                <Button
                  secondary
                  onClick={prevCall}
                  inContainer
                  className="u-3/12 u-1/3@m u-1/1@s"
                >
                  {t("shared:back-button")}
                </Button>

                {wantsToBeContacted ? (
                  <Button
                    onClick={() => onBackToOverview()}
                    inContainer
                    className="u-3/12 u-1/3@m u-1/1@s"
                  >
                    {t("shared:renewal-overview-button")}
                  </Button>
                ) : (
                  <Button
                    onClick={onSubmit}
                    inContainer
                    className="u-3/12 u-1/3@m u-1/1@s"
                  >
                    {t("contact-button")}
                  </Button>
                )}
              </ButtonContainer>
            </div>
            <div className="o-component-wrapper">
              <hr className="u-hide@s-up" />
            </div>
          </div>

          <div className="o-layout__item u-4/12 u-1/1@s u-mt-xsmall">
            <h6>{t("selected-car-title")}</h6>
            <article className="c-card c-card--image">
              <div className="c-card__image u-ph-small">
                <TileImage
                  images={
                    selectedOfferQueryResult.financeOffer?.carImages || []
                  }
                  imgAlt={t("shared:vehicle-technical-terms.image-alt", {
                    brand: getBrandName(),
                    model: selectedOfferQueryResult.financeOffer?.carModel,
                    variant:
                      selectedOfferQueryResult.financeOffer?.carModelVariant
                  })}
                  placeholder={placeholderImg}
                  galleryPlaceholder={galleryPlaceholder}
                  fixedRatio={fixedRatio}
                  shouldFitInRatio={shouldFitInRatio}
                  imgLoading={imgLoading}
                />
              </div>
              <header className="c-card__header u-mb-small  u-text-left">
                <h4 className="c-card__title">
                  {selectedOfferQueryResult.financeOffer?.carModel}
                </h4>
                <p className="c-card__subtitle">
                  {selectedOfferQueryResult.financeOffer?.carModelVariant}
                </p>
              </header>

              <div className="c-card__body u-mb-small u-text-left">
                <hr className="u-mb-small" />
                <p className="u-mb-small">{t("selected-car-term-label")}</p>
                <p>
                  <small>
                    <b>
                      {t("shared:duration", {
                        months:
                          selectedOfferQueryResult.financeOffer?.quote.term
                      })}
                    </b>
                  </small>
                </p>

                <hr className="u-mb-small" />

                <p className="u-mb-small">
                  <b>{t("selected-car-payment-label")}</b>
                </p>
                <p>
                  <b>
                    {t("shared:currency", {
                      value:
                        selectedOfferQueryResult.financeOffer?.quote.installment
                    })}
                  </b>
                </p>
              </div>
              <footer className="c-card__footer" />
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export { CarOfferOverview };
