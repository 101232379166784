import { ApolloError } from "@apollo/client";
import React from "react";

import DefaultError from "./DefaultError";
import DefaultLoading from "./DefaultLoading";

function loadingOrError(loading: boolean, error?: ApolloError): JSX.Element {
  if (loading) return <DefaultLoading />;

  if (error) return <DefaultError />;

  throw new Error("wrong usage");
}

export { loadingOrError };
