import { Icons } from "@renewal/rosie";
import { DefinitionItem } from "@renewal/rosie";
import { Link } from "@vwfs-its/bronson-react";
import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import { IVehicle } from "../../../../__generated__/graphql-types";
import { RouterHelpers } from "../../../../router/router-helpers";
import { getFuelTypeText } from "../../../helpers/vehicle-helpers";

interface OwnProps {
  vehicle: IVehicle;
  currentLocation: string;
  handleFlip?: () => void;
}

const UIButtonClose: React.FunctionComponent<
  HTMLAttributes<HTMLButtonElement>
> = ({ onClick }) => (
  <button
    className="c-notification__close dr-close-btn"
    onClick={onClick}
    aria-label="Close"
  />
);

export const VehicleContractTileBack: React.FC<OwnProps> = ({
  currentLocation,
  vehicle,
  handleFlip
}) => {
  const { t } = useTranslation("vehicle-tile-component");
  const { t: matchMakerT } = useTranslation("match-maker");

  return (
    <article className="c-card">
      <header className="c-card__header">
        <h4 className="c-card__title">{vehicle.carModel}</h4>

        <UIButtonClose
          onClick={() => {
            if (handleFlip) handleFlip();
          }}
        />
        <p className="c-card__subtitle">{vehicle.carModelVariant}</p>
      </header>
      <div className="c-card__body">
        <hr className="u-mb-small" />
        <dl data-component-id="car-details">
          <DefinitionItem
            label={t("fuel-type-label")}
            // @ts-ignore
            data={getFuelTypeText(vehicle.fuelType, matchMakerT)}
            icon={Icons.Fuel}
          />
          <DefinitionItem
            label={t("car-width-label")}
            data={vehicle.carWidth}
            icon={Icons.CarSide}
          />

          <DefinitionItem
            label={t("exterior-label")}
            data={vehicle.exteriorColor}
            icon={Icons.ExteriorColor}
          />

          <DefinitionItem
            label={t("interior-label")}
            data={vehicle.interiorColor}
            icon={Icons.InteriorColor}
          />
        </dl>

        <hr className="u-mv-small" />
        <Link
          to={RouterHelpers.getFullDetailsPath(vehicle.vin, currentLocation)}
          icon="semantic-forward"
          normalLink={false}
        >
          {t("full-details-link")}
        </Link>
      </div>
    </article>
  );
};
