"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _this = this;
exports.__esModule = true;
exports.CarSelectionInitialCard = exports.SelectionStates = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var index_1 = require("../../index");
var SavingContext_1 = require("../../lib/SavingContext");
var SelectionStates;
(function (SelectionStates) {
    SelectionStates[SelectionStates["nothingProvided"] = 0] = "nothingProvided";
    SelectionStates[SelectionStates["mmCompleted"] = 1] = "mmCompleted";
    SelectionStates[SelectionStates["savedOffers"] = 2] = "savedOffers";
})(SelectionStates = exports.SelectionStates || (exports.SelectionStates = {}));
var CarSelectionInitialCard = function (_a) {
    var t = _a.t, selectionState = _a.selectionState, savedOffersCount = _a.savedOffersCount, pushToHistory = _a.pushToHistory, noCarImg = _a.noCarImg, vehicleSelectionPath = _a.vehicleSelectionPath, savedOffersPath = _a.savedOffersPath, onViewMatches = _a.onViewMatches;
    var goToMatchingOffers = pushToHistory.bind(_this, false, vehicleSelectionPath);
    return (jsx_runtime_1.jsx(SavingContext_1.SavingContext.Consumer, { children: function (hideSaving) { return (jsx_runtime_1.jsx(index_1.RenewalCard, __assign({ title: t("offers-selection-title"), isInactive: selectionState === SelectionStates.nothingProvided }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "c-card__body u-text-left" }, { children: [jsx_runtime_1.jsx("div", __assign({ className: "u-text-center u-m" }, { children: jsx_runtime_1.jsx("img", { src: noCarImg, alt: t("offers-selection-silhouette-alt") }, void 0) }), void 0), jsx_runtime_1.jsx("p", __assign({ className: "u-mt-large u-mb-small" }, { children: jsx_runtime_1.jsx("small", { children: t("offers-selection-none") }, void 0) }), void 0), !(selectionState === SelectionStates.nothingProvided) ? (jsx_runtime_1.jsx(index_1.TrackableComponent, __assign({ trackingComponent: "button", trackingFunction: "buttonViewYourMatchesRO" }, { children: jsx_runtime_1.jsx("button", __assign({ className: "c-btn c-btn--full u-mb", onClick: function () {
                                onViewMatches && onViewMatches();
                                goToMatchingOffers();
                            } }, { children: jsx_runtime_1.jsx("span", __assign({ className: "c-btn__text" }, { children: t("offers-selection-matches-link") }), void 0) }), void 0) }), void 0)) : null, !hideSaving && (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx("hr", { className: "u-mb" }, void 0), jsx_runtime_1.jsx(index_1.SavedOffersSection, { savedOffersCount: savedOffersCount, pathToSavedOffers: savedOffersPath, t: t }, void 0)] }, void 0))] }), void 0) }), void 0)); } }, void 0));
};
exports.CarSelectionInitialCard = CarSelectionInitialCard;
