"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.QuoteRightSideRow = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var __1 = require("../..");
var QuoteRightSideRow = function (_a) {
    var type = _a.type, text = _a.text, _b = _a.amount, amount = _b === void 0 ? "" : _b, tooltip = _a.tooltip, trackingFunction = _a.trackingFunction, onOpenToolTip = _a.onOpenToolTip, handlers = __rest(_a, ["type", "text", "amount", "tooltip", "trackingFunction", "onOpenToolTip"]);
    return (jsx_runtime_1.jsx("div", __assign({ "data-group": type }, handlers, { children: jsx_runtime_1.jsxs("div", __assign({ className: "o-layout u-mt-xsmall u-ph-small" }, { children: [jsx_runtime_1.jsx("div", __assign({ className: "o-layout__item u-2/12 u-mv-xsmall u-text-right" }, { children: jsx_runtime_1.jsx(__1.Circle, { className: type + "-color" }, void 0) }), void 0), jsx_runtime_1.jsxs("div", __assign({ className: "o-layout__item u-10/12 u-mv-xsmall" }, { children: [jsx_runtime_1.jsx("small", { children: text }, void 0), jsx_runtime_1.jsx("br", {}, void 0), jsx_runtime_1.jsx("small", { children: jsx_runtime_1.jsx("b", { children: amount }, void 0) }, void 0), tooltip && (jsx_runtime_1.jsx(__1.TrackableComponent, __assign({ trackingComponent: "tooltip", trackingFunction: trackingFunction }, { children: jsx_runtime_1.jsx(bronson_react_1.InfoIcon, __assign({ icon: "semantic-info", tippyProps: {
                                    placement: "top",
                                    onTrigger: function () {
                                        onOpenToolTip && onOpenToolTip();
                                    }
                                } }, { children: jsx_runtime_1.jsx("span", { dangerouslySetInnerHTML: { __html: tooltip } }, void 0) }), void 0) }), void 0))] }), void 0)] }), void 0) }), void 0));
};
exports.QuoteRightSideRow = QuoteRightSideRow;
