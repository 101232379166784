"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.FormFieldDatepicker = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var __1 = require("..");
function FormFieldDatepicker(props) {
    return (jsx_runtime_1.jsxs("div", __assign({ className: "c-form-field u-mb" }, { children: [jsx_runtime_1.jsxs("label", __assign({ htmlFor: props.fieldId, className: "c-form-field__label" }, { children: [props.labelText, props.tooltipText && (jsx_runtime_1.jsx(bronson_react_1.InfoIcon, __assign({ icon: "semantic-info", tippyProps: { placement: "top" } }, { children: jsx_runtime_1.jsx("span", { dangerouslySetInnerHTML: { __html: props.tooltipText } }, void 0) }), void 0))] }), void 0), jsx_runtime_1.jsxs("div", __assign({ className: "c-form-field__box" }, { children: [jsx_runtime_1.jsx(__1.TrackableComponent, __assign({ trackingComponent: "inputbox", trackingFunction: props.trackingFunction }, { children: jsx_runtime_1.jsx(bronson_react_1.DatePicker, { id: props.fieldId, maxLength: 10, onChange: props.handleChange, onBlur: props.handleBlur, value: props.value, placeholder: props.placeholder, showMask: props.useMask, dateFormat: props.dateFormat }, void 0) }), void 0), props.error && props.touched && (jsx_runtime_1.jsx("p", __assign({ className: "c-error-message" }, { children: props.error }), void 0))] }), void 0)] }), void 0));
}
exports.FormFieldDatepicker = FormFieldDatepicker;
