import {
  CardEditLink,
  Icons,
  LearnMoreEquityModal,
  MatchMakerFieldResult,
  MatchMakerSingleCardLayoutWithTitle
} from "@renewal/rosie";
import {
  Button,
  ButtonContainer,
  SectionHeading
} from "@vwfs-its/bronson-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  IAllContractFieldsFragment,
  ICarSearchType,
  IEligibilityStatus,
  IMatchMakerAnswers
} from "../../__generated__/graphql-types";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { getFieldSummaryText } from "../../lib/helpers/vehicle-helpers";
import { RouterHelpers } from "../../router/router-helpers";

interface OwnProps {
  matchMakerAnswers: IMatchMakerAnswers;
  contract: IAllContractFieldsFragment;
  onSeeMatchOffersButtonClicked: Function;
}

const MatchMakerResult: React.FC<OwnProps> = ({
  matchMakerAnswers,
  contract,
  onSeeMatchOffersButtonClicked
}) => {
  const { t } = useTranslation("match-maker");

  useEffect(() => {
    if (matchMakerAnswers) {
      adobeDataLayer.matchMakerSummary(matchMakerAnswers);
    }
  }, [matchMakerAnswers]);

  return (
    <>
      <div className="o-component-wrapper">
        <SectionHeading
          subtitle={t("results.subtitle")}
          level="3"
          smallSpacing={true}
        >
          {t("results.title")}
        </SectionHeading>
      </div>

      <div className="o-component-wrapper u-text-right">
        <Button
          link
          small
          type="button"
          icon="semantic-forward"
          onClick={() => onSeeMatchOffersButtonClicked()}
        >
          {t("results.offers-button")}
        </Button>
      </div>

      <div className="o-component-wrapper">
        <MatchMakerSingleCardLayoutWithTitle
          title={t("results.page-one-summary.title")}
          editButton={
            <CardEditLink
              linkText={t("shared:edit-button")}
              linkTo={RouterHelpers.getMatchMakerQuestionPage1EditPath()}
              trackingFunction="linkEditCarCharacteristics"
              onClick={() => {
                adobeDataLayer.matchMakerSummaryEdit(
                  "Body & Drive type",
                  matchMakerAnswers
                );
              }}
            />
          }
          titleIcon="semantic-car"
        >
          <div className="o-layout">
            {matchMakerAnswers.carSearchType === ICarSearchType.BYTYPE ? (
              <MatchMakerFieldResult
                title={t("results.items.bodystyle-label")}
                data={getFieldSummaryText(matchMakerAnswers, "typeOfCar", t)}
              />
            ) : (
              <MatchMakerFieldResult
                title={t("results.items.model-label")}
                data={getFieldSummaryText(matchMakerAnswers, "modelOfCar", t)}
              />
            )}

            <MatchMakerFieldResult
              title={t("results.items.color-label")}
              data={getFieldSummaryText(matchMakerAnswers, "colorOfCar", t)}
            />

            <MatchMakerFieldResult
              title={t("results.items.drivetrain-label")}
              data={getFieldSummaryText(matchMakerAnswers, "typeOfTrain", t)}
            />
          </div>
        </MatchMakerSingleCardLayoutWithTitle>
      </div>
      <div className="o-component-wrapper">
        <MatchMakerSingleCardLayoutWithTitle
          title={t("results.page-two-summary.title")}
          editButton={
            <CardEditLink
              linkText={t("shared:edit-button")}
              linkTo={RouterHelpers.getMatchMakerQuestionPage2EditPath()}
              trackingFunction="linkEditCarFeatures"
              onClick={() => {
                adobeDataLayer.matchMakerSummaryEdit(
                  "Fuel & Performance",
                  matchMakerAnswers
                );
              }}
            />
          }
          titleIcon="semantic-performance"
        >
          <div className="o-layout">
            <MatchMakerFieldResult
              title={t("results.items.width-label")}
              data={getFieldSummaryText(matchMakerAnswers, "parkingType", t)}
            />

            <MatchMakerFieldResult
              title={t("results.items.fuel-label")}
              data={getFieldSummaryText(matchMakerAnswers, "fuelTypes", t)}
            />

            <MatchMakerFieldResult
              title={t("results.items.driving-style-label")}
              data={getFieldSummaryText(
                matchMakerAnswers,
                "importantAspects",
                t
              )}
            />
          </div>
        </MatchMakerSingleCardLayoutWithTitle>
      </div>
      <div className="o-component-wrapper">
        <MatchMakerSingleCardLayoutWithTitle
          title={t("results.page-three-summary.title")}
          editButton={
            <CardEditLink
              linkText={t("shared:edit-button")}
              linkTo={RouterHelpers.getMatchMakerQuestionPage3EditPath()}
              trackingFunction="linkEditContractTerms"
              onClick={() => {
                adobeDataLayer.matchMakerSummaryEdit(
                  "Financial",
                  matchMakerAnswers
                );
              }}
            />
          }
          titleIcon="semantic-file"
        >
          <div className="o-layout">
            <MatchMakerFieldResult
              title={t("results.items.budget-label")}
              data={t("shared:currency", {
                value: matchMakerAnswers.expectedMonthlyPayment
              })}
            />

            <MatchMakerFieldResult
              title={t("results.items.additional-deposit-label")}
              data={t("shared:currency", {
                value: matchMakerAnswers.additionalDeposit
              })}
            />

            <MatchMakerFieldResult
              title={t("results.items.bonus-payment-label")}
              data={t("shared:currency", {
                value: matchMakerAnswers.bonusPayment
              })}
            />

            <MatchMakerFieldResult
              title={t("results.items.duration-label")}
              data={
                matchMakerAnswers.expectedDuration
                  ? matchMakerAnswers.expectedDuration.toString()
                  : ""
              }
            />
          </div>
        </MatchMakerSingleCardLayoutWithTitle>
      </div>

      <div className="o-component-wrapper">
        <MatchMakerSingleCardLayoutWithTitle
          title={t("results.page-four-summary.title")}
          editButton={
            <CardEditLink
              linkText={t("shared:edit-button")}
              linkTo={RouterHelpers.getMatchMakerQuestionPage4EditPath()}
              trackingFunction="linkEditFinanceTerms"
              onClick={() => {
                adobeDataLayer.matchMakerSummaryEdit(
                  "Financing plan",
                  matchMakerAnswers
                );
              }}
            />
          }
          titleIcon={Icons.Payment}
        >
          <div className="o-layout">
            <MatchMakerFieldResult
              title={t("results.items.finance-plan-title")}
              data={getFieldSummaryText(matchMakerAnswers, "financePlan", t)}
            />
          </div>
        </MatchMakerSingleCardLayoutWithTitle>
      </div>

      {contract.eligibleStatus === IEligibilityStatus.POSITIVE ? (
        <div className="o-component-wrapper">
          <MatchMakerSingleCardLayoutWithTitle
            title={t("results.page-five-summary.title")}
            titleIcon={Icons.Calculator}
          >
            <div className="o-layout">
              <MatchMakerFieldResult
                title={t("results.items.equity-label")}
                data={t("shared:currency", { value: contract.equity })}
              >
                <LearnMoreEquityModal
                  linkText={t("shared:learn-more-button")}
                  currentVehicleValue={contract.currentVehicleValue}
                  amountToSettle={
                    0 - (contract.amountToSettle ? contract.amountToSettle : 0)
                  }
                  equity={contract.equity}
                />
              </MatchMakerFieldResult>
            </div>
          </MatchMakerSingleCardLayoutWithTitle>
        </div>
      ) : null}

      <div className="o-component-wrapper">
        <ButtonContainer center>
          <Button onClick={() => onSeeMatchOffersButtonClicked()} inContainer>
            {t("results.offers-button")}
          </Button>
        </ButtonContainer>
      </div>
    </>
  );
};

export default MatchMakerResult;
