"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.TileImage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var index_1 = require("../../index");
var TileImage = function (_a) {
    var className = _a.className, images = _a.images, imgAlt = _a.imgAlt, placeholder = _a.placeholder, galleryPlaceholder = _a.galleryPlaceholder, fixedRatio = _a.fixedRatio, shouldFitInRatio = _a.shouldFitInRatio, _b = _a.imgLoading, imgLoading = _b === void 0 ? undefined : _b, _c = _a.useCarouselForPlaceholder, useCarouselForPlaceholder = _c === void 0 ? false : _c, onFullScreenOpen = _a.onFullScreenOpen;
    return (jsx_runtime_1.jsx("div", __assign({ className: "c-card__image u-text-center u-mb-none " + className }, { children: jsx_runtime_1.jsx("div", __assign({ className: "u-mb" }, { children: jsx_runtime_1.jsx(index_1.ImageGalleryCarousel, { images: images, imageAlt: imgAlt, placeholder: placeholder, placeholderImg: galleryPlaceholder, fixedRatio: fixedRatio, shouldFitInRatio: shouldFitInRatio, imgLoading: imgLoading, useCarouselForPlaceholder: useCarouselForPlaceholder, onFullScreenOpen: onFullScreenOpen }, void 0) }), void 0) }), void 0));
};
exports.TileImage = TileImage;
