import "./LearnMoreAboutFinancePlan.scss";

import { Button, Modal } from "@vwfs-its/bronson-react";
import React, { HTMLAttributes, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { IFinancePlan } from "../../../__generated__/graphql-types";
import { getFinancePlanCmsKey } from "../../helpers/utility";
import AFD from "./assets/AFD.svg";
import SLoan from "./assets/S_Loan.svg";
import Solutions from "./assets/Solutions.svg";

export const LearnMoreAboutFinancePlanTrigger: React.FunctionComponent<
  HTMLAttributes<HTMLButtonElement> & {
    linkText: string;
    contractType: IFinancePlan;
  }
> = ({ linkText, contractType, ...rest }) => {
  const modalId = `learn-more-about-${contractType}`;

  const [isOpen, setIsOpen] = React.useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  if (contractType === IFinancePlan.OTHERS) return <></>;

  return (
    <>
      <Button
        className="js-modal-trigger"
        type="button"
        data-modal={modalId}
        link
        small
        icon="semantic-forward"
        onClick={() => {
          setIsOpen(true);
        }}
        {...rest}
      >
        {linkText}
      </Button>

      <LearnMoreAboutModal
        shown={isOpen}
        onClose={onClose}
        modalId={modalId}
        financePlan={contractType}
      />
    </>
  );
};

const modelMetaData: Record<
  Exclude<IFinancePlan, IFinancePlan.OTHERS>,
  { cmsKey: string; imageUrl?: string; imageAlt?: string }
> = {
  FUTURE_DRIVE: {
    cmsKey: getFinancePlanCmsKey(IFinancePlan.FUTURE_DRIVE),
    imageUrl: AFD,
    imageAlt: "Audi Future Drive"
  },
  SLOAN: {
    cmsKey: getFinancePlanCmsKey(IFinancePlan.SLOAN),
    imageUrl: SLoan,
    imageAlt: "Sローン"
  },
  OPEN_END_LEASE: {
    cmsKey: getFinancePlanCmsKey(IFinancePlan.OPEN_END_LEASE),
    imageUrl: undefined,
    imageAlt: undefined
  },
  CLOSE_END_LEASE: {
    cmsKey: getFinancePlanCmsKey(IFinancePlan.CLOSE_END_LEASE),
    imageUrl: undefined,
    imageAlt: undefined
  },
  SOLUTIONS: {
    cmsKey: getFinancePlanCmsKey(IFinancePlan.SOLUTIONS),
    imageUrl: Solutions,
    imageAlt: undefined
  }
};

const LearnMoreAboutModal: React.FC<{
  shown: boolean;
  onClose: () => void;
  financePlan: Exclude<IFinancePlan, IFinancePlan.OTHERS>;
  modalId: string;
}> = ({ shown, onClose, financePlan, modalId }) => {
  const metaData = modelMetaData[financePlan];

  const { t } = useTranslation("finance-modal---jp");
  const interlalT = useCallback(
    key => t(`${metaData.cmsKey}.${key}`),
    [t, metaData]
  );

  return (
    <Modal
      id={modalId}
      title={interlalT("title")}
      shown={shown}
      buttonCloseLabel={t("shared:modal-close-button")}
      onClose={onClose}
      className="dr-modal c-modal--large c-modal--stretch"
    >
      <h6 className="u-text-left">{interlalT("subtitle")}</h6>

      <p>{interlalT("description")}</p>
      <p>{interlalT("footnote")}</p>
      <div className="u-text-center">
        <img
          src={metaData.imageUrl}
          alt={metaData.imageAlt}
          className="dr-finance-modal-img"
        />
      </div>
    </Modal>
  );
};
