import { SelectableTileGroup } from "@renewal/rosie";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { IMatchMakerAnswers } from "../../../__generated__/graphql-types";
import { fuelList } from "../../helpers/vehicle-helpers";

export function FuelType(): React.ReactElement {
  const { t } = useTranslation("match-maker");
  const { values } = useFormikContext<IMatchMakerAnswers>();

  return (
    <SelectableTileGroup
      itemList={fuelList(t)}
      itemColumnCount={[4, 6, 12]}
      propName="fuelTypes"
      propObject={values.fuelTypes || []}
      className="o-layout__item u-3/5 u-1/1@m"
    />
  );
}
