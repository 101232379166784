import { SelectableTileRadioButtonGroup } from "@renewal/rosie";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { IMatchMakerAnswers } from "../../../__generated__/graphql-types";
import { financePlanList } from "../../helpers/vehicle-helpers";

export function FinancePlan(): React.ReactElement {
  const { t } = useTranslation("match-maker");
  const { values } = useFormikContext<IMatchMakerAnswers>();

  return (
    <>
      <SelectableTileRadioButtonGroup
        itemList={financePlanList(t)}
        itemColumnCount={[6, 6, 12]}
        propName="financePlan"
        propObject={values}
        className="o-layout__item u-8/12 u-1/1@m"
        displayDescription={false}
      />
    </>
  );
}
