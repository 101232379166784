"use strict";
exports.__esModule = true;
exports.skodaBrand = exports.skodaBase = exports.skodaSubtle = exports.skodaPale = exports.seatBrand = exports.seatBase = exports.seatPale = exports.vw6Inactive = exports.vw6Blue = exports.vwSubtle = exports.vwInfo = exports.vwBlueAlt = exports.vwBlue = exports.vwPale = exports.vwBase = exports.vwBaseText = exports.audiSubtle = exports.audiSuccess = exports.audiInfo = exports.audiBase = exports.audiBaseText = exports.audiInactive = exports.audiPale = exports.audiRed = exports.white = exports.black = void 0;
exports.black = "#000";
exports.white = "#fff";
exports.audiRed = "#bb0a30";
exports.audiPale = "#b3b3b3";
exports.audiInactive = "#d9d9d9";
exports.audiBaseText = "#333333";
exports.audiBase = "#4c4c4c";
exports.audiInfo = "#e5e5e5";
exports.audiSuccess = "#009900";
exports.audiSubtle = "#808080";
exports.vwBaseText = "#2f3538";
exports.vwBase = "#2f3538";
exports.vwPale = "#f3f5f6";
exports.vwBlue = "#0099da";
exports.vwBlueAlt = "#0099da";
exports.vwInfo = "#0068aa";
exports.vwSubtle = "#dee1e3";
exports.vw6Blue = "#001e50";
exports.vw6Inactive = "#dfe4e8";
exports.seatPale = "#dedad4";
exports.seatBase = "#0f0f0f";
exports.seatBrand = "#ea5d1a";
exports.skodaPale = "#cecece";
exports.skodaSubtle = "#a7aeb4";
exports.skodaBase = "#000";
exports.skodaBrand = "#4ba82e";
