"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.ThirdPartyLicenseTables = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var _ = __importStar(require("lodash"));
var LicenseTexts_1 = require("./ThirdPartyLicenseTexts/LicenseTexts");
var ThirdPartyLicenseTables = function (_a) {
    var licenseJson = _a.licenseJson, tableHeaders = _a.tableHeaders;
    var licenseTypes = _.uniq(_.map(licenseJson, "license"));
    return (jsx_runtime_1.jsx(jsx_runtime_1.Fragment, { children: licenseTypes.map(function (type, i) {
            return (jsx_runtime_1.jsx("section", __assign({ className: "o-content-section" }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "o-page-wrap" }, { children: [jsx_runtime_1.jsx("header", __assign({ className: "c-section-heading" }, { children: jsx_runtime_1.jsx("h3", __assign({ className: "c-section-heading__title" }, { children: jsx_runtime_1.jsx("span", __assign({ className: "c-section-heading__title-text" }, { children: type }), void 0) }), void 0) }), void 0), jsx_runtime_1.jsx("div", __assign({ className: "c-table-wrapper" }, { children: jsx_runtime_1.jsxs("table", { children: [jsx_runtime_1.jsx("thead", { children: jsx_runtime_1.jsxs("tr", { children: [jsx_runtime_1.jsx("th", { children: tableHeaders.copyright }, void 0), jsx_runtime_1.jsx("th", { children: tableHeaders.version }, void 0), jsx_runtime_1.jsx("th", { children: tableHeaders.copyright }, void 0)] }, void 0) }, void 0), jsx_runtime_1.jsx("tbody", { children: _.filter(licenseJson, function (o) {
                                            return o.license === type;
                                        }).map(function (item, i) {
                                            return (jsx_runtime_1.jsxs("tr", { children: [jsx_runtime_1.jsx("td", { children: item.name }, void 0), jsx_runtime_1.jsx("td", { children: item.version }, void 0), jsx_runtime_1.jsxs("td", { children: [" ", item.copyright] }, void 0)] }, i));
                                        }) }, void 0)] }, void 0) }), void 0), LicenseTexts_1.licenseTexts(type)] }), void 0) }), i));
        }) }, void 0));
};
exports.ThirdPartyLicenseTables = ThirdPartyLicenseTables;
