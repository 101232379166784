import { VerticalBar } from "@renewal/rosie";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { RouterHelpers } from "../../../router/router-helpers";

type AllProps = RouteComponentProps;

class VerticalBarContainer extends React.PureComponent<AllProps> {
  render() {
    let logoLinkUrl = "";
    logoLinkUrl = RouterHelpers.getRenewalOverviewPath();

    return <VerticalBar logoLinkUrl={logoLinkUrl} />;
  }
}

export default withRouter(VerticalBarContainer);
