"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.MatchMakerFieldResult = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var MatchMakerFieldResult = function (_a) {
    var title = _a.title, data = _a.data, children = _a.children;
    return (jsx_runtime_1.jsxs("div", __assign({ className: "o-layout__item u-1/2 u-1/1@xs" }, { children: [jsx_runtime_1.jsxs("p", __assign({ className: "u-mb-xsmall" }, { children: [jsx_runtime_1.jsx("small", { children: title }, void 0), jsx_runtime_1.jsx("br", {}, void 0), data] }), void 0), children] }), void 0));
};
exports.MatchMakerFieldResult = MatchMakerFieldResult;
