"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.TileBody = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
require("./TileBody.scss");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var index_1 = require("../../../index");
var MatchPercentageCycle_1 = require("./MatchPercentageCycle");
var TileBody = function (_a) {
    var t = _a.t, carModel = _a.carModel, carModelVariant = _a.carModelVariant, installment = _a.installment, typeOfMatch = _a.typeOfMatch, referenceInstallment = _a.referenceInstallment, handleFlip = _a.handleFlip, _b = _a.isActive, isActive = _b === void 0 ? true : _b, carDescriptionTwo = _a.carDescriptionTwo, paymentDescriptionTwo = _a.paymentDescriptionTwo, paymentDescriptionThree = _a.paymentDescriptionThree, _c = _a.disableRanking, disableRanking = _c === void 0 ? false : _c;
    /*
     * When no variant is available we render a line break to ensure
     * that the cards have the same layout
     */
    var carModelVariantComponent = function () {
        if (carModelVariant !== "" && carModelVariant !== null) {
            return carModelVariant;
        }
        else {
            return jsx_runtime_1.jsx("br", {}, void 0);
        }
    };
    return (jsx_runtime_1.jsxs("div", __assign({ className: "c-card__body u-mb-small u-text-left" }, { children: [jsx_runtime_1.jsx("h4", __assign({ className: "u-mb-xsmall" }, { children: carModel }), void 0), jsx_runtime_1.jsx("p", __assign({ className: "u-mb-small" }, { children: carModelVariantComponent() }), void 0), carDescriptionTwo && jsx_runtime_1.jsx("p", __assign({ className: "u-mb-small" }, { children: carDescriptionTwo }), void 0), jsx_runtime_1.jsxs("h6", __assign({ className: "u-mb-small", "data-testid": "card-price" }, { children: [t("shared:installment", { amount: installment }), jsx_runtime_1.jsx("br", {}, void 0), paymentDescriptionTwo, paymentDescriptionThree && (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx("br", {}, void 0), paymentDescriptionThree] }, void 0))] }), void 0), jsx_runtime_1.jsx("hr", { className: "u-mb-small" }, void 0), jsx_runtime_1.jsx("div", __assign({ className: "dr-linkViewDetailsMatch" }, { children: jsx_runtime_1.jsx(index_1.TrackableComponent, __assign({ trackingComponent: "link", trackingFunction: "linkViewDetailsMatch" }, { children: jsx_runtime_1.jsx(bronson_react_1.Button, __assign({ onClick: function () { return (handleFlip ? handleFlip() : null); }, icon: "semantic-forward", link: true, small: true, disabled: !isActive }, { children: t("view-details-button") }), void 0) }), void 0) }), void 0), !disableRanking && (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx("hr", { className: "u-mv-small" }, void 0), jsx_runtime_1.jsx(MatchPercentageCycle_1.MatchPercentageCycle, { referenceInstallment: referenceInstallment, offerInstallment: installment, typeOfMatch: typeOfMatch, t: t }, void 0)] }, void 0))] }), void 0));
};
exports.TileBody = TileBody;
