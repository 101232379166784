import { Header, HeaderLinkItem, HeaderStates } from "@renewal/rosie";
import React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, withRouter } from "react-router";

import { adobeDataLayer } from "../../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { RouterHelpers } from "../../../router/router-helpers";

const HeaderContainer: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation("scaffolding");

  // To avoid a constructor we are using arrow functions
  const isLinkActive = (url: string): HeaderStates => {
    return matchPath(location.pathname, url)
      ? HeaderStates.Active
      : HeaderStates.Inactive;
  };

  let logoLinkUrl = "";

  logoLinkUrl = RouterHelpers.getLandingPagePath();

  const secondaryLinks: HeaderLinkItem[] = [
    {
      name: t("header.help"),
      link: RouterHelpers.getFAQPath(),
      status: isLinkActive(RouterHelpers.getFAQPath())
    },
    {
      name: t("header.logout"),
      link: RouterHelpers.getLogoutPath(),
      status: HeaderStates.Inactive,
      isAbsolute: true,
      additionalOnClick: async () => {
        await adobeDataLayer.logout();
      }
    }
  ];

  return (
    <Header
      links={[]}
      secondaryLinks={secondaryLinks}
      logoLinkUrl={logoLinkUrl}
    />
  );
};

export default withRouter(HeaderContainer);
