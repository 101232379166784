import { SelectableTileGroup } from "@renewal/rosie";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { IMatchMakerAnswers } from "../../../__generated__/graphql-types";
import { drivingStyleList } from "../../helpers/vehicle-helpers";

export function DrivingStyle(): React.ReactElement {
  const { t } = useTranslation("match-maker");
  const { values } = useFormikContext<IMatchMakerAnswers>();

  return (
    <SelectableTileGroup
      itemList={drivingStyleList(t)}
      itemColumnCount={[6, 6, 12]}
      propName="importantAspects"
      propObject={values.importantAspects || []}
      className="o-layout__item u-2/5 u-1/1@m"
    />
  );
}
