"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.GetInTouch = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var __1 = require("../../");
var GET_IN_TOUCH_ID = "get-in-touch-modal";
var GetInTouch = function (_a) {
    var t = _a.t, showRetailerName = _a.showRetailerName, retailer = _a.retailer, trackingCall = _a.trackingCall;
    return (jsx_runtime_1.jsx("div", __assign({ className: "c-card u-mt-large " }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "c-card__body u-text-center" }, { children: [jsx_runtime_1.jsx("h4", __assign({ className: "c-card__title" }, { children: t("contact-section-title") }), void 0), jsx_runtime_1.jsx("p", __assign({ className: "u-mb" }, { children: t("contact-section-subtitle") }), void 0), jsx_runtime_1.jsx("div", __assign({ className: "o-button-container" }, { children: jsx_runtime_1.jsxs(__1.ModalWithButton, __assign({ id: GET_IN_TOUCH_ID, buttonText: t("contact-section-button"), title: t("contact-modal-title"), trackingFunction: "linkGetInTouchModal", closeButtonHoverLabel: t("shared:modal-close-button"), trackingCall: trackingCall }, { children: [jsx_runtime_1.jsx("h5", { children: t("contact-modal-subtitle", {
                                    dealerName: retailer.name
                                }) }, void 0), showRetailerName && jsx_runtime_1.jsx("h3", { children: retailer.name }, void 0), jsx_runtime_1.jsxs("div", __assign({ className: "o-layout" }, { children: [jsx_runtime_1.jsx("div", __assign({ className: "o-layout__item u-1/1" }, { children: jsx_runtime_1.jsx(__1.ItemTeaserSmall, { iconType: "semantic-phone", classNamesOnIcon: "u-hide@s", title: t("contact-modal-phone"), linkRef: "tel:" + retailer.phoneNumber, text: retailer.phoneNumber }, void 0) }), void 0), retailer.email && (jsx_runtime_1.jsx("div", __assign({ className: "o-layout__item u-1/1 u-mt-small" }, { children: jsx_runtime_1.jsx(__1.ItemTeaserSmall, { iconType: "semantic-mail", classNamesOnIcon: "u-hide@s", title: t("contact-modal-email"), teaserText: t("contact-modal-email-text"), linkRef: "mailto:" + retailer.email, text: retailer.email }, void 0) }), void 0))] }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
exports.GetInTouch = GetInTouch;
