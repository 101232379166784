import { Notification } from "@vwfs-its/bronson-react";
import React from "react";
import { useTranslation } from "react-i18next";

const EmptySavedOffersComponent: React.FC = () => {
  const { t } = useTranslation("saved-offers-page");
  return (
    <div className="u-m">
      <Notification status="info" notClosable shown>
        {t("no-saved-offers")}
      </Notification>
    </div>
  );
};

export default EmptySavedOffersComponent;
