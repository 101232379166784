import { ApolloError, QueryLazyOptions } from "@apollo/client";
import { FieldError } from "@renewal/rosie";
import { ContentSection, SectionHeading } from "@vwfs-its/bronson-react";
import { Button, ButtonContainer } from "@vwfs-its/bronson-react";
import { TFunction } from "i18next";
import React, { useReducer } from "react";

import {
  IContactDate,
  IContactMethod,
  IContactTime,
  IGetDealersQuery,
  IGetSelectedOfferQuery,
  IMatchMakerAnswers
} from "../../__generated__/graphql-types";
import {
  ActionTypes,
  ContactDetails,
  ContactMethod,
  DealerData
} from "./reducerTypes";
import { renderContactDetailsSections } from "./sections/ContactDetailsSection";
import { renderContactMethodSection } from "./sections/ContactMethodSection";
import { renderDealerSection } from "./sections/DealerSection";

interface OwnProps {
  onSubmit: (values: DealerAppointmentState) => void;
  t: TFunction;
  offer: IGetSelectedOfferQuery;
  prevCall: () => void;
  getDealers: (
    options?: QueryLazyOptions<Record<string, unknown>> | undefined
  ) => void;
  dealers: IGetDealersQuery | undefined;
  retrievalError?: ApolloError;
  submitError?: ApolloError;
  matchMakerAnswers: IMatchMakerAnswers;
}

export type DealerAppointmentState = {
  dealerData: DealerData;
  contactDetails: ContactDetails;
  contactMethod: ContactMethod;
  contactDetailsEditable: boolean;
  dealerEditable: boolean;
  contactMethodError: boolean;
};

const DealerAppointmentReducer = (
  state: DealerAppointmentState,
  action: ActionTypes
): DealerAppointmentState => {
  switch (action.type) {
    case "UPDATE_DEALER":
      return {
        ...state,
        dealerData: action.payload
      };
    case "UPDATE_CONTACT_DETAILS":
      return {
        ...state,
        contactDetails: action.payload
      };
    case "UPDATE_CONTACT_METHOD": {
      return {
        ...state,
        contactMethod: action.payload
      };
    }
    case "SET_CONTACT_DETAILS_EDITABLE": {
      return {
        ...state,
        contactDetailsEditable: action.payload
      };
    }
    case "SET_DEALER_EDITABLE": {
      return {
        ...state,
        dealerEditable: action.payload
      };
    }
    case "SET_METHOD_ERROR": {
      return {
        ...state,
        contactMethodError: action.payload
      };
    }
  }
};

const initializeState = (
  offer: IGetSelectedOfferQuery
): DealerAppointmentState => {
  const email = !!offer.customer.email ? offer.customer.email : "";
  const mobilePhone = !!offer.customer.mobilePhone
    ? offer.customer.mobilePhone
    : "";
  const homePhone = !!offer.customer.homePhone ? offer.customer.homePhone : "";

  return {
    dealerData: {
      name: offer.customer.contract.retailer.name,
      address: offer.customer.contract.retailer.address,
      email: offer.customer.contract.retailer.email,
      code: offer.customer.contract.retailer.code
    },
    contactDetails: {
      email,
      phoneNumber: !!mobilePhone ? mobilePhone : homePhone
    },
    contactMethod: {
      selectedMethod: "" as IContactMethod,
      date: IContactDate.WEEKDAYS,
      time: IContactTime.DAYTIME
    },
    contactMethodError: false,
    contactDetailsEditable: false,
    dealerEditable: false
  };
};

const DealerAppointmentComponent: React.FC<OwnProps> = props => {
  const {
    t,
    onSubmit,
    prevCall,
    offer,
    getDealers,
    dealers,
    retrievalError,
    submitError,
    matchMakerAnswers
  } = props;

  const [state, dispatch] = useReducer(
    DealerAppointmentReducer,
    initializeState(offer)
  );

  return (
    <ContentSection pageWrap>
      <SectionHeading
        level="3"
        subtitle={t("contract-submission.appointment-sub-headline")}
      >
        {t("contract-submission.appointment-headline")}
      </SectionHeading>
      <ContentSection.ComponentWrapper>
        {renderDealerSection(
          state,
          dispatch,
          t,
          dealers,
          getDealers,
          matchMakerAnswers
        )}
      </ContentSection.ComponentWrapper>
      <ContentSection.ComponentWrapper>
        {renderContactMethodSection(state, dispatch, t)}
      </ContentSection.ComponentWrapper>
      <ContentSection.ComponentWrapper>
        {renderContactDetailsSections(state, dispatch, t, matchMakerAnswers)}
      </ContentSection.ComponentWrapper>
      <ContentSection.ComponentWrapper>
        <ButtonContainer center>
          <Button
            secondary
            onClick={prevCall}
            className="u-3/12 u-1/3@m u-1/1@s"
            inContainer
          >
            {t("shared:back-button")}
          </Button>

          <Button
            disabled={state.dealerEditable || state.contactDetailsEditable}
            onClick={() => {
              if (state.contactMethod.selectedMethod) {
                dispatch({ type: "SET_METHOD_ERROR", payload: false });
                return onSubmit(state);
              }
              dispatch({ type: "SET_METHOD_ERROR", payload: true });
            }}
            className="u-3/12 u-1/3@m u-1/1@s"
            inContainer
          >
            {t("contract-submission.contact-button")}
          </Button>
        </ButtonContainer>
        {retrievalError && (
          <FieldError
            errorMessage={t(
              "contract-submission.error-messages.err-service-unavailable"
            )}
          />
        )}
        {submitError && (
          <FieldError
            errorMessage={t(
              "contract-submission.error-messages.err-check-box-save"
            )}
          />
        )}
      </ContentSection.ComponentWrapper>
    </ContentSection>
  );
};

export default DealerAppointmentComponent;
