import React from "react";
import { RouteProps } from "react-router";

import ProtectedRoute from "./ProtectedRoute";

function ProtectedRouteContainer(props: RouteProps): JSX.Element {
  return <ProtectedRoute {...props} />;
}

export default ProtectedRouteContainer;
