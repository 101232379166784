import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { adobeDataLayer } from "../../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { DrivingStyle } from "../../../lib/components/MatchMakerAnswersEditComponents/DrivingStyle";
import { FuelType } from "../../../lib/components/MatchMakerAnswersEditComponents/FuelType";
import { ParkingType } from "../../../lib/components/MatchMakerAnswersEditComponents/ParkingType";
import { getFieldValidators } from "../../../lib/helpers/match-maker-validator";
import { RouterHelpers } from "../../../router/router-helpers";
import { genericValidator } from "../MatchMakerHelper";
import MatchMakerQuestionWrapper from "./MatchMakerQuestionWrapper";

interface OwnProps {
  matchMakerAnswersId: string;
  goToPage: (path: string) => void;
  isEditMode?: boolean;
}

export const MatchMakerQuestion2Fields = ["fuelTypes", "importantAspects"];

const MatchMakerQuestion2: React.FC<OwnProps> = props => {
  const { t } = useTranslation("match-maker");
  const { goToPage, matchMakerAnswersId, isEditMode } = props;

  useEffect(() => {
    adobeDataLayer.matchMakerQuestionPage(2, "Fuel & Performance");
  }, []);

  return (
    <MatchMakerQuestionWrapper
      pageTitle={t("question-pages.question-page-two.title")}
      isEditMode={isEditMode}
      currentPercentage={2}
      nextPage={RouterHelpers.getMatchMakerQuestionPage3Path()}
      previousPage={RouterHelpers.getMatchMakerQuestionPage1Path()}
      goToPage={goToPage}
      validate={genericValidator.bind(
        this,
        getFieldValidators(t, ["parkingType", "fuelTypes", "importantAspects"])
      )}
      matchMakerAnswersId={matchMakerAnswersId}
    >
      {() => (
        <div className="o-page-wrap o-page-wrap--small">
          <h4>{t("question-pages.items.width.label")}</h4>
          <ParkingType />
          <hr className="u-mv-xlarge"></hr>

          <h4>{t("question-pages.items.fuel.label")}</h4>
          <FuelType />
          <hr className="u-mv-xlarge"></hr>

          <h4>{t("question-pages.items.driving-style.label")}</h4>
          <DrivingStyle />
        </div>
      )}
    </MatchMakerQuestionWrapper>
  );
};

export default MatchMakerQuestion2;
