import {
  ApolloClient,
  gql,
  useApolloClient,
  useMutation
} from "@apollo/client";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  IAllVehicleOfferFieldsFragment,
  useGetSavedOffersQuery,
  useSaveOfferMutation
} from "../../__generated__/graphql-types";
import { DELETE_OFFER } from "./saved-offers.graphql";

// use graphqlTag to skip generating typescript types/operations in `codegen`
// command. otherwise the fragments which are used to write to Apollo Cache will
// cause some weird errors during `codegen`
const gqlIgnoredByCodeGen = gql;

export function useOfferOperations(contractId: string): {
  saveOffer: (
    offer: IAllVehicleOfferFieldsFragment,
    rollbackRemovedFlagOnly: Boolean,
    sendRequestToBackend?: Boolean
  ) => Promise<boolean>;
  deleteOffer: (
    offer: IAllVehicleOfferFieldsFragment,
    setRemovedFlagOnly: Boolean,
    sendRequestToBackend?: Boolean
  ) => Promise<void>;
  savedOfferCount: number;
  savedOffers: IAllVehicleOfferFieldsFragment[];
} {
  const client = useApolloClient();
  const [saveOfferMutation] = useSaveOfferMutation();
  const [deleteOfferMutation] = useMutation(DELETE_OFFER);
  const { t } = useTranslation("shared");

  const { data } = useGetSavedOffersQuery({
    variables: { contractNumber: contractId }
  });

  const currentSavedOffers =
    data?.customer?.contract?.savedOffers?.filter(
      o => !o.hasExpired || o.wantsToBeContacted
    ) || [];

  const invalidOffers = data?.customer?.contract?.savedOffers?.filter(
    o => o.hasExpired && !o.wantsToBeContacted
  );

  const saveOffer = async (
    offer: IAllVehicleOfferFieldsFragment,
    rollbackRemovedFlagOnly: Boolean,
    sendRequestToBackend: Boolean = true
  ) => {
    addSavedOfferToCache(
      client,
      offer,
      contractId,
      currentSavedOffers,
      rollbackRemovedFlagOnly
    );

    if (sendRequestToBackend) {
      const saveOfferResponse = await saveOfferMutation({
        variables: {
          vin: offer.vehicle.vin,
          quoteId: offer.quote.quoteId,
          matchType: offer.typeOfMatch,
          contractId
        }
      });

      if (currentSavedOffers.length >= 30) {
        toast.info(t("saved-offers-limit-text"));
        replaceCache(
          client,
          saveOfferResponse.data?.saveOffer as IAllVehicleOfferFieldsFragment[],
          contractId
        );
      }
    }
    return true;
  };

  const deleteOffer = async (
    offer: IAllVehicleOfferFieldsFragment,
    // setting `setRemovedFlagOnly` to true to mark the offer as removed only
    // without actually removing it from the savedOffers array in ApolloCache
    setRemovedFlagOnly: Boolean,
    sendRequestToBackend: Boolean = true
  ) => {
    deleteSavedOfferInCache(
      client,
      offer,
      contractId,
      currentSavedOffers,
      setRemovedFlagOnly
    );

    if (sendRequestToBackend)
      await deleteOfferMutation({
        variables: {
          quoteId: offer.quote.quoteId,
          contractId
        }
      });
  };

  const savedOfferCount = currentSavedOffers.filter(
    o => o.wasRemoved !== true
  ).length;

  invalidOffers?.forEach(o => {
    deleteOffer(o, false);
  });

  return {
    saveOffer,
    deleteOffer,
    savedOfferCount,
    savedOffers: currentSavedOffers
  };
}

function replaceCache(
  client: ApolloClient<object>,
  offers: IAllVehicleOfferFieldsFragment[],
  contractId: string
) {
  client.writeFragment({
    id: `Contract:{"contractNumber":"${contractId}"}`,
    fragment: gqlIgnoredByCodeGen`
      fragment deleteSavedOfferInCacheSaveOffers on Contract {
        savedOffers
      }
    `,
    data: {
      savedOffers: offers.map(o => {
        return {
          __ref: `VehicleOffer:{"typeOfMatch":"${o.typeOfMatch}","quote":{"quoteId":"${o.quote.quoteId}"}}`
        };
      })
    }
  });
}

function addSavedOfferToCache(
  client: ApolloClient<object>,
  offer: IAllVehicleOfferFieldsFragment,
  contractId: string,
  currentSavedOffers: IAllVehicleOfferFieldsFragment[],
  rollbackRemovedFlagOnly: Boolean
) {
  client.writeFragment({
    id: `VehicleOffer:{"typeOfMatch":"${offer.typeOfMatch}","quote":{"quoteId":"${offer.quote.quoteId}"}}`,
    fragment: gqlIgnoredByCodeGen`
      fragment addSavedOfferToCache on VehicleOffer {
        quote {
          quoteId
          annualPercentageRate
          installment
          term
        }
        typeOfMatch
        wasSaved
        wasRemoved
      }
    `,
    data: {
      quote: { ...offer.quote },
      typeOfMatch: offer.typeOfMatch,
      wasSaved: true,
      wasRemoved: false
    }
  });

  if (!rollbackRemovedFlagOnly) {
    // remove current offer from existing saved-offers-list as it may exist
    // there with "wasRemoved" equals to "true"
    const newCurrentSavedOffers = currentSavedOffers.filter(
      o => o.quote.quoteId !== offer.quote.quoteId
    );

    const newSavedOffers: IAllVehicleOfferFieldsFragment[] = [
      ...newCurrentSavedOffers,
      { ...offer, wasSaved: true, wasRemoved: false }
    ];

    client.writeFragment({
      id: `Contract:{"contractNumber":"${contractId}"}`,
      fragment: gqlIgnoredByCodeGen`
      fragment addSavedOfferToCacheSavedOffer on Contract {
        savedOffers
      }
    `,
      data: {
        savedOffers: newSavedOffers.map(o => {
          return {
            __ref: `VehicleOffer:{"typeOfMatch":"${o.typeOfMatch}","quote":{"quoteId":"${o.quote.quoteId}"}}`
          };
        })
      }
    });
  }
}

function deleteSavedOfferInCache(
  client: ApolloClient<object>,
  offer: IAllVehicleOfferFieldsFragment,
  contractId: string,
  currentSavedOffers: IAllVehicleOfferFieldsFragment[],
  setRemovedFlagOnly: Boolean = false
) {
  client.writeFragment({
    id: `VehicleOffer:{"typeOfMatch":"${offer.typeOfMatch}","quote":{"quoteId":"${offer.quote.quoteId}"}}`,
    fragment: gqlIgnoredByCodeGen`
      fragment deleteSavedOfferInCache on VehicleOffer {
        wasSaved
        wasSelected
        wasRemoved @client
      }
    `,
    data: {
      wasSaved: false,
      wasSelected: false,
      wasRemoved: true
    }
  });

  if (!setRemovedFlagOnly) {
    const newSavedOffers = [...currentSavedOffers];
    const toBeDeletedSavedOfferIndex = currentSavedOffers.findIndex(
      o => o.quote.quoteId === offer.quote.quoteId
    );
    if (toBeDeletedSavedOfferIndex < 0) return;
    newSavedOffers.splice(toBeDeletedSavedOfferIndex, 1);

    client.writeFragment({
      id: `Contract:{"contractNumber":"${contractId}"}`,
      fragment: gqlIgnoredByCodeGen`
      fragment deleteSavedOfferInCacheSaveOffers on Contract {
        savedOffers
      }
    `,
      data: {
        savedOffers: newSavedOffers.map(o => {
          return {
            __ref: `VehicleOffer:{"typeOfMatch":"${o.typeOfMatch}","quote":{"quoteId":"${o.quote.quoteId}"}}`
          };
        })
      }
    });
  }
}
