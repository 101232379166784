"use strict";
exports.__esModule = true;
exports.fetchUrlParameter = void 0;
function parseQueryString(query) {
    var vars = query.split("&");
    var queryString = {};
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);
        // If first entry with this name
        // @ts-ignore
        if (typeof queryString[key] === "undefined") {
            // @ts-ignore
            queryString[key] = decodeURIComponent(value);
            // If second entry with this name
            // @ts-ignore
        }
        else if (typeof queryString[key] === "string") {
            // @ts-ignore
            var arr = [queryString[key], decodeURIComponent(value)];
            // @ts-ignore
            queryString[key] = arr;
            // If third or later entry with this name
        }
        else {
            // @ts-ignore
            queryString[key].push(decodeURIComponent(value));
        }
    }
    //@ts-ignore
    return queryString;
}
function fetchUrlParameter(key, queryString) {
    var qs = queryString;
    if (!qs)
        return "";
    if (qs.endsWith("/"))
        qs = qs.substring(0, qs.length - 1);
    if (qs.startsWith("?"))
        qs = qs.substring(1);
    if (!qs)
        return "";
    var parameters = parseQueryString(qs);
    // @ts-ignore
    return parameters[key];
}
exports.fetchUrlParameter = fetchUrlParameter;
