import { prepareTranslations } from "@renewal/rosie";
import i18n from "i18next";
import numbro from "numbro";
import { initReactI18next } from "react-i18next";

import resourcesEn from "./i18n.en.json";
import resourcesJp from "./i18n.ja-JP.json";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const languages = require("numbro/dist/languages.min.js");

// -------------- load numbro.js settings --------------------
numbro.registerLanguage(languages["ja-JP"]);
numbro.setLanguage("ja-JP");

// Overwrite numbro's standard symbol for the 'spelled-out' yen.
numbro.languageData().currency.symbol = "円";

// Get brand and translations
const reactBrand = process.env.REACT_APP_BRAND
  ? process.env.REACT_APP_BRAND
  : "";

const translationsEn = prepareTranslations(reactBrand, resourcesEn, "en");
const translationsJp = prepareTranslations(reactBrand, resourcesJp, "ja-JP");

// -------------- init i18next --------------------
i18n.use(initReactI18next).init({
  lng: process.env.REACT_APP_LANG || "en",
  fallbackLng: "en",

  resources: {
    en: translationsEn,
    "ja-JP": translationsJp
  },

  // disable debug in production and test
  // disabling debug in testing gives us less chatter
  debug:
    "production" !== process.env.NODE_ENV && "test" !== process.env.NODE_ENV,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  // handle translation keys that can't be found. if keys has suffix "tooltip",
  // return empty string. otherwise return key. This is to support show/hide
  // tooltip based on its value, at the meantime avoid some weird behaviors like
  // empty validation error message
  parseMissingKeyHandler: key => (key.endsWith("tooltip") ? "" : key),

  keySeparator: ".", // we use hierarchically nested translations

  interpolation: {
    escapeValue: false,
    format: function (value, format) {
      if (format === "currency")
        return value !== null
          ? numbro(value).formatCurrency({
              mantissa: 2,
              thousandSeparated: true,
              optionalMantissa: true,
              currencyPosition: "postfix"
            })
          : "";
      if (format === "percentage")
        return value !== null
          ? numbro(value).format({ output: "percent", mantissa: 2 })
          : "";

      return value;
    }
  }
});

export default i18n;
