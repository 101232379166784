"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.FilterExpanded = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var formik_1 = require("formik");
var __1 = require("../..");
var FilterExpanded = function (_a) {
    var matchMakerAnswerEditComponents = _a.matchMakerAnswerEditComponents, smallIcons = _a.smallIcons;
    var formikProps = formik_1.useFormikContext();
    var shouldDisplayError = function (fieldName) {
        return (Boolean(formikProps.errors[fieldName]) &&
            Boolean(formikProps.touched[fieldName]));
    };
    var anyFieldNameHasError = function (fieldName) {
        if (Array.isArray(fieldName)) {
            return fieldName.some(function (f) { return shouldDisplayError(f); });
        }
        return shouldDisplayError(fieldName);
    };
    return (jsx_runtime_1.jsx("div", __assign({ className: "u-bg-alternative u-ph" }, { children: jsx_runtime_1.jsx(__1.AccordionGroupWrapper, __assign({ className: "u-bg-alternative", allowMultipleOpen: false, itemKeyProp: "itemKeyProp" }, { children: matchMakerAnswerEditComponents.map(function (editComponent, index) { return (jsx_runtime_1.jsx(__1.GroupAccordion, __assign({ trackableComponent: "link", trackingFunction: "LinkExpand" + editComponent.trackingFunction, title: editComponent.title, icon: editComponent.icon, shouldDisplayTitleInErrorState: anyFieldNameHasError(editComponent.fieldName), smallIcons: smallIcons }, { children: jsx_runtime_1.jsx("div", __assign({ className: "o-page-wrap" }, { children: editComponent.editComponent }), void 0) }), index)); }) }), void 0) }), void 0));
};
exports.FilterExpanded = FilterExpanded;
