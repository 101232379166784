import { gql, useQuery } from "@apollo/client";
import { CurrentContractCard } from "@renewal/rosie";
import { LicensePlate } from "@vwfs-its/bronson-react";
import React from "react";
import { useTranslation } from "react-i18next";

import { IGetContractForCurrentContractCardQuery } from "../../../__generated__/graphql-types";
import { useCurrentContractId } from "../../../App.state";
import { loadingOrError } from "../../ui/Loaders/GraphqlLoadingUtility";
import { LearnMoreAboutFinancePlanTrigger } from "../LearnMoreAboutFinancePlan/LearnMoreAboutFinancePlan";

const GET_CONTRACT = gql`
  query getContractForCurrentContractCard($contractNumber: String!) {
    customer {
      customerId
      isRegistered
      contract(contractId: $contractNumber) {
        contractNumber
        contractType
        contractTypeText
        equity
        installmentAmount
        term
        customerEligibility
        currentVehicleValue
        amountToSettle
        carModel
        licensePlate1
        licensePlate2
        licensePlate3
        retailer {
          street
          address
          website
          name
          phoneNumber
          email
          code
        }
      }
    }
  }
`;

interface OwnProps {
  onOpenToolTip?: (keyWord: string) => void;
}

const CurrentContractCardContainer: React.FC<OwnProps> = ({
  onOpenToolTip
}) => {
  const { t } = useTranslation("contract-card-component");

  const { currentContractId } = useCurrentContractId();

  const {
    loading,
    error,
    data = {} as IGetContractForCurrentContractCardQuery
  } = useQuery<IGetContractForCurrentContractCardQuery>(GET_CONTRACT, {
    fetchPolicy: "cache-first",
    variables: { contractNumber: currentContractId }
  });

  if (loading || error) return loadingOrError(loading, error);

  const contract = data.customer.contract;
  return (
    <CurrentContractCard
      licensePlate={
        <LicensePlate
          registrationNumber={contract.licensePlate3}
          horizontalStripJp
          size="small"
          jpRegistrationPrefix={contract.licensePlate2}
          jpPrefectureCode={contract.licensePlate1.split(/([0-9]+)/)[0]}
          jpVehicleCode={contract.licensePlate1.split(/([0-9]+)/)[1]}
          jp
        />
      }
      vehicleModel={contract.carModel ? contract.carModel : ""}
      installmentAmount={
        contract.installmentAmount ? contract.installmentAmount : 0
      }
      terms={contract.term ? contract.term : 0}
      currentVehicleValue={
        contract.currentVehicleValue ? contract.currentVehicleValue : 0
      }
      amountToSettle={contract.amountToSettle ? contract.amountToSettle : 0}
      equity={contract.equity ? contract.equity : 0}
      t={t}
      hasMonthlyPayment={contract.installmentAmount > 0}
      financePlan={contract.contractTypeText}
      onOpenToolTip={onOpenToolTip}
    >
      <LearnMoreAboutFinancePlanTrigger
        linkText={t("shared:learn-more-button")}
        contractType={contract.contractType}
      />
    </CurrentContractCard>
  );
};

export default CurrentContractCardContainer;
