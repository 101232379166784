import {
  FormFieldCheckbox,
  FormFieldDatepicker,
  FormFieldInput,
  LoadingSpinner,
  validateAge
} from "@renewal/rosie";
import { Button } from "@vwfs-its/bronson-react";
import { Formik } from "formik";
import { History } from "history";
import React, { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRequestVerificationMutation } from "../../__generated__/graphql-types";
import { adobeDataLayer } from "../../lib/adobe-analytics-data-layer/AdobeDataLayer";
import { useTrackOnStartAndQuit } from "../../lib/adobe-analytics-data-layer/customHooks";
import { RouterHelpers } from "../../router/router-helpers";
import { PrivacyModal } from "./components/PrivacyModal";
import { TaCModal } from "./components/TaCModal";
import { STEP_ONE_DATA, STEP_ONE_RESPONSE } from "./SessionStorageKeys";

const DATE_FORMAT = "Y/M/D";
const DATE_FORMAT_DATE_PICKER = "Y/m/d";
export const DATE_REGEX_JP = /^\d{4}\/\d{2}\/\d{2}$/;
const PHONE_REGEX_JP = /^0[789]0-?\d{4}-?\d{4}$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("名の入力は必須です"),
  surname: Yup.string().required("姓の入力は必須です"),
  birthDate: Yup.string()
    .required("生年月日の入力は必須です")
    .matches(DATE_REGEX_JP, `生年月日は、西暦/月/日で入力してください`)
    .test("age", "18歳以上の方が対象です", birthDate => {
      if (!birthDate) return false;
      return validateAge(birthDate, DATE_FORMAT);
    })
    .test("maxAge", "入力した生年月日をご確認ください", birthDate => {
      if (!birthDate) return false;
      return validateAge(birthDate, DATE_FORMAT, true);
    }),
  mobilePhone: Yup.string()
    .matches(PHONE_REGEX_JP, "正しい携帯電話番号を入力してください")
    .required("正しい携帯電話番号を入力してください"),
  privacyPolicy: Yup.string().required(
    "プライバシーポリシーをご確認の上、同意するにチェックをお願いします。"
  ),
  termsConditions: Yup.string().required(
    "利用規約をご確認の上、同意するにチェックをお願いします。"
  )
});

export const RegisterForm: React.FC<{ history: History }> = ({ history }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [lastTouched, setLastTouched] = useState("");
  const [init, setInit] = useState(false);
  const { t } = useTranslation("registration-page");

  const [requestVerification] = useRequestVerificationMutation();

  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsAndConditionsOpen, setIsTermsAndConditionsOpen] =
    useState(false);

  useTrackOnStartAndQuit(
    () => {
      adobeDataLayer.registerPage();
      setInit(true);
    },
    async () => await adobeDataLayer.abortRegistration(lastTouched),
    lastTouched === "" && !init,
    "Registration"
  );

  return (
    <Formik
      initialValues={
        process.env.NODE_ENV === "development"
          ? {
              firstName: "二",
              surname: "受入",
              birthDate: "2000/01/02",
              mobilePhone: "070-8112-1612",
              privacyPolicy: "",
              termsConditions: ""
            }
          : {
              firstName: "",
              surname: "",
              birthDate: "",
              mobilePhone: "",
              privacyPolicy: "",
              termsConditions: ""
            }
      }
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { data } = await requestVerification({
            variables: {
              firstName: values.firstName,
              surname: values.surname,
              birthdate: values.birthDate,
              mobilePhoneNumber: values.mobilePhone
            }
          });

          if (data) {
            const res = JSON.parse(data.requestVerification || "");
            const businessCode: string = res.businessCode;
            const message: string = res.message;

            if (businessCode === "200") {
              sessionStorage.setItem(STEP_ONE_DATA, JSON.stringify(values));

              sessionStorage.setItem(
                STEP_ONE_RESPONSE,
                JSON.stringify(message)
              );

              window.onbeforeunload = null;
              window.onpopstate = null;
              history.push(RouterHelpers.getRegisterSMSAuthorizationPagePath());
            } else if (businessCode === "410") {
              setErrorMessage(
                "申し訳ありません。ご入力された電話番号は、SMSメッセージを受け取ることができません。携帯電話の番号をご入力ください。問題が解決されない場合は、お問合せ窓口へお電話ください。"
              );
            } else if (businessCode === "420") {
              setErrorMessage(
                "すでに登録のある情報です。確認の上再度入力をお願いします。"
              );
            } else if (businessCode === "430") {
              setErrorMessage(
                "ディーラーに登録されている携帯電話番号、<br/>生年月日の情報が一致していない可能性があります。<br/>オンラインサービスサポートセンター：0570-783-930<br/>（平日10:00～18：00）までお問合せください"
              );
            } else {
              setErrorMessage(
                "入力された情報を確認することができませんでした。正しい内容を入力してください。"
              );
            }
          } else {
            setErrorMessage(
              "入力された情報を確認することができませんでした。正しい内容を入力してください。"
            );
          }
          setSubmitting(false);
        } catch (e) {
          setErrorMessage(t("shared:connection-error"));
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => (
        <LoadingSpinner
          isLoading={isSubmitting}
          isCenter={true}
          isFullPage={false}
        >
          <form onSubmit={handleSubmit}>
            <FormFieldInput
              labelText={t("family-label")}
              tooltipText={t("family-tooltip")}
              fieldId="surname"
              handleChange={handleChange}
              handleBlur={params => {
                setLastTouched("surname");
                return handleBlur(params);
              }}
              value={values.surname}
              error={errors.surname}
              touched={touched.surname}
              trackingComponent="inputbox"
              trackingFunction="inputboxLastName"
            />
            <FormFieldInput
              labelText={t("given-label")}
              tooltipText={t("given-tooltip")}
              fieldId="firstName"
              handleChange={handleChange}
              handleBlur={params => {
                setLastTouched("firstname");
                return handleBlur(params);
              }}
              value={values.firstName}
              error={errors.firstName}
              touched={touched.firstName}
              trackingComponent="inputbox"
              trackingFunction="inputboxFirstName"
            />
            <FormFieldDatepicker
              labelText={t("dob-label")}
              tooltipText={t("dob-tooltip")}
              fieldId="birthDate"
              placeholder={DATE_FORMAT}
              dateFormat={DATE_FORMAT_DATE_PICKER}
              handleChange={e => {
                setFieldValue("birthDate", e);
                return handleChange(e);
              }}
              handleBlur={e => {
                setLastTouched("birthDate");
                setFieldValue("birthDate", e);
                handleBlur(e);
              }}
              value={values.birthDate}
              error={errors.birthDate}
              touched={touched.birthDate}
              trackingFunction="inputboxDateBirth"
              useMask
            />
            <FormFieldInput
              labelText={t("mobile-phone-label")}
              tooltipText={t("mobile-phone-tooltip")}
              fieldId="mobilePhone"
              handleChange={handleChange}
              handleBlur={params => {
                setLastTouched("mobilePhone");
                return handleBlur(params);
              }}
              value={values.mobilePhone}
              error={errors.mobilePhone}
              touched={touched.mobilePhone}
              trackingComponent="inputbox"
              trackingFunction="inputboxMobilePhoneNumber"
              placeholder="XXX-XXXX-XXXX"
              useMask={[
                /0/,
                /[789]/,
                /[0]/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]}
              maskChar="X"
              fieldType="tel"
            />
            <FormFieldCheckbox
              name="privacyPolicyAccepted"
              tooltipText="プライバシーポリシーをご確認の上、同意するにチェックをお願いします。"
              value={values.privacyPolicy}
              handleBlur={() => {
                setFieldTouched("privacyPolicy", true);
                setLastTouched("privacyPolicy");
              }}
              handleChange={() => {
                if (values.privacyPolicy) {
                  setFieldValue("privacyPolicy", "");
                } else {
                  setFieldValue("privacyPolicy", "1");
                }
              }}
            >
              <Button
                link
                className="u-base-link"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  setIsPrivacyModalOpen(true);
                }}
              >
                プライバシーポリシー
              </Button>
              に同意する
            </FormFieldCheckbox>
            {isPrivacyModalOpen && (
              <PrivacyModal
                isPrivacyModalOpen={isPrivacyModalOpen}
                onClose={() => {
                  setIsPrivacyModalOpen(false);
                }}
              />
            )}
            {errors.privacyPolicy && touched.privacyPolicy && (
              <p className="c-error-message">{errors.privacyPolicy}</p>
            )}
            <FormFieldCheckbox
              name="termsAndConditionsAccepted"
              tooltipText="利用規約をご確認の上、同意するにチェックをお願いします。"
              value={values.termsConditions}
              handleBlur={() => {
                setFieldTouched("termsConditions", true);
                setLastTouched("termsConditions");
              }}
              handleChange={() => {
                if (values.termsConditions) {
                  setFieldValue("termsConditions", "");
                } else {
                  setFieldValue("termsConditions", "1");
                }
              }}
            >
              <Button
                className="u-base-link"
                link
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  setIsTermsAndConditionsOpen(true);
                }}
              >
                利用規約
              </Button>
              に同意する
            </FormFieldCheckbox>
            {isTermsAndConditionsOpen && (
              <TaCModal
                isTermsAndConditionsOpen={isTermsAndConditionsOpen}
                onClose={() => setIsTermsAndConditionsOpen(false)}
              />
            )}
            {errors.termsConditions && touched.termsConditions && (
              <p className="c-error-message">{errors.termsConditions}</p>
            )}
            {errorMessage !== "" && (
              <p
                className="c-error-message"
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              />
            )}
            <div className="c-form-field u-text-center u-mt-xlarge">
              <button className="c-btn" disabled={isSubmitting} type="submit">
                <span className="c-btn__text">確認して続ける</span>
              </button>
            </div>
          </form>
        </LoadingSpinner>
      )}
    </Formik>
  );
};
