"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.LearnMoreEquityModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var react_i18next_1 = require("react-i18next");
var index_1 = require("../index");
var LEARN_MORE_EQUITY_ID = "learn-more-equity";
var LearnMoreEquityModal = function (_a) {
    var linkText = _a.linkText, currentVehicleValue = _a.currentVehicleValue, amountToSettle = _a.amountToSettle, equity = _a.equity, infoParagraphs = _a.infoParagraphs;
    var t = react_i18next_1.useTranslation("match-maker").t;
    // We can not set the default in the deconstruction since we need the t-function
    // to resolve the default translation.
    if (!infoParagraphs) {
        infoParagraphs = [t("results.equity-modal.text")];
    }
    return (jsx_runtime_1.jsx(LearnMoreEquityModalImpl, { buttonText: linkText, modalTitle: t("results.equity-modal.title"), closeBtnHover: t("shared:modal-close-button"), infoParagraphs: infoParagraphs, currentCarValueLabel: t("results.equity-modal.car-value"), currentCarValue: t("shared:currency", {
            value: currentVehicleValue
        }), outstandingFinanceLabel: t("results.equity-modal.outstanding-finance"), outstandingFinance: t("shared:currency", {
            value: amountToSettle
        }), equityLabel: t("results.equity-modal.equity"), equity: t("shared:currency", { value: equity }) }, void 0));
};
exports.LearnMoreEquityModal = LearnMoreEquityModal;
var LearnMoreEquityModalImpl = function (_a) {
    var buttonText = _a.buttonText, modalTitle = _a.modalTitle, closeBtnHover = _a.closeBtnHover, infoParagraphs = _a.infoParagraphs, currentCarValueLabel = _a.currentCarValueLabel, currentCarValue = _a.currentCarValue, outstandingFinanceLabel = _a.outstandingFinanceLabel, outstandingFinance = _a.outstandingFinance, equityLabel = _a.equityLabel, equity = _a.equity, currentCarValueTooltip = _a.currentCarValueTooltip, outstandingFinanceTooltip = _a.outstandingFinanceTooltip, equityTooltip = _a.equityTooltip, finalParagraph = _a.finalParagraph;
    return (jsx_runtime_1.jsx(index_1.ModalWithButton, __assign({ id: LEARN_MORE_EQUITY_ID, buttonText: buttonText, title: modalTitle, closeButtonHoverLabel: closeBtnHover, trackingFunction: "linkLearnMoreEquity" }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "u-text-left" }, { children: [infoParagraphs &&
                    infoParagraphs.map(function (info, i) {
                        return jsx_runtime_1.jsx("p", { children: info }, i);
                    }), jsx_runtime_1.jsx("div", __assign({ className: "o-component-wrapper" }, { children: jsx_runtime_1.jsxs("div", __assign({ className: "c-definition-table" }, { children: [jsx_runtime_1.jsxs("dl", __assign({ className: "c-definition-table__section" }, { children: [jsx_runtime_1.jsxs("dt", __assign({ className: "c-definition-table__item-title" }, { children: [currentCarValueLabel, currentCarValueTooltip && (jsx_runtime_1.jsx(bronson_react_1.InfoIcon, __assign({ icon: "semantic-info", tippyProps: { placement: "top" } }, { children: jsx_runtime_1.jsx("span", { dangerouslySetInnerHTML: {
                                                        __html: currentCarValueTooltip
                                                    } }, void 0) }), void 0))] }), void 0), jsx_runtime_1.jsx("dd", __assign({ className: "c-definition-table__item-data" }, { children: currentCarValue }), void 0), jsx_runtime_1.jsxs("dt", __assign({ className: "c-definition-table__item-title" }, { children: [outstandingFinanceLabel, outstandingFinanceTooltip && (jsx_runtime_1.jsx(bronson_react_1.InfoIcon, __assign({ icon: "semantic-info", tippyProps: { placement: "top" } }, { children: jsx_runtime_1.jsx("span", { dangerouslySetInnerHTML: {
                                                        __html: outstandingFinanceTooltip
                                                    } }, void 0) }), void 0))] }), void 0), jsx_runtime_1.jsx("dd", __assign({ className: "c-definition-table__item-data" }, { children: outstandingFinance }), void 0)] }), void 0), jsx_runtime_1.jsxs("dl", __assign({ className: "c-definition-table__section" }, { children: [jsx_runtime_1.jsxs("dt", __assign({ className: "c-definition-table__item-title" }, { children: [equityLabel, equityTooltip && (jsx_runtime_1.jsx(bronson_react_1.InfoIcon, __assign({ icon: "semantic-info", tippyProps: { placement: "top" } }, { children: jsx_runtime_1.jsx("span", { dangerouslySetInnerHTML: { __html: equityTooltip } }, void 0) }), void 0))] }), void 0), jsx_runtime_1.jsx("dd", __assign({ className: "c-definition-table__item-data" }, { children: equity }), void 0)] }), void 0)] }), void 0) }), void 0), jsx_runtime_1.jsx("div", __assign({ className: "o-component-wrapper" }, { children: finalParagraph && jsx_runtime_1.jsx("p", { children: finalParagraph }, void 0) }), void 0)] }), void 0) }), void 0));
};
