"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.QuoteSVGRing = exports.ArcStyle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var index_1 = require("../../index");
var Switch = __importStar(require("../../utils/asset-switches"));
var CircleCalculator_1 = require("./CircleCalculator");
var ArcStyle;
(function (ArcStyle) {
    ArcStyle["thick"] = "thick";
    ArcStyle["thin"] = "thin";
})(ArcStyle = exports.ArcStyle || (exports.ArcStyle = {}));
// options to tune appearance
function getArcStyle(arcStyle) {
    var thinArc = { innerRadius: 148, ringWidth: 18 };
    var thickArc = { innerRadius: 143, ringWidth: 27 };
    if (arcStyle === ArcStyle.thick)
        return thickArc;
    return thinArc;
}
var QuoteSVGRing = /** @class */ (function (_super) {
    __extends(QuoteSVGRing, _super);
    function QuoteSVGRing() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    QuoteSVGRing.prototype.render = function () {
        var _this = this;
        var _a = this.props, arcData = _a.arcData, selectedClass = _a.selectedClass, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave;
        var imageWidth = 360;
        var vehicleImageWidth = 250;
        var vehicleImageHeight = 95;
        var textBasePosition = 195;
        var imagePosition = {
            x: (imageWidth - vehicleImageWidth) / 2,
            y: 70
        };
        var calculatedArcs = CircleCalculator_1.calculateArcPositions(arcData.map(function (v) { return v.value; }));
        var renderTitle = function () {
            if (_this.props.title2) {
                if (_this.props.title3) {
                    return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx("tspan", __assign({ y: textBasePosition + 15, className: "u-h5", x: imageWidth / 2 }, { children: _this.props.title2 }), void 0), jsx_runtime_1.jsx("tspan", __assign({ y: textBasePosition + 30, className: "u-h5", x: imageWidth / 2 }, { children: _this.props.title3 }), void 0), jsx_runtime_1.jsx("tspan", __assign({ y: textBasePosition + 55, className: "u-text-fs-2", x: imageWidth / 2 }, { children: _this.props.description1 }), void 0)] }, void 0));
                }
                else {
                    return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx("tspan", __assign({ y: textBasePosition + 15, className: "u-h5", x: imageWidth / 2 }, { children: _this.props.title2 }), void 0), jsx_runtime_1.jsx("tspan", __assign({ y: textBasePosition + 45, className: "u-text-fs-2", x: imageWidth / 2 }, { children: _this.props.description1 }), void 0)] }, void 0));
                }
            }
            else {
                return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [" ", jsx_runtime_1.jsx("tspan", __assign({ y: textBasePosition + 30, className: "u-text-fs-2", x: imageWidth / 2 }, { children: _this.props.description1 }), void 0), jsx_runtime_1.jsx("tspan", __assign({ y: textBasePosition + 50, className: "u-text-fs-2", x: imageWidth / 2 }, { children: _this.props.description2 }), void 0)] }, void 0));
            }
        };
        return (jsx_runtime_1.jsx(index_1.SVGContainer, __assign({ height: 360, width: 360, svgClassName: "quote-svg-ring", translateToTheCenter: false }, { children: jsx_runtime_1.jsxs(index_1.Group, __assign({ className: selectedClass }, { children: [jsx_runtime_1.jsx("image", { x: imagePosition.x, y: imagePosition.y, width: vehicleImageWidth, height: vehicleImageHeight, href: this.props.imageUrl
                            ? this.props.imageUrl
                            : Switch.placeholderImg() }, void 0), jsx_runtime_1.jsxs("text", __assign({ textAnchor: "middle" }, { children: [jsx_runtime_1.jsx("tspan", __assign({ y: textBasePosition, className: "u-h5", x: imageWidth / 2 }, { children: this.props.title }), void 0), renderTitle(), jsx_runtime_1.jsx("tspan", __assign({ y: textBasePosition + 75, className: "u-text-fs-2", x: imageWidth / 2 }, { children: this.props.description3 }), void 0), this.props.shouldDisplayFullDetailsLink &&
                                this.props.fullDetailsLinkPath && (jsx_runtime_1.jsx("tspan", __assign({ y: textBasePosition + 100, className: "u-text-fs-2", x: imageWidth / 2 }, { children: jsx_runtime_1.jsx(index_1.TrackableComponent, __assign({ trackingComponent: "link", trackingFunction: "linkFullDetailsFQ" }, { children: jsx_runtime_1.jsxs(react_router_dom_1.Link, __assign({ to: this.props.fullDetailsLinkPath, className: "c-btn c-btn--link u-text-fs-2" }, { children: [jsx_runtime_1.jsx("tspan", { className: "c-btn__icon c-icon c-icon--[semantic-forward] u-text-fs-2", dangerouslySetInnerHTML: {
                                                    __html: Switch.bronsonIconSwitch("&#xe9a4;", "&#xe943;", "&#xe91c;", "&#xe93e;")
                                                } }, void 0), " ", jsx_runtime_1.jsx("tspan", __assign({ className: "c-btn__text" }, { children: this.props.fullDetailsLinkText }), void 0)] }), void 0) }), void 0) }), void 0))] }), void 0), jsx_runtime_1.jsx(index_1.TranslateTransform, __assign({ x: 180, y: 180 }, { children: arcData.map(function (data, index) {
                            return data.value.length === 1 ? (jsx_runtime_1.jsx(index_1.Arc, { outerRadius: getArcStyle(data.style).innerRadius +
                                    getArcStyle(data.style).ringWidth, innerRadius: getArcStyle(data.style).innerRadius, startAngle: calculatedArcs[index][0].start, endAngle: calculatedArcs[index][0].end, className: data.className, "data-group": data.dataGroup, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }, index)) : (jsx_runtime_1.jsxs(react_1["default"].Fragment, { children: [jsx_runtime_1.jsx(index_1.Group, { children: data.value.map(function (_ignored, groupIndex) { return (jsx_runtime_1.jsx(react_1["default"].Fragment, { children: jsx_runtime_1.jsx(index_1.Arc, { outerRadius: getArcStyle(data.style).innerRadius +
                                                    getArcStyle(data.style).ringWidth, innerRadius: getArcStyle(data.style).innerRadius, startAngle: calculatedArcs[index][groupIndex].start, endAngle: calculatedArcs[index][groupIndex].end, className: data.className, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }, void 0) }, groupIndex)); }) }, void 0), jsx_runtime_1.jsx(index_1.Arc, { outerRadius: getArcStyle(data.style).innerRadius +
                                            getArcStyle(data.style).ringWidth, innerRadius: getArcStyle(data.style).innerRadius, startAngle: calculatedArcs[index][0].start, endAngle: calculatedArcs[index][data.value.length - 1].end, className: data.dataGroup + "-transparent", "data-group": data.dataGroup, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }, void 0)] }, index));
                        }) }), void 0)] }), void 0) }), void 0));
    };
    return QuoteSVGRing;
}(react_1.Component));
exports.QuoteSVGRing = QuoteSVGRing;
