import { SelectableTileGroup } from "@renewal/rosie";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { IMatchMakerAnswers } from "../../../__generated__/graphql-types";
import { parkingTypeList } from "../../helpers/vehicle-helpers";

export function ParkingType(): React.ReactElement {
  const { t } = useTranslation("match-maker");
  const { values } = useFormikContext<IMatchMakerAnswers>();

  return (
    <SelectableTileGroup
      itemList={parkingTypeList(t)}
      itemColumnCount={[6, 6, 12]}
      propName="parkingType"
      propObject={values.parkingType || []}
      className="o-layout__item u-8/12 u-1/1@m"
      displayDescription={false}
    />
  );
}
