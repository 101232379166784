"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.UILink = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var bronson_react_1 = require("@vwfs-its/bronson-react");
var UILink = function (_a) {
    var text = _a.text, to = _a.to, _b = _a.icon, icon = _b === void 0 ? "semantic-forward" : _b, _c = _a.useRouteLink, useRouteLink = _c === void 0 ? true : _c, className = _a.className, onClick = _a.onClick, rest = __rest(_a, ["text", "to", "icon", "useRouteLink", "className", "onClick"]);
    return (jsx_runtime_1.jsx(bronson_react_1.Link, __assign({ to: to, href: to, normalLink: !useRouteLink, className: className, icon: icon, onClick: onClick }, rest, { children: text }), void 0));
};
exports.UILink = UILink;
