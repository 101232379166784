import React from "react";
import { RouteComponentProps } from "react-router";

import { RegisterSMSForm } from "./RegisterSMSForm";

type AllProps = RouteComponentProps;

class RegisterSMSAuthorization extends React.Component<AllProps> {
  render(): JSX.Element {
    let phoneNumber = "";
    const stepOneResponse = sessionStorage.getItem("STEP_ONE_VALUES");
    if (stepOneResponse) {
      const response: { mobilePhone: string } = JSON.parse(stepOneResponse);

      phoneNumber = response.mobilePhone.substr(
        response.mobilePhone.length - 4
      );
    }

    return (
      <section className="o-content-section">
        <div className="o-page-wrap o-page-wrap--xsmall">
          <div className="o-component-wrapper u-text-center">
            <header className="c-section-heading c-section-heading--center">
              <h1 className="c-section-heading__title">
                <span className="c-section-heading__title-text">
                  ご本人様確認
                </span>
              </h1>
            </header>
            <p>
              下4桁{phoneNumber}
              の携帯電話に送信されたSMS認証コードを入力してください。お客様の個人情報を特定します。
            </p>
          </div>
          <div className="o-layout o-layout--center">
            <div className="o-layout__item u-3/4">
              <RegisterSMSForm history={this.props.history} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RegisterSMSAuthorization;
