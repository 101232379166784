import { AccordionGroupWrapper, GroupAccordion } from "@renewal/rosie";
import React from "react";

export const FAQAccordion: React.FunctionComponent<{ brand: string }> = ({
  brand
}) => {
  return (
    <>
      <h3>マッチメーカーに関する質問</h3>
      <AccordionGroupWrapper>
        <GroupAccordion title="1. なぜ招待メールが送られてきたのか？">
          <p>
            1-A.
            {brand}
            オーナー様限定で、新しい車にお乗り換えサポートさせていただくための特別なWebサイトをご案内しております。マッチメーカーへお進みいただくと、お客様の現在のお車の価値を確認いただきながら、お客様にベストな
            {brand}とご購入プランをシミュレーションすることが可能です。
          </p>
        </GroupAccordion>

        <GroupAccordion title="2. マッチメーカーとはどんなサービスですか？">
          <p>
            2-A.
            マッチメーカーとは、お客様の好みに合った車を簡単に検索し、ご購入プラン共にシミュレーションできるWebサイトです。お客様の車の好み・予算を回答していただくだけで、AIがお客様にベストマッチした車・ご購入プランをご提案します。
          </p>
        </GroupAccordion>

        <GroupAccordion title="3. マッチメーカーにかかる所要時間と利用方法は？">
          <p>
            3ステップ（質問回答・車両選択・お近くのディーラーに見積依頼する）で、お気に入りの車の検索とお見積りができます。ログインからディーラーへの見積依頼までにかかる時間は約5分です。
          </p>
        </GroupAccordion>

        <GroupAccordion title="4. スマートフォン、タブレットから利用できますか？">
          <p>
            4-A.
            PCだけでなくスマートフォン、各種モバイル機器からもご利用いただけます。
          </p>
        </GroupAccordion>

        <GroupAccordion title="5. なぜログインが必要なのですか？">
          <p>
            5-A.
            マッチメーカーはお客様のご契約情報を反映したサービスを提供します。セキュリティ上、ご本人様確認のためログイン時にメールアドレス・パスワードを入力し、IDを作成の上、ご本人様情報（氏名・生年月日・携帯電話番号）を入力いただき、SMSによる確認を経て認証完了となます。
          </p>
        </GroupAccordion>

        <GroupAccordion title="6.マッチメーカーは一度に全て回答しないといけないでしょうか？見積リクエスト前に一時保存できますか？">
          <p>
            6-A.
            マッチメーカーで選択した条件はお気に入りとして保存できます。また再ログインされた際は前回の途中から選択を再開することができ、選択内容をサマリーよりご確認いただけます。ただし、車両在庫状況や適用金利・キャンペーン内容は数カ月ごとに見直されるため、前回の保存内容から変更となる場合がございます。
          </p>
        </GroupAccordion>

        <GroupAccordion
          title="7. お気に入り保存とはなんですか？
                お気に入りに保存していた車両情報が削除されたのはなぜですか？
                "
        >
          <p>
            7-A.
            車両情報をお気に入りに保存しておくと、次回ログイン時でもスムーズに比較検討いただくことができます。保存されたお気に入り車両情報は、上部メニューバー「お気に入り」よりご確認いただけます。
            保存されていたお気に入りは、在庫が欠品の場合、もしくは保存されていた適用金利等のキャンペーン内容に変更がある場合、期限切れのメッセージを表示の上、その後自動的に削除されます。キャンペーン終了期日までの日数もご案内しておりますので、期日までにディーラーに見積依頼をしていただきますようお願い致します。
          </p>
        </GroupAccordion>

        <GroupAccordion title="8.ディーラーに直接相談したいのですが？">
          <p>
            8-A.
            マッチメーカーを完了いただいた後、直接ディーラーでお車・お見積りについてご相談していただけます。マッチメーカーの最後に正式な見積のリクエストを行うことができます。リクエスト完了後、ディーラーでお客様情報を確認の上、アポイント日時のご相談をディーラーよりお客様ご希望の連絡先にさせていただきますので、ご希望のご連絡日時・連絡先を見積リクエスト時にご記入ください。
          </p>
        </GroupAccordion>

        <GroupAccordion title="9.希望する車両は何台までリクエスト送信できますか？">
          <p>
            9-A.
            現時点では、お客様お一人につき、1台のみリクエスト送信が可能です。もし複数のモデルをご希望の場合は、担当ディーラーに希望される車両についてご相談ください。
          </p>
        </GroupAccordion>
      </AccordionGroupWrapper>

      <h3 className="u-mt-large">ログインに関する質問</h3>
      <AccordionGroupWrapper>
        <GroupAccordion title="1. 会員登録したのに、メールが届きません。">
          <p>
            1-A.
            お客様のメール受信設定によって、メールを受け取ることができない状態になっている可能性がございますので、改めてメール受信設定をご確認ください。
            <br></br>
            また、メール受信設定に問題がない場合は、ご入力いただいたメールアドレスに誤りがある可能性がございます。お手数ですが、再度メールアドレス・パスワード登録を行ってくださいますようお願いいたします。
          </p>
        </GroupAccordion>
        <GroupAccordion title="2. 利用解除はできますか？">
          <p>
            2-A.
            マッチメーカー上での利用解除はできませんので、カスタマーポータルにログイン後、「お客様情報」から利用解除のお申込みを行ってください。マッチメーカーの利用解除と同時にカスタマーポータルも利用解除となります。
          </p>
        </GroupAccordion>
        <GroupAccordion title="3．登録したはずのID・パスワードでログインできません">
          <p>
            3-A.
            入力に誤りがある恐れがあります。再度試してもログインできない場合は、ログインページの「パスワードを忘れた方はこちら」からパスワードの再設定を行ってください。
          </p>
        </GroupAccordion>
      </AccordionGroupWrapper>

      <h3 className="u-mt-large">現在のご契約内容に関する質問</h3>

      <AccordionGroupWrapper>
        <GroupAccordion title="1. 契約満了までまだ期間がありますが、今乗り換えした方がいいのはなぜですか？">
          <p>
            1-A.
            お客様の現契約で保有されているお車の価値をビックデータを元に試算し、その金額から現契約の残りのお支払い金額を差し引いた上で、お乗り換えにベストタイミングをご提案させていただいております。まずはお気軽にマッチメーカーでお好みのお車でのシミュレーションをお勧めします。
          </p>
          <p>
            *マッチメーカーで表示された査定額と販売店で提示する査定額が異なる・変更となる場合がございますので予めご了承ください。
          </p>
        </GroupAccordion>

        <GroupAccordion title="2．「査定額-残債額」とは何ですか？">
          <p>
            2-A.
            「査定額-残債額」とは中古車市場価格から現契約の残りのお支払い金額を差し引いた金額のこと指します。当金額は、お乗り換えのお車を購入する際の頭金等の車両購入代金の一部として充当していただくことも可能です。
          </p>
        </GroupAccordion>

        <GroupAccordion title="3．中古車市場価格はどのように計算されていますか？">
          <p>
            3-A.
            マッチメーカー上で表示される市場価格はお客様が保有されているお車の年式・モデル・正規ディーラー入庫時の走行距離等々を加味し試算された価格です。お車の傷や事故等の条件は加味しておりませんので、実際の査定額とは異なりますので予めご了承ください。下取価格は担当ディーラーにてご案内させていただきますので、ショールームにお越しください。
            <br></br>
            また下取り価格を保証するものではございませんので、参考値としてご理解くださいますようお願いいたします。
          </p>
        </GroupAccordion>

        <GroupAccordion title="4. 「査定額-残債額」が表示されないのはなぜですか？">
          <p>
            4-A.
            お客様の所有されている車種によっては、お車の市場価格をが表示できない場合がございます。その際は担当ディーラーにお越しいただき、お車の査定をご依頼ください。
          </p>
        </GroupAccordion>

        {brand === "Audi" ? (
          <GroupAccordion title="5. ご購入プランは Audi Future DriveとSローン以外も選択できますか？">
            <p>
              5-A. 現在マッチメーカーより選択していただけるご購入プランは、①
              {brand}
              Future
              Drive、②Sローンのみとなります。その他プランをご希望される場合は、一度どちらかのプランをご選択の上、見積リクエストを実施いただき、担当ディーラーより連絡ありましたら他プランをご要望ください。他ご購入プランを詳しく知りたい場合はWebサイトをご確認ください→
              <a href="https://corp.vfj.co.jp/audif/services/">
                https://corp.vfj.co.jp/audif/services/
              </a>
            </p>
          </GroupAccordion>
        ) : (
          <GroupAccordion
            title={`5. ご購入プランはソリューションズ以外も選択できますか?`}
          >
            <p>
              5-A.
              現在マッチメーカーより選択していただけるご購入プランは、ソリューションズのみとなります。その他プランをご希望される場合は、一度ソリューションズをご選択の上、見積リクエストを実施いただき、担当ディーラーより連絡ありましたら他プランをご要望ください。他ご購入プランを詳しく知りたい場合はWebサイトをご確認ください
              <a href="https://corp.vfj.co.jp/vwf/financialplans/autoleaseownersplan.html">
                https://corp.vfj.co.jp/vwf/financialplans/autoleaseownersplan.html
              </a>
            </p>
          </GroupAccordion>
        )}

        <GroupAccordion title="6.見積リクエストをした車両は確約されますか？">
          <p>
            6-A.見積リクエスト選択された車両の在庫は確約ではありません。ご希望の車両の在庫状況については、担当ディーラーよりご案内いたします。
          </p>
        </GroupAccordion>

        <GroupAccordion title="7. 選択した車両の試乗・購入はできますか？">
          <p>
            7-A.見積リクエスト後2営業日以内に、担当ディーラーよりにご連絡いたします。試乗・購入をご希望の際はディーラーから連絡が参りましたら、その際にお申し出ください。
          </p>
        </GroupAccordion>
        <hr />
        <p>
          <b>
            その他ご質問に関しては、各種お問い合わせ先までご連絡ください。
            <br />
            ①マッチメーカーに関するお問い合わせはこちら　→お問合せ窓口:0570-783-930（平日10:00～18:00）
            <br />
            ②ご契約中のローン・リースに関するお問い合わせはこちら　→
          </b>
          <a href="https://www.vfj.co.jp/contact.html">
            https://www.vfj.co.jp/contact.html
          </a>
        </p>
      </AccordionGroupWrapper>
    </>
  );
};
