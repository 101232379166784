import {
  FilterWidget,
  MatchMakerAnswerEditComponent,
  bronsonIconSwitch,
  reactNodeSwitch
} from "@renewal/rosie";
import { TFunction } from "i18next";
import React from "react";

import {
  ICarSearchType,
  IContract,
  IMatchMakerAnswers
} from "../../../../__generated__/graphql-types";
import { AdditionalDeposit } from "../../../../lib/components/MatchMakerAnswersEditComponents/AdditionalDeposit";
import { BonusPayment } from "../../../../lib/components/MatchMakerAnswersEditComponents/BonusPayment";
import { CarType } from "../../../../lib/components/MatchMakerAnswersEditComponents/CarType";
import { ColorOfCar } from "../../../../lib/components/MatchMakerAnswersEditComponents/ColorOfCar";
import { DrivingStyle } from "../../../../lib/components/MatchMakerAnswersEditComponents/DrivingStyle";
import { ExpectedDuration } from "../../../../lib/components/MatchMakerAnswersEditComponents/ExpectedDuration";
import { ExpectedMonthlyPayment } from "../../../../lib/components/MatchMakerAnswersEditComponents/ExpectedMonthlyPayment";
import { FinancePlan } from "../../../../lib/components/MatchMakerAnswersEditComponents/FinancePlan";
import { FinancePlanVW } from "../../../../lib/components/MatchMakerAnswersEditComponents/FinancePlanVW";
import { FuelType } from "../../../../lib/components/MatchMakerAnswersEditComponents/FuelType";
import { ParkingType } from "../../../../lib/components/MatchMakerAnswersEditComponents/ParkingType";
import { TypeOfTrain } from "../../../../lib/components/MatchMakerAnswersEditComponents/TypeOfTrain";
import { getAllFieldValidators } from "../../../../lib/helpers/match-maker-validator";
import { genericValidator } from "../../../MatchMaker/MatchMakerHelper";

interface OwnProps {
  matchMakerAnswers: IMatchMakerAnswers;
  numberOfMatchedVehicles: number;
  contract: IContract;
  onFilterOptionChanged: (values: IMatchMakerAnswers) => Promise<void>;
  onSortingOptionChanged: (newValue: string) => Promise<void>;
  onEditPreferences?: () => void;
  t: TFunction;
}

type AllProps = OwnProps;

const FilterWidgetContainer: React.FC<AllProps> = ({
  matchMakerAnswers,
  contract,
  numberOfMatchedVehicles,
  onFilterOptionChanged,
  onSortingOptionChanged,
  onEditPreferences,
  t
}) => {
  const matchMakerAnswerEditComponents: MatchMakerAnswerEditComponent[] = [
    {
      title: t("filter-bodystyle"),
      icon: "semantic-car",
      fieldName: ["typeOfCar", "modelOfCar"],
      editComponent: <CarType />,
      trackingFunction: "CarType"
    },
    {
      title: t("filter-color"),
      icon: "semantic-car-side",
      fieldName: "colorOfCar",
      editComponent: <ColorOfCar />,
      trackingFunction: "ColorOfCar"
    },
    {
      title: t("filter-drivetrain"),
      icon: bronsonIconSwitch("quattro", "four-wheel-drive"),
      fieldName: "typeOfTrain",
      editComponent: <TypeOfTrain />,
      trackingFunction: "DriveTrain"
    },
    {
      title: t("filter-width"),
      icon: bronsonIconSwitch("parking", "semantic-download"),
      fieldName: "parkingType",
      editComponent: <ParkingType />,
      trackingFunction: "ParkingType"
    },
    {
      title: t("filter-fuel"),
      icon: "semantic-fuel",
      fieldName: "fuelTypes",
      editComponent: <FuelType />,
      trackingFunction: "FuelType"
    },
    {
      title: t("filter-driving-style"),
      icon: "semantic-performance",
      fieldName: "importantAspects",
      editComponent: <DrivingStyle />,
      trackingFunction: "DrivingStyle"
    },
    {
      title: t("filter-budget"),
      icon: "semantic-calendar",
      fieldName: "expectedMonthlyPayment",
      editComponent: (
        <ExpectedMonthlyPayment
          installmentAmount={contract.installmentAmount}
        />
      ),
      trackingFunction: "MonthlyPayment"
    },
    {
      title: t("filter-additional-deposit"),
      icon: "semantic-fs-payment",
      fieldName: "additionalDeposit",
      editComponent: (
        <AdditionalDeposit installmentAmount={contract.installmentAmount} />
      ),
      trackingFunction: "AdditionalDeposit"
    },
    {
      title: t("filter-bonus-payment"),
      icon: "semantic-plus",
      fieldName: "bonusPayment",
      editComponent: <BonusPayment />,
      trackingFunction: "BonusPayment"
    },
    {
      title: t("filter-duration"),
      icon: "semantic-clock",
      fieldName: "expectedDuration",
      editComponent: <ExpectedDuration />,
      trackingFunction: "Duration"
    },
    {
      title: t("filter-finance-plan"),
      icon: "brochure",
      fieldName: "financePlan",
      editComponent: reactNodeSwitch(<FinancePlan />, <FinancePlanVW />),
      trackingFunction: "FinancePlan"
    }
  ];

  return (
    <FilterWidget
      matchMakerAnswerEditComponents={matchMakerAnswerEditComponents}
      numberOfMatchedVehicles={numberOfMatchedVehicles}
      matchMakerAnswers={{
        ...matchMakerAnswers,
        sortingOption: matchMakerAnswers.sortingOption
          ? matchMakerAnswers.sortingOption
          : "Ranking"
      }}
      onFilterOptionChanged={values => onFilterOptionChanged(values)}
      onSortingOptionChanged={onSortingOptionChanged}
      validate={values => {
        return genericValidator(
          getAllFieldValidators(
            t,
            values.carSearchType || ICarSearchType.BYMODEL
          ),
          values
        );
      }}
      t={t}
      onEditPreferences={onEditPreferences}
      smallIcons={process.env.REACT_APP_BRAND === "audi"}
    ></FilterWidget>
  );
};

export default FilterWidgetContainer;
