import { FormFieldNumberInput } from "@renewal/rosie";
import React from "react";
import { useTranslation } from "react-i18next";

interface OwnProps {
  installmentAmount: number;
}

export const AdditionalDeposit: React.FC<OwnProps> = ({
  installmentAmount
}) => {
  const { t } = useTranslation("match-maker");

  return (
    <FormFieldNumberInput
      fieldName="additionalDeposit"
      placeholder={t(
        "question-pages.items.additional-deposit.input-field.placeholder"
      )}
      max={(installmentAmount ? installmentAmount : 0) * 2}
      addon={t("question-pages.items.additional-deposit.input-field.addon")}
      info={t("question-pages.items.additional-deposit.validation.optional")}
      useNumberFormatting
    />
  );
};
