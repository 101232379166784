import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  ServerError,
  from,
  gql
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { LoadingSpinner } from "@renewal/rosie";
import { createHashHistory } from "history";
import objectFitImages from "object-fit-images";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";

import { isAuthenticatedState, isAuthorizedState } from "./App.state";
import { ContractSelectionContainer } from "./modules/ContractSelection/ContractSelectionContainer";
import ErrorPage499 from "./pages/ErrorPages/499/ErrorPage499Container";
import ErrorPage401 from "./pages/ErrorPages/ErrorPage401";
import ErrorPage403 from "./pages/ErrorPages/ErrorPage403";
import ErrorPage404 from "./pages/ErrorPages/ErrorPage404";
import SessionExpiredPage from "./pages/ErrorPages/SessionExpired";
import LandingPage from "./pages/LandingPage/LandingPage";
import FAQ from "./pages/LegalPages/FAQ";
import MasterPage from "./pages/MasterPage";
import PublicOutline from "./pages/PublicOutline";
import Register from "./pages/Register/Register";
import RegisterSMSAuthorization from "./pages/Register/RegisterSMSAuthorization";
import EntryPoint from "./router/EntryPoint";
import ProtectedRouteContainer from "./router/ProtectedRouteContainer";
import { RouterHelpers } from "./router/router-helpers";

const history = createHashHistory();

const httpLink = new HttpLink({
  uri: "/graphql",
  headers: {
    "x-brand-id": process.env.REACT_APP_BRAND || "",
    "x-language": process.env.REACT_APP_LANG || "",
    "x-api-key":
      window.APPSYNC_API_KEY || process.env.REACT_APP_APPSYNC_API_KEY || ""
  },
  fetchOptions: {}
});

const authStateUpdateLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    // this method will get called on every success graphql calls (200 HTTP
    // Response). so we set isLoggedInState to `true` here
    if (
      operation.operationName !== "requestVerification" &&
      operation.operationName !== "verifyAccount" &&
      operation.operationName !== "getUserPassedKYC" &&
      isAuthenticatedState() === false
    ) {
      isAuthenticatedState(true);
      isAuthorizedState(true);
    }
    return response;
  });
});

const logoutLink = onError(({ networkError, graphQLErrors }) => {
  if (
    // @ts-ignore networkError does have statusCode
    networkError?.statusCode === 401 &&
    // Adding the following condition to avoid redirecting to error page due to
    // the graphql Error of IntrospectionQuery which is fired on Landing page
    // even there is no graphql query happens
    history.location.pathname !== RouterHelpers.getLandingPagePath()
  ) {
    isAuthenticatedState(false);
    const errorMessage = networkError
      ? (networkError as ServerError).result.errors[0].message
      : "";
    if (errorMessage.includes("Token")) {
      history.push(RouterHelpers.getSessionExpiredPage());
    } else {
      history.push(RouterHelpers.getErrorPage401Path());
    }
  }

  if (graphQLErrors && graphQLErrors.length) {
    //@ts-ignore, the GraphQLError does have `errorType` on it
    if (graphQLErrors.some(e => e.errorType === "Unauthorized")) {
      isAuthenticatedState(true);
      isAuthorizedState(false);
      // only redirect to register page if not done already (without it would rerender the error
      // or register page multiple times on load)
      if (
        history.location.pathname !== RouterHelpers.getErrorPage401Path() &&
        history.location.pathname !== RouterHelpers.getRegisterPagePath()
      ) {
        history.push(RouterHelpers.getRegisterPagePath());
      }
    }
  }
});

const typeDefs = gql`
  extend type VehicleOffer {
    wasRemoved: Boolean!
  }
`;

export const cache = (): InMemoryCache =>
  new InMemoryCache({
    typePolicies: {
      VehicleOffer: {
        // Note: We need typeOfMatch since the getSavedOffers api call still does not support
        // the match level resulting in "No Match" for saved offers on the result page
        keyFields: ["typeOfMatch", "quote", ["quoteId"]],
        fields: {
          wasRemoved: {
            read(value = false) {
              return value;
            }
          }
        }
      },

      Customer: { keyFields: ["__typename"] },
      MatchMakerAnswers: { keyFields: ["__typename"] },
      Contract: { keyFields: ["contractNumber"] },
      Query: {
        fields: {
          dealer(_, { args, toReference }) {
            return toReference({
              __typename: "Dealer",
              id: args?.dealerId
            });
          }
        }
      }
    },
    addTypename: true
  });

const client = new ApolloClient({
  uri: "/graphql",
  typeDefs: typeDefs,
  link: from([authStateUpdateLink, logoutLink, httpLink]),
  cache: cache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first"
    }
  }
});

function App(): JSX.Element {
  objectFitImages(null, { watchMQ: true });
  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <LastLocationProvider>
          <MasterPage>
            <Switch>
              <ProtectedRouteContainer
                path={RouterHelpers.getEntryPointPath()}
                component={EntryPoint}
              />
              <Route
                path={RouterHelpers.getErrorPage401Path()}
                component={ErrorPage401}
              />
              <Route
                path={RouterHelpers.getErrorPage403Path()}
                component={ErrorPage403}
              />
              <Route
                path={RouterHelpers.getErrorPageNotEligiblePath()}
                component={ErrorPage499}
              />
              <Route
                path={RouterHelpers.getSessionExpiredPage()}
                component={SessionExpiredPage}
              />
              <Route
                exact
                path={RouterHelpers.getLandingPagePath()}
                component={LandingPage}
              />
              <Route
                path={RouterHelpers.getContractSelectionPath()}
                component={ContractSelectionContainer}
              />
              <Route exact path={RouterHelpers.getFAQPath()} component={FAQ} />
              <ProtectedRouteContainer
                path={RouterHelpers.getPublicOutlinePath()}
                component={PublicOutline}
              />
              <ProtectedRouteContainer
                path={RouterHelpers.getRegisterPagePath()}
                component={Register}
              />
              <ProtectedRouteContainer
                path={RouterHelpers.getRegisterSMSAuthorizationPagePath()}
                component={RegisterSMSAuthorization}
              />
              <Route exact path={RouterHelpers.getLogoutPath()}>
                <LoadingSpinner isLoading isCenter isFullPage>
                  <></>
                </LoadingSpinner>
              </Route>
              <Route component={ErrorPage404} />
            </Switch>
          </MasterPage>
        </LastLocationProvider>
      </Router>
    </ApolloProvider>
  );
}

export default App;
