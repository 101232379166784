"use strict";
exports.__esModule = true;
exports.DATE_REGEX_EU = exports.convertStringToDate = exports.getExpirationInDaysWithTimeInString = exports.getExpirationInDays = void 0;
function getExpirationInDays(expirationTime) {
    if (!expirationTime)
        return 0;
    var now = new Date();
    var diff = Math.floor((Date.UTC(expirationTime.getFullYear(), expirationTime.getMonth(), expirationTime.getDate()) -
        Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())) /
        (1000 * 60 * 60 * 24));
    return diff;
}
exports.getExpirationInDays = getExpirationInDays;
function getExpirationInDaysWithTimeInString(expirationTimeString) {
    if (!expirationTimeString)
        return 0;
    var expirationTime = convertStringToDate(expirationTimeString);
    var now = new Date();
    var diff = Math.floor((Date.UTC(expirationTime.getFullYear(), expirationTime.getMonth(), expirationTime.getDate()) -
        Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())) /
        (1000 * 60 * 60 * 24));
    return diff;
}
exports.getExpirationInDaysWithTimeInString = getExpirationInDaysWithTimeInString;
function convertStringToDate(dateString) {
    var dateParts = dateString.split("/");
    return new Date(+dateParts[2], 
    // Doesn't get recognized as a number
    // @ts-ignore
    dateParts[1] - 1, +dateParts[0]);
}
exports.convertStringToDate = convertStringToDate;
exports.DATE_REGEX_EU = /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
