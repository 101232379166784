"use strict";
exports.__esModule = true;
exports.LegendElements = exports.LegendElement = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var QuoteRightSideRow_1 = require("./QuoteRightSideRow");
var LegendElement;
(function (LegendElement) {
    LegendElement[LegendElement["initialDeposit"] = 0] = "initialDeposit";
    LegendElement[LegendElement["brandContribution"] = 1] = "brandContribution";
    LegendElement[LegendElement["additionalDeposit"] = 2] = "additionalDeposit";
    LegendElement[LegendElement["monthlyPayments"] = 3] = "monthlyPayments";
    LegendElement[LegendElement["bonusPayment"] = 4] = "bonusPayment";
    LegendElement[LegendElement["finalPayment"] = 5] = "finalPayment";
})(LegendElement = exports.LegendElement || (exports.LegendElement = {}));
function LegendElements(t, idx, equity, finalPayment, numberOfMonthlyPayments, installment, brandContribution, additionalDeposit, hasVehicleOverrideValue, bonusPayment, onOpenToolTip) {
    var additionalDepositText = function () {
        if (hasVehicleOverrideValue !== undefined) {
            return hasVehicleOverrideValue
                ? t("quote-additional-deposit-label")
                : t("quote-your-deposit-label");
        }
        if (equity <= 0 &&
            (brandContribution === undefined || brandContribution <= 0)) {
            return t("quote-total-deposit-label");
        }
        return t("quote-additional-deposit-label");
    };
    return [
        {
            element: LegendElement.initialDeposit,
            node: (jsx_runtime_1.jsx(QuoteRightSideRow_1.QuoteRightSideRow, { type: "initial-deposit", text: t("quote-initial-deposit-label"), amount: t("shared:currency", {
                    value: equity
                }), tooltip: t("quote-initial-deposit-tooltip"), trackingFunction: "tooltipInitialDepositCovered", hidden: equity <= 0, onOpenToolTip: function () {
                    return onOpenToolTip && onOpenToolTip("Initial deposit");
                } }, idx))
        },
        {
            element: LegendElement.brandContribution,
            node: brandContribution ? (jsx_runtime_1.jsx(QuoteRightSideRow_1.QuoteRightSideRow, { type: "brand-contribution", text: t("quote-brand-contribution-label"), amount: t("shared:currency", {
                    value: brandContribution
                }), tooltip: t("quote-brand-contribution-tooltip"), trackingFunction: "tooltipBrandContribution", hidden: brandContribution < 0, onOpenToolTip: function () {
                    return onOpenToolTip && onOpenToolTip("Brand contribution");
                } }, idx)) : null
        },
        {
            element: LegendElement.additionalDeposit,
            node: additionalDeposit ? (jsx_runtime_1.jsx(QuoteRightSideRow_1.QuoteRightSideRow, { type: "additional-deposit", text: additionalDepositText(), amount: t("shared:currency", {
                    value: additionalDeposit
                }), tooltip: t("quote-additional-deposit-tooltip"), trackingFunction: "tooltipAdditionalDeposit", onOpenToolTip: function () {
                    return onOpenToolTip && onOpenToolTip("Additional deposit");
                } }, idx)) : null
        },
        {
            element: LegendElement.monthlyPayments,
            node: (jsx_runtime_1.jsx(QuoteRightSideRow_1.QuoteRightSideRow, { type: "monthly-payment", text: t("quote-payments-label", {
                    count: numberOfMonthlyPayments
                }), amount: t("shared:currency", {
                    value: installment
                }), tooltip: t("quote-payments-tooltip"), trackingFunction: "tooltipMonthlyPayments", onOpenToolTip: function () {
                    return onOpenToolTip && onOpenToolTip("Monthly payment");
                } }, idx))
        },
        {
            element: LegendElement.bonusPayment,
            node: bonusPayment ? (jsx_runtime_1.jsx(QuoteRightSideRow_1.QuoteRightSideRow, { type: "bonus-payment", text: t("quote-bonus-payment-label"), amount: t("shared:currency", {
                    value: bonusPayment
                }), tooltip: t("quote-bonus-payment-tooltip"), trackingFunction: "tooltipBonusPayment", onOpenToolTip: function () { return onOpenToolTip && onOpenToolTip("Bonus payment"); } }, idx)) : null
        },
        {
            element: LegendElement.finalPayment,
            node: (jsx_runtime_1.jsx(QuoteRightSideRow_1.QuoteRightSideRow, { type: "optional-final-payment", text: t("quote-final-payment-label"), amount: t("shared:currency", {
                    value: finalPayment
                }), tooltip: t("quote-final-payment-tooltip"), trackingFunction: "tooltipOptFinalPayment", onOpenToolTip: function () {
                    return onOpenToolTip && onOpenToolTip("Optional final payment");
                } }, idx))
        }
    ];
}
exports.LegendElements = LegendElements;
