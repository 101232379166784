"use strict";
exports.__esModule = true;
exports.parseNumberFromDisplayFormat = exports.numberToDisplayFormat = void 0;
var numberToDisplayFormat = function (value) {
    var number = value || value === 0 ? value.toString().replace(/[^0-9]/g, "") : null;
    if (number) {
        number = Intl.NumberFormat(process.env.REACT_APP_LANG, {
            style: "decimal"
        }).format(Number.parseInt(number));
        return number;
    }
    return value;
};
exports.numberToDisplayFormat = numberToDisplayFormat;
function parseNumberFromDisplayFormat(value) {
    if (value == null || value === "") {
        // this should handle null, undefined and empty string
        return null;
    }
    return Number.parseInt(value.toString().replace(/[^0-9]/g, ""));
}
exports.parseNumberFromDisplayFormat = parseNumberFromDisplayFormat;
