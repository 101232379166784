"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.Circle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Circle = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b;
    return (jsx_runtime_1.jsxs("span", __assign({ className: className + " dr-finance-quote-legend-bullets" }, { children: ["\u25CF", " "] }), void 0));
};
exports.Circle = Circle;
