import { useEffect } from "react";

export const useTrackOnStartAndQuit = (
  trackingFunctionStart: () => void,
  trackingFunctionQuit: () => Promise<void>,
  shouldTrackOnStart: boolean,
  pageName: string
): void => {
  useEffect(() => {
    if (shouldTrackOnStart) {
      trackingFunctionStart();
    }
    window.onbeforeunload = async () => await trackingFunctionQuit();
    window.onpopstate = async (
      state: PopStateEvent & { target: { pageName: string } }
    ) => {
      if (state.target.pageName === pageName) {
        await trackingFunctionQuit();
      }
    };

    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
    };
  }, [
    pageName,
    shouldTrackOnStart,
    trackingFunctionQuit,
    trackingFunctionStart
  ]);
};
